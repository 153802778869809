import { createAction } from "@reduxjs/toolkit";
import {
    SendClientCommandPayload,
    FetchClientCommandsSuccessPayload,
    UpdateCenterCodePayload,
    UpdateCenterCodeSuccessPayload,
    UpdateClientTelephoneNumberPayload,
    UpdateClientTelephoneNumberSuccessPayload,
    SortCommandsPayload,
} from "./centers-types";

export const FetchClientCommandsAction = createAction<void>("FETCH_CLIENT_COMMANDS");
export const FetchClientCommandsSuccessAction = createAction<FetchClientCommandsSuccessPayload>(
    "FETCH_CLIENT_COMMANDS_SUCCESS",
);
export const FetchClientCommandsFailureAction = createAction<void>("FETCH_CLIENT_COMMANDS_FAILURE");

export const SendClientCommandAction = createAction<SendClientCommandPayload>("SEND_CLIENT_COMMAND");
export const SendClientCommandSuccessAction = createAction<void>("SEND_CLIENT_COMMAND_SUCCESS");
export const SendClientCommandFailureAction = createAction<void>("SEND_CLIENT_COMMAND_FAILURE");

export const UpdateClientTelephoneNumberAction = createAction<UpdateClientTelephoneNumberPayload>(
    "UPDATE_CLIENT_TELEPHONE_NUMBER",
);
export const UpdateClientTelephoneNumberSuccessAction = createAction<UpdateClientTelephoneNumberSuccessPayload>(
    "UPDATE_CLIENT_TELEPHONE_NUMBER_SUCCESS",
);
export const UpdateClientTelephoneNumberFailureAction = createAction<void>("UPDATE_CLIENT_TELEPHONE_NUMBER_FAILURE");

export const OpenEditCenterCodeAction = createAction<void>("EDIT_CENTER_CODE_ON");
export const UpdateCenterCodeAction = createAction<UpdateCenterCodePayload>("UPDATE_CENTER_CODE");
export const UpdateCenterCodeSuccessAction = createAction<UpdateCenterCodeSuccessPayload>("UPDATE_CENTER_CODE_SUCCESS");
export const UpdateCenterCodeFailureAction = createAction<void>("UPDATE_CENTER_CODE_FAILURE");

export const SortCommandsAction = createAction<SortCommandsPayload>("SORT_COMMANDS");
