import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { Table, TableHead, TableRow, TableHeadCell, TableBody, TableCell } from "../../components/Table";
import FloatButtonsRight from "../../components/FloatButtonsRight";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MeterAlarmHistoryEvent } from "../../redux/smoke-alarms/smoke-alarms-types";
import Sorter from "../../components/Sorter";
import {
    FetchAlarmsHistoryAction,
    SmokeAlarmsHistoryPaginationItemsPerPageChangeAction,
    SmokeAlarmsHistoryPaginationPageChangeAction,
    SortSmokeAlarmsAction,
} from "../../redux/smoke-alarms/smoke-alarms-actions";
import Pagination from "../../components/common/pagination";
import useSortedAndFiltered from "../../utils/sortAndFilter";
import { ViewContainer } from "../../components/ViewContainer";
import { table } from "../../theme";
import { displayDate, convertDateToLocalDateString } from "../../utils/utils";
import { AlarmType } from "../../redux/companies/companies-types";
import { asValidLanguage } from "../../components/common/language-selector/LanguageSelector";
import { DownloadReportAction } from "../../redux/reports/reports-actions";
import HistoryDateRangeSelectionTable from "./HistoryDateRangeSelectionTable";

const getSortFunction = (sortByField: string) => {
    switch (sortByField) {
        case "apartmentName":
            return (event: MeterAlarmHistoryEvent) => event.apartmentName;
        case "alarmEnabledDate":
            return (event: MeterAlarmHistoryEvent) => event.alarmEnabledDate;
    }
};

const searchData = (alarm: MeterAlarmHistoryEvent) => [alarm.alarmEnabledDate ?? ""];

const SmokeAlarmsHistory = ({
    alarmHistoryEvents,
    sortByField,
    isAscending,
    alarmTypes,
}: Readonly<{
    alarmHistoryEvents: MeterAlarmHistoryEvent[];
    sortByField: string;
    isAscending: boolean;
    alarmTypes: AlarmType[];
}>): JSX.Element => {
    const t = useTranslation().t;
    const params = useParams();
    const dispatch = useDispatch();
    const language = useTranslation().i18n.language;
    const navigate = useNavigate();
    const location = useLocation();

    const [from, setFrom] = useState<Date | null>(new Date());
    const [until, setUntil] = useState<Date | null>(new Date());
    const [loaded, setLoaded] = useState<boolean>(false);
    const [isSortingQueryChanged, setIsSortingQueryChanged] = useState(false);
    let search = "";

    const companyName = useSelector((state: RootState) => state.hydrolink.companies.companies).find(
        (c) => c.id.toString() === params.companyId,
    )?.name;

    const pagination = useSelector((state: RootState) => state.hydrolink.smokeAlarms.pagination);

    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const pageOnUrl = Number(queryParams.get("pageQuery")) || 1;
    const sortKeyOnUrl = queryParams.get("sortByQuery") ?? "default";
    const orderKeyOnUrl = queryParams.get("orderByQuery") ?? "asc";

    const invalidDateRange = (from?.getTime() ?? 0) > (until?.getTime() ?? 0);

    const historyBlockTitle = t("smokeAlarms.history");

    const sortedAndFiltered = useSortedAndFiltered(
        alarmHistoryEvents,
        getSortFunction(sortByField),
        isAscending,
        search,
        searchData,
        pagination,
    );

    useEffect(() => {
        if (pageOnUrl !== pagination.currentPage) {
            dispatch(SmokeAlarmsHistoryPaginationPageChangeAction(pageOnUrl));
        }
    }, [pagination.currentPage, dispatch, pageOnUrl]);

    useEffect(() => {
        const isQueryChanged = sortKeyOnUrl !== sortByField || orderKeyOnUrl !== (isAscending ? "asc" : "desc");

        setIsSortingQueryChanged(isQueryChanged);
        dispatch(
            SortSmokeAlarmsAction({
                sortBy: sortKeyOnUrl,
                ascending: orderKeyOnUrl === "asc",
            }),
        );
    }, [isAscending, sortByField, dispatch, isSortingQueryChanged, queryParams, sortKeyOnUrl, orderKeyOnUrl]);

    const fetchAlarmsHistory = (overrideFrom: Date | null, overrideUntil: Date | null) => {
        const fromInclDate = overrideFrom || from;
        const toInclDate = overrideUntil || until;

        if (fromInclDate && isNaN(fromInclDate.getTime())) {
            return;
        }
        if (toInclDate && isNaN(toInclDate.getTime())) {
            return;
        }
        setLoaded(true);

        dispatch(
            FetchAlarmsHistoryAction({
                companyId: params.companyId!,
                fromInclDate: convertDateToLocalDateString(fromInclDate!),
                toInclDate: convertDateToLocalDateString(toInclDate!),
            }),
        );
    };

    const sortSmokeAlarmsAction = (sortBy: string, ascending: boolean) => {
        dispatch(
            SortSmokeAlarmsAction({
                sortBy: sortBy,
                ascending: ascending,
            }),
        );
    };

    const pageChange = useCallback(
        (pageNumber: number) => {
            dispatch(SmokeAlarmsHistoryPaginationPageChangeAction(pageNumber));
            const sortingQueriesOnPageChange =
                sortKeyOnUrl !== "default" ? `&sortByQuery=${sortKeyOnUrl}&orderByQuery=${orderKeyOnUrl}` : "";
            navigate(`?pageQuery=${pageNumber}${sortingQueriesOnPageChange}`);
        },
        [dispatch, navigate, orderKeyOnUrl, sortKeyOnUrl],
    );

    const itemsPerPageChange = (itemsPerPage: number) => {
        dispatch(SmokeAlarmsHistoryPaginationItemsPerPageChangeAction(itemsPerPage));
    };

    const downloadAlarmsHistory = () => {
        dispatch(
            DownloadReportAction({
                companyId: parseInt(params.companyId!),
                from: convertDateToLocalDateString(from!),
                until: convertDateToLocalDateString(until!),
                format: "alarm_history_pdf",
                usingNewLogic: true,
            }),
        );
    };

    const formatDate = (dateString: string): string => {
        if (!dateString) {
            return "-";
        }

        let date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return "-";
        }

        let day = String(date.getDate()).padStart(2, "0");
        let month = String(date.getMonth() + 1).padStart(2, "0");
        let year = date.getFullYear();

        return `${day}.${month}.${year}`;
    };

    return (
        <div style={{ paddingBottom: "50px" }}>
            <h2>
                {companyName} | {historyBlockTitle}{" "}
                {from && until ? `(${displayDate(new Date(from))} - ${displayDate(new Date(until))})` : ""}
            </h2>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "40px",
                    justifyContent: "space-between",
                }}
            >
                <HistoryDateRangeSelectionTable
                    from={from}
                    setFrom={setFrom}
                    until={until}
                    setUntil={setUntil}
                    invalidDateRange={invalidDateRange}
                    setLoaded={setLoaded}
                    canLoad={!invalidDateRange && !loaded}
                    fetchAlarmsHistory={fetchAlarmsHistory}
                />
                <div
                    style={{
                        alignSelf: "flex-end",
                        marginLeft: "50px",
                    }}
                >
                    <FloatButtonsRight
                        buttons={[
                            <Button
                                key="smoke-alarms-history-download"
                                variant="outlined"
                                disabled={invalidDateRange}
                                onClick={downloadAlarmsHistory}
                            >
                                {t("smokeAlarms.download")}
                            </Button>,
                        ]}
                    />
                </div>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <Sorter
                            sortKey="apartmentName"
                            sortAction={sortSmokeAlarmsAction}
                            sortBy={sortByField}
                            currentAscending={isAscending}
                            title={t("apartments.apartment")}
                            width={table.tableCellWidthSmall}
                        />
                        <TableHeadCell>{t("meters.code")}</TableHeadCell>
                        <TableHeadCell>{t("smokeAlarms.alarmType")}</TableHeadCell>
                        <Sorter
                            sortKey="alarmEnabledDate"
                            sortAction={sortSmokeAlarmsAction}
                            sortBy={sortByField}
                            currentAscending={isAscending}
                            title={t("smokeAlarms.alarmActivated")}
                            width={table.tableCellWidthSmall}
                        />
                        <TableHeadCell>{t("smokeAlarms.alarmRemoved")}</TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedAndFiltered.items.map((event) =>
                        alarmTypes
                            .filter((alarmType) => alarmType.alarmName === event.alarmRuleName)
                            .map((alarmType) => (
                                <TableRow key={event.apartmentName}>
                                    <TableCell>{event.apartmentName}</TableCell>
                                    <TableCell>{event.meterCode}</TableCell>
                                    <TableCell>
                                        {alarmType.localizations[asValidLanguage(language)].alarmName}
                                    </TableCell>
                                    <TableCell>{formatDate(event.alarmEnabledDate)}</TableCell>
                                    <TableCell>
                                        {event.alarmDisabledDate != null ? formatDate(event.alarmDisabledDate) : "-"}
                                    </TableCell>
                                </TableRow>
                            )),
                    )}
                </TableBody>
            </Table>
            {
                <ViewContainer style={{ marginTop: "20px" }}>
                    <Pagination
                        current={pagination.currentPage}
                        itemsCount={sortedAndFiltered.itemCount}
                        currentPageCallback={pageChange}
                        itemsPerPageCallback={itemsPerPageChange}
                        itemsPerPage={pagination.itemsPerPage}
                    />
                </ViewContainer>
            }
        </div>
    );
};

export default SmokeAlarmsHistory;
