import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { TableContainer, Table, TableBody } from "../../../components/Table";
import { useCallback, useEffect, useState } from "react";
import DropDown from "../../../components/DropDown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../interfaces/RootState";
import { ReportSettings } from "../../../redux/apartments/apartments-types";
import {
    FetchReportFormatsAction,
    SaveReportSettingsAction,
    SendTestReportAction,
} from "../../../redux/apartments/apartments-actions";
import deepEqual from "deep-equal";
import Checkbox from "../../../components/Checkbox";
import { TextField } from "@mui/material";
import { DatePickerComponent } from "../../../components/DatePicker";
import React from "react";

import FloatButtonsRight from "../../../components/FloatButtonsRight";
import ReportFormatSelector from "../../../components/ReportFormatSelector";
import { convertDateToLocalDateString } from "../../../utils/utils";
import { useHasPrivileges } from "../../../utils/useHasPrivilegesOf";
import { useButtonDisabled } from "../../../utils/useButtonDisabled";
import InfoModal from "../../../components/InfoModal";
import InfoLink from "../../../components/InfoLink";
import { TableRowWithLabel } from "../../../components/TableRowWithLabel";

type Props = {
    companyId: number;
};

export default function Reporting({ companyId }: Props) {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    const existingReportSettings = useSelector((state: RootState) => state.hydrolink.apartments.reportSettings);

    const reportFormats = useSelector((state: RootState) => state.hydrolink.apartments.reportFormats);
    const [reportSettings, setReportSettings] = useState<ReportSettings>(existingReportSettings);
    const reportStart = reportSettings.reportStart ? new Date(reportSettings.reportStart) : null;

    const areReportSettingsValid = (): boolean => {
        if (reportSettings.reportFormat !== "") {
            return true;
        }
        return reportSettings.reportingId !== "" && !reportSettings.sendReports;
    };
    const [infoModalOpen, setInfoModalOpen] = React.useState(false);

    const changed = (): boolean => !deepEqual(existingReportSettings, reportSettings) && areReportSettingsValid();

    const canSaveReportSettings = useHasPrivileges("can save report settings", companyId).hasPrivilege;

    const canSendTestReports = useHasPrivileges("can send test reports", companyId).hasPrivilege;

    const saveReportSettingsButtonDisabled = useButtonDisabled(canSaveReportSettings, !changed());

    const getButtons = (): JSX.Element[] => {
        let buttons: JSX.Element[] = [];
        if (canSendTestReports) {
            buttons.push(<Button onClick={sendTestReport}>{t("apartments.report.adminReportTestFeature")}</Button>);
        }
        buttons.push(
            <Button onClick={saveReportSettings} disabled={saveReportSettingsButtonDisabled}>
                {t("generic.save")}
            </Button>,
        );
        return buttons;
    };

    const timeSpans = [
        { value: "1", displayText: t("apartments.report.reportInterval.1month") },
        { value: "2", displayText: t("apartments.report.reportInterval.2month") },
        { value: "3", displayText: t("apartments.report.reportInterval.3month") },
        { value: "6", displayText: t("apartments.report.reportInterval.6month") },
        { value: "12", displayText: t("apartments.report.reportInterval.12month") },
    ];

    const languages = [
        { value: "fi", displayText: t("country.finland") },
        /*     { value: 'se', displayText: t('country.sweden') } */
    ];

    useEffect(() => {
        dispatch(FetchReportFormatsAction());
        setReportSettings(existingReportSettings);
    }, [existingReportSettings, dispatch]);

    const sendTestReport = () => {
        dispatch(SendTestReportAction({ companyId: companyId }));
    };

    const changeFormatAction = (e: string) => {
        setReportSettings({
            ...reportSettings,
            reportFormat: e,
        });
    };

    const changesStartDateAction = useCallback(
        (date: Date | null) => {
            if (date) {
                setReportSettings({
                    ...reportSettings,
                    reportStart: convertDateToLocalDateString(date),
                });
            }
        },
        [reportSettings],
    );

    const changesReportingId = (e: string) => {
        setReportSettings({
            ...reportSettings,
            reportingId: e,
        });
    };

    const changeSendReports = () => {
        const reportIntervalMonths = parseInt(inteval);
        const reportStartAsString = convertDateToLocalDateString(reportStart ?? new Date());
        setReportSettings({
            ...reportSettings,
            sendReports: !reportSettings.sendReports,
            reportStart: reportStartAsString,
            reportIntervalMonths: reportIntervalMonths,
        });
    };

    const changeReportEmail = (e: string) => {
        setReportSettings({
            ...reportSettings,
            reportEmail: e,
        });
    };

    const changeReportIntervalMonth = (e: number) => {
        setReportSettings({
            ...reportSettings,
            reportIntervalMonths: e,
        });
    };

    const changeLanguage = (e: string) => {
        setReportSettings({
            ...reportSettings,
            reportLang: e,
        });
    };

    const saveReportSettings = () => {
        dispatch(
            SaveReportSettingsAction({
                reportSettings: reportSettings,
                companyId: companyId,
            }),
        );
    };

    const handleReportIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        changeReportIntervalMonth(parseInt(e.target.value));
    };

    const inteval = reportSettings.reportIntervalMonths
        ? String(reportSettings.reportIntervalMonths)
        : timeSpans[0].value;

    return (
        <>
            <InfoModal
                open={infoModalOpen}
                onClose={() => setInfoModalOpen(false)}
                title={t("apartments.report.infoModalTitle")}
                body={t("apartments.report.infoModalBody")}
            />
            <div style={{ width: "60%" }}>
                <TableContainer component="div">
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRowWithLabel htmlFor={"companyIdInReports"} label={t("apartments.report.companyId")}>
                                <TextField
                                    id="companyIdInReports"
                                    size="small"
                                    value={reportSettings.reportingId}
                                    onChange={(e) => changesReportingId((e.target as HTMLInputElement).value)}
                                />
                            </TableRowWithLabel>
                            <TableRowWithLabel htmlFor={"reportEmails"} label={t("apartments.report.reportEmails")}>
                                <Checkbox onChange={() => changeSendReports()} checked={reportSettings.sendReports} />
                            </TableRowWithLabel>
                            <TableRowWithLabel htmlFor={"email"} label={t("apartments.report.receiverEmails")}>
                                <TextField
                                    id="email"
                                    size="small"
                                    style={{ width: "100%" }}
                                    placeholder={t("apartments.report.emailPlaceholder")}
                                    disabled={!reportSettings.sendReports}
                                    value={reportSettings.reportEmail}
                                    onChange={(e: React.ChangeEvent) =>
                                        changeReportEmail((e.target as HTMLInputElement).value)
                                    }
                                />
                            </TableRowWithLabel>
                            {reportFormats.length > 0 && (
                                <TableRowWithLabel htmlFor={"format"} label={t("apartments.report.format")}>
                                    <ReportFormatSelector
                                        id={"format"}
                                        disabled={!reportSettings.sendReports}
                                        selected={reportSettings.reportFormat}
                                        changeFormatAction={changeFormatAction}
                                        companyId={companyId}
                                    />
                                </TableRowWithLabel>
                            )}
                            <TableRowWithLabel
                                htmlFor={"reportStart"}
                                label={t("apartments.report.reportStart")}
                                labelElement={<InfoLink onClick={() => setInfoModalOpen(true)} />}
                            >
                                <DatePickerComponent
                                    disableManualTyping={true}
                                    value={reportStart}
                                    setValue={changesStartDateAction}
                                />
                            </TableRowWithLabel>
                            {reportSettings.reportIntervalMonths ? (
                                <TableRowWithLabel htmlFor={"interval"} label={t("apartments.report.interval")}>
                                    <DropDown
                                        id={"interval"}
                                        options={timeSpans}
                                        disabled={!reportSettings.sendReports}
                                        selectedValue={inteval}
                                        onChange={handleReportIntervalChange}
                                    />
                                </TableRowWithLabel>
                            ) : (
                                <></>
                            )}
                            <TableRowWithLabel htmlFor={"reportLanguage"} label={t("generic.language")}>
                                <DropDown
                                    id={"reportLanguage"}
                                    options={languages}
                                    disabled={!reportSettings.sendReports}
                                    selectedValue={reportSettings.reportLang}
                                    onChange={(e: React.ChangeEvent) =>
                                        changeLanguage((e.target as HTMLInputElement).value)
                                    }
                                />
                            </TableRowWithLabel>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <FloatButtonsRight buttons={getButtons()} />
        </>
    );
}
