import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody } from "../../components/Table";
import { Paper } from "@mui/material";
import { DatePickerComponent } from "../../components/DatePicker";
import { Button } from "../../components/Button";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { colors, spacings, typography } from "../../theme";
import DateRangeShortcutButton from "./DateRangeShortcutButton";

type Props = {
    from: Date | null;
    until: Date | null;
    setFrom: Dispatch<SetStateAction<Date | null>>;
    setUntil: Dispatch<SetStateAction<Date | null>>;
    canLoad: boolean;
    setLoaded: Dispatch<SetStateAction<boolean>>;
    fetchAlarmsHistory: (overrideFrom: Date | null, overrideUntil: Date | null) => void;
    invalidDateRange: boolean;
};

const HistoryDateRangeSelectionTable = ({
    from,
    setFrom,
    until,
    setUntil,
    canLoad,
    setLoaded,
    fetchAlarmsHistory,
    invalidDateRange,
}: Readonly<Props>): JSX.Element => {
    const t = useTranslation().t;

    const onChange = () => {
        setLoaded(false);
    };

    const getDate365DaysAgo = (): Date => {
        const pastDate = new Date();
        if (from) {
            pastDate.setDate(from.getDate() - 365);
        }
        return pastDate;
    };

    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <div style={{ width: "550px" }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={4} style={{ textAlign: "center" }}>
                                    {t("alarms.historyInterval")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <DatePickerComponent
                                        value={from}
                                        setValue={setFrom}
                                        onChange={onChange}
                                        minDate={getDate365DaysAgo()}
                                        maxDate={new Date()}
                                    />
                                    <div
                                        style={{
                                            color: colors.warm06,
                                            fontSize: typography.fontSize.small,
                                            position: "absolute",
                                        }}
                                    >
                                        {invalidDateRange ? t("errors.endingDateIsBeforeStartingDate") : ""}
                                    </div>
                                </TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>
                                    <DatePickerComponent
                                        value={until}
                                        setValue={setUntil}
                                        onChange={onChange}
                                        minDate={getDate365DaysAgo()}
                                        maxDate={new Date()}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => fetchAlarmsHistory(null, null)} disabled={!canLoad}>
                                        {t("generic.apply")}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div
                style={{
                    marginLeft: spacings.inlineSpacing,
                    display: "grid",
                }}
            >
                <DateRangeShortcutButton
                    setFrom={setFrom}
                    setUntil={setUntil}
                    setLoaded={setLoaded}
                    fetchAlarmsHistory={fetchAlarmsHistory}
                />
            </div>
        </div>
    );
};

export default HistoryDateRangeSelectionTable;
