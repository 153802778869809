import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "../../components/Table";
import { RootState } from "../../interfaces/RootState";
import { FetchClientCommandsAction } from "../../redux/centers/centers-actions";
import { Client } from "../../redux/centers/centers-types";
import { FetchCompaniesWithoutAlarmsAction } from "../../redux/companies/companies-actions";
import { CentersRow } from "./CentersRow";

type Props = {
    companyId: number;
};

const CentersView = ({ companyId }: Props): JSX.Element => {
    const t = useTranslation().t;

    const companies = useSelector((state: RootState) => state.hydrolink.companies.companies);

    const [centers, setCenters] = useState<Client[]>([]);

    useEffect(() => {
        const clients = companies.find((c) => c.id === companyId)?.clients;
        if (clients) {
            setCenters(clients);
        }
    }, [companies, companyId]);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("centers.center")}</TableCell>
                        <TableCell>{/* Expand center button */}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {centers.map((c: Client) => (
                        <CentersRow key={`parent_${c.id}`} client={c} companyId={companyId} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default function Centers() {
    const dispatch = useDispatch();
    const params = useParams();
    const companyId = parseInt(params.companyId ?? "0");

    useEffect(() => {
        dispatch(FetchClientCommandsAction());
        dispatch(FetchCompaniesWithoutAlarmsAction());
    }, [dispatch]);

    return <CentersView companyId={companyId} />;
}
