import { useTranslation } from "react-i18next";
import { Company } from "../../redux/companies/companies-types";
import ContractsTable from "./ContractsTable";
import CreateCompanyDpaModal, * as createCompanyDpaModal from "./CreateCompanyDpaModal";
import CreateCompanySubscriptionModal, * as createCompanySubscriptionModal from "./CreateCompanySubscriptionModal";
import { Contract, ContractState } from "../../redux/contracts/contracts-types";
import { useState } from "react";
import { AppDispatch } from "../../interfaces/RootState";

/**
 * Props for {@link CompanyContractsView}.
 */
export type Props = {
    /**
     * Redux dispatch function.
     */
    dispatch: AppDispatch;

    /**
     * ID of the user who signs the contracts.
     */
    signerUserId: number;

    /**
     * Company whose contracts are displayed.
     */
    company: Company;

    /**
     * Company's current subscription contract, if any.
     */
    subscription: Contract | null;

    /**
     * Company's current DPA contract, if any.
     */
    dpa: Contract | null;

    /**
     * Test IDs for component parts.
     */
    testIds?: TestIds;
};

/**
 * Test IDs for {@link CompanyContractsView}.
 */
export type TestIds = {
    subscriptionActionButton?: string;
    subscriptionModal?: createCompanySubscriptionModal.TestIds;
    dpaActionButton?: string;
    dpaModal?: createCompanyDpaModal.TestIds;
};

/**
 * {@link ContractsTable} specialized for the given company, together with modals for creating new contracts.
 */
export default function CompanyContractsView({
    dispatch,
    signerUserId,
    company,
    subscription,
    dpa,
    testIds,
}: Props): JSX.Element {
    const { t } = useTranslation();

    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
    const [isDpaModalOpen, setIsDpaModalOpen] = useState(false);

    const dpaSignDisabledReason =
        subscription?.state === ContractState.ACTIVE ? null : t("contracts.tooltips.subscriptionMustBeSignedFirst");

    return (
        <>
            <ContractsTable
                dispatch={dispatch}
                rows={[
                    {
                        key: 1,
                        type: t("contracts.types.companySubscription"),
                        contract: subscription,
                        signActionDisabledReason: null,
                        onSignClicked: () => setIsSubscriptionModalOpen(true),
                        actionButtonTestId: testIds?.subscriptionActionButton,
                    },
                    {
                        key: 2,
                        type: t("contracts.types.companyDpa"),
                        contract: dpa,
                        signActionDisabledReason: dpaSignDisabledReason,
                        onSignClicked: () => setIsDpaModalOpen(true),
                        actionButtonTestId: testIds?.dpaActionButton,
                    },
                ]}
            />
            <CreateCompanySubscriptionModal
                dispatch={dispatch}
                isOpen={isSubscriptionModalOpen}
                close={() => setIsSubscriptionModalOpen(false)}
                userId={signerUserId}
                companyId={company.id}
                initialData={{
                    housingCompanyBusinessId: "",
                    housingCompanyStreetAddress: company.streetAddress,
                    housingCompanyZipCode: company.zipCode,
                    housingCompanyTown: company.town,
                    managementCompanyName: "",
                    managementCompanyBusinessId: "",
                    managementCompanyStreetAddress: "",
                    managementCompanyZipCode: "",
                    managementCompanyTown: "",
                    managerPhone: "",
                }}
                testIds={testIds?.subscriptionModal}
            />
            <CreateCompanyDpaModal
                dispatch={dispatch}
                isOpen={isDpaModalOpen}
                close={() => setIsDpaModalOpen(false)}
                userId={signerUserId}
                companyId={company.id}
                initialData={{
                    businessId: "",
                    streetAddress: company.streetAddress,
                    zipCode: company.zipCode,
                    town: company.town,
                }}
                testIds={testIds?.dpaModal}
            />
        </>
    );
}
