import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Meter, SMOKE_METER } from "../../../../redux/apartments/apartments-types";
import AlertIcon from "../../../../assets/ICON_alert.svg";
import { RootState } from "../../../../interfaces/RootState";
import { displayAlarms } from "../../../../utils/utils";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import NotificationsOnApartmentTableRow from "./NotificationsOnApartmentTableRow";

type Props = Readonly<{
    meters: Meter[];
    companyId: number;
}>;

const ApartmentAlarmNotification: React.FC<Props> = ({ meters, companyId }) => {
    const companyAlarms = useSelector((state: RootState) => state.hydrolink.companies.alarmTypes);
    const userCanViewPremiumAlarms = useHasPrivileges("can see premium alarms", companyId).hasPrivilege;
    const userCanViewAdminAlarms = useHasPrivileges("can see admin alarms", companyId).hasPrivilege;

    const activePremiumAlarms = useMemo(
        () => meters.some((meter) => meter.type === SMOKE_METER && meter.hasActivePremiumAlarms),
        [meters],
    );

    const activeAlarmsCount = useMemo(() => {
        return meters.reduce((count, meter) => {
            return count + displayAlarms(meter.activeAlarmNames, userCanViewAdminAlarms, companyAlarms).length;
        }, 0);
    }, [meters, userCanViewAdminAlarms, companyAlarms]);

    if (!activeAlarmsCount && !activePremiumAlarms) {
        return null;
    }

    const totalAlarmsCount = adjustAlarmCountForPremiumAlarms(
        activePremiumAlarms,
        userCanViewAdminAlarms,
        userCanViewPremiumAlarms,
        activeAlarmsCount,
    );

    return (
        <NotificationsOnApartmentTableRow
            title={"alarms.apartmentHasActiveAlarms"}
            activeAlarmsCount={totalAlarmsCount}
            altText="Alert"
            iconSrc={AlertIcon}
        />
    );
};

const adjustAlarmCountForPremiumAlarms = (
    activePremiumAlarms: boolean,
    userCanViewAdminAlarms: boolean,
    userCanViewPremiumAlarms: boolean,
    activeAlarmsCount: number,
): number => {
    // Increment the active alarms count by one if there are active premium alarms and the user can't view them
    return activePremiumAlarms && !userCanViewAdminAlarms && !userCanViewPremiumAlarms
        ? activeAlarmsCount + 1
        : activeAlarmsCount;
};

export default ApartmentAlarmNotification;
