import { createAction } from "@reduxjs/toolkit";
import {
    CreatePrincipalManagerDpaRequestPayload,
    CreatePrincipalManagerDpaRequestSuccessPayload,
    CreateCompanyDpaRequestPayload,
    CreateCompanyDpaRequestSuccessPayload,
    FetchUserContractsRequestPayload,
    FetchUserContractsSuccessPayload,
    SendInviteeSignRequestPayload,
    SendInviteeSignRequestSuccessPayload,
    CreateCompanySubscriptionRequestPayload,
    CreateCompanySubscriptionRequestSuccessPayload,
    FetchUserContractsFailurePayload,
} from "./contracts-types";

export const FetchUserContractsAction = createAction<FetchUserContractsRequestPayload>("FETCH_USER_CONTRACTS");
export const FetchUserContractsSuccessAction =
    createAction<FetchUserContractsSuccessPayload>("FETCH_USER_CONTRACTS_SUCCESS");
export const FetchUserContractsFailureAction =
    createAction<FetchUserContractsFailurePayload>("FETCH_USER_CONTRACTS_FAILURE");

export const CreatePrincipalManagerDPARequestAction = createAction<CreatePrincipalManagerDpaRequestPayload>(
    "CREATE_PRINCIPAL_MANAGER_DPA_REQUEST",
);
export const CreatePrincipalManagerDPARequestSuccessAction =
    createAction<CreatePrincipalManagerDpaRequestSuccessPayload>("CREATE_PRINCIPAL_MANAGER_DPA_REQUEST_SUCCESS");
export const CreatePrincipalManagerDPARequestFailureAction = createAction<void>(
    "CREATE_PRINCIPAL_MANAGER_DPA_REQUEST_FAILURE",
);

export const CreateCompanySubscriptionRequestAction = createAction<CreateCompanySubscriptionRequestPayload>(
    "CREATE_COMPANY_SUBSCRIPTION_REQUEST",
);
export const CreateCompanySubscriptionRequestSuccessAction =
    createAction<CreateCompanySubscriptionRequestSuccessPayload>("CREATE_COMPANY_SUBSCRIPTION_REQUEST_SUCCESS");
export const CreateCompanySubscriptionRequestFailureAction = createAction<void>(
    "CREATE_PRINCIPAL_MANAGER_DPA_REQUEST_FAILURE",
);

export const CreateCompanyDPARequestAction = createAction<CreateCompanyDpaRequestPayload>("CREATE_COMPANY_DPA_REQUEST");
export const CreateCompanyDPARequestSuccessAction = createAction<CreateCompanyDpaRequestSuccessPayload>(
    "CREATE_COMPANY_DPA_REQUEST_SUCCESS",
);
export const CreateCompanyDPARequestFailureAction = createAction<void>("CREATE_PRINCIPAL_MANAGER_DPA_REQUEST_FAILURE");

export const SendInviteeSignRequestAction = createAction<SendInviteeSignRequestPayload>("SEND_INVITEE_SIGN_REQUEST");
export const SendInviteeSignRequestSuccessAction = createAction<SendInviteeSignRequestSuccessPayload>(
    "SEND_INVITEE_SIGN_REQUEST_SUCCESS",
);
export const SendInviteeSignRequestFailureAction = createAction<void>("SEND_INVITEE_SIGN_REQUEST_FAILURE");
