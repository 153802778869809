import { AnyAction } from "@reduxjs/toolkit";
import { ErrorState } from "./error-types";
import { AcknowledgeNextErrorAction, ErrorOccurredAction } from "./error-actions";

const initialState: ErrorState = {
    errors: [],
};

export default function errorReducer(state: ErrorState = initialState, action: AnyAction): ErrorState {
    if (ErrorOccurredAction.match(action)) {
        return {
            ...state,
            errors: state.errors.concat({ message: action.payload }),
        };
    }

    if (AcknowledgeNextErrorAction.match(action)) {
        return {
            ...state,
            errors: state.errors.slice(1),
        };
    }

    return state;
}
