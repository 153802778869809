import { AnyAction } from "redux";
import { stateWithLoadingFalse, stateWithLoadingTrue } from "../../utils/utils";
import {
    FetchReportsAction,
    FetchReportsFailureAction,
    FetchReportsSuccessAction,
    FetchInitReportSuccessAction,
    FetchInitReportFailureAction,
    FetchInitReportAction,
    SortWaterMeterApartmentsAction,
    SortEnergyMeterApartmentsAction,
    DownloadReportAction,
    DownloadReportSuccessAction,
    DownloadReportFailureAction,
} from "./reports-actions";
import { ReportsState } from "./reports-types";

const initialState: ReportsState = {
    reports: [],
    loading: 0,
    energyMeterApartmentsSortBy: "id",
    waterMeterApartmentsSortBy: "id",
    waterMeterApartmentsAscending: true,
    energyMeterApartmentsAscending: true,
};

const actions = (state: ReportsState, action: AnyAction) => {
    if (FetchReportsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (FetchInitReportAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (DownloadReportAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    return state;
};

const successActions = (state: ReportsState, action: AnyAction) => {
    if (FetchReportsSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            reports: action.payload.reports,
        };
    }

    if (FetchInitReportSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DownloadReportSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
};

const failureActions = (state: ReportsState, action: AnyAction) => {
    if (FetchReportsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (FetchInitReportFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DownloadReportFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }
    return state;
};

const otherActions = (state: ReportsState, action: AnyAction) => {
    if (SortWaterMeterApartmentsAction.match(action)) {
        return {
            ...state,
            waterMeterApartmentsSortBy: action.payload.waterMeterApartmentsSortBy,
            waterMeterApartmentsAscending: action.payload.waterMeterApartmentsAscending,
        };
    }

    if (SortEnergyMeterApartmentsAction.match(action)) {
        return {
            ...state,
            energyMeterApartmentsSortBy: action.payload.energyMeterApartmentsSortBy,
            energyMeterApartmentsAscending: action.payload.energyMeterApartmentsAscending,
        };
    }
    return state;
};

export default function reportsReducer(state: ReportsState = initialState, action: AnyAction): ReportsState {
    state = actions(state, action);
    state = successActions(state, action);
    state = failureActions(state, action);
    state = otherActions(state, action);

    return state;
}
