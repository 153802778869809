import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import { Modal } from "../../components/common/modal/Modal";
import { ModalInput } from "../../components/common/modal/ModalInput";
import { RootState } from "../../interfaces/RootState";
import { ErrorOccurredAction } from "../../redux/error/error-actions";
import { CloseCreateUserModalAction, CreateUserAction } from "../../redux/users/users-actions";
import { CreateUserModalState, CreateUserPayload } from "../../redux/users/users-types";
import { alignments, spacings } from "../../theme";
import { emailRegex } from "../../utils/utils";

const emptyUserData: CreateUserPayload = {
    admin: false,
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
};

function CreateUserModal(): JSX.Element {
    const dispatch = useDispatch();
    const t = useTranslation().t;

    const modalState = useSelector((state: RootState) => state.hydrolink.users.createUserModalState);
    const [userData, setUserData] = useState<CreateUserPayload>(emptyUserData);

    const isOpen = modalState !== CreateUserModalState.Closed;
    const withAdminCheckbox = modalState === CreateUserModalState.OpenWithAdminCheckbox;

    // Clear all fields when the modal closes
    useEffect(() => {
        setUserData(emptyUserData);
    }, [modalState]);

    const onFormChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof CreateUserPayload) => {
        setUserData({
            ...userData,
            [field]: e.target.value,
        });
    };

    const save = () => {
        if (userData.firstName === "") {
            dispatch(ErrorOccurredAction("errors.firstNameMissing"));
        } else if (userData.lastName === "") {
            dispatch(ErrorOccurredAction("errors.lastNameMissing"));
        } else if (userData.email === "") {
            dispatch(ErrorOccurredAction("errors.emailMissing"));
        } else if (!emailRegex.test(userData.email)) {
            dispatch(ErrorOccurredAction("errors.badEmailFormat"));
        } else {
            createUser();
        }
    };

    const createUser = () => {
        dispatch(CreateUserAction(userData));
    };

    const closeModal = () => {
        dispatch(CloseCreateUserModalAction());
    };

    const getDialogActions = (): JSX.Element => (
        <>
            <Button onClick={closeModal}>{t("generic.cancel")}</Button>
            <Button variant="contained" onClick={save}>
                {t("generic.save")}
            </Button>
        </>
    );

    // The reason as to why this is not a normal React component is because the input field unselects on every render
    function createUserModalInput(field: keyof CreateUserPayload) {
        return (
            <ModalInput
                key={field}
                title={t(`users.${field}`)}
                value={userData[field] as string}
                handleFormValue={(e) => onFormChange(e, field)}
            />
        );
    }

    return (
        <Modal title={t("users.createNew")} open={isOpen} dialogActions={getDialogActions()} closeModal={closeModal}>
            <div
                style={{
                    ...alignments.twoElementGrid,
                }}
            >
                {createUserModalInput("firstName")}
                {createUserModalInput("lastName")}
                {createUserModalInput("email")}
                {createUserModalInput("phone")}
                {/* createUserModalInput isn't for checkboxes, so create one separately */}
                {withAdminCheckbox && (
                    <div style={{ padding: `${spacings.inlineSpacing} 0` }}>
                        <Checkbox
                            label={t("users.admin")}
                            checked={userData.admin}
                            onChange={() => setUserData({ ...userData, admin: !userData.admin })}
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default CreateUserModal;
