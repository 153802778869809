import { createTheme, TextFieldProps } from "@mui/material";

export const colors = {
    neutral08: "#12263F", // main text
    neutral06: "#6E84A3", // header titles, icons
    neutral05: "#B5B9B9", // for soft borders
    neutral04: "#EDF2F9", // separator lines, section background
    neutral02: "#F9FBFD", // app background
    neutral00: "#FFFFFF", // element background, navbar text

    blue00: "#21348C", // new hamburger menu
    blue01: "#203384", // new header utils text
    blue02: "#46566C", //new table header text
    blue03: "#1976D2",

    primary06: "#81D1E8", // premium off background, hovered button background
    primary04: "#04A3D0", // premium on background, pressed button background, link, cold water, button outline

    warm06: "#BE366C", // alarms, hot water
    cold06: "#04A3D0", // alarms, cold water
    electricity06: "#34958A", //electricity energy meter
    success06: "#04D08D", // check marks
    star06: "#D0CA04", // premium stars

    rowColorAdd: "#60F260",
    rowColorRemove: "#F26060",

    link: "#0000FF", // link color, same as using color: "blue"
};

export const barChartStyles = {
    borderRadius: 5,
};

export const theme = createTheme({
    palette: {
        primary: {
            main: colors.blue03, //colors.primary04,
            // dark: //colors.primary06,
        },
        text: {
            primary: colors.neutral08,
        },
    },
    typography: {
        fontFamily: "Arial, sans-serif",
    },
});

export const spacings = {
    largeMargin: "50px",
    standardSpacing: "25px",
    standardMargin: "20px",
    inlineSpacing: "10px",
    narrow: "5px",
};

export const typography = {
    fontSize: {
        default: "14.6px",
        small: "13.6px",
        medium: "15.3px",
        title: "18.6px",
        large: "18px",
        modalTitle: "24px",
        inputField: "12px",
        hambugerMenu: "30.4px",
    },
};

export const borders = {
    defaultBorderRadius: "5px",
    buttonBorderRadius: "0.4em",
    fineOutline: `1px solid ${colors.neutral04}`,
    basicOutline: {
        border: `2px solid ${colors.neutral04}`,
        borderRadius: "5px",
    },
    modalTextAreaBorder: {
        border: `1px solid ${colors.neutral05}`,
        borderRadius: "5px",
    },
    styledButtonBorder: `1px solid ${colors.primary04}`,
    attentionBorder: `2px solid ${colors.rowColorRemove}`,
};

export const textInputForDataPicker = {
    fontSize: typography.fontSize.inputField,
    font: "revert",
    width: "100%",
    height: "auto",
    padding: spacings.inlineSpacing,
};

export const textFieldProps: TextFieldProps = {
    size: "small",
    variant: "outlined",
    inputProps: {
        style: {
            padding: "4px 8px",
        },
    },
    sx: {
        ".MuiInputBase-root": {
            display: "block",
            padding: `2px ${spacings.inlineSpacing}`,
        },
    },
    style: {
        width: "28em",
        margin: `${spacings.inlineSpacing} 0`,
    },
};

export const table = {
    tableCellWidthExtraSmall: "100em",
    tableCellWidthSmall: "250em",
    tableCellWidthMedium: "400em",
    tableCellWidthLarge: "500em",
    tableCellWidthExtraLarge: "700em",
};

export const alignments = {
    evenHorizontal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    twoElementGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        justifyContent: "space-between",
        gap: "1rem",
    },
    rightSide: {
        display: "flex",
        justifyContent: "end",
    },
    centered: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
} as const; // This is needed or you get "string not comparable to FlexDirection" problems
