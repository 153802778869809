import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ExpandableBlock from "../../../components/ExpandableBlock";
import { Apartment } from "../../../redux/apartments/apartments-types";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { DeleteApartmentAction, ToggleApartmentAction } from "../../../redux/apartments/apartments-actions";
import MeterList from "./meters/MeterList";
import FontAwesomeIconButton from "../../../components/FontAwesomeIconButton";
import { RootState } from "../../../interfaces/RootState";
import { useHasPrivileges } from "../../../utils/useHasPrivilegesOf";
import { Client } from "../../../redux/centers/centers-types";
import ApartmentRenaming from "./apartments-table-row-components/ApartmentRenaming";
import ApartmentMergingCheckbox from "./apartments-table-row-components/ApartmentMergingCheckbox";
import GaugeIcon from "../../../assets/gauge-solid.svg";
import CreativeCommonZeroIcon from "../../../assets/creative-common-zero.svg";
import { alignments, typography } from "../../../theme";
import { Company } from "../../../redux/companies/companies-types";
import NotificationsOnApartmentTableRow from "./apartments-table-row-components/NotificationsOnApartmentTableRow";
import ApartmentAlarmNotification from "./apartments-table-row-components/ApartmentAlarmNotification";

interface Props {
    apartment: Apartment;
    company: Company;
    mergeApartmentsOn: boolean;
    apartmentsToMerge: number[];
    setApartmentsToMerge: React.Dispatch<React.SetStateAction<number[]>>;
    clients: Client[];
}

export default function ApartmentsTableRow({
    apartment,
    company,
    apartmentsToMerge,
    setApartmentsToMerge,
    mergeApartmentsOn,
    clients,
}: Props) {
    const dispatch = useDispatch();
    const [metersOpen, setMetersOpen] = React.useState<number[]>([]);
    const client = clients.find((c) => c.id === apartment.clientId);
    const t = useTranslation().t;
    const noReadingYet = apartment.meters.some((meter) => !meter.reading);
    const checked = (): boolean => apartmentsToMerge.includes(apartment.id) && mergeApartmentsOn;

    const isApartmentOpen = useSelector(
        (state: RootState) => state.hydrolink.apartments.apartmentOpenById[apartment.id] ?? false,
    );

    const canDeleteApartment = useHasPrivileges("can delete apartment", company.id);
    const canSeeCenterIdAndVersion = useHasPrivileges("can see center id and version", company.id).hasPrivilege;

    const onToggleOpen = () => {
        dispatch(ToggleApartmentAction({ id: apartment.id, isOpen: !isApartmentOpen }));
    };

    const deleteApartment = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (window.confirm(`${t("apartments.deleteApartmentConfirmation")} "${apartment.code}"?`)) {
            dispatch(DeleteApartmentAction({ id: apartment.id, companyId: company.id }));
            // Close expanded apartment, so it doesn't open a new one after
            setMetersOpen([...metersOpen.filter((k) => k !== apartment.id)]);
        }
    };

    const check = () => {
        if (apartmentsToMerge.includes(apartment.id)) {
            setApartmentsToMerge(apartmentsToMerge.filter((a) => a !== apartment.id));
        } else {
            setApartmentsToMerge([...apartmentsToMerge, apartment.id]);
        }
    };

    const displayReadingsNotifications = () => {
        let nullReadings = apartment.meters.filter((meter) => meter.reading === null);
        let readingsAsZero = apartment.meters.filter((meter) => meter.reading === 0);
        if (nullReadings.length > 0)
            return (
                <NotificationsOnApartmentTableRow
                    title="apartments.noReadingsYetTooltip"
                    activeAlarmsCount={nullReadings.length}
                    altText="Gauge"
                    iconSrc={GaugeIcon}
                />
            );
        if (readingsAsZero.length > 0)
            return (
                <NotificationsOnApartmentTableRow
                    title="apartments.readingIsZeroTooltip"
                    activeAlarmsCount={readingsAsZero.length}
                    altText="Zero"
                    iconSrc={CreativeCommonZeroIcon}
                />
            );
        return <></>;
    };

    return (
        <div key={apartment.id} style={{ display: "flex", flexDirection: "column" }}>
            <ExpandableBlock
                isOpen={isApartmentOpen}
                onToggleOpen={onToggleOpen}
                childrenNoFlex
                leftSide={
                    <>
                        <ApartmentMergingCheckbox
                            mergeApartmentsOn={mergeApartmentsOn}
                            apartmentId={apartment.id}
                            check={check}
                            checked={checked()}
                        />
                        <span className="text-bold" style={{ minWidth: "50px" }}>
                            {apartment.code}
                        </span>
                        {canSeeCenterIdAndVersion && (
                            <span
                                className="text-grey"
                                style={{
                                    fontSize: typography.fontSize.small,
                                    marginLeft: "0.5em",
                                }}
                            >
                                {t("apartments.centerId")}: {client?.code} | {t("apartments.clientSoftwareVersion")}:{" "}
                                {client?.softwareVersion ?? ""}
                            </span>
                        )}

                        <ApartmentAlarmNotification meters={apartment.meters} companyId={company.id} />
                        {noReadingYet && displayReadingsNotifications()}
                    </>
                }
                rightSide={
                    <>
                        {isApartmentOpen ? (
                            <div style={{ marginRight: "0.5em" }}>
                                <div
                                    style={{
                                        ...alignments.evenHorizontal,
                                        flexDirection: "row",
                                        alignContent: "center",
                                    }}
                                >
                                    <ApartmentRenaming apartment={apartment} companyId={company.id} />

                                    <FontAwesomeIconButton
                                        tooltip={t("apartments.removeApartmentTooltip")}
                                        disabled={!canDeleteApartment.hasPrivilege}
                                        disabledTooltip={canDeleteApartment.missingRequirement}
                                        onClick={deleteApartment}
                                        icon={faTrash}
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </>
                }
            >
                <div>
                    <MeterList meters={apartment.meters} company={company} apartmentId={apartment.id} />
                </div>
            </ExpandableBlock>
        </div>
    );
}
