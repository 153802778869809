import { useTranslation } from "react-i18next";
import { colors, spacings, typography } from "../theme";

type Props = {
    onClick: () => void;
};

export default function InfoLink({ onClick }: Props): JSX.Element {
    const t = useTranslation().t;

    return (
        <span
            style={{
                cursor: "pointer",
                color: colors.link,
                fontSize: typography.fontSize.default,
                marginLeft: spacings.inlineSpacing,
            }}
            onClick={onClick}
        >
            {t("generic.info")}
        </span>
    );
}
