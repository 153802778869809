import * as api from "../../api";
import { all, put, takeEvery } from "redux-saga/effects";
import { AcknowledgeNextErrorAction, ErrorOccurredAction } from "../error/error-actions";
import {
    AcceptTermsAndConditionsAction,
    AcceptTermsAndConditionsFailureAction,
    AcceptTermsAndConditionsSuccessAction,
    FetchUserInformationAction,
    ForceLogoutAction,
    SendCurrentUserPasswordResetAction,
    SendCurrentUserPasswordResetFailureAction,
    SendCurrentUserPasswordResetSuccessAction,
    SendPasswordResetAction,
    SendPasswordResetFailureAction,
    SendPasswordResetSuccessAction,
    SetLanguageAction,
    UserReceivedAction,
    UserReceivedFailureAction,
} from "./auth-actions";
import {
    FetchUserInformationPayload,
    SendCurrentUserPasswordResetPayload,
    SendPasswordResetPayload,
} from "./auth-types";
import i18next from "i18next";
import { AddMessageAction } from "../messages/messages-actions";
import { MessageTypes } from "../messages/messages-types";
import { retrieveLanguage, storeLanguage } from "../../utils/languageStore";
import { ApiGetCurrentUserResponse } from "../../api/api-types";
import { convertApiGetCurrentUserResponse } from "../../api/api-conversions";

export function* fetchCurrentUser(action: { payload: FetchUserInformationPayload }) {
    yield api.get(
        "/current_user",
        (resp: ApiGetCurrentUserResponse) =>
            action.payload.background
                ? all([put(UserReceivedAction(convertApiGetCurrentUserResponse(resp, true)))])
                : all([
                      put(UserReceivedAction(convertApiGetCurrentUserResponse(resp, false))),
                      put(SetLanguageAction(resp.lang)),
                      put(AcknowledgeNextErrorAction()), // If there are errors on the login page, remove them
                  ]),
        (err: string) =>
            action.payload.background
                ? all([put(UserReceivedFailureAction({ error: err, background: true })), put(ErrorOccurredAction(err))])
                : all([
                      put(UserReceivedFailureAction({ error: err, background: false })),
                      put(ForceLogoutAction()),
                      put(ErrorOccurredAction(err)),
                  ]),
    );
}

export function* sendPasswordReset(action: { payload: SendPasswordResetPayload }) {
    yield api.post(
        "/password_reset",
        action.payload,
        () => all([put(SendPasswordResetSuccessAction()), put(ForceLogoutAction())]),
        (err: string) => all([put(SendPasswordResetFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* acceptTermsAndConditionsAction() {
    yield api.post(
        "/current_user/terms_and_conditions_acceptance",
        {},
        () => all([put(FetchUserInformationAction({})), put(AcceptTermsAndConditionsSuccessAction())]),
        (err: string) => all([put(AcceptTermsAndConditionsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* sendCurrentUserPasswordReset(action: { payload: SendCurrentUserPasswordResetPayload }) {
    yield api.post(
        "/current_user/password",
        action.payload,
        () =>
            all([
                put(SendCurrentUserPasswordResetSuccessAction()),
                put(AddMessageAction({ message: "passwordReset.passwordChanged", type: MessageTypes.info })),
            ]),
        (err: string) => all([put(SendCurrentUserPasswordResetFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* setLanguage(action: { payload: string }) {
    if (!retrieveLanguage()) {
        storeLanguage(action.payload);
        yield i18next.changeLanguage(action.payload);
    }
}

export function* authSagas() {
    yield takeEvery(FetchUserInformationAction, fetchCurrentUser);
    yield takeEvery(SendPasswordResetAction, sendPasswordReset);
    yield takeEvery(SendCurrentUserPasswordResetAction, sendCurrentUserPasswordReset);
    yield takeEvery(SetLanguageAction, setLanguage);
    yield takeEvery(AcceptTermsAndConditionsAction, acceptTermsAndConditionsAction);
}
