import { useTranslation } from "react-i18next";
import { Role } from "../../../../redux/companies/companies-types";
import DropDown from "../../../../components/DropDown";

export function ManagerRole({ selectedValue, onchange }: { selectedValue: Role; onchange: (value: Role) => void }) {
    const t = useTranslation().t;
    const value = selectedValue !== "no role" ? selectedValue : "";
    return (
        <DropDown
            id={"managerRole"}
            options={[
                { value: "manager", displayText: t("roles.manager") },
                { value: "viewer", displayText: t("roles.viewer") },
            ]}
            selectedValue={value}
            placeholder={true}
            onChange={(e) => onchange(e.target.value)}
        ></DropDown>
    );
}
