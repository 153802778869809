import { Meter, MeterHistoryType, MeterType } from "../../../../redux/apartments/apartments-types";
import MetersTableHead from "./MetersTableHead";
import { Table, TableBody, TableContainer } from "../../../../components/Table";

import { Paper } from "@mui/material";
import MeterRow from "./MeterRow";
import { Company } from "../../../../redux/companies/companies-types";

export type FirstMetersByType = Record<MeterHistoryType, string | undefined>;

export const getMeterHistoryType = (meterType: MeterType): MeterHistoryType | null => {
    if (meterType === "water" || meterType === "energy" || meterType === "ambient") {
        return meterType;
    }
    return null;
};

export const isElectricityEnergyMeter = (meter: Meter) => {
    return meter.type === "energy" && !meter.heating && !meter.cooling;
};

const isTheFirstMeterByType = (meter: Meter, firstMetersByType: FirstMetersByType) => {
    const meterType = getMeterHistoryType(meter.type);
    if (meterType && !firstMetersByType[meterType]) {
        firstMetersByType[meterType] = meter.id;
    }
};

function calculateFirstMetersByType(meters: Meter[]) {
    const firstMetersByType: FirstMetersByType = {
        water: undefined,
        ambient: undefined,
        energy: undefined,
    };
    meters.forEach((meter) => isTheFirstMeterByType(meter, firstMetersByType));
    return firstMetersByType;
}

export default function MeterList(props: { meters: Meter[]; company: Company; apartmentId: number }): JSX.Element {
    const typeOrder: Record<MeterType, number> = {
        water: 0,
        ambient: 1,
        energy: 2,
        leakage: 3,
        smoke: 4,
    };

    const meterSorter = (a: Meter, b: Meter): number => {
        // First, sort by type
        const typeDiff = typeOrder[a.type] - typeOrder[b.type];
        if (typeDiff) {
            return typeDiff;
        }

        // Then sort water meters by cold ones first
        if (a.type === "water") {
            if (a.warm !== b.warm) {
                return a.warm ? 1 : -1;
            }
        }

        // Then energy meters with cooling ones first, then heating and lastly plain energy ones
        if (a.type === "energy") {
            if (a.cooling !== b.cooling) {
                return a.cooling ? -1 : 1;
            }
            if (a.heating !== b.heating) {
                return a.heating ? -1 : 1;
            }
        }

        // Sort by code if type and properties match
        return Number(a.code) - Number(b.code);
    };

    const sortedMeters = props.meters.sort((a, b) => meterSorter(a, b));
    const firstMetersByType = calculateFirstMetersByType(sortedMeters);

    return (
        <TableContainer component={Paper}>
            <Table>
                <MetersTableHead />
                <TableBody>
                    {sortedMeters.map((meter) => (
                        <MeterRow
                            meter={meter}
                            key={`${meter.type}_${meter.id}${meter.humidity ? ":humidity" : ""}`}
                            apartmentId={props.apartmentId}
                            company={props.company}
                            firstMetersByType={firstMetersByType}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
