import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../interfaces/RootState";
import React from "react";
import { Block } from "../../../components/common/block";
import { ChartModal } from "./ChartModal";
import {
    CloseChartAction,
    ToggleAllApartmentsAction,
    ToggleAllApartmentsLoadingAction,
} from "../../../redux/apartments/apartments-actions";
import { useTranslation } from "react-i18next";
import ExpandIcons from "../../../components/ExpandIcons";
import ApartmentsTableRow from "./ApartmentsTableRow";
import { Client } from "../../../redux/centers/centers-types";
import { alignments, spacings } from "../../../theme";
import { sortByAlphabetically } from "../../../utils/utils";
import { Company } from "../../../redux/companies/companies-types";

const ApartmentList = ({
    company,
    mergeApartmentsOn,
    apartmentsToMerge,
    setApartmentsToMerge,
    clients,
}: {
    company: Company;
    mergeApartmentsOn: boolean;
    apartmentsToMerge: number[];
    setApartmentsToMerge: React.Dispatch<React.SetStateAction<number[]>>;
    clients: Client[];
}) => {
    const chartModalOpen = useSelector((state: RootState) => state.hydrolink.apartments.chartState.isChartOpen);
    const apartments = useSelector((state: RootState) => state.hydrolink.apartments.apartments);
    const allApartmentsOpen = useSelector((state: RootState) => state.hydrolink.apartments.allApartmentsOpen);
    const dispatch = useDispatch();
    const t = useTranslation().t;

    const openAllApartmentBlocks = () => {
        dispatch(ToggleAllApartmentsLoadingAction());
        dispatch(ToggleAllApartmentsAction(!allApartmentsOpen));
    };

    return (
        <>
            <ChartModal
                isOpen={chartModalOpen}
                close={() => {
                    dispatch(CloseChartAction());
                }}
            />
            <Block
                onClick={() => openAllApartmentBlocks()}
                noMargin
                variant="white"
                style={{
                    cursor: "pointer",
                    ...alignments.evenHorizontal,
                }}
            >
                <span className="text-grey">{t("apartments.apartment")}</span>
                <div>
                    <span className="text-grey" style={{ marginRight: spacings.standardMargin }}>
                        {!allApartmentsOpen ? t("apartments.openAllApartments") : t("apartments.closeAllApartments")}
                    </span>
                    <ExpandIcons closed={!allApartmentsOpen} />
                </div>
            </Block>
            {apartments
                .sort((a, b) => sortByAlphabetically(a.code, b.code))
                .map((c) => {
                    return (
                        <ApartmentsTableRow
                            key={c.id}
                            apartment={c}
                            mergeApartmentsOn={mergeApartmentsOn}
                            company={company}
                            setApartmentsToMerge={setApartmentsToMerge}
                            apartmentsToMerge={apartmentsToMerge}
                            clients={clients}
                        />
                    );
                })}
        </>
    );
};

export default ApartmentList;
