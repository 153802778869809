import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { spacings } from "../../../../theme";
import { useTranslation } from "react-i18next";
import { Role } from "../../../../redux/companies/companies-types";
import { ManagerRole } from "./ManagerRole";
import { CompanyUser } from "./CompanyUser";

interface Props {
    manager: CompanyUser;
    changeRole: (id: number, role: Role) => void;
    makePrincipal: (id: number) => void;
}

const ManagerControls = (props: Props) => {
    const { t } = useTranslation();

    const changeRole = (value: Role) => {
        props.changeRole(props.manager.userId, value);
    };

    const makePrincipal = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            props.makePrincipal(props.manager.userId);
        }
    };

    return (
        <>
            <ManagerRole selectedValue={props.manager.role} onchange={changeRole} />
            <FormControlLabel
                style={{
                    marginLeft: spacings.inlineSpacing,
                    display: props.manager.role === "manager" ? "" : "none",
                }}
                control={<Switch checked={props.manager.principal === true} onChange={makePrincipal} />}
                label={String(t("roles.principal"))}
            />
        </>
    );
};

export default ManagerControls;
