import { User } from "../../redux/users/users-types";
import React from "react";
import { useDispatch } from "react-redux";
import { UpdateUserAdminAction } from "../../redux/users/users-actions";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { Tooltip } from "@mui/material";

type Props = {
    user: User;
    canChangeUserAdminStatus: boolean;
};

function AdminRightsButton({ user, canChangeUserAdminStatus }: Props): JSX.Element {
    const dispatch = useDispatch();
    const t = useTranslation().t;
    const [admin, setAdmin] = React.useState<boolean>(user.admin);

    const change = () => {
        if (canChangeUserAdminStatus) {
            dispatch(UpdateUserAdminAction({ userId: user.id, admin: !admin }));
            setAdmin(!admin);
        }
    };

    const tooltipTitle: string = !canChangeUserAdminStatus ? t("users.cannotRemoveYourOwnAdminRights") : "";

    return (
        <Tooltip title={tooltipTitle}>
            <div>
                <Button disabled={!canChangeUserAdminStatus} onClick={change}>
                    {admin ? t("users.removeAdminRights") : t("users.grantAdminRights")}
                </Button>
            </div>
        </Tooltip>
    );
}

export default AdminRightsButton;
