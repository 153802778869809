import { TableCell, TableRow, TextField } from "@mui/material";
import React from "react";
import FontAwesomeIconButton from "../../components/FontAwesomeIconButton";
import { OpenEditCenterCodeAction, UpdateCenterCodeAction } from "../../redux/centers/centers-actions";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Client } from "../../redux/centers/centers-types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import { useHasPrivileges } from "../../utils/useHasPrivilegesOf";
import { spacings } from "../../theme";

type Props = {
    client: Client;
    companyId: number;
};

function CenterCodeField({ client, companyId }: Props): JSX.Element {
    const dispatch = useDispatch();
    const isEditing = useSelector<RootState, boolean>((state) => state.hydrolink.centers.editingMode);
    const [code, setCode] = React.useState<string>(client.code);

    function onChange(ev: React.ChangeEvent<HTMLInputElement>): void {
        setCode(ev.target.value);
    }

    function onKeyPress(ev: React.KeyboardEvent): void {
        if (ev.key === "Enter") {
            ev.preventDefault();
            dispatch(
                UpdateCenterCodeAction({
                    clientId: client.id,
                    companyId: companyId,
                    newCode: code,
                    editingMode: false,
                }),
            );
        }
    }

    if (!isEditing) {
        return <span className="text-grey">{code}</span>;
    }

    return (
        <TextField
            id="center-code"
            size="small"
            value={code}
            autoFocus={true}
            onChange={onChange}
            onKeyPress={onKeyPress}
            inputProps={{ maxLength: 50 }}
        />
    );
}

export default function CenterCodeRow({ client, companyId }: Props): JSX.Element | null {
    const dispatch = useDispatch();
    const canSeeCenterCodeRow = useHasPrivileges("can see center code row", companyId).hasPrivilege;

    function onEditClick() {
        dispatch(OpenEditCenterCodeAction());
    }

    if (canSeeCenterCodeRow) {
        return (
            <TableRow key={client.id}>
                <TableCell colSpan={2} style={{ borderBottom: "none" }}>
                    <div style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}>
                        <CenterCodeField client={client} companyId={companyId} />
                        <FontAwesomeIconButton
                            onClick={onEditClick}
                            icon={faPen}
                            style={{ marginLeft: spacings.inlineSpacing }}
                        />
                    </div>
                </TableCell>
            </TableRow>
        );
    }
    return null;
}
