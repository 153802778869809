import React from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import { AlarmsLoadingState } from "../../redux/companies/companies-types";
import { alignments, colors } from "../../theme";
import DeviceNotifications from "./DeviceNotifications";
import { Meter } from "../../redux/apartments/apartments-types";

type Props = Readonly<{
    companyId: number;
    meter: Meter | null;
    alarms: string[];
    alarmLoadingState?: AlarmsLoadingState;
    isCompanyScope?: boolean;
}>;

const DeviceNotificationsCell: React.FC<Props> = ({
    companyId,
    meter,
    alarms,
    alarmLoadingState,
    isCompanyScope = false,
}) => {
    const renderNotifications = (): React.ReactNode => {
        switch (alarmLoadingState) {
            case undefined:
            case AlarmsLoadingState.loading:
                return <CircularProgress color="primary" size="20px"></CircularProgress>;
            case AlarmsLoadingState.failed:
                return <FontAwesomeIcon color={colors.warm06} icon={faXmark} data-testid={"LoadingFailedIcon"} />;
            case AlarmsLoadingState.success:
                return (
                    <DeviceNotifications
                        companyId={companyId}
                        isCompanyScope={isCompanyScope}
                        meter={meter}
                        alarms={alarms}
                    />
                );
            default:
                return null;
        }
    };

    return <div style={{ ...alignments.centered }}>{renderNotifications()}</div>;
};

export default DeviceNotificationsCell;
