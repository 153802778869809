import { Message, MessagesState, Size } from "./messages-types";
import { AddMessageAction, AcknowledgeMessageAction } from "./messages-actions";
import { AnyAction } from "redux";

const initialState: MessagesState = {
    messages: [],
};

export default function messagesReducer(state: MessagesState = initialState, action: AnyAction): MessagesState {
    if (AddMessageAction.match(action)) {
        const m: Message = {
            id: Date.now().toString(),
            message: action.payload.message,
            type: action.payload.type,
            size: action.payload.size ?? Size.small,
        };
        return {
            ...state,
            messages: [...state.messages, m],
        };
    }

    if (AcknowledgeMessageAction.match(action)) {
        const newMessages = state.messages.filter((e) => e.id !== action.payload.id);
        return {
            ...state,
            messages: newMessages,
        };
    }

    return state;
}
