import { all, put, takeEvery } from "redux-saga/effects";
import * as api from "../../api";
import { convertApiUser, convertCreateUserPayload, convertUpdateUserPayload } from "../../api/api-conversions";
import { ApiUser } from "../../api/api-types";
import { FetchCompaniesWithoutAlarmsAction } from "../companies/companies-actions";
import { ErrorOccurredAction } from "../error/error-actions";
import {
    CloseCreateUserModalAction,
    CreateNewUserPasswordAction,
    CreateNewUserPasswordFailureAction,
    CreateNewUserPasswordSuccessAction,
    CreateUserAction,
    CreateUserFailureAction,
    CreateUserSuccessAction,
    DeleteUserAction,
    DeleteUserFailureAction,
    DeleteUserSuccessAction,
    FetchUsersAction,
    FetchUsersFailureAction,
    FetchUsersSuccessAction,
    MassMarkPrincipalsWithCSVAction,
    MassMarkPrincipalsWithCSVFailureAction,
    MassMarkPrincipalsWithCSVSuccessAction,
    UpdateUserAction,
    UpdateUserAdminAction,
    UpdateUserAdminFailureAction,
    UpdateUserEmailAction,
    UpdateUserEmailFailureAction,
    UpdateUserEmailSuccessAction,
    UpdateUserFailureAction,
    UpdateUserSuccessAction,
} from "./users-actions";

import {
    CreateNewUserPasswordPayload,
    CreateUserPayload,
    DeleteUserPayload,
    MassMarkPrincipalsWithCSVPayload,
    UpdateUserAdminPayload,
    UpdateUserEmailPayload,
    UpdateUserPayload,
} from "./users-types";

export function* fetchAllUsers() {
    yield api.get(
        `/users`,
        (resp: ApiUser[]) => put(FetchUsersSuccessAction(resp.map(convertApiUser))),
        (err) => all([put(FetchUsersFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* updateUser(action: { payload: UpdateUserPayload }) {
    const body = convertUpdateUserPayload(action.payload);
    const userId: number = action.payload.id;

    yield api.put(
        `/users/${userId}/basic_info`,
        body,
        () => all([put(UpdateUserSuccessAction()), put(FetchUsersAction())]),
        (err) => all([put(UpdateUserFailureAction()), put(FetchUsersAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* updateUserAdmin(action: { payload: UpdateUserAdminPayload }) {
    const admin: boolean = action.payload.admin;
    const userId: number = action.payload.userId;

    yield api.put(
        `/users/${userId}/user_admin`,
        admin,
        () => all([put(UpdateUserSuccessAction()), put(FetchUsersAction())]),
        (err) => all([put(UpdateUserAdminFailureAction()), put(FetchUsersAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* deleteUser(action: { payload: DeleteUserPayload }) {
    const userId: number = action.payload;

    yield api.remove(
        `/users/${userId}`,
        {},
        () => all([put(DeleteUserSuccessAction(userId)), put(FetchUsersAction())]),
        (err) => all([put(DeleteUserFailureAction()), put(FetchUsersAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* createUser(action: { payload: CreateUserPayload }) {
    const body = convertCreateUserPayload(action.payload);

    yield api.post(
        `/users`,
        body,
        () => all([put(CreateUserSuccessAction()), put(CloseCreateUserModalAction()), put(FetchUsersAction())]),
        (err) => all([put(CreateUserFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* createNewUserPassword(action: { payload: CreateNewUserPasswordPayload }) {
    const userId: number = action.payload;

    yield api.post(
        `/users/${userId}/password`,
        {},
        () => put(CreateNewUserPasswordSuccessAction()),
        (err) => all([put(CreateNewUserPasswordFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* updateUserEmail(action: { payload: UpdateUserEmailPayload }) {
    const userId: number = action.payload.userId;
    const email: string = action.payload.newEmail;

    yield api.put(
        `/users/${userId}/email`,
        { email: email },
        () => all([put(UpdateUserEmailSuccessAction()), put(FetchUsersAction())]),
        (err) => all([put(UpdateUserEmailFailureAction()), put(FetchUsersAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* massMarkPrincipalsWithCSV(action: { payload: MassMarkPrincipalsWithCSVPayload }) {
    yield api.put(
        `/users/massMarkPrincipalsWithCsv`,
        { ...action.payload },
        () =>
            all([
                put(MassMarkPrincipalsWithCSVSuccessAction()),
                put(FetchUsersAction()),
                put(FetchCompaniesWithoutAlarmsAction()),
            ]),
        (err) =>
            all([
                put(MassMarkPrincipalsWithCSVFailureAction()),
                put(FetchUsersAction()),
                put(FetchCompaniesWithoutAlarmsAction()),
                put(ErrorOccurredAction(err)),
            ]),
    );
}

export function* usersSaga() {
    yield takeEvery(FetchUsersAction, fetchAllUsers);
    yield takeEvery(UpdateUserAction, updateUser);
    yield takeEvery(UpdateUserAdminAction, updateUserAdmin);
    yield takeEvery(CreateUserAction, createUser);
    yield takeEvery(CreateNewUserPasswordAction, createNewUserPassword);
    yield takeEvery(UpdateUserEmailAction, updateUserEmail);
    yield takeEvery(DeleteUserAction, deleteUser);
    yield takeEvery(MassMarkPrincipalsWithCSVAction, massMarkPrincipalsWithCSV);
}
