import ApiAccess from "./ApiAccess";
import ApiKey from "./ApiKey";
import DownloadIntegrationMeterIds from "./DownloadIntegrationMeterIds";

type Props = {
    companyId: number;
    apiKey: string | null;
};

export default function ApiSettings({ companyId, apiKey }: Props) {
    return (
        <div style={{ width: "100%" }}>
            <ApiAccess companyId={companyId} />
            <ApiKey companyId={companyId} apiKey={apiKey} />
            <DownloadIntegrationMeterIds companyId={companyId} />
        </div>
    );
}
