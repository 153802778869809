import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../components/Button";
import { OpenCreateUserModalAction } from "../../redux/users/users-actions";
import AddIcon from "@mui/icons-material/Add";
import { CreateUserModalState } from "../../redux/users/users-types";

type Props = {
    withAdminCheckbox: boolean;
};

export default function CreateUserButton({ withAdminCheckbox }: Props) {
    const dispatch = useDispatch();
    const t = useTranslation().t;

    const openCreateUserModal = () => {
        let e = withAdminCheckbox
            ? CreateUserModalState.OpenWithAdminCheckbox
            : CreateUserModalState.OpenWithoutAdminCheckbox;
        dispatch(OpenCreateUserModalAction(e));
    };

    return (
        <Button data-testid="create-new-user-button" onClick={openCreateUserModal} startIcon={<AddIcon />}>
            {t("users.createNew")}
        </Button>
    );
}
