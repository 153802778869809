import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import { Button } from "../../components/Button";
import { DownloadReportAction, FetchReportsAction } from "../../redux/reports/reports-actions";
import { DateChoosingTable } from "./DateChoosingTable";
import { convertDateToLocalDateString, displayDate } from "../../utils/utils";
import { Apartment, ReportResoluton } from "../../redux/apartments/apartments-types";
import { FetchReportFormatsAction } from "../../redux/apartments/apartments-actions";
import ReportFormatSelector from "../../components/ReportFormatSelector";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "../../components/Table";
import { spacings } from "../../theme";

function FormatTable({
    companyId,
    downloadReport,
    changeFormatAction,
    chosenFormat,
    invalidTimeSpan,
    datesAreValid,
}: {
    companyId: number;
    downloadReport: () => void;
    changeFormatAction: (e: string) => void;
    chosenFormat: string | undefined;
    invalidTimeSpan: boolean;
    datesAreValid: boolean;
}) {
    const t = useTranslation().t;

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("consumptionReporting.format")} </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={{ height: "4em", padding: "0 16px" }}>
                            <ReportFormatSelector
                                id={"format"}
                                style={{ width: "100%" }}
                                selected={chosenFormat}
                                changeFormatAction={changeFormatAction}
                                companyId={companyId}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Button
                                style={{ width: "100%" }}
                                onClick={downloadReport}
                                disabled={!chosenFormat || invalidTimeSpan || !datesAreValid}
                            >
                                {t("consumptionReporting.download")}
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

type Props = {
    apartments: Apartment[];
    companyId: number;
};

export function TopBlock({ companyId, apartments }: Props): JSX.Element {
    const [chosenFormat, setChosenFormat] = React.useState<string | undefined>(undefined);
    const reportFormats = useSelector((state: RootState) => state.hydrolink.apartments.reportFormats);
    const [from, setFrom] = React.useState<Date | null>(new Date());
    const [until, setUntil] = React.useState<Date | null>(new Date());
    const [datesAreValid, setDatesAreValid] = React.useState<boolean>(false);

    const dispatch = useDispatch();
    const t = useTranslation().t;
    const dayNotIncluded =
        reportFormats.find((r) => r.id === chosenFormat)?.resolution === ReportResoluton.MONTH ?? false;
    const invalidTimeSpan = (from?.getTime() ?? 0) > (until?.getTime() ?? 0);

    useEffect(() => {
        if (reportFormats.length === 0) {
            dispatch(FetchReportFormatsAction());
        }
    }, [reportFormats, dispatch]);

    const fetchReport = () => {
        if (from && isNaN(from.getTime())) {
            return;
        }
        if (until && isNaN(until.getTime())) {
            return;
        }
        setDatesAreValid(true);

        if (from && until && apartments) {
            const snapped = dayNotIncluded
                ? {
                      from: new Date(from.getFullYear(), from.getMonth(), 1),
                      until: new Date(until.getFullYear(), until.getMonth() + 1, 0),
                  }
                : { from, until };

            dispatch(
                FetchReportsAction({
                    companyId: companyId,
                    from: convertDateToLocalDateString(snapped.from),
                    until: convertDateToLocalDateString(snapped.until),
                    apartments: apartments,
                }),
            );
        }
    };

    const downloadReport = () => {
        if (from && until && chosenFormat) {
            dispatch(
                DownloadReportAction({
                    companyId: companyId,
                    from: convertDateToLocalDateString(from),
                    until: convertDateToLocalDateString(until),
                    format: chosenFormat,
                    usingNewLogic: false,
                }),
            );
        }
    };

    const changeFormatAction = (e: string) => {
        setChosenFormat(reportFormats.find((r) => r.id === e)?.id ?? reportFormats[0].id);
    };

    return (
        <>
            {" "}
            <h3>
                {t("consumptionReporting.consumption")}{" "}
                {from && until ? `(${displayDate(new Date(from))} - ${displayDate(new Date(until))})` : ""}
            </h3>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div style={{ width: "500px" }}>
                    <DateChoosingTable
                        from={from}
                        until={until}
                        setFrom={setFrom}
                        setUntil={setUntil}
                        dayNotIncluded={dayNotIncluded}
                        invalidTimeSpan={invalidTimeSpan}
                        fetchReport={fetchReport}
                        setDatesAreValid={setDatesAreValid}
                        datesAreValid={datesAreValid}
                    />
                </div>
                <div style={{ marginLeft: spacings.inlineSpacing }}>
                    <FormatTable
                        companyId={companyId}
                        downloadReport={downloadReport}
                        changeFormatAction={changeFormatAction}
                        chosenFormat={chosenFormat}
                        invalidTimeSpan={invalidTimeSpan}
                        datesAreValid={datesAreValid}
                    />
                </div>
            </div>
        </>
    );
}
