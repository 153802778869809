import { ResidentEditType } from "./ResidentEdit";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../../components/Button";
import { ModalInput } from "../../../components/common/modal/ModalInput";
import { alignments, spacings } from "../../../theme";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { DeleteResidentAction } from "../../../redux/residents/residents-actions";
import FontAwesomeIconButton from "../../../components/FontAwesomeIconButton";
import MoveInDate from "./MoveInDate";

export default function ResidentEditRow({
    resident,
    title,
    setResident,
    children,
}: {
    resident: ResidentEditType;
    setResident: React.Dispatch<React.SetStateAction<ResidentEditType>>;
    title: string;
    children: React.ReactNode;
}) {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const [email, setEmail] = React.useState<string>(resident.email);

    useEffect(() => {
        setEmail(resident.email);
    }, [resident]);

    const onChange = (e: string) => {
        setResident({ ...resident, email: e });
    };

    const deleteResident = () => {
        if (email.trim() !== "" && resident.id !== undefined) {
            if (window.confirm(`${t("residents.deleteResidentConfirmation")}?`)) {
                dispatch(
                    DeleteResidentAction({
                        companyId: resident.companyId,
                        residentId: resident.id,
                        apartmentId: resident.apartmentId,
                    }),
                );
                setResident({
                    ...resident,
                    id: undefined,
                    email: "",
                    moveInMonth: new Date(),
                });
            }
        }
    };

    const requestPasswordChangeForResident = () => {
        setResident({ ...resident, passwordChangeRequested: true });
    };

    return (
        <div
            style={{
                ...alignments.twoElementGrid,
                alignItems: "center",
                gridTemplateColumns: "repeat(5, 1fr)",
            }}
        >
            <ModalInput
                key={resident.id}
                title={title}
                value={email}
                handleFormValue={(e) => onChange(e.target.value)}
            />
            <div style={{ padding: `${spacings.inlineSpacing} 0` }}>
                <MoveInDate resident={resident} setResident={setResident} />
            </div>
            <div
                style={{
                    ...alignments.evenHorizontal,
                    padding: `2.9em ${spacings.standardSpacing} 0`,
                }}
            >
                <FontAwesomeIconButton
                    icon={faTrash}
                    tooltip={t("residents.removeResidentTooltip")}
                    disabled={!resident.id}
                    onClick={() => deleteResident()}
                    style={{
                        width: "0%",
                        height: "130%",
                        paddingRight: spacings.narrow,
                    }}
                />
                {resident.passwordChangeRequested ? (
                    <p style={{ width: "max-content" }}>{t("residents.newPasswordWillBeCreated")}</p>
                ) : (
                    <Button
                        disabled={!resident.id}
                        // onClick={() => createNewPasswordForResident(id!!)} [!]
                        onClick={() => requestPasswordChangeForResident()}
                        style={{ width: "max-content" }}
                    >
                        {t("residents.newPassword")}
                    </Button>
                )}
            </div>
            {children}
        </div>
    );
}
