import { useTranslation } from "react-i18next";
import { Contract, ContractState } from "../../redux/contracts/contracts-types";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeadCell,
    TableRow,
} from "../../components/Table";
import { Paper } from "@mui/material";
import { colors } from "../../theme";
import ActionButton from "./ActionButton";
import { AppDispatch } from "../../interfaces/RootState";
import { displayDateTime } from "../../utils/utils";

/**
 * One row in a {@link ContractsTable}.
 */
export type Row = {
    /**
     * Table-unique key for this row (for React).
     */
    key: number;

    /**
     * Type of the contract (translated), such as "Company DPA".
     */
    type: string;

    /**
     * Contract data. May be `null` if there is no contract yet.
     */
    contract: Contract | null;

    /**
     * Reason why the sign action is disabled. Passed to {@link ActionButton}.
     */
    signActionDisabledReason: string | null;

    /**
     * Callback to be called when the sign action is clicked. Passed to {@link ActionButton}.
     */
    onSignClicked: () => void;

    /**
     * Test ID for the action button.
     */
    actionButtonTestId?: string;
};

/**
 * Props for {@link ContractsTable}.
 */
export type Props = {
    /**
     * Redux dispatch function.
     */
    dispatch: AppDispatch;

    /**
     * Rows to be displayed in the table.
     */
    rows: Row[];
};

/**
 * Table that displays contracts and their {@link ActionButton}s.
 */
export default function ContractsTable({ dispatch, rows }: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeadCell>{t("contracts.table.header.contractType")}</TableHeadCell>
                        <TableHeadCell>{t("contracts.table.header.state")}</TableHeadCell>
                        <TableHeadCell>{t("contracts.table.header.updatedAt")}</TableHeadCell>
                        <TableHeadCell></TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.key}>
                            <TableCell>{row.type}</TableCell>

                            <ContractStateCell contract={row.contract} />

                            <TableCell>
                                {row.contract?.updatedAt !== undefined
                                    ? displayDateTime(new Date(row.contract.updatedAt))
                                    : "-"}
                            </TableCell>

                            <TableCell>
                                <ActionButton
                                    dispatch={dispatch}
                                    contract={row.contract}
                                    signActionDisabledReason={row.signActionDisabledReason}
                                    onSignClicked={row.onSignClicked}
                                    testId={row.actionButtonTestId}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function ContractStateCell({ contract }: { contract: Contract | null }) {
    const translatedState = useContractStateTranslation(contract);
    const color = getContractStateColor(contract?.state);

    return <TableCell style={color}>{translatedState}</TableCell>;
}

function useContractStateTranslation(contract: Contract | null): String {
    const { t } = useTranslation();

    switch (contract?.state) {
        case undefined:
            return t("contracts.state.MISSING");
        case ContractState.PENDING:
            return t("contracts.state.PENDING");
        case ContractState.CANCELLED:
            return t("contracts.state.CANCELLED");
        case ContractState.ACTIVE:
            return t("contracts.state.ACTIVE");
        case ContractState.UNKNOWN:
            return t("contracts.state.UNKNOWN");
    }
}

function getContractStateColor(state: ContractState | undefined): React.CSSProperties {
    switch (state) {
        case undefined:
            return { color: colors.rowColorRemove };
        case ContractState.PENDING:
            return { color: colors.rowColorRemove };
        case ContractState.CANCELLED:
            return { color: colors.rowColorRemove };
        case ContractState.ACTIVE:
            return { color: colors.success06 };
        case ContractState.UNKNOWN:
            return {};
    }
}
