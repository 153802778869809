import { useTranslation } from "react-i18next";
import { TableContainer, Table, TableBody } from "@mui/material";
import { Button } from "../../../../components/Button";
import { useDispatch } from "react-redux";
import { ReactElement, useCallback, useEffect, useMemo } from "react";
import { ManageCompanyUsersAction } from "../../../../redux/companies/companies-actions";
import { Company } from "../../../../redux/companies/companies-types";
import CreateUserModal from "../../../users/CreateUserModal";
import FloatButtonsRight from "../../../../components/FloatButtonsRight";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import AdminView from "./AdminView";
import ManagerAndViewerViews from "./ManagerAndViewerViews";
import { FetchUsersAction } from "../../../../redux/users/users-actions";
import { withEdits } from "./listEditor";
import { CompanyUser } from "./CompanyUser";
import CreateUserButton from "../../../users/CreateUserButton";
import useSortedAndFiltered from "../../../../utils/sortAndFilter";
import { createCompanyUsersPayload, getCompanyUserSortFunction, sortManagersById, withUserInfo } from "./ManagerUtils";
import { useUserActions } from "./hooks/useUserActions";
import ManagerTableHead from "./ManagerTableHead";
import { useAppSelector } from "../../../../utils/store-helpers";

export const Managers = ({ company }: { company: Company }) => {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    const managers = useAppSelector(
        (state) => state.hydrolink.companies.companies.find((c) => c.id === company.id)?.managers,
    );

    const users = useAppSelector((state) => state.hydrolink.users.users);
    const companies = useAppSelector((state) => state.hydrolink.companies);
    const currentSorting = useAppSelector((state) => state.hydrolink.users.sortBy);
    const currentAscending = useAppSelector((state) => state.hydrolink.users.ascending);

    const { edits, addNewUser, addExistingUser, deleteManager, changeRole, makePrincipal, setEdits } = useUserActions();

    const companyManagers: CompanyUser[] = useMemo(() => {
        const sorted = sortManagersById(managers ?? []);
        return sorted.flatMap((m) => withUserInfo(users, m) ?? []);
    }, [managers, users]);

    const canAddUsers = useHasPrivileges("is allowed to add users to the company", company.id).hasPrivilege;
    const canAddCompanyUser = useHasPrivileges("as an admin can add company user", company.id).hasPrivilege;
    const canSeeCreateUserButton = useHasPrivileges(
        "can see create user button in the managers block",
        company.id,
    ).hasPrivilege;

    useEffect(() => {
        // Fetch users and reset edits when companies/managers update
        dispatch(FetchUsersAction());
        setEdits([]);
    }, [companies, dispatch, managers, setEdits]);

    const editedManagers = useMemo(
        () => withEdits(users, companyManagers ?? [], edits),
        [edits, companyManagers, users],
    );

    const saveChanges = useCallback(() => {
        const payload = createCompanyUsersPayload(company.id, editedManagers);
        dispatch(ManageCompanyUsersAction(payload));
    }, [company.id, editedManagers, dispatch]);

    const haveManagersChanged = useMemo(
        () => editedManagers.some((cu) => cu.state.toBeCreated || cu.state.toBeUpdated || cu.state.toBeDeleted),
        [editedManagers],
    );

    const sortedAndFilteredManagers = useSortedAndFiltered(
        editedManagers,
        getCompanyUserSortFunction(currentSorting),
        currentAscending,
    );

    const RoleBasedView = useCallback(() => {
        return canAddCompanyUser ? (
            <TableBody data-testid="admin-view">
                <AdminView
                    company={company}
                    addUser={addExistingUser}
                    deleteManager={deleteManager}
                    editedManagers={sortedAndFilteredManagers.items}
                    users={users}
                    changeRole={changeRole}
                    makePrincipal={makePrincipal}
                />
            </TableBody>
        ) : (
            <TableBody data-testid="manager-and-viewer-view">
                <ManagerAndViewerViews
                    company={company}
                    addUser={addNewUser}
                    deleteManager={deleteManager}
                    editedManagers={sortedAndFilteredManagers.items}
                />
            </TableBody>
        );
    }, [
        canAddCompanyUser,
        company,
        addExistingUser,
        deleteManager,
        sortedAndFilteredManagers.items,
        users,
        changeRole,
        makePrincipal,
        addNewUser,
    ]);

    const addUserButtons = useMemo(() => {
        const buttons: ReactElement[] = [];
        if (canSeeCreateUserButton) {
            buttons.push(<CreateUserButton key="create-user" withAdminCheckbox={false} />);
        }
        buttons.push(
            <Button key="save" variant="contained" disabled={!haveManagersChanged} onClick={saveChanges}>
                {t("generic.save")}
            </Button>,
        );
        return buttons;
    }, [canSeeCreateUserButton, haveManagersChanged, saveChanges, t]);

    return (
        <div style={{ width: "100%" }}>
            <CreateUserModal />
            <TableContainer component="div">
                <Table aria-label="simple table">
                    <ManagerTableHead />
                    <RoleBasedView />
                </Table>
            </TableContainer>
            {canAddUsers && <FloatButtonsRight buttons={addUserButtons} />}
        </div>
    );
};
