import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from "../../components/Table";
import { Paper } from "@mui/material";
import { Apartment } from "../../redux/apartments/apartments-types";
import { spacings } from "../../theme";
import Sorter from "../../components/Sorter";
import { Column, TableItem } from ".";

export default function ReportTable({
    sortAction,
    sortBy,
    title,
    columns,
    ascending,
    apartments,
    unit,
}: {
    sortAction: (sortBy: string, ascending: boolean) => void;
    sortBy: string;
    title: string;
    columns: Column[];
    ascending: boolean;
    apartments: Apartment[];
    unit: string;
}) {
    const getValueWithUnit = (apartmentId: number, tableItems: TableItem[]) => {
        const tableItem = tableItems.find((t) => t.apartmentId === apartmentId);
        if (!tableItem) return "";
        return `${tableItem.value.toLocaleString("fi-FI")} ${unit}`;
    };

    return (
        <div style={{ marginTop: spacings.largeMargin }}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <Sorter
                                sortKey={"code"}
                                sortAction={sortAction}
                                sortBy={sortBy}
                                title={title}
                                currentAscending={ascending}
                            />
                            {columns.map((c) => (
                                <Sorter
                                    key={c.titleKey}
                                    sortKey={c.titleKey}
                                    sortAction={sortAction}
                                    sortBy={sortBy}
                                    title={c.titleText}
                                    currentAscending={ascending}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apartments.map((a) => (
                            <TableRow key={a.id}>
                                <TableCell>{a.code}</TableCell>
                                {columns.map((e) => (
                                    <TableCell key={e.titleKey + a.id}>{getValueWithUnit(a.id, e.content)}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
