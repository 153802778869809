import { createAction } from "@reduxjs/toolkit";
import {
    UploadCSVPayload,
    DeleteMeterPayload,
    DeleteApartmentPayload,
    FetchApartmentsPayload,
    FetchApartmentsSuccessPayload,
    FetchGraphsPayload,
    FetchGraphsSuccessPayload,
    PremiumSettings,
    SavePremiumSettingsPayload,
    FetchPremiumSettingsPayload,
    FetchAlarmSettingsPayload,
    AlarmSettings,
    SaveAlarmSettingsPayload,
    FetchReportSettingsPayload,
    ReportSettings,
    SaveReportSettingsPayload,
    ReportFormatsPayload,
    ChangeMeterPayload,
    UpdateApartmentNamePayload,
    AlarmCustomization,
    SaveAlarmCustomizationPayload,
    HydrodigitLRAlarmResetPayload,
    ToggleApartmentPayLoad,
    SendTestReportPayload,
    DownloadMeterUsageReportPayload,
    ToggleAlarmByMeterIdPayLoad,
    SendSetDataLoggerDownlinkPayload,
} from "./apartments-types";

export const FetchApartmentsAction = createAction<FetchApartmentsPayload>("FETCH_APARTMENTS");
export const FetchApartmentsSuccessAction = createAction<FetchApartmentsSuccessPayload>("FETCH_APARTMENTS_SUCCESS");
export const FetchApartmentsFailureAction = createAction<void>("FETCH_APARTMENTS_FAILURE");

export const ToggleApartmentAction = createAction<ToggleApartmentPayLoad>("TOGGLE_APARTMENT");
export const ToggleAllApartmentsAction = createAction<boolean>("TOGGLE_ALL_APARTMENTS");
export const ToggleAllApartmentsLoadingAction = createAction<void>("TOGGLE_ALL_APARTMENTS_LOADING");

export const ClearApartmentsAction = createAction<void>("CLEAR_APARTMENTS");

export const UpdateApartmentNameAction = createAction<UpdateApartmentNamePayload>("UPDATE_APARTMENT");
export const UpdateApartmentNameSuccessAction = createAction<void>("UPDATE_APARTMENT_SUCCESS");
export const UpdateApartmentNameFailureAction = createAction<void>("UPDATE_APARTMENT_FAILURE");

export const UploadCSVAction = createAction<UploadCSVPayload>("UPLOAD_CSV");
export const UploadCSVSuccessAction = createAction<void>("UPLOAD_CSV_SUCCESS");
export const UploadCSVFailureAction = createAction<void>("UPLOAD_CSV_FAILURE");

export const OpenChartAction = createAction<FetchGraphsPayload>("OPEN_CHART");
export const FetchGraphsSuccessAction = createAction<FetchGraphsSuccessPayload>("FETCH_GRAPHS_SUCCESS_ACTION");
export const FetchGraphsFailureAction = createAction<void>("FETCH_GRAPHS_FAILURE_ACTION");
export const CloseChartAction = createAction<void>("CLOSE_CHART");

export const DownloadMeterUsageReportAction = createAction<DownloadMeterUsageReportPayload>(
    "DOWNLOAD_METER_USAGE_REPORT_ACTION",
);
export const DownloadMeterUsageReportSuccessAction = createAction<void>("DOWNLOAD_METER_USAGE_REPORT_SUCCESS_ACTION");
export const DownloadMeterUsageReportFailureAction = createAction<void>("DOWNLOAD_METER_USAGE_REPORT_FAILURE_ACTION");

export const ChangeMeterAction = createAction<ChangeMeterPayload>("CHANGE_METER_ACTION");
export const ChangeMeterSuccessAction = createAction<void>("CHANGE_METER_SUCCESS_ACTION");
export const ChangeMeterFailureAction = createAction<void>("CHANGE_METER_FAILURE_ACTION");

export const DeleteMeterAction = createAction<DeleteMeterPayload>("DELETE_METER");
export const DeleteMeterSuccessAction = createAction<void>("DELETE_METER_SUCCESS");
export const DeleteMeterFailureAction = createAction<void>("DELETE_METER_FAILURE");

export const HydrodigitLRAlarmResetAction = createAction<HydrodigitLRAlarmResetPayload>("HYDRODIGIT_LR_ALARM_RESET");
export const HydrodigitLRAlarmResetSuccessAction = createAction<void>("HYDRODIGIT_LR_ALARM_RESET_SUCCESS");
export const HydrodigitLRAlarmResetFailureAction = createAction<void>("HYDRODIGIT_LR_ALARM_RESET_FAILURE");

export const DeleteApartmentAction = createAction<DeleteApartmentPayload>("DELETE_APARTMENT");
export const DeleteApartmentSuccessAction = createAction<void>("DELETE_APARTMENT_SUCCESS");
export const DeleteApartmentFailureAction = createAction<void>("DELETE_APARTMENT_FAILURE");

export const FetchPremiumSettingsAction = createAction<FetchPremiumSettingsPayload>("FETCH_PREMIUM_SETTINGS");
export const FetchPremiumSettingsSuccessAction = createAction<PremiumSettings>("FETCH_PREMIUM_SETTINGS_SUCCESS");
export const FetchPremiumSettingsFailureAction = createAction<void>("FETCH_PREMIUM_SETTINGS_FAILURE");

export const SavePremiumSettingsAction = createAction<SavePremiumSettingsPayload>("SAVE_PREMIUM_SETTINGS");
export const SavePremiumSettingsSuccessAction = createAction<void>("SAVE_PREMIUM_SETTINGS_SUCCESS");
export const SavePremiumSettingsFailureAction = createAction<void>("SAVE_PREMIUM_SETTINGS_FAILURE");

export const FetchAlarmSettingsAction = createAction<FetchAlarmSettingsPayload>("FETCH_ALARM_SETTINGS");
export const FetchAlarmSettingsSuccessAction = createAction<AlarmSettings>("FETCH_ALARM_SETTINGS_SUCCESS");
export const FetchAlarmSettingsFailureAction = createAction<void>("FETCH_ALARM_SETTINGS_FAILURE");

export const SaveAlarmSettingsAction = createAction<SaveAlarmSettingsPayload>("SAVE_ALARM_SETTINGS");
export const SaveAlarmSettingsSuccessAction = createAction<void>("SAVE_ALARM_SETTINGS_SUCCESS");
export const SaveAlarmSettingsFailureAction = createAction<void>("SAVE_ALARM_SETTINGS_FAILURE");

export const SaveAlarmCustomizationAction = createAction<SaveAlarmCustomizationPayload>("SAVE_ALARM_CUSTOMIZATION");
export const SaveAlarmCustomizationSuccessAction = createAction<AlarmCustomization>("SAVE_ALARM_CUSTOMIZATION_SUCCESS");
export const SaveAlarmCustomizationFailureAction = createAction<void>("SAVE_ALARM_CUSTOMIZATION_FAILURE");

export const FetchReportSettingsAction = createAction<FetchReportSettingsPayload>("FETCH_REPORT_SETTINGS");
export const FetchReportSettingsSuccessAction = createAction<ReportSettings>("FETCH_REPORT_SETTINGS_SUCCESS");
export const FetchReportSettingsFailureAction = createAction<void>("FETCH_REPORT_SETTINGS_FAILURE");

export const FetchReportFormatsAction = createAction<void>("FETCH_REPORT_FORMATS");
export const FetchReportFormatsSuccessAction = createAction<ReportFormatsPayload>("FETCH_REPORT_FORMATS_SUCCESS");
export const FetchReportFormatsFailureAction = createAction<void>("FETCH_REPORT_FORMATS_FAILURE");

export const SaveReportSettingsAction = createAction<SaveReportSettingsPayload>("SAVE_REPORT_SETTINGS");
export const SaveReportSettingsSuccessAction = createAction<void>("SAVE_REPORT_SETTINGS_SUCCESS");
export const SaveReportSettingsFailureAction = createAction<void>("SAVE_REPORT_SETTINGS_FAILURE");

export const SendTestReportAction = createAction<SendTestReportPayload>("SEND_TEST_REPORT_SETTINGS");
export const SendTestReportSuccessAction = createAction<void>("SEND_TEST_REPORT_SETTINGS_SUCCESS");
export const SendTestReportFailureAction = createAction<void>("SEND_TEST_REPORT_SETTINGS_FAILURE");

export const ToggleAlarmByMeterIdAction = createAction<ToggleAlarmByMeterIdPayLoad>("TOGGLE_ALARM_BY_METER_ID");

export const ToggleAlarmByMeterIdSuccessAction = createAction<void>("TOGGLE_ALARM_BY_METER_ID_SUCCESS");

export const ToggleAlarmByMeterIdFailureAction = createAction<void>("TOGGLE_ALARM_BY_METER_ID_FAILURE");

export const DeleteAllApartmentsAction = createAction<number>("DELETE_ALL_APARTMENTS");
export const DeleteAllApartmentsSuccessAction = createAction<void>("DELETE_ALL_APARTMENTS_SUCCESS");
export const DeleteAllApartmentsFailureAction = createAction<void>("DELETE_ALL_APARTMENTS_FAILURE");

export const SendSetDataLoggerDownlinkAction = createAction<SendSetDataLoggerDownlinkPayload>(
    "SEND_SET_DATA_LOGGER_DOWNLINK",
);
export const SendSetDataLoggerDownlinkSuccessAction = createAction<void>("SEND_SET_DATA_LOGGER_DOWNLINK_SUCCESS");
export const SendSetDataLoggerDownlinkFailureAction = createAction<void>("SEND_SET_DATA_LOGGER_DOWNLINK_FAILURE");
