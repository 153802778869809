import { createAction } from "@reduxjs/toolkit";
import {
    FetchReportsPayload,
    FetchReportsSuccessPayLoad,
    SortWaterMeterApartmentsPayload,
    SortEnergyMeterApartmentsPayload,
    FetchInitReportPayload,
    DownloadReportPayload,
    FetchInterimReportPayload,
} from "./reports-types";

export const FetchReportsAction = createAction<FetchReportsPayload>("FETCH_CONSUMPTION_REPORTS");
export const FetchReportsSuccessAction = createAction<FetchReportsSuccessPayLoad>("FETCH_CONSUMPTION_REPORTS_SUCCESS");
export const FetchReportsFailureAction = createAction<void>("FETCH_CONSUMPTION_REPORTS_FAILURE");

export const FetchInitReportAction = createAction<FetchInitReportPayload>("FETCH_INIT_REPORT");
export const FetchInitReportSuccessAction = createAction<void>("FETCH_INIT_REPORT_SUCCESS");
export const FetchInitReportFailureAction = createAction<void>("FETCH_INIT_REPORT_FAILURE");

export const FetchInterimReportAction = createAction<FetchInterimReportPayload>("FETCH_INTERIM_REPORT");
export const FetchInterimReportSuccessAction = createAction<void>("FETCH_INTERIM_REPORT_SUCCESS");
export const FetchInterimReportFailureAction = createAction<void>("FETCH_INTERIM_REPORT_FAILURE");

export const DownloadReportAction = createAction<DownloadReportPayload>("DOWNLOAD_REPORT");
export const DownloadReportSuccessAction = createAction<void>("DOWNLOAD_REPORT_SUCCESS");
export const DownloadReportFailureAction = createAction<void>("DOWNLOAD_REPORT_FAILURE");

export const SortWaterMeterApartmentsAction = createAction<SortWaterMeterApartmentsPayload>("SORT_WATER_APARTMENTS");
export const SortEnergyMeterApartmentsAction = createAction<SortEnergyMeterApartmentsPayload>("SORT_ENERGY_APARTMENTS");
