import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Message, Size } from "../../../redux/messages/messages-types";
import { RootState } from "../../../interfaces/RootState";
import { HydrolinkDispatch } from "../../../configureStore";
import { AcknowledgeMessageAction } from "../../../redux/messages/messages-actions";
import { useTranslation } from "react-i18next";

const Toasts = ({ messages, dispatch }: { messages: Message[]; dispatch: HydrolinkDispatch }): JSX.Element => {
    const t = useTranslation().t;

    const getAnchorOrigin = (m: Message): { vertical: "top" | "bottom"; horizontal: "left" | "center" | "right" } => {
        if (m.size === Size.large) {
            return {
                vertical: "top",
                horizontal: "center",
            };
        }
        return {
            vertical: "bottom",
            horizontal: "left",
        };
    };

    const getSnackbarStyles = (m: Message) => {
        if (m.size === Size.large) {
            return {};
        }
        return {};
    };

    const getAlertStyles = (m: Message) => {
        if (m.size === Size.large) {
            return {
                padding: "20px 30px 20px 30px",
            };
        }
        return {};
    };

    return (
        <React.Fragment>
            {messages.map((m) => {
                return (
                    <Snackbar
                        anchorOrigin={getAnchorOrigin(m)}
                        sx={getSnackbarStyles(m)}
                        open={true}
                        key={m.id}
                        autoHideDuration={10000}
                        onClose={() => {
                            dispatch(AcknowledgeMessageAction({ id: m.id }));
                        }}
                    >
                        <Alert
                            onClose={() => {
                                dispatch(AcknowledgeMessageAction({ id: m.id }));
                            }}
                            severity={m.type}
                            sx={{ width: "100%", ...getAlertStyles(m) }}
                        >
                            {t(m.message)}
                        </Alert>
                    </Snackbar>
                );
            })}
        </React.Fragment>
    );
};

export default function ConnectedToasts(): JSX.Element {
    const { messages } = useSelector((state: RootState) => state.hydrolink.messages);
    const dispatch = useDispatch();
    return <Toasts messages={messages} dispatch={dispatch} />;
}
