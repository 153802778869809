import React, { ChangeEventHandler } from "react";
import FontAwesomeIconButton from "../../FontAwesomeIconButton";
import { StyledBaseReadingTextField, StyledFieldsText } from "./Modal.style";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { spacings } from "../../../theme";

type Props = {
    title: string;
    value: string;
    tooltip?: string;
    type?: React.HTMLInputTypeAttribute;
    style?: React.CSSProperties;
    hidePassword?: boolean;
    setHidePassword?: React.Dispatch<React.SetStateAction<boolean>>;
    handleFormValue: ChangeEventHandler<HTMLInputElement>;
    dataTestId?: string;
};

export function ModalInput({
    title,
    value,
    tooltip,
    style,
    type,
    handleFormValue,
    hidePassword,
    setHidePassword,
    dataTestId,
}: Props) {
    const isPasswordField = type === "password";

    const getType = () => (isPasswordField && !hidePassword ? "text" : type);

    const getIcon = () => (hidePassword ? faEye : faEyeSlash);

    const clickHandler = () => {
        if (hidePassword !== undefined && setHidePassword) {
            setHidePassword(!hidePassword);
        }
    };

    const getPosition = () => (hidePassword ? "35px" : "36.2px");

    return (
        <div style={{ padding: `${spacings.inlineSpacing} 0`, ...style }}>
            <StyledFieldsText data-tip={tooltip}>{title}</StyledFieldsText>
            <div style={{ display: "flex", alignItems: "center" }}>
                <StyledBaseReadingTextField
                    style={{ minWidth: "280px" }}
                    type={getType()}
                    value={value}
                    onChange={handleFormValue}
                    data-testid={dataTestId}
                />
                {isPasswordField && (
                    <FontAwesomeIconButton
                        style={{
                            right: getPosition(),
                        }}
                        onClick={clickHandler}
                        icon={getIcon()}
                    />
                )}
            </div>
        </div>
    );
}
