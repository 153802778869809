import { useTranslation } from "react-i18next";
import { Table, TableHead, TableBody, TableCell, TableRow, TableHeadCell } from "../../components/Table";
import { SmokeAlarms } from "../../redux/smoke-alarms/smoke-alarms-types";
import { table } from "../../theme";
import FloatButtonsRight from "../../components/FloatButtonsRight";
import { Button } from "../../components/Button";
import { useDispatch } from "react-redux";
import { DownloadReportAction } from "../../redux/reports/reports-actions";
import { convertDateToLocalDateString } from "../../utils/utils";
import { asValidLanguage } from "../../components/common/language-selector/LanguageSelector";
import { AlarmType } from "../../redux/companies/companies-types";

export default function ActiveSmokeAlarms({
    activeSmokeAlarms,
    companyId,
    alarmTypes,
}: Readonly<{ activeSmokeAlarms: SmokeAlarms[]; companyId: number; alarmTypes: AlarmType[] }>) {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const language = useTranslation().i18n.language;

    const formatActivatedDate = (date?: string) => {
        if (date === null) {
            return "-";
        }
        return date;
    };

    const downloadActiveAlarms = () => {
        dispatch(
            DownloadReportAction({
                companyId: companyId,
                from: convertDateToLocalDateString(new Date()),
                until: convertDateToLocalDateString(new Date()),
                format: "active_smoke_alarms_pdf",
                usingNewLogic: true,
            }),
        );
    };

    return (
        <div style={{ paddingBottom: "50px" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeadCell>{t("apartments.apartment")}</TableHeadCell>
                        <TableHeadCell>{t("meters.code")}</TableHeadCell>
                        <TableHeadCell>{t("smokeAlarms.alarmType")}</TableHeadCell>
                        <TableHeadCell>{t("smokeAlarms.alarmStatusChanged")}</TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {activeSmokeAlarms.map((activeSmokeAlarm) =>
                        activeSmokeAlarm.activeAlarms.map((alarmName) =>
                            alarmTypes
                                .filter((alarmType) => alarmType.alarmName === alarmName)
                                .map((alarmType) => (
                                    <TableRow key={`${activeSmokeAlarm.meterCode}-${alarmName}`}>
                                        <TableCell>{activeSmokeAlarm.apartmentName}</TableCell>
                                        <TableCell>{activeSmokeAlarm.meterCode}</TableCell>
                                        <TableCell width={table.tableCellWidthMedium}>
                                            {alarmType.localizations[asValidLanguage(language)].alarmName}
                                        </TableCell>
                                        <TableCell>{formatActivatedDate(activeSmokeAlarm.activatedDate)}</TableCell>
                                    </TableRow>
                                )),
                        ),
                    )}
                </TableBody>
            </Table>
            <FloatButtonsRight
                buttons={[
                    <Button variant="outlined" onClick={downloadActiveAlarms}>
                        {t("smokeAlarms.download")}
                    </Button>,
                ]}
            />
        </div>
    );
}
