import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { FetchInitReportAction, FetchInterimReportAction } from "../../redux/reports/reports-actions";
import { spacings } from "../../theme";

const InitReport = () => {
    const params = useParams();
    const companyId = parseInt(params.companyId ?? "0");
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const buttonStyle = {
        width: "170px",
        marginTop: spacings.standardMargin,
    };

    const fetchInitReport = () => {
        dispatch(FetchInitReportAction({ companyId: companyId }));
    };

    const fetchInterimReport = () => {
        dispatch(FetchInterimReportAction({ companyId: companyId }));
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
            }}
        >
            <Button style={buttonStyle} onClick={fetchInitReport}>
                {t("initReport.download")}
            </Button>
            <Button style={buttonStyle} onClick={fetchInterimReport}>
                {t("initReport.interimReport")}
            </Button>
        </div>
    );
};

export default InitReport;
