import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { elementWithHeaderAndFooter } from "../../components/common/header/Header";
import { Typography } from "@mui/material";
import { Button } from "../../components/Button";
import Centered from "../../components/Centered";
import PasswordResetView from "../../components/PasswordResetView";

function NoToken({ t }: { t: TFunction<"translation"> }): JSX.Element {
    return (
        <Centered style={{ marginTop: "8em" }}>
            <Typography>{t("passwordReset.invalidToken")}</Typography>
            <Link to={"/login"} style={{ marginTop: "1em" }}>
                <Button>{t("passwordReset.toLoginPage")}</Button>
            </Link>
        </Centered>
    );
}

export default function ResidentPasswordReset() {
    const token = useParams().token;
    const t = useTranslation().t;

    return elementWithHeaderAndFooter(
        token ? <PasswordResetView resident={true} token={token} /> : <NoToken t={t} />,
        false,
    );
}
