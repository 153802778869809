import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch, Tooltip, Typography } from "@mui/material";
import { RootState } from "../../interfaces/RootState";
import { DeleteCompanyAction, PremiumToggleAction } from "../../redux/companies/companies-actions";
import { Company } from "../../redux/companies/companies-types";
import { TableCell, TableRow } from "../../components/Table";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import PremiumIcon from "../../assets/ICON_star.svg";
import FontAwesomeIconButton from "../../components/FontAwesomeIconButton";
import { User } from "../../redux/users/users-types";
import { CompanyRole } from "../../redux/auth/auth-types";
import { useHasPrivileges } from "../../utils/useHasPrivilegesOf";
import { alignments, typography } from "../../theme";
import { displayAlarms } from "../../utils/utils";
import DeviceNotificationsCell from "../../components/device_notifications_cell/DeviceNotificationsCell";

const getManagerCell = (company: Company, users: User[]) => {
    const principalManager = company.managers.find((manager) => manager.principal);

    if (principalManager !== undefined) {
        let foundPrincipalManager = users.find((u) => u.id === principalManager.userId);
        if (foundPrincipalManager !== undefined) {
            return foundPrincipalManager.email + (company.managers.length > 1 ? "..." : "");
        }
    }
};

type Props = Readonly<{
    company: Company;
    companyRole?: CompanyRole;
}>;

export default function CompanyTableRow({ company, companyRole }: Props) {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const canTogglePremium = useHasPrivileges("can toggle premium", company.id).hasPrivilege;
    const contractsNotNeeded = useHasPrivileges(
        "can see a company without contract requirements",
        company.id,
    ).hasPrivilege;
    const canDeleteCompany = useHasPrivileges("can delete company", company.id);
    const users = useSelector((state: RootState) => state.hydrolink.users.users);
    const canSeeAdminAlarms = useHasPrivileges("can see admin alarms", company.id).hasPrivilege;
    const alarmTypes = useSelector((state: RootState) => state.hydrolink.companies.alarmTypes);

    const alarmList = displayAlarms(company.alarmNames, canSeeAdminAlarms, alarmTypes);

    const deleteCompany = () => {
        let confirmation = prompt(t("companies.deleteConfirmation", { companyName: company.name }));

        if (confirmation === null) {
            return;
        }

        if (confirmation.toLocaleLowerCase() === company.name.toLowerCase()) {
            dispatch(DeleteCompanyAction(company.id));
        } else {
            alert(t("companies.invalidCompanyName", { companyName: company.name }));
        }
    };

    const changePremium = () => {
        let confirmed = false;
        if (
            company.premium &&
            window.confirm(t("companies.removePremiumConfirmation", { companyName: company.name }))
        ) {
            confirmed = true;
        } else if (
            !company.premium &&
            window.confirm(t("companies.addPremiumConfirmation", { companyName: company.name }))
        ) {
            confirmed = true;
        }
        if (confirmed) {
            dispatch(
                PremiumToggleAction({
                    companyId: company.id,
                    premium: !company.premium,
                }),
            );
        }
    };

    return (
        <TableRow>
            <TableCell>
                <span style={{ ...alignments.centered }}>
                    {canTogglePremium ? (
                        <FormControlLabel
                            label={<Typography sx={{ fontSize: typography.fontSize.small }}>Premium</Typography>}
                            control={<Switch checked={company.premium} onChange={() => changePremium()} />}
                        />
                    ) : (
                        company.premium && <img src={PremiumIcon} alt="premium-icon" width={"20px"} />
                    )}
                </span>
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
                {contractsNotNeeded || companyRole?.areRequiredContractsSigned ? (
                    <Link to={`/companies/${company.id}/`} style={{ fontSize: typography.fontSize.medium }}>
                        {company.name}
                    </Link>
                ) : (
                    <Tooltip title={t("contracts.tooltips.accessDeniedBecauseMissingContracts")}>
                        <span>{company.name}</span>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell>
                <DeviceNotificationsCell
                    companyId={company.id}
                    meter={null}
                    isCompanyScope={true}
                    alarmLoadingState={company.alarmLoadingState}
                    alarms={alarmList}
                />
            </TableCell>
            <TableCell>{company.streetAddress}</TableCell>
            <TableCell>{company.town ? company.town.toUpperCase() : ""}</TableCell>
            <TableCell>{getManagerCell(company, users)}</TableCell>
            <TableCell>
                <FontAwesomeIconButton
                    tooltip={t("companies.removeCompanyTooltip")}
                    disabled={!canDeleteCompany.hasPrivilege}
                    disabledTooltip={canDeleteCompany.missingRequirement}
                    onClick={() => deleteCompany()}
                    icon={faTrash}
                />
            </TableCell>
        </TableRow>
    );
}
