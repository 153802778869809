const _API_URL = process.env.REACT_APP_API_URL;
const _ASSETS_HOST_URL = process.env.REACT_APP_ASSETS_HOST;

function getApiURL() {
    return _API_URL;
}

function getAssetsHostURL() {
    return _ASSETS_HOST_URL;
}

export const API_URL = getApiURL();

export const ASSETS_HOST = getAssetsHostURL();
