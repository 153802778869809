import React, { Dispatch, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFilePicker } from "use-file-picker";
import { faSave, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../components/Button";
import { RootState } from "../../interfaces/RootState";
import { InsertResidentsAction, UploadResidentsAction } from "../../redux/residents/residents-actions";
import { Resident, ResidentData } from "../../redux/residents/residents-types";
import { spacings } from "../../theme";
import { getApartmentIdFromCode } from "../../utils/utils";
import { Alert } from "@mui/material";
import { FileContent } from "use-file-picker/dist/interfaces";
import { Apartment } from "../../redux/apartments/apartments-types";
import { AnyAction } from "redux";

type Props = {
    companyId: number;
};

type UploadingProps = {
    loading: boolean;
    openFileSelector: () => void;
};

type SaveProps = {
    companyId: number;
    residents: Resident[];
    loading: boolean;
    clear: () => void;
    dispatch: Dispatch<AnyAction>;
};

const getExistingResidentsFromApartment = (apartmentId: number, residents: Resident[]): Resident | undefined => {
    return residents.find((resident) => resident.apartmentId === apartmentId);
};

const createResidentData = (
    existingResident: ResidentData | undefined | null,
    email: string,
    moveInMonth: string,
): ResidentData | null => {
    if (
        existingResident &&
        email.trim() === existingResident.email &&
        moveInMonth === dataVisibleFromToMoveInMonthString(existingResident.dataVisibleFrom)
    ) {
        return existingResident;
    }

    if (!email || email.trim().length <= 0 || !moveInMonth || moveInMonth.length <= 0) {
        return null;
    }

    // This parsing could also be done with momentjs if the dates get more complicated, but for now this is fine
    const moveInMonthSections = moveInMonth.split(".");

    return {
        email,
        dataVisibleFrom: {
            month: parseInt(moveInMonthSections[0]),
            year: parseInt(moveInMonthSections[1]),
        },
    };
};

const dataVisibleFromToMoveInMonthString = (dataVisibleFrom: { year: number; month: number }) => {
    return `${dataVisibleFrom.month}.${dataVisibleFrom.year}`;
};

const parseFile = (filesContent: FileContent[], apartments: Apartment[], residents: Resident[]) => {
    const updatedResidents = [];

    try {
        const rows = filesContent[0].content
            .split("\n") // Separator
            .slice(1); // Skip header

        for (const row of rows) {
            // Skip empty lines
            if (row.trim().length <= 0) {
                continue;
            }

            const sections = row.split(";");

            const apartmentCode = sections[0];
            const mainResidentEmail = sections[1];
            const mainResidentMIM = sections[2];
            const otherResidentEmail = sections[3];
            const otherResidentMIM = sections[4];

            const apartmentId = getApartmentIdFromCode(apartmentCode, apartments);

            // No apartment found with the given apartmentCode. Maybe show an error here to the user?
            if (!apartmentId) {
                continue;
            }

            const existingResidents = getExistingResidentsFromApartment(apartmentId, residents);

            const main: ResidentData | null = createResidentData(
                existingResidents?.main,
                mainResidentEmail,
                mainResidentMIM,
            );
            const other: ResidentData | null = createResidentData(
                existingResidents?.other,
                otherResidentEmail,
                otherResidentMIM,
            );

            if (apartmentId && (main || other)) {
                updatedResidents.push({ apartmentId, main, other });
            }
        }

        return updatedResidents;
    } catch (error) {
        return undefined;
    }
};

const AddingResidents = ({ loading, openFileSelector }: UploadingProps): JSX.Element => {
    const t = useTranslation().t;
    return (
        <Button disabled={loading} onClick={() => openFileSelector()}>
            <FontAwesomeIcon icon={faUpload} style={{ marginRight: spacings.inlineSpacing }} />
            {t("residents.uploadResidents")}
        </Button>
    );
};

const SavingResidents = ({ companyId, residents, loading, clear, dispatch }: SaveProps): JSX.Element => {
    const t = useTranslation().t;
    return (
        <>
            <Alert severity="warning" style={{ marginRight: spacings.standardSpacing }}>
                {t("residents.notSavedYet")}
            </Alert>
            <Button
                disabled={loading}
                onClick={() => {
                    clear(); // Clears uploaded files
                    dispatch(UploadResidentsAction({ companyId, residents }));
                }}
            >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: spacings.inlineSpacing }} />
                {t("generic.save")}
            </Button>
        </>
    );
};

export default function UploadResidents(props: Props): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const residents = useSelector((state: RootState) => state.hydrolink.residents.residents);
    const fileRead = useSelector((state: RootState) => state.hydrolink.residents.residentsFileRead);
    const apartments = useSelector((state: RootState) => state.hydrolink.apartments.apartments);

    const [openFileSelector, { filesContent, loading, clear }] = useFilePicker({
        accept: [".csv", ".CSV"],
        multiple: false,
    });

    const fileSelected: boolean = filesContent[0] != null;

    useEffect(() => {
        if (fileSelected && !fileRead) {
            const res = parseFile(filesContent, apartments, residents);
            clear(); // Clears uploaded files
            if (res === undefined) {
                alert(t("residents.wrongCsvFormat"));
                return;
            }

            for (const residents of res) {
                dispatch(InsertResidentsAction(residents));
            }
        }
    }, [fileSelected, filesContent, apartments, residents, t, dispatch, clear, fileRead]);

    return fileRead ? (
        <SavingResidents
            companyId={props.companyId}
            residents={residents}
            loading={loading}
            clear={clear}
            dispatch={dispatch}
        />
    ) : (
        <AddingResidents loading={loading} openFileSelector={openFileSelector} />
    );
}
