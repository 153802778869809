import { Alert, AlertTitle, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FetchUserInformationAction } from "../../../redux/auth/auth-actions";
import { spacings } from "../../../theme";
import Centered from "../../Centered";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../utils/store-helpers";
import { useMemo } from "react";
import { usePeriodicCallback } from "../../../utils/usePeriodicCallback";

/**
 * Interval in milliseconds between contract refreshes.
 */
const REFRESH_INTERVAL_MS = 60 * 1000; // one minute

/**
 * Notification that is shown to the user when they have contracts that they can sign.
 *
 * Only shown under `/companies/*` since the other routes can be accessed without contracts (guides, contracts page,
 * ...) and the notification would just be a distraction there.
 */
export default function MissingContractsNotification(): JSX.Element {
    const dispatch = useDispatch();
    const t = useTranslation().t;
    const location = useLocation();

    const roleByCompanyId = useAppSelector((state) => state.hydrolink.auth.roleByCompanyId);
    const hasContractsUserCanSign = useMemo(
        () => Object.values(roleByCompanyId).some(({ hasContractsUserCanSign }) => hasContractsUserCanSign),
        [roleByCompanyId],
    );

    const shouldShowNotification = hasContractsUserCanSign && location.pathname.includes("/companies");

    // Refresh user information (including session contract statuses) periodically so the user does not have to reload
    // the page to see signature updates.
    usePeriodicCallback(
        () => shouldShowNotification && dispatch(FetchUserInformationAction({ background: true })),
        REFRESH_INTERVAL_MS,
    );

    if (!shouldShowNotification) {
        return <></>;
    }
    return (
        <Centered style={{ marginBottom: spacings.standardSpacing }}>
            <Alert severity="warning" style={{ width: "640px" }}>
                <AlertTitle>
                    <strong>{t("contracts.missingContractsNotification.title")}</strong>
                </AlertTitle>
                <p style={{ whiteSpace: "break-spaces" }}>{t("contracts.missingContractsNotification.attention")}</p>
                <p style={{ whiteSpace: "break-spaces" }}>{t("contracts.missingContractsNotification.description")} </p>
                <p style={{ whiteSpace: "break-spaces" }}>{t("contracts.missingContractsNotification.guide")}</p>
                <p>
                    <Link href={"/current-user-contracts"}>
                        {t("contracts.missingContractsNotification.openContractsView")}
                    </Link>
                </p>
            </Alert>
        </Centered>
    );
}
