import React from "react";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import {
    NoReadingsNotification,
    ZeroReadingsNotification,
} from "../../pages/company/apartments/apartments-table-row-components/ZeroOrNoReadingsNotification";
import { Meter, SMOKE_METER } from "../../redux/apartments/apartments-types";
import { AlarmType, Localization } from "../../redux/companies/companies-types";
import { asValidLanguage } from "../common/language-selector/LanguageSelector";
import { useHasPrivileges } from "../../utils/useHasPrivilegesOf";
import { colors } from "../../theme";
import { RootState } from "../../interfaces/RootState";

type Props = Readonly<{
    companyId: number;
    meter: Meter | null;
    isCompanyScope: boolean;
    alarms: string[];
}>;

const DeviceNotifications: React.FC<Props> = ({ companyId, meter, isCompanyScope = false, alarms }) => {
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin);
    const { hasPrivilege: userCanViewPremiumAlarms } = useHasPrivileges("can see premium alarms", companyId);
    const alarmTypes = useSelector((state: RootState) => state.hydrolink.companies.alarmTypes);

    const isSmokeMeter = meter?.type === SMOKE_METER;
    const hasPremiumAlarms = meter?.hasActivePremiumAlarms;
    const userCannotViewPremiumAlarms = !isAdmin && !userCanViewPremiumAlarms;

    const showPremiumAlarmsNotification = isSmokeMeter && hasPremiumAlarms && userCannotViewPremiumAlarms;
    const showNoReadingNotification = !isCompanyScope && !meter?.reading;
    const hasNotifications = alarms.length !== 0 || showPremiumAlarmsNotification || showNoReadingNotification;

    const getAlarmTranslation = (alarmType: AlarmType): Localization => {
        return alarmType.localizations[asValidLanguage(language)];
    };

    const getTooltipText = (alarmType: AlarmType) => {
        const translation = getAlarmTranslation(alarmType);
        return isCompanyScope ? translation.alarmName : translation.explanation ?? "";
    };

    const renderNoReadingNotification = (meter: Meter | null) => {
        if (meter?.reading === null) return <NoReadingsNotification meterType={meter.type} />;
        if (meter?.reading === 0) return <ZeroReadingsNotification />;
        return null;
    };

    const renderAlarm = ({
        key,
        tooltipContent,
        content,
        marginTop = showNoReadingNotification ? "20px" : "0px",
    }: {
        key: string;
        tooltipContent: string;
        content: JSX.Element;
        marginTop?: string;
    }) => (
        <Tooltip key={key} title={tooltipContent}>
            <div style={{ marginTop: marginTop }}>
                <FontAwesomeIcon color={colors.warm06} icon={faTriangleExclamation} />
                &nbsp;{content}
            </div>
        </Tooltip>
    );

    if (!hasNotifications) {
        return (
            <Tooltip title={t("alarms.descriptions.ok") ?? ""}>
                <DoneIcon className="text-green" />
            </Tooltip>
        );
    }

    if (isCompanyScope && alarms.length > 1) {
        return renderAlarm({
            key: `${companyId}-multiple`,
            tooltipContent: t("alarms.descriptions.multiple"),
            content: <></>,
            marginTop: "0px",
        });
    }

    let premiumAlarmsNotification: JSX.Element | null = null;
    if (showPremiumAlarmsNotification) {
        premiumAlarmsNotification = renderAlarm({
            key: `${meter.id}-premium`,
            tooltipContent: t("alarms.premiumAlarmsPromotion"),
            content: !isCompanyScope ? <>{t("alarms.deviceHasPremiumAlarms")}</> : <></>,
        });
    }

    return (
        <>
            {showNoReadingNotification && renderNoReadingNotification(meter)}
            {premiumAlarmsNotification}
            {alarms.map((alarm) =>
                alarmTypes
                    .filter((alarmType) => alarmType.alarmName === alarm)
                    .map((alarmType) =>
                        renderAlarm({
                            key: `${meter?.id}-${alarmType.alarmName}`,
                            tooltipContent: getTooltipText(alarmType),
                            content: !isCompanyScope ? <>{getAlarmTranslation(alarmType).alarmName}</> : <></>,
                        }),
                    ),
            )}
        </>
    );
};

export default DeviceNotifications;
