import { isSwedishVersion } from "../../utils/location";

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-content">{renderContent()}</div>
        </div>
    );
};

export default Footer;

const renderContent = () => {
    if (!isSwedishVersion()) {
        return (
            <>
                <p style={{ textDecoration: "underline" }}>onlinetuki@hydrolink.fi</p>
                <p>|</p>
                <p>Koka Oy 2024</p>
                <p>|</p>
                <p>010 286 2020</p>
            </>
        );
    }
    // Note: Empty footer content for SE version for now.
    // Waiting for the right content to be decided for SE.
    return null;
};
