import { all, put, takeEvery } from "redux-saga/effects";
import * as api from "../../api";
import {
    FetchAlarmsHistoryAction,
    FetchAlarmsHistoryFailureAction,
    FetchAlarmsHistorySuccessAction,
    FetchSmokeAlarmsAction,
    FetchSmokeAlarmsFailureAction,
    FetchSmokeAlarmsSuccessAction,
} from "./smoke-alarms-actions";
import { FetchAlarmsHistoryPayload, FetchSmokeAlarmsPayload } from "./smoke-alarms-types";
import { ApiMeterAlarmHistory, ApiSmokeAlarms } from "../../api/api-types";
import { convertApiAlarmHistoryEvent, convertApiSmokeAlarms } from "../../api/api-conversions";
import { ErrorOccurredAction } from "../error/error-actions";

export function* fetchSmokeAlarms(action: { payload: FetchSmokeAlarmsPayload }) {
    const companyId = action.payload.companyId;
    yield api.get(
        `/companies/${companyId}/smoke_alarms`,
        (resp: ApiSmokeAlarms[]) => put(FetchSmokeAlarmsSuccessAction(resp.map(convertApiSmokeAlarms))),
        (err) => all([put(FetchSmokeAlarmsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* fetchAlarmsHistory(action: { payload: FetchAlarmsHistoryPayload }) {
    const companyId = action.payload.companyId;

    yield api.get(
        `/companies/${companyId}/alarms_history/${action.payload.fromInclDate}/${action.payload.toInclDate}`,
        (resp: ApiMeterAlarmHistory[]) =>
            put(FetchAlarmsHistorySuccessAction(resp.flatMap(convertApiAlarmHistoryEvent))),
        (err) => all([put(FetchAlarmsHistoryFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* smokeAlarmsSaga() {
    yield takeEvery(FetchSmokeAlarmsAction, fetchSmokeAlarms);
    yield takeEvery(FetchAlarmsHistoryAction, fetchAlarmsHistory);
}
