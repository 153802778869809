import { Button } from "../../components/Button";
import { useTranslation } from "react-i18next";
import { Dialog } from "@mui/material";
import { useDispatch } from "react-redux";
import { Company } from "../../redux/companies/companies-types";
import { alignments, borders, colors, spacings } from "../../theme";
import { MarkNewPrincipalManagerMessageAsAcknowledgedAction } from "../../redux/companies/companies-actions";

export default function NewPrincipalManagreMessageModal({ company }: { company: Company }): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    const accept = () => {
        dispatch(
            MarkNewPrincipalManagerMessageAsAcknowledgedAction({
                companyId: company.id,
            }),
        );
    };

    return (
        <Dialog open={true} maxWidth="md" fullWidth>
            <div
                style={{
                    ...alignments.centered,
                }}
            >
                <form>
                    <div
                        style={{
                            textAlign: "center",
                            overflow: "hidden",
                        }}
                    >
                        {" "}
                        <br></br>
                        <div
                            style={{
                                ...borders.modalTextAreaBorder,
                                marginTop: "40px",
                                display: "block",
                                backgroundColor: colors.neutral04,
                                color: colors.neutral08,
                                width: "680px",
                                overflow: "auto",
                                padding: `${spacings.narrow} ${spacings.standardSpacing} ${spacings.narrow} ${spacings.standardSpacing}`,
                                textAlign: "left",
                                textJustify: "inter-word",
                            }}
                        >
                            <h2>{t("principalManagerIntroMessage.title")}</h2>
                            {t("principalManagerIntroMessage.firstParagraph", {
                                companyName: company.name,
                            })}
                            <br></br>
                            <br></br>
                            {t("principalManagerIntroMessage.secondParagraph")}
                            <br></br>
                            <br></br>
                        </div>
                    </div>

                    <div
                        style={{
                            float: "right",
                            marginTop: "25px",
                            marginBottom: "25px",
                        }}
                    >
                        <Button variant="outlined" onClick={accept}>
                            {t("principalManagerIntroMessage.ok")}
                        </Button>
                    </div>
                </form>
            </div>
        </Dialog>
    );
}
