import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../components/Button";
import { DownloadResidentsListAction } from "../../redux/residents/residents-actions";
import { spacings } from "../../theme";

type Props = {
    companyId: number;
};

export default function DownloadResidents({ companyId }: Props): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    const downloadResidentsList = () => {
        dispatch(DownloadResidentsListAction({ companyId }));
    };

    return (
        <Button style={{ marginLeft: spacings.standardSpacing }} onClick={downloadResidentsList}>
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: spacings.inlineSpacing }} />
            {t("residents.downloadResidents")}
        </Button>
    );
}
