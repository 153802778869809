import React from "react";
import { useTranslation } from "react-i18next";
import { Chip, Tooltip } from "@mui/material";
import { alignments, spacings } from "../../../../theme";

type Props = Readonly<{
    readonly title: string;
    readonly activeAlarmsCount: number;
    readonly altText: string;
    readonly iconSrc: any;
}>;

const NotificationsOnApartmentTableRow: React.FC<Props> = ({ title, activeAlarmsCount, altText, iconSrc }) => {
    const t = useTranslation().t;
    return (
        <Tooltip title={t(title, { count: activeAlarmsCount })}>
            <div
                style={{
                    height: spacings.narrow /* For tooltip position */,
                    marginLeft: spacings.standardSpacing,
                    marginRight: spacings.inlineSpacing,
                    ...alignments.evenHorizontal,
                }}
            >
                <Chip
                    size="medium"
                    label={activeAlarmsCount}
                    icon={
                        <img
                            alt={altText}
                            src={iconSrc}
                            style={{
                                width: "20px",
                                marginLeft: spacings.narrow,
                                paddingLeft: spacings.narrow,
                            }}
                        />
                    }
                />
            </div>
        </Tooltip>
    );
};

export default NotificationsOnApartmentTableRow;
