import { useTranslation } from "react-i18next";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import { AlarmDropDown, AlarmDropdownProps, parseBool } from "./AlarmDropdown";

const HighPriorityDropdown = ({ custom, alarmType, company, onChange }: AlarmDropdownProps) => {
    const t = useTranslation().t;

    const saveHighPriorityCustomization = (e: any) => {
        let value: string = e.target.value;

        let toSave = {
            alarmType: alarmType.alarmName,
            highPriority: parseBool(value),
            notifyOnNew: custom.notifyOnNew,
            alarmInterval: custom.alarmInterval,
            companyId: company.id,
        };

        onChange(toSave, company.id);
    };

    const canSetPremiumAlarms = useHasPrivileges("can set premium alarm settings", company.id).hasPrivilege;

    return (
        <AlarmDropDown
            id={`high-priority-dropdown-${alarmType.alarmName}`}
            disabled={!canSetPremiumAlarms}
            selectedValue={custom.highPriority !== null ? custom.highPriority.toString() : "null"}
            onChange={(e) => saveHighPriorityCustomization(e)}
            options={[
                {
                    value: "null",
                    displayText: alarmType.defaultHighPriority
                        ? `${t("generic.default")} (${t("generic.yes")})`
                        : `${t("generic.default")} (${t("generic.no")})`,
                },
                { value: "true", displayText: `${t("generic.yes")}` },
                { value: "false", displayText: `${t("generic.no")}` },
            ]}
        />
    );
};

export default HighPriorityDropdown;
