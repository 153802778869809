import { TooltipProps, tooltipClasses, Tooltip, styled } from "@mui/material";

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} placement="top" enterDelay={200} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "600px",
        fontSize: "large",
        backgroundColor: "black",
    },
});
