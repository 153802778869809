import { all, put, select, takeEvery } from "redux-saga/effects";
import * as api from "../../api";
import {
    convertApiAlarmSettings,
    convertApiApartment,
    convertApiPremiumSettings,
    convertApiReportFormat,
    convertApiReportSettings,
    convertFetchGraphsSuccessResponse,
    createChangeMeterRequestPayload,
    createCustomizationRequestPayload,
    createPremiumSettingsRequestPayload,
    createReportSettingsRequestPayload,
} from "../../api/api-conversions";
import {
    ApiAlarmSettings,
    ApiApartment,
    ApiPremiumSettings,
    ApiReportFormat,
    ApiReportSettings,
    FetchGraphsSuccessResponse,
} from "../../api/api-types";
import { API_URL } from "../../constants/urls";
import selectIdToken from "../auth/select-id-token";
import { ErrorOccurredAction } from "../error/error-actions";
import {
    ChangeMeterAction,
    ChangeMeterFailureAction,
    ChangeMeterSuccessAction,
    DeleteAllApartmentsAction,
    DeleteAllApartmentsFailureAction,
    DeleteAllApartmentsSuccessAction,
    DeleteApartmentAction,
    DeleteApartmentFailureAction,
    DeleteApartmentSuccessAction,
    DeleteMeterAction,
    DeleteMeterFailureAction,
    DeleteMeterSuccessAction,
    DownloadMeterUsageReportAction,
    DownloadMeterUsageReportFailureAction,
    DownloadMeterUsageReportSuccessAction,
    FetchAlarmSettingsAction,
    FetchAlarmSettingsFailureAction,
    FetchAlarmSettingsSuccessAction,
    FetchApartmentsAction,
    FetchApartmentsFailureAction,
    FetchApartmentsSuccessAction,
    FetchGraphsFailureAction,
    FetchGraphsSuccessAction,
    FetchPremiumSettingsAction,
    FetchPremiumSettingsFailureAction,
    FetchPremiumSettingsSuccessAction,
    FetchReportFormatsAction,
    FetchReportFormatsFailureAction,
    FetchReportFormatsSuccessAction,
    FetchReportSettingsAction,
    FetchReportSettingsFailureAction,
    FetchReportSettingsSuccessAction,
    HydrodigitLRAlarmResetAction,
    HydrodigitLRAlarmResetFailureAction,
    HydrodigitLRAlarmResetSuccessAction,
    OpenChartAction,
    SaveAlarmCustomizationAction,
    SaveAlarmCustomizationFailureAction,
    SaveAlarmCustomizationSuccessAction,
    SaveAlarmSettingsAction,
    SaveAlarmSettingsFailureAction,
    SaveAlarmSettingsSuccessAction,
    SavePremiumSettingsAction,
    SavePremiumSettingsFailureAction,
    SavePremiumSettingsSuccessAction,
    SaveReportSettingsAction,
    SaveReportSettingsFailureAction,
    SaveReportSettingsSuccessAction,
    SendSetDataLoggerDownlinkAction,
    SendSetDataLoggerDownlinkFailureAction,
    SendSetDataLoggerDownlinkSuccessAction,
    SendTestReportAction,
    SendTestReportFailureAction,
    SendTestReportSuccessAction,
    ToggleAlarmByMeterIdAction,
    ToggleAlarmByMeterIdFailureAction,
    ToggleAlarmByMeterIdSuccessAction,
    UpdateApartmentNameAction,
    UpdateApartmentNameFailureAction,
    UpdateApartmentNameSuccessAction,
    UploadCSVAction,
    UploadCSVFailureAction,
    UploadCSVSuccessAction,
} from "./apartments-actions";
import {
    AMBIENT_METER,
    ChangeMeterPayload,
    DeleteApartmentPayload,
    DeleteMeterPayload,
    DownloadMeterUsageReportPayload,
    FetchAlarmSettingsPayload,
    FetchApartmentsPayload,
    FetchGraphsPayload,
    FetchPremiumSettingsPayload,
    FetchReportSettingsPayload,
    HydrodigitLRAlarmResetPayload,
    MeterHistoryType,
    MeterType,
    SaveAlarmCustomizationPayload,
    SaveAlarmSettingsPayload,
    SavePremiumSettingsPayload,
    SaveReportSettingsPayload,
    SendSetDataLoggerDownlinkPayload,
    SendTestReportPayload,
    ToggleAlarmByMeterIdPayLoad,
    UpdateApartmentNamePayload,
    UploadCSVPayload,
} from "./apartments-types";
import { FetchCompaniesWithoutAlarmsAction } from "../companies/companies-actions";

export function* fetchApartments(action: { type: string; payload: FetchApartmentsPayload }) {
    const companyId: number = action.payload.companyId;

    yield api.get(
        `/companies/${companyId}/apartments`,
        (resp: ApiApartment[]) => put(FetchApartmentsSuccessAction(resp.map(convertApiApartment))),
        (err) => all([put(FetchApartmentsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* fetchGraphs(action: { payload: FetchGraphsPayload }) {
    const apartmentId: number = action.payload.apartmentId;
    const meterType: MeterHistoryType | null = action.payload.meterType;
    const type: string = action.payload.type;
    const offset: number = action.payload.offset;
    const reportType = meterType === AMBIENT_METER ? "report" : "usage_report";

    yield api.get(
        `/apartments/${apartmentId}/${meterType}_${reportType}?type=${type}&offset=${offset}`,
        (resp: FetchGraphsSuccessResponse) => put(FetchGraphsSuccessAction(convertFetchGraphsSuccessResponse(resp))),
        (err) => all([put(FetchGraphsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* fetchAlarmSettings(action: { payload: FetchAlarmSettingsPayload }) {
    const companyId: number = action.payload.companyId;

    yield api.get(
        `/companies/${companyId}/alarm_settings`,
        (res: ApiAlarmSettings) => put(FetchAlarmSettingsSuccessAction(convertApiAlarmSettings(res))),
        (err) => all([put(FetchAlarmSettingsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* fetchPremiumSettings(action: { payload: FetchPremiumSettingsPayload }) {
    const companyId: number = action.payload.companyId;

    yield api.get(
        `/companies/${companyId}/premium_settings`,
        (res: ApiPremiumSettings) => put(FetchPremiumSettingsSuccessAction(convertApiPremiumSettings(res))),
        (err) => all([put(FetchPremiumSettingsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* fetchReportSettings(action: { payload: FetchReportSettingsPayload }) {
    const companyId: number = action.payload.companyId;

    yield api.get(
        `/companies/${companyId}/report_settings`,
        (res: ApiReportSettings) => put(FetchReportSettingsSuccessAction(convertApiReportSettings(res))),
        (err) => all([put(FetchReportSettingsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* fetchReportFormats() {
    yield api.get(
        `/report_formats`,
        (res: ApiReportFormat[]) => put(FetchReportFormatsSuccessAction(res.map((r) => convertApiReportFormat(r)))),
        (err) => all([put(FetchReportFormatsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* uploadCSV(action: { type: string; payload: UploadCSVPayload }) {
    const companyId: number = action.payload.companyId;
    const file: string = action.payload.file;

    yield api.post(
        `/companies/${companyId}/apartments/csv`,
        { file: file },
        () =>
            all([
                put(UploadCSVSuccessAction()),
                put(FetchApartmentsAction({ companyId: companyId })),
                put(FetchCompaniesWithoutAlarmsAction()),
            ]),
        (err) => all([put(UploadCSVFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* deleteMeter(action: { payload: DeleteMeterPayload }) {
    const companyId: number = action.payload.companyId;
    const id: number = action.payload.id;
    const type: MeterType = action.payload.type;
    const body = {
        id: id,
        type: type,
    };

    yield api.remove(
        `/meter`,
        body,
        () => all([put(DeleteMeterSuccessAction()), put(FetchApartmentsAction({ companyId: companyId }))]),
        (err) => all([put(DeleteMeterFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* deleteApartment(action: { payload: DeleteApartmentPayload }) {
    const apartmentId: number = action.payload.id;
    const companyId: number = action.payload.companyId;
    const body = {
        id: apartmentId,
        companyId: companyId,
    };

    yield api.remove(
        `companies/${companyId}/apartments/${apartmentId}`,
        body,
        () => all([put(DeleteApartmentSuccessAction()), put(FetchApartmentsAction({ companyId: companyId }))]),
        (err) => all([put(DeleteApartmentFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* savePremiumSettings(action: { payload: SavePremiumSettingsPayload }) {
    const companyId: number = action.payload.companyId;
    const body = createPremiumSettingsRequestPayload(action.payload.settings);

    yield api.put(
        `/companies/${companyId}/premium_settings`,
        body,
        () => all([put(SavePremiumSettingsSuccessAction()), put(FetchPremiumSettingsAction({ companyId: companyId }))]),
        (err) => all([put(SavePremiumSettingsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* saveAlarmSettings(action: { payload: SaveAlarmSettingsPayload }) {
    const companyId: number = action.payload.companyId;
    const alarmLang: string = action.payload.alarmSettings.alarmLang;
    const stuckEmail: string = action.payload.alarmSettings.stuckEmail;
    const body = {
        alarmLang: alarmLang,
        stuckEmail: stuckEmail,
    };

    yield api.put(
        `/companies/${action.payload.companyId}/alarm_settings`,
        body,
        () => all([put(SaveAlarmSettingsSuccessAction()), put(FetchAlarmSettingsAction({ companyId: companyId }))]),
        (err) => all([put(SaveAlarmSettingsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* saveAlarmCustomization(action: { payload: SaveAlarmCustomizationPayload }) {
    const companyId: number = action.payload.companyId;
    const body = createCustomizationRequestPayload(action.payload.alarmCustomization);

    yield api.post(
        `/companies/${companyId}/alarm_customization`,
        body,
        () =>
            all([
                put(SaveAlarmCustomizationSuccessAction(action.payload.alarmCustomization)),
                put(FetchAlarmSettingsAction({ companyId: companyId })),
            ]),
        (err) => all([put(SaveAlarmCustomizationFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* saveReportSettings(action: { payload: SaveReportSettingsPayload }) {
    const companyId: number = action.payload.companyId;
    const body = createReportSettingsRequestPayload(action.payload.reportSettings);

    yield api.put(
        `/companies/${companyId}/report_settings`,
        body,
        () => all([put(SaveReportSettingsSuccessAction()), put(FetchReportSettingsAction({ companyId: companyId }))]),
        (err) => all([put(SaveReportSettingsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* sendTestReport(action: { payload: SendTestReportPayload }) {
    const companyId: number = action.payload.companyId;
    yield api.post(
        `/companies/${companyId}/test_report_request`,
        {},
        () => all([put(SendTestReportSuccessAction()), put(FetchReportSettingsAction({ companyId: companyId }))]),
        (err) => all([put(SendTestReportFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* changeMeter(action: { payload: ChangeMeterPayload }) {
    const companyId: number = action.payload.companyId;
    const meterId: number = action.payload.meterId;
    const body = createChangeMeterRequestPayload(action.payload);

    yield api.post(
        `/meter/${meterId}/mark_as_changed`,
        body,
        () => all([put(ChangeMeterSuccessAction()), put(FetchApartmentsAction({ companyId: companyId }))]),
        (err) => all([put(ChangeMeterFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* updateApartmentName(action: { payload: UpdateApartmentNamePayload }) {
    const apartmentId: number = action.payload.apartmentId;
    const companyId: number = action.payload.companyId;
    const apartmentCode: string = action.payload.name;

    yield api.put(
        `/apartments/${apartmentId}/name`,
        { apartmentCode: apartmentCode },
        () => all([put(UpdateApartmentNameSuccessAction()), put(FetchApartmentsAction({ companyId: companyId }))]),
        (err) => all([put(UpdateApartmentNameFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* hydrodigitLRAlarmReset(action: { payload: HydrodigitLRAlarmResetPayload }) {
    const meterId: number = action.payload.meterId;

    yield api.post(
        `/meter/${meterId}/alarm_reset`,
        {},
        () => put(HydrodigitLRAlarmResetSuccessAction()),
        (err) => all([put(HydrodigitLRAlarmResetFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* downloadMeterUsageReport(action: { payload: DownloadMeterUsageReportPayload }) {
    const getFileUrlEndpoint = () => {
        if (action.payload.meterType === "energy") {
            return "energy_meter_usage_report";
        }

        return "meter_usage_report";
    };

    const offset = action.payload.offset;

    const fileUrl = `${API_URL}/apartments/${action.payload.apartmentId}/${getFileUrlEndpoint()}/${
        action.payload.type
    }/${offset}`;
    const idToken: string = yield select(selectIdToken);

    yield api.download(
        fileUrl,
        "GET",
        null,
        [{ name: "Authorization", value: `Bearer ${idToken}` }],
        [put(DownloadMeterUsageReportSuccessAction())],
        [put(DownloadMeterUsageReportFailureAction())],
    );
}

export function* toggleAlarmByMeterId(action: { payload: ToggleAlarmByMeterIdPayLoad }) {
    const companyId: number = action.payload.companyId;

    yield api.put(
        `/companies/${companyId}/meter_alarm_deactivation`,
        {
            meterId: action.payload.meterId,
            meterType: action.payload.meterType,
            alarmType: action.payload.alarmType,
            deactivate: action.payload.deactivate,
        },
        () => all([put(ToggleAlarmByMeterIdSuccessAction()), put(FetchApartmentsAction({ companyId: companyId }))]),
        (err) => all([put(ToggleAlarmByMeterIdFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* deleteAllApartments(action: { payload: number }) {
    yield api.remove(
        `/companies/${action.payload}/apartments`,
        {},
        () => put(DeleteAllApartmentsSuccessAction()),
        (err) => all([put(DeleteAllApartmentsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* sendSetDataLoggerDownlink(action: { payload: SendSetDataLoggerDownlinkPayload }) {
    yield api.post(
        `/set_datalogger_downlink`,
        {
            meterId: action.payload.meterId,
            meterType: action.payload.meterType,
            value: action.payload.value,
        },
        () => put(SendSetDataLoggerDownlinkSuccessAction()),
        (err) => all([put(SendSetDataLoggerDownlinkFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* apartmentsSaga() {
    yield takeEvery(FetchApartmentsAction, fetchApartments);
    yield takeEvery(UploadCSVAction, uploadCSV);
    yield takeEvery(DeleteMeterAction, deleteMeter);
    yield takeEvery(OpenChartAction, fetchGraphs);
    yield takeEvery(DeleteApartmentAction, deleteApartment);
    yield takeEvery(FetchPremiumSettingsAction, fetchPremiumSettings);
    yield takeEvery(SavePremiumSettingsAction, savePremiumSettings);
    yield takeEvery(SendTestReportAction, sendTestReport);
    yield takeEvery(FetchAlarmSettingsAction, fetchAlarmSettings);
    yield takeEvery(SaveAlarmSettingsAction, saveAlarmSettings);
    yield takeEvery(FetchReportSettingsAction, fetchReportSettings);
    yield takeEvery(SaveReportSettingsAction, saveReportSettings);
    yield takeEvery(SaveAlarmCustomizationAction, saveAlarmCustomization);
    yield takeEvery(FetchReportFormatsAction, fetchReportFormats);
    yield takeEvery(UpdateApartmentNameAction, updateApartmentName);
    yield takeEvery(HydrodigitLRAlarmResetAction, hydrodigitLRAlarmReset);
    yield takeEvery(ChangeMeterAction, changeMeter);
    yield takeEvery(DownloadMeterUsageReportAction, downloadMeterUsageReport);
    yield takeEvery(ToggleAlarmByMeterIdAction, toggleAlarmByMeterId);
    yield takeEvery(DeleteAllApartmentsAction, deleteAllApartments);
    yield takeEvery(SendSetDataLoggerDownlinkAction, sendSetDataLoggerDownlink);
}
