import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../components/Button";
import { Block } from "../../../components/common/block";
import ExpandableBlock from "../../../components/ExpandableBlock";
import { RootState } from "../../../interfaces/RootState";
import { OpenCompanyBasicInfoModal } from "../../../redux/companies/companies-actions";
import { Company } from "../../../redux/companies/companies-types";
import { CompanyBasicInfoModal } from "../../companies/CompanyBasicInfoModal";
import { TableContainer, Table, TableBody } from "../../../components/Table";
import { useHasPrivileges } from "../../../utils/useHasPrivilegesOf";
import { TableRowWithLabel } from "../../../components/TableRowWithLabel";
import { alignments } from "../../../theme";

export default function BasicCompanyInfo({ company }: { company: Company }) {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const modalOpen = useSelector((state: RootState) => state.hydrolink.companies.companyBasicInfoModalOpen);
    const canUpdateCompanyInfo = useHasPrivileges("can update basic company info", company.id).hasPrivilege;

    const title = t("companies.basicInfo");

    const handleOpenCreateCompanyBasicInfoModal = () => {
        dispatch(OpenCompanyBasicInfoModal());
    };

    return canUpdateCompanyInfo ? (
        <>
            <CompanyBasicInfoModal isOpen={modalOpen} company={company} />

            <Block variant={"white"}>
                <div
                    style={{
                        ...alignments.evenHorizontal,
                    }}
                >
                    {title}
                    <Button variant="outlined" onClick={() => handleOpenCreateCompanyBasicInfoModal()}>
                        {t("generic.edit")}
                    </Button>
                </div>
            </Block>
        </>
    ) : (
        <ExpandableBlock leftSide={title}>
            <div style={{ width: "50%" }}>
                <TableContainer component="div">
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRowWithLabel htmlFor={"name"} label={t("companies.create.name")}>
                                {company.name}
                            </TableRowWithLabel>
                            <TableRowWithLabel htmlFor={"streetAddress"} label={t("companies.create.streetAddress")}>
                                {company.streetAddress}
                            </TableRowWithLabel>
                            <TableRowWithLabel htmlFor={"zipCode"} label={t("companies.create.zipCode")}>
                                {company.zipCode}
                            </TableRowWithLabel>
                            <TableRowWithLabel htmlFor={"town"} label={t("companies.create.town")}>
                                {company.town ? company.town.toUpperCase() : ""}
                            </TableRowWithLabel>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </ExpandableBlock>
    );
}
