import React from "react";
import { faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableCell, TableRow } from "../../components/Table";
import { Client } from "../../redux/centers/centers-types";
import ExpandIcons from "../../components/ExpandIcons";
import { borders } from "../../theme";
import CentersInfo from "./CentersInfo";
import CenterCodeRow from "./CenterCodeRow";

type Props = {
    client: Client;
    companyId: number;
};

export function CentersRow({ client, companyId }: Props): JSX.Element {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    return (
        <>
            <TableRow key={client.id} onClick={() => setExpanded(!expanded)} style={{ cursor: "pointer" }}>
                <TableCell>
                    <span className="text-black">{client.code}</span>
                    <span style={{ paddingLeft: "1em" }}>
                        {client.wireless ? <FontAwesomeIcon icon={faWifi} size="1x" /> : null}
                    </span>
                </TableCell>
                <TableCell style={{ width: "1.8em" }}>
                    <ExpandIcons closed={!expanded} />
                </TableCell>
            </TableRow>
            {expanded && (
                <>
                    <CenterCodeRow client={client} companyId={companyId} />
                    <TableRow key={`exp_${client.id}`}>
                        <TableCell colSpan={2}>
                            <div style={borders.basicOutline}>
                                <CentersInfo client={client} />
                            </div>
                        </TableCell>
                    </TableRow>
                </>
            )}
        </>
    );
}
