import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { colors, spacings } from "../theme";

type Props = {
    sortKey: string;
    sortBy: string;
    ascending: boolean;
};

export default function SorterIcon(props: Props) {
    const color = props.sortKey === props.sortBy ? colors.success06 : colors.neutral06;

    if (props.sortKey === props.sortBy) {
        return (
            <FontAwesomeIcon
                size="lg"
                color={color}
                icon={props.ascending ? faAngleDown : faAngleUp}
                style={{
                    marginLeft: spacings.inlineSpacing,
                    position: "relative",
                    bottom: "-3px",
                }}
            />
        );
    }
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: spacings.inlineSpacing,
                position: "relative",
                bottom: "3px",
            }}
        >
            <FontAwesomeIcon size="lg" color={color} icon={faAngleUp} />
            <FontAwesomeIcon size="lg" color={color} icon={faAngleDown} style={{ marginTop: "-7px" }} />
        </div>
    );
}
