import { elementWithHeaderAndFooter } from "../../components/common/header/Header";
import PdfView, { LanguageToUrlMap } from "../../components/PdfView";
import { ViewContainer } from "../../components/ViewContainer";

export default function Guides() {
    const languageToUrlMap: LanguageToUrlMap = {
        default: "/documents/hydrolink_fi_online_guide_fi.pdf",
        se: "/documents/hydrolink_se_online_guide_sv.pdf",
        sv: "/documents/hydrolink_fi_online_guide_sv.pdf",
    };

    return elementWithHeaderAndFooter(
        <ViewContainer>
            <PdfView title="Manual" languageToUrlMap={languageToUrlMap} />;
        </ViewContainer>,
    );
}
