import { Apartment } from "../../../../redux/apartments/apartments-types";
import { faPen, faSave, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { UpdateApartmentNameAction } from "../../../../redux/apartments/apartments-actions";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import FontAwesomeIconButton from "../../../../components/FontAwesomeIconButton";
import { TextField } from "@mui/material";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import { spacings } from "../../../../theme";

type IconButtonData = {
    icon: IconDefinition;
    tooltip: string;
    disabledTooltip: string;
};

type IconButtonType = "edit" | "save";

export default function ApartmentRenaming({
    apartment,
    companyId,
}: {
    apartment: Apartment;
    companyId: number;
}): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const apartmentNameEditingField = useRef();
    const [nameEditingOn, setNameEditingOn] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>(apartment.code);
    const canRenameApartment = useHasPrivileges("can rename apartment", companyId);

    const icon: Record<IconButtonType, IconButtonData> = {
        edit: {
            icon: faPen,
            tooltip: t("apartments.renameApartmentTooltip"),
            disabledTooltip: canRenameApartment.missingRequirement ?? "",
        },
        save: {
            icon: faSave,
            tooltip: t("apartments.saveRenamedApartmentTooltip"),
            disabledTooltip: t("apartments.apartmentNameIsEmpty"),
        },
    };

    // `trim()` is called to make sure the user doesn't give a blank apartment name (such as just whitespaces)
    const isApartmentNameBlank = (): boolean => name.trim().length <= 0;

    const selectIcon: IconButtonType = nameEditingOn ? "save" : "edit";

    const buttonDisabled = (): boolean => {
        if (!canRenameApartment.hasPrivilege) return true;
        return isApartmentNameBlank();
    };

    const getApartmentNameHelperText = (): string | null =>
        isApartmentNameBlank() ? t("apartments.apartmentNameIsEmpty") : null;

    const changeNameEditingStatus = () => {
        if (isApartmentNameBlank()) {
            if (apartmentNameEditingField.current) {
                (apartmentNameEditingField.current as HTMLElement).focus();
            }
            return;
        }

        if (nameEditingOn && name !== apartment.code) {
            dispatch(
                UpdateApartmentNameAction({
                    companyId: companyId,
                    apartmentId: apartment.id,
                    name: name,
                }),
            );
        }

        setNameEditingOn(!nameEditingOn);
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}>
            {!nameEditingOn ? (
                <></>
            ) : (
                <TextField
                    id="name"
                    size="small"
                    value={name}
                    autoFocus={true}
                    error={isApartmentNameBlank()}
                    inputRef={apartmentNameEditingField}
                    helperText={getApartmentNameHelperText()}
                    onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                            ev.preventDefault();
                            changeNameEditingStatus();
                        }
                    }}
                    onBlur={() => {
                        if (!nameEditingOn) {
                            changeNameEditingStatus();
                        }
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                />
            )}
            <FontAwesomeIconButton
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    changeNameEditingStatus();
                }}
                disabled={buttonDisabled()}
                icon={icon[selectIcon].icon}
                tooltip={icon[selectIcon].tooltip}
                disabledTooltip={icon[selectIcon].disabledTooltip}
                style={{
                    marginLeft: spacings.inlineSpacing,
                    alignItems: "center",
                }}
            />
        </div>
    );
}
