import React from "react";
import { Alert } from "@mui/material";
import { Info } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { colors } from "../theme";

const BannerContainer = styled("div")({
    top: 0,
    width: "100%",
    zIndex: 1300,
    display: "flex",
    justifyContent: "center",
    padding: "10px 0",
    backgroundColor: colors.blue01,
    marginBottom: "1rem",
});

const BannerTitle = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: "white",
    gap: "8px",
    width: "100%",
    textAlign: "center",
    marginBottom: "0.5rem",
});

const StyledInfoIcon = styled(Info)({
    color: "white",
    fontSize: "1.75rem",
});

const formatParagraphsInBody = (bodyText: string): JSX.Element[] =>
    bodyText.split("\n").map((line, _) => (
        <span key={line} style={{ display: "block", marginBottom: "0.5em" }}>
            {line}
        </span>
    ));

/**
 * NoticeBanner component can be used to display important notices to users
 * e.g. in Login page informing users about upcoming maintenance events.
 *
 * The component is anchored to the top of the page and displays a notice banner.
 * This means that only one notice banner can be displayed at a time.
 */
const NoticeBanner: React.FC = () => {
    const { t } = useTranslation();
    const title = t("noticeBanner.title");
    const body = formatParagraphsInBody(t("noticeBanner.body"));

    return (
        <BannerContainer>
            <Alert
                severity="info"
                icon={false}
                sx={{
                    color: "white",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    maxWidth: "800px",
                    width: "100%",
                    padding: "0 20px",
                }}
            >
                <BannerTitle>
                    <StyledInfoIcon />
                    {title}
                </BannerTitle>
                {body}
            </Alert>
        </BannerContainer>
    );
};

export default NoticeBanner;
