import BaseState from "../interfaces/BaseState";
import { Apartment, MeterType } from "../redux/apartments/apartments-types";
import { AlarmType } from "../redux/companies/companies-types";

export function displayDateTime(d: Date | null): string {
    if (d === null) {
        return "";
    }
    if (d.getDate === undefined) {
        return "";
    }
    if (isNaN(d.getDate())) {
        return "";
    }
    var dd = `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
    var t = `0${d.getHours()}`.slice(-2) + ":" + `0${d.getMinutes()}`.slice(-2);
    return dd + " " + t;
}

export function displayDate(d: Date | null): string {
    if (d === null) {
        return "";
    }
    if (d.getDate === undefined) {
        return "";
    }
    if (isNaN(d.getDate())) {
        return "";
    }
    var dd = `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
    return dd;
}

/**
 * @param offsetDays current offset
 * @param date (current) date
 * @param direction number of years moved in relation to the date - offset
 * @returns the number of days it takes to move direction full years from date - offsetDays
 */
export function getNextYearOffsetDays(offsetDays: number, date: Date, direction: number): number {
    const offsetDate = new Date(date);
    offsetDate.setDate(date.getDate() - offsetDays);
    const nextDate = new Date(offsetDate);
    nextDate.setFullYear(nextDate.getFullYear() + direction);
    return Math.round((offsetDate.getTime() - nextDate.getTime()) / (1000 * 3600 * 24));
}

/**
 * @param offsetDays current offset
 * @param date (current) date
 * @param direction number of months moved in relation to the date - offset
 * @returns the number of days it takes to move direction full months from date - offsetDays
 */
export function getNextMonthOffsetDays(offsetDays: number, date: Date, direction: number): number {
    const offsetDate = new Date(date);
    offsetDate.setDate(offsetDate.getDate() - offsetDays);
    const nextDate = new Date(offsetDate);
    nextDate.setMonth(nextDate.getMonth() + direction);
    return Math.round((offsetDate.getTime() - nextDate.getTime()) / (1000 * 3600 * 24));
}

export function convertDateToLocalDateString(date: Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 10);
}

/**
 * @param meterType Type of the meter
 * @param humidity Whether the meter is a humidity meter or not as they don't have their own type
 * @returns Translation key for the correct unit for the meter type
 */
export function getMeterUnit(meterType: MeterType, humidity: boolean = false): string {
    return `meters.units.${humidity ? "humidity" : meterType}`;
}

// Email validation regex
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const hasUsageData = (usageByMeterId: Record<any, any> | undefined): boolean =>
    usageByMeterId !== undefined && Object.keys(usageByMeterId).length > 0;

// js-file-downloader doesn't give an export to their `DownloadError` type so we make and export one here
export interface DownloadError extends Error {
    request: XMLHttpRequest;
}

// `trim()` is necessary or this won't ever give true on comparisons (for example "As1" === "As1"), don't ask why because idk either
export const getApartmentIdFromCode = (apartmentCode: string, apartments: Apartment[]): number | undefined =>
    apartments.find((apartment) => apartment.code.trim() === apartmentCode.trim())?.id;

export const convertMeterStringIdToInt = (id: string): number => {
    let i = id.indexOf(":");
    let idInt: number | undefined = undefined;
    if (i !== -1) {
        idInt = parseInt(id.substring(0, i));
    } else {
        idInt = parseInt(id);
    }
    return idInt;
};

export const sortByAlphabetically = (a: string, b: string) => a.localeCompare(b, undefined, { numeric: true });

export const displayAlarms = (alarms: string[], canSeeAdminAlarms: boolean, companyAlarms: AlarmType[]) => {
    let validAlarmTypesNames: string[] = [];

    companyAlarms.forEach((alarm) => {
        if (alarm.visibleForUser || canSeeAdminAlarms) {
            validAlarmTypesNames.push(alarm.alarmName);
        }
    });

    return alarms.filter((alarm) => validAlarmTypesNames.includes(alarm));
};

export const stateWithLoadingTrue = <T extends BaseState>(state: T): T => ({ ...state, loading: state.loading + 1 });
export const stateWithLoadingFalse = <T extends BaseState>(state: T): T => ({ ...state, loading: state.loading - 1 });
