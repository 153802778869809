import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../components/Button";
import { Block } from "../../../../components/common/block";
import { alignments, borders, spacings, textInputForDataPicker } from "../../../../theme";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StyledFieldsText } from "../../../../components/common/modal/Modal.style";
import {
    ChangeMeterAction,
    HydrodigitLRAlarmResetAction,
    SendSetDataLoggerDownlinkAction,
} from "../../../../redux/apartments/apartments-actions";
import { Meter } from "../../../../redux/apartments/apartments-types";
import LocalizationProvider from "../../../../components/LocalizationProvider";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import { convertMeterStringIdToInt } from "../../../../utils/utils";
import MeterAlarmCustomization from "./MeterAlarmCustomization";
import { RootState } from "../../../../interfaces/RootState";

export default function MeterProperties({
    apartmentId,
    companyId,
    meter,
    deleteMeter,
}: {
    apartmentId: number;
    companyId: number;
    meter: Meter;
    deleteMeter: () => void;
}): JSX.Element {
    const dispatch = useDispatch();
    const t = useTranslation().t;

    const resetAlarmButtonRights = useHasPrivileges("can reset Hydrodigit LR Alarms", companyId).hasPrivilege;

    const deleteMeterButtonRights = useHasPrivileges("can delete meter", companyId).hasPrivilege;

    const changeDateButtonRights = useHasPrivileges("can mark alarm date changes", companyId).hasPrivilege;

    const isAdmin = useSelector((state: RootState) => state.hydrolink.auth.isAdmin);

    const [changingDate, setChangingDate] = React.useState<Date | null>(null);

    const changeMeter = () => {
        if (changingDate) {
            dispatch(
                ChangeMeterAction({
                    companyId: companyId,
                    apartmentId: apartmentId,
                    meterId: convertMeterStringIdToInt(meter.id),
                    date: changingDate,
                }),
            );
        }
    };

    const resetHydrodigitLRAlarms = () => {
        dispatch(
            HydrodigitLRAlarmResetAction({
                meterId: convertMeterStringIdToInt(meter.id),
            }),
        );
    };

    const dateWhenMeterChanged = () => {
        const inputFormat = "d.M.yyyy";

        return (
            <LocalizationProvider>
                <StyledFieldsText>{t("meters.changeDate")}</StyledFieldsText>
                <div>
                    <DatePicker
                        views={["year", "month", "day"]}
                        value={changingDate}
                        format={inputFormat}
                        onChange={(date) => {
                            setChangingDate(date);
                        }}
                        slotProps={{
                            textField: {
                                variant: "outlined",
                                inputProps: {
                                    readOnly: true,
                                },
                                style: {
                                    ...borders.basicOutline,
                                },
                                sx: {
                                    input: { ...textInputForDataPicker },
                                    fieldset: {
                                        border: "0",
                                    },
                                },
                            },
                        }}
                    />
                </div>
            </LocalizationProvider>
        );
    };

    const sendSetDataLoggerDownlinkOnOrOff = (value: boolean) => {
        dispatch(
            SendSetDataLoggerDownlinkAction({
                meterId: convertMeterStringIdToInt(meter.id),
                meterType: meter.type.toUpperCase(),
                value: value,
            }),
        );
    };

    function dataLoggerOnOff(): JSX.Element {
        return (
            <LocalizationProvider>
                <StyledFieldsText>{"Data logger on/off"}</StyledFieldsText>
                <div style={{ ...alignments.twoElementGrid }}>
                    <Button
                        disabled={!isAdmin}
                        onClick={() => sendSetDataLoggerDownlinkOnOrOff(true)}
                        data-testid="set-data-logger-on-button"
                    >
                        {"On"}
                    </Button>
                    <Button
                        disabled={!isAdmin}
                        onClick={() => sendSetDataLoggerDownlinkOnOrOff(false)}
                        data-testid="set-data-logger-off-button"
                    >
                        {"Off"}
                    </Button>
                </div>
            </LocalizationProvider>
        );
    }

    return (
        <Block noMargin noBottomBorder variant={"white"} style={{}}>
            <h3>{t("meters.properties")}:</h3>
            <div style={{ ...alignments.evenHorizontal }}>
                {changeDateButtonRights && <div style={{ width: "250px" }}>{dateWhenMeterChanged()}</div>}
                {isAdmin && <div data-testid="data-logger-feature">{dataLoggerOnOff()}</div>}
            </div>
            <MeterAlarmCustomization meter={meter} companyId={companyId} />
            <div style={{ ...alignments.rightSide, marginTop: spacings.standardMargin }}>
                {changeDateButtonRights && (
                    <Button onClick={changeMeter} disabled={!changingDate}>
                        {t("meters.markAsChanged")}
                    </Button>
                )}

                {meter.supportsHydrodigitLRAlarmReset && (
                    <>
                        <div style={{ marginLeft: spacings.standardMargin }}> </div>
                        <Button disabled={!resetAlarmButtonRights} onClick={resetHydrodigitLRAlarms}>
                            {t("meters.resetAlarms")}
                        </Button>
                    </>
                )}
                <div style={{ marginLeft: spacings.standardMargin }}> </div>
                {deleteMeterButtonRights && (
                    <Button disabled={!deleteMeterButtonRights} onClick={deleteMeter}>
                        {t("meters.removeMeter")}
                    </Button>
                )}
            </div>
        </Block>
    );
}
