import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StyledFieldsText } from "../../../components/common/modal/Modal.style";
import LocalizationProvider from "../../../components/LocalizationProvider";
import { ResidentEditType } from "./ResidentEdit";
import { borders, textInputForDataPicker } from "../../../theme";

export default function MoveInDate({
    resident,
    setResident,
}: {
    resident: ResidentEditType;
    setResident: React.Dispatch<React.SetStateAction<ResidentEditType>>;
}) {
    const t = useTranslation().t;
    const [moveInMonth, setMoveInMonth] = React.useState<Date>(resident.moveInMonth);
    useEffect(() => {
        setMoveInMonth(resident.moveInMonth);
    }, [resident]);

    const onChange = (date: Date | null) => {
        if (date) {
            setResident({ ...resident, moveInMonth: date });
        }
    };

    return (
        <LocalizationProvider>
            <StyledFieldsText>{t("residents.moveInMonth")}</StyledFieldsText>
            <div>
                <DatePicker
                    views={["year", "month"]}
                    value={moveInMonth}
                    onChange={(date) => onChange(date)}
                    slotProps={{
                        textField: {
                            variant: "outlined",
                            style: { ...borders.basicOutline },
                            sx: {
                                input: { ...textInputForDataPicker },
                                fieldset: {
                                    border: "0",
                                },
                            },
                        },
                    }}
                />
            </div>
        </LocalizationProvider>
    );
}
