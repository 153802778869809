import { ViewContainer } from "../../components/ViewContainer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import React, { useEffect } from "react";
import { FetchAlarmTypesAction } from "../../redux/companies/companies-actions";
import { AlarmType } from "../../redux/companies/companies-types";
import { Table, TableHead, TableRow, TableBody, TableCell } from "@mui/material";
import { TableHeadCell } from "../../components/Table";
import { elementWithHeaderAndFooter } from "../../components/common/header/Header";

/**
 * Page for Admins to view the currently active alarm rules and related settings.
 */
export default function AlarmTypes(): JSX.Element {
    const dispatch = useDispatch();
    const alarmTypes = useSelector((state: RootState) => state.hydrolink.companies.alarmTypes);

    useEffect(() => {
        dispatch(FetchAlarmTypesAction());
    }, [dispatch]);

    return elementWithHeaderAndFooter(
        <ViewContainer>
            <h2>Active Alarm Rules</h2>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeadCell>alarmName</TableHeadCell>
                        <TableHeadCell>defaultInterval</TableHeadCell>
                        <TableHeadCell>adminOnly</TableHeadCell>
                        <TableHeadCell>requiresPremium</TableHeadCell>
                        <TableHeadCell>visibleForUser</TableHeadCell>
                        <TableHeadCell>defaultNotifyOnNew</TableHeadCell>
                        <TableHeadCell>supportedMeterTypes</TableHeadCell>
                        <TableHeadCell>FI - name</TableHeadCell>
                        <TableHeadCell>FI - explanation</TableHeadCell>
                        <TableHeadCell>SV - name</TableHeadCell>
                        <TableHeadCell>SV - explanation</TableHeadCell>
                        <TableHeadCell>EN - name</TableHeadCell>
                        <TableHeadCell>EN - explanation</TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {alarmTypes.map((alarmType) => (
                        <AlarmTypeRow key={alarmType.alarmName} alarmType={alarmType} />
                    ))}
                </TableBody>
            </Table>
        </ViewContainer>,
    );
}

const AlarmTypeRow = ({
    alarmType,
}: Readonly<{
    alarmType: AlarmType;
}>): JSX.Element => {
    return (
        <TableRow key={alarmType.alarmName}>
            <TableCell>{alarmType.alarmName}</TableCell>
            <TableCell>{alarmType.defaultInterval}</TableCell>
            <TableCell>{alarmType.adminOnly ? "true" : "false"}</TableCell>
            <TableCell>{alarmType.requiresPremium ? "true" : "false"}</TableCell>
            <TableCell>{alarmType.visibleForUser ? "true" : "false"}</TableCell>
            <TableCell>{alarmType.defaultNotifyOnNew ? "true" : "false"}</TableCell>
            <TableCell>{alarmType.supportedMeterTypes}</TableCell>
            <TableCell>{alarmType.localizations.fi.alarmName}</TableCell>
            <TableCell>{alarmType.localizations.fi.explanation}</TableCell>
            <TableCell>{alarmType.localizations.sv.alarmName}</TableCell>
            <TableCell>{alarmType.localizations.sv.explanation}</TableCell>
            <TableCell>{alarmType.localizations.en.alarmName}</TableCell>
            <TableCell>{alarmType.localizations.en.explanation}</TableCell>
        </TableRow>
    );
};
