import ViewNotes from "./ViewNotes";
import { useParams } from "react-router-dom";
import AddNote from "./AddNote";

const Notes = () => {
    const params = useParams();
    const companyId = parseInt(params.companyId ?? "0");

    return (
        <>
            <ViewNotes companyId={companyId} />
            <AddNote companyId={companyId} />
        </>
    );
};

export default Notes;
