import { useState, useCallback } from "react";
import { EditEvent } from "../listEditor";
import { NewUser, Role } from "../../../../../redux/companies/companies-types";

export function useUserActions() {
    const [edits, setEdits] = useState<EditEvent[]>([]);

    const addNewUser = useCallback((newUser: NewUser) => {
        setEdits((prevEdits) => [
            ...prevEdits,
            {
                event: "added-new",
                userId: newUser.id,
                email: newUser.email,
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                role: newUser.role,
            },
        ]);
    }, []);

    const addExistingUser = useCallback((userId: number, role: Role) => {
        setEdits((prevEdits) => [...prevEdits, { event: "added-from-list", userId, role }]);
    }, []);

    const deleteManager = useCallback((userId: number) => {
        setEdits((prevEdits) => [...prevEdits, { event: "delete-toggled", userId }]);
    }, []);

    const changeRole = useCallback((userId: number, role: Role) => {
        setEdits((prevEdits) => [...prevEdits, { event: "role-changed", userId, role }]);
    }, []);

    const makePrincipal = useCallback((userId: number) => {
        setEdits((prevEdits) => [...prevEdits, { event: "made-principal", userId }]);
    }, []);

    return { edits, addNewUser, addExistingUser, deleteManager, changeRole, makePrincipal, setEdits };
}
