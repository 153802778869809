import { useTranslation } from "react-i18next";
import { Block } from "../../components/common/block";
import ExpandableBlock from "../../components/ExpandableBlock";
import CompanyContractStatusIcon from "./CompanyContractStatusIcon";
import CompanyContractsView from "./CompanyContractsView";
import PersonalContractsView from "./PersonalContractsView";
import { AppDispatch } from "../../interfaces/RootState";
import { useMemo } from "react";
import { UserContractsViewData } from "./hooks/useUserContractsViewData";

// Imported so it can be linked to in the JSDoc. @todo There should be a way to do this without importing
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useUserContractsViewData } from "./hooks/useUserContractsViewData";

/**
 * Props for {@link UserContractsView}.
 */
export type Props = {
    /**
     * Redux dispatch function.
     */
    dispatch: AppDispatch;

    /**
     * Data for the view. Compute with {@link useUserContractsViewData}.
     */
    data: UserContractsViewData;

    /**
     * Test IDs for component parts.
     */
    testIds?: TestIds;
};

/**
 * Test IDs for {@link UserContractsView}.
 */
export type TestIds = {
    companyTestIdsByCompanyId?: Record<number, CompanyTestIds>;
};

export type CompanyTestIds = {
    header?: string;
    subscriptionActionButton?: string;
    dpaActionButton?: string;
};

/**
 * View for managing a user's personal contracts as well as contracts for the companies they are the principal manager of.
 * Consists of a list of {@link CompanyContractsView}s and a {@link PersonalContractsView}.
 */
export default function UserContractsView({ dispatch, data, testIds }: Props): JSX.Element {
    const { t } = useTranslation();

    const {
        userId,
        personalDpa,
        principallyManagedCompaniesWithContracts,
        somePrincipallyManagedCompanyHasActiveSubscription,
    } = data;

    const sortedPrincipallyManagedCompaniesWithContracts = useMemo(
        () => principallyManagedCompaniesWithContracts.sort((a, b) => a.company.name.localeCompare(b.company.name)),
        [principallyManagedCompaniesWithContracts],
    );

    return (
        <>
            <Block variant="white">
                <h3>{t("contracts.companyContractsSectionTitle")}</h3>
                {sortedPrincipallyManagedCompaniesWithContracts.map(
                    ({ company, subscription, dpa, allContractsActive }) => {
                        const companyTestIds = testIds?.companyTestIdsByCompanyId?.[company.id];

                        return (
                            <ExpandableBlock
                                key={company.id}
                                leftSide={
                                    <span>
                                        {company.name}{" "}
                                        <CompanyContractStatusIcon allContractsSigned={allContractsActive} />
                                    </span>
                                }
                                grey
                                headerDataTestId={companyTestIds?.header}
                            >
                                <CompanyContractsView
                                    dispatch={dispatch}
                                    signerUserId={userId}
                                    company={company}
                                    subscription={subscription}
                                    dpa={dpa}
                                    testIds={{
                                        dpaActionButton: companyTestIds?.dpaActionButton,
                                        subscriptionActionButton: companyTestIds?.subscriptionActionButton,
                                    }}
                                />
                            </ExpandableBlock>
                        );
                    },
                )}
            </Block>
            <Block variant="white">
                <h3>{t("contracts.principalManagerDpaSectionTitle")}</h3>
                <PersonalContractsView
                    dispatch={dispatch}
                    userId={userId}
                    dpa={personalDpa}
                    somePrincipallyManagedCompanyHasActiveSubscription={
                        somePrincipallyManagedCompanyHasActiveSubscription
                    }
                />
            </Block>
        </>
    );
}
