import { useCallback, useEffect } from "react";
import { ViewContainer } from "../../components/ViewContainer";
import { alignments } from "../../theme";
import Pagination from "../../components/common/pagination";
import { RootState } from "../../interfaces/RootState";
import { useDispatch, useSelector } from "react-redux";
import {
    FetchResidentsAction,
    ResidentsPaginationItemsPerPageChangeAction,
    ResidentsPaginationPageChangeAction,
} from "../../redux/residents/residents-actions";
import ResidentTable from "./ResidentTable";
import CompanyTabs, { Tabs } from "../companies/CompanyTabs";
import CommonSearchBar from "../../components/common/search-bar/CommonSearchBar";
import { Apartment } from "../../redux/apartments/apartments-types";
import UploadResidents from "./UploadResidents";
import DownloadResidents from "./DownloadResidents";
import React from "react";
import { Resident } from "../../redux/residents/residents-types";
import useSortedAndFiltered from "../../utils/sortAndFilter";
import { useHasPrivileges } from "../../utils/useHasPrivilegesOf";

const findResident = (apartmentId: number, residents: Resident[]) => {
    return residents.find((r) => r.apartmentId === apartmentId);
};

const getSortData = (currentSorting: string, residents: Resident[]) => {
    switch (currentSorting) {
        case "code":
            return (apartment: Apartment) => apartment.code;
        case "residentEmails":
            return (apartment: Apartment) =>
                findResident(apartment.id, residents)?.main?.email ??
                "" + findResident(apartment.id, residents)?.other?.email ??
                "";
    }
};

const searchData = (residents: Resident[]) => (apartment: Apartment) =>
    residents
        .filter((resident) => resident.apartmentId === apartment.id)
        .flatMap((resident) =>
            [resident.main?.email ?? "", resident.other?.email ?? ""].filter((email) => email !== ""),
        );

const ResidentApartmentList = ({ companyId }: { companyId: number }) => {
    const dispatch = useDispatch();
    const [search, setSearch] = React.useState("");

    const apartments = useSelector((state: RootState) => state.hydrolink.apartments.apartments);
    const currentPage = useSelector((root: RootState) => root.hydrolink.residents.pagination.currentPage);
    const itemsPerPage = useSelector((root: RootState) => root.hydrolink.residents.pagination.itemsPerPage);
    const residents = useSelector((state: RootState) => state.hydrolink.residents.residents);
    const pagination = useSelector((state: RootState) => state.hydrolink.residents.pagination);
    const currentSorting = useSelector((state: RootState) => state.hydrolink.residents.sortBy);
    const currentAscending = useSelector((state: RootState) => state.hydrolink.residents.ascending);

    const canUploadAndDownloadResidents = useHasPrivileges("can upload & download residents", companyId).hasPrivilege;

    const sortedAndFiltered = useSortedAndFiltered(
        apartments,
        getSortData(currentSorting, residents),
        currentAscending,
        search,
        searchData(residents),
        pagination,
    );

    useEffect(() => {
        dispatch(FetchResidentsAction({ companyId: companyId }));
    }, [companyId, dispatch]);

    const pageChange = useCallback(
        (pageNumber: number) => {
            dispatch(ResidentsPaginationPageChangeAction(pageNumber));
        },
        [dispatch],
    );

    const itemsPerPageChange = (itemsPerPage: number) => {
        dispatch(ResidentsPaginationItemsPerPageChangeAction(itemsPerPage));
    };

    return (
        <ViewContainer>
            <CompanyTabs tab={Tabs.RESIDENTS} />
            <ViewContainer style={alignments.evenHorizontal}>
                <CommonSearchBar
                    search={search}
                    setSearch={setSearch}
                    pageChange={pageChange}
                    currentPage={currentPage}
                />
                {canUploadAndDownloadResidents && (
                    <div style={alignments.evenHorizontal}>
                        <UploadResidents companyId={companyId} />
                        <DownloadResidents companyId={companyId} />
                    </div>
                )}
            </ViewContainer>
            <ViewContainer>
                <ResidentTable companyId={companyId} apartments={sortedAndFiltered.items} />
            </ViewContainer>
            <ViewContainer>
                <Pagination
                    current={currentPage}
                    itemsCount={sortedAndFiltered.itemCount}
                    currentPageCallback={pageChange}
                    itemsPerPageCallback={itemsPerPageChange}
                    itemsPerPage={itemsPerPage}
                />
            </ViewContainer>
        </ViewContainer>
    );
};

export default ResidentApartmentList;
