import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../interfaces/RootState";
import { FetchReportFormatsAction } from "../redux/apartments/apartments-actions";
import { ReportFormat } from "../redux/apartments/apartments-types";
import { FetchCompaniesWithoutAlarmsAction, FetchCompanyInvoiceInfoAction } from "../redux/companies/companies-actions";
import { Company, CompanyInvoiceSettings } from "../redux/companies/companies-types";
import { useHasPrivileges } from "../utils/useHasPrivilegesOf";
import DropDown from "./DropDown";
import { useTranslation } from "react-i18next";

type Props = {
    id: string;
    disabled?: boolean;
    selected?: string;
    style?: React.CSSProperties;
    changeFormatAction: (value: string) => void;
    companyId: number;
};

// this seems a little clunky, but might be the easiest way
// since otherwise the translation keys would have to be inserted into the production DB which sounds messy
const literalToTransKey = (literal: string) => {
    switch (literal) {
        case "VismaL7":
            return "vismal7";
        case "Visma Fivaldi":
            return "fivaldi";
        case "Kupari":
            return "kupari";
        case "Domus":
            return "domus";
        case "Unes":
            return "unes";
        case "Kulutus (CSV)":
            return "csv";
        case "Kulutus (PDF)":
            return "pdf";
        case "Lukemat (CSV)":
            return "csv2";
        case "Lukemat (PDF)":
            return "pdf2";
        case "Päiväkohtaiset lukemat (PDF)":
            return "daily_pdf";
        case "Visma":
            return "vismal7v2";
        case "Tampuuri":
            return "tampuuriv3";
        case "Asteri (CSV)":
            return "asteri";
        case "Haahtela":
            return "haahtela";
        case "Hausvise":
            return "hausvise";
        case "Hausvise (m^3)":
            return "hausvisem3";
        case "Laskut":
            return "invoice";
        case "Kenno":
            return "kenno";
    }
};

export default function ReportFormatSelector({
    id,
    disabled,
    selected,
    style,
    changeFormatAction,
    companyId,
}: Props): JSX.Element {
    const canSeeInvoiceReports = useHasPrivileges("can see and download invoice reports", companyId).hasPrivilege;
    const reportFormats = useSelector((state: RootState) => state.hydrolink.apartments.reportFormats);
    const [formatOptions, setFormatOptions] = React.useState<ReportFormat[]>([]);
    const [selectedFormat, setSelectedFormat] = React.useState<string>("");
    const invoiceInfo = useSelector(
        (state: RootState): CompanyInvoiceSettings | undefined => state.hydrolink.companies.invoiceInfo,
    );
    const companies = useSelector((state: RootState): Company[] => state.hydrolink.companies.companies);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const isBlank = (field: string | number | undefined | null) =>
        field === "" || field === undefined || field === null;

    const hasInvoiceInfo =
        invoiceInfo !== undefined &&
        !isBlank(invoiceInfo.bank) &&
        !isBlank(invoiceInfo.daysToPay) &&
        !isBlank(invoiceInfo.referenceNumberBody) &&
        !isBlank(invoiceInfo.contactPerson);

    const onChange = (e: string) => {
        setSelectedFormat(e);
        changeFormatAction(e);
    };

    useEffect(() => {
        if (companies.length === 0) {
            dispatch(FetchCompaniesWithoutAlarmsAction());
        }
    }, [companies, dispatch]);

    useEffect(() => {
        if (selected) {
            setSelectedFormat(selected);
        }
    }, [selected]);

    useEffect(() => {
        if (canSeeInvoiceReports && !invoiceInfo) {
            dispatch(FetchCompanyInvoiceInfoAction({ companyId: companyId }));
        }
        if (!reportFormats) {
            dispatch(FetchReportFormatsAction());
        }
    }, [dispatch, reportFormats, companyId, invoiceInfo, canSeeInvoiceReports]);

    useEffect(() => {
        let newReportFormats: ReportFormat[] = JSON.parse(JSON.stringify(reportFormats));
        if (!hasInvoiceInfo) {
            newReportFormats = newReportFormats.filter((r: ReportFormat) => r.id !== "invoice");
        }
        setFormatOptions(newReportFormats);
    }, [canSeeInvoiceReports, reportFormats, hasInvoiceInfo]);

    return (
        <DropDown
            id={id}
            style={style}
            options={formatOptions.map((r) => {
                return { value: r.id, displayText: t(`consumptionReporting.reports.${literalToTransKey(r.name)}`) };
            })}
            disabled={disabled}
            placeholder={true}
            selectedValue={selectedFormat}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        />
    );
}
