import React, { useEffect, useMemo } from "react";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandIcons from "../../components/ExpandIcons";
import { TableCell, TableRow } from "../../components/Table";
import { Company } from "../../redux/companies/companies-types";
import { getCompanyManagerByUser, getHighestRoleForUser } from "../../redux/users/users-reducer";
import { User } from "../../redux/users/users-types";
import UsersInfo from "./UserInfo";
import { borders } from "../../theme";
import { useLocation } from "react-router-dom";

type Props = {
    user: User;
    companies: Company[];
};

export default function UsersRow({ user, companies }: Props): JSX.Element {
    const t = useTranslation().t;
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    useEffect(() => {
        // Open specific user information if user is given.
        const userId = queryParams.get("id");
        if (userId === user.id.toString() && userId) {
            setExpanded((expanded) => !expanded);
            window.scrollTo({ top: 0 });
        }
    }, [queryParams, user.id]);

    // A bit 'hacky' but works flawlessly!
    const getHighestRoleTranslation = (): string => {
        let highestRole = getHighestRoleForUser(companies, user);
        let isPrincipalManager = false;
        if (highestRole === "manager") {
            for (var c of companies) {
                if (getCompanyManagerByUser(c, user)?.principal) {
                    isPrincipalManager = true;
                }
            }
        }

        let translated = t(`roles.${isPrincipalManager ? "principal" : getHighestRoleForUser(companies, user)}`);
        return translated.includes("roles.") ? "" : translated;
    };

    const handleRowClick = () => {
        setExpanded(!expanded);
    };
    return (
        <>
            <TableRow key={user.id} onClick={handleRowClick} style={{ cursor: "pointer" }}>
                <TableCell>
                    {user.firstName} {user.lastName}
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                    {companies.map((c: Company) => (
                        <Chip key={c.id} label={c.name} />
                    ))}
                </TableCell>
                <TableCell>{getHighestRoleTranslation()}</TableCell>
                <TableCell>
                    <ExpandIcons closed={!expanded} />
                </TableCell>
            </TableRow>
            {expanded && (
                <TableRow key={`exp_${user.id}`}>
                    <TableCell colSpan={5}>
                        <div style={borders.basicOutline}>
                            <UsersInfo companies={companies} user={user} />
                        </div>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}
