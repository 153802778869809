import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandIcons from "../../components/ExpandIcons";
import { TableCell, TableRow } from "../../components/Table";
import { borders } from "../../theme";
import { Apartment } from "../../redux/apartments/apartments-types";
import { Resident } from "../../redux/residents/residents-types";
import ResidentEdit from "./resident-edit/ResidentEdit";

type Props = {
    apartment: Apartment;
    resident: Resident | null | undefined;
    companyId: number;
    isOpen: boolean;
    onToggleOpen: (id: number) => void;
};

export default function ResidentRow({ apartment, resident, companyId, isOpen, onToggleOpen }: Props): JSX.Element {
    const t = useTranslation().t;

    return (
        <>
            <TableRow onClick={() => onToggleOpen(apartment.id)} style={{ cursor: "pointer" }}>
                <TableCell>{apartment.code}</TableCell>
                <TableCell>
                    {resident !== null ? (
                        <>
                            {resident?.main?.email ? <Chip label={resident.main.email} /> : <></>}
                            {resident?.other?.email ? <Chip label={resident.other.email} /> : <></>}
                        </>
                    ) : (
                        <>{t("residents.noManager")} </>
                    )}
                </TableCell>
                <TableCell></TableCell>
                <TableCell style={{ width: "1.8em" }}>
                    {resident !== null && <ExpandIcons closed={!isOpen} />}
                </TableCell>
            </TableRow>
            {isOpen && resident !== null && (
                <TableRow>
                    <TableCell colSpan={3}>
                        <div style={borders.basicOutline}>
                            <ResidentEdit apartment={apartment} companyId={companyId} residents={resident} />
                        </div>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}
