export enum MessageTypes {
    success = "success",
    warning = "warning",
    error = "error",
    info = "info",
}

export enum Size {
    small = "small",
    large = "large",
}

export interface Message {
    type: MessageTypes;
    message: string;
    id: string;
    size: Size;
}

export interface MessagesState {
    messages: Message[];
}

export interface AddMessagePayload {
    message: string;
    type: MessageTypes;
    size?: Size;
}

export interface AcknowledgeMessagePayload {
    id: string;
}
