import { useTranslation } from "react-i18next";
import { TableHeadCell, TableHead, TableRow } from "../../../../components/Table";
import { alignments } from "../../../../theme";

export default function MetersTableHead() {
    const t = useTranslation().t;
    return (
        <TableHead>
            <TableRow>
                <TableHeadCell>{t("apartments.meters")}</TableHeadCell>
                <TableHeadCell>{t("apartments.code")}</TableHeadCell>
                <TableHeadCell style={{ ...alignments.centered }}>{t("apartments.alarm")}</TableHeadCell>
                <TableHeadCell>{t("apartments.type")}</TableHeadCell>
                <TableHeadCell>{t("apartments.earliestReading")}</TableHeadCell>
                <TableHeadCell>{t("apartments.meterUpdated")}</TableHeadCell>
                <TableHeadCell>{t("apartments.meterReading")}</TableHeadCell>
                <TableHeadCell colSpan={2}>
                    {/* Empty space - Meter details and delete buttons are for these last columns */}
                </TableHeadCell>
            </TableRow>
        </TableHead>
    );
}
