import { Pagination } from "@mui/material";
import styled from "styled-components";

export const StyledPagination = styled(Pagination)`
    padding: 0;
    display: flex;
    color: red;
    border: 1px solid #edf2f9;
    background-color: #ffffff;
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding-bottom: 2em;
    width: max-content;
`;

export const SelectContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    align-items: center;
    margin-left: 1rem;
    border: 1px solid #eff0f3;
    border-radius: 5px;
    height: 40px;
    padding: 0 10px;
    align-self: center;
    background-color: #ffffff;
`;

export const AmountSelectText = styled.p`
    margin: 0;
    justify-self: center;
`;
