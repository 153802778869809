import { AnyAction } from "redux";
import { stateWithLoadingFalse, stateWithLoadingTrue } from "../../utils/utils";
import {
    FetchResidentsUnauthorizedAction,
    FetchResidentsSuccessAction,
    ResidentsPaginationPageChangeAction,
    ResidentsPaginationItemsPerPageChangeAction,
    UpsertResidentSuccessAction,
    FetchResidentsAction,
    UpsertResidentAction,
    DeleteResidentAction,
    DeleteResidentSuccessAction,
    FetchResidentsFailureAction,
    UpsertResidentFailureAction,
    DeleteResidentFailureAction,
    SortResidentsAction,
    CreateNewResidentPasswordSuccessAction,
    CreateNewResidentPasswordAction,
    CreateNewResidentPasswordFailureAction,
    DownloadResidentsListAction,
    DownloadResidentsListFailureAction,
    DownloadResidentsListSuccessAction,
    InsertResidentsAction,
    UploadResidentsSuccessAction,
    UploadResidentsAction,
    UploadResidentsFailureAction,
} from "./residents-actions";
import { ResidentsState } from "./residents-types";

const initialState: ResidentsState = {
    residents: [],
    pagination: {
        currentPage: 1,
        itemsPerPage: 10,
    },
    authorized: false,
    credentials: {},
    loading: 0,
    sortBy: "id",
    ascending: true,
    residentsFileRead: false,
};

const actions = (state: ResidentsState, action: AnyAction) => {
    if (FetchResidentsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (UpsertResidentAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (DeleteResidentAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (CreateNewResidentPasswordAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (DownloadResidentsListAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (UploadResidentsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    return state;
};

const successActions = (state: ResidentsState, action: AnyAction): ResidentsState => {
    if (FetchResidentsSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            residents: action.payload,
            authorized: true,
        };
    }

    if (UpsertResidentSuccessAction.match(action)) {
        let updatedCredentials = {
            ...state.credentials,
            ...action.payload.newCredentialsByResidentId,
        };
        return {
            ...stateWithLoadingFalse(state),
            residents: action.payload.residentsByApartmentId,
            credentials: updatedCredentials,
            authorized: true,
        };
    }

    if (CreateNewResidentPasswordSuccessAction.match(action)) {
        let updatedCredentials = { ...state.credentials, ...action.payload };
        return {
            ...stateWithLoadingFalse(state),
            credentials: updatedCredentials,
            authorized: true,
        };
    }

    if (DeleteResidentSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DownloadResidentsListSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UploadResidentsSuccessAction.match(action)) {
        return { ...stateWithLoadingFalse(state), residentsFileRead: false };
    }

    return state;
};

const failureActions = (state: ResidentsState, action: AnyAction) => {
    if (FetchResidentsUnauthorizedAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            authorized: false,
        };
    }

    if (FetchResidentsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpsertResidentFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteResidentFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (CreateNewResidentPasswordFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DownloadResidentsListFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UploadResidentsFailureAction.match(action)) {
        return { ...stateWithLoadingFalse(state), residentsFileRead: false };
    }

    return state;
};

const otherActions = (state: ResidentsState, action: AnyAction) => {
    if (SortResidentsAction.match(action)) {
        return {
            ...state,
            sortBy: action.payload.sortBy,
            ascending: action.payload.ascending,
        };
    }

    if (ResidentsPaginationPageChangeAction.match(action)) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                currentPage: action.payload,
            },
        };
    }

    if (ResidentsPaginationItemsPerPageChangeAction.match(action)) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                itemsPerPage: action.payload,
            },
        };
    }

    if (InsertResidentsAction.match(action)) {
        const residentsWithoutTarget = [...state.residents].filter((r) => r.apartmentId !== action.payload.apartmentId);
        return {
            ...state,
            residentsFileRead: true,
            residents: [...residentsWithoutTarget, action.payload],
        };
    }

    return state;
};

export default function residentsReducer(state: ResidentsState = initialState, action: AnyAction): ResidentsState {
    state = actions(state, action);
    state = successActions(state, action);
    state = failureActions(state, action);
    state = otherActions(state, action);

    return state;
}
