import { SetStateAction, useCallback, Dispatch } from "react";
import { useTranslation } from "react-i18next";

const DateRangeShortcutButton = ({
    setFrom,
    setUntil,
    setLoaded,
    fetchAlarmsHistory,
}: Readonly<{
    setFrom: Dispatch<SetStateAction<Date | null>>;
    setUntil: Dispatch<SetStateAction<Date | null>>;
    setLoaded: Dispatch<SetStateAction<boolean>>;
    fetchAlarmsHistory: (overrideFrom: Date | null, overrideUntil: Date | null) => void;
}>): JSX.Element => {
    const t = useTranslation().t;
    const shortcutDays = [1, 7, 30];
    const setDateRange = useCallback(
        (days: number) => {
            const until = new Date();
            const from = new Date();
            from.setDate(from.getDate() - days);
            setFrom(from);
            setUntil(until);
            setLoaded(true);
            fetchAlarmsHistory(from, until);
        },
        [setFrom, setUntil, setLoaded, fetchAlarmsHistory],
    );

    return (
        <>
            {shortcutDays.map((d) => (
                <button
                    key={d}
                    style={{
                        color: "blue",
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                        marginBottom: "10px",
                        textAlign: "left",
                    }}
                    onClick={() => setDateRange(d)}
                >
                    {t("smokeAlarms.dateRangeButton", { count: d })}
                </button>
            ))}
        </>
    );
};

export default DateRangeShortcutButton;
