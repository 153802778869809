import { retrieveLanguage } from "../utils/languageStore";

function AddLanguageToURL(path: string) {
    if (path.includes("?")) {
        path += "&";
    } else {
        path += "?";
    }
    path += "lang=" + retrieveLanguage();
    return path;
}

export default AddLanguageToURL;
