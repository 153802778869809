import { all, put, select, takeEvery } from "redux-saga/effects";
import { ErrorOccurredAction } from "../error/error-actions";
import * as api from "../../api";
import { API_URL } from "../../constants/urls";
import {
    DownloadReportAction,
    DownloadReportFailureAction,
    DownloadReportSuccessAction,
    FetchInitReportAction,
    FetchInitReportFailureAction,
    FetchInitReportSuccessAction,
    FetchInterimReportAction,
    FetchReportsAction,
    FetchReportsFailureAction,
    FetchReportsSuccessAction,
} from "./reports-actions";
import {
    DownloadReportPayload,
    FetchInitReportPayload,
    FetchInterimReportPayload,
    FetchReportsPayload,
} from "./reports-types";
import { ApiReport } from "../../api/api-types";
import { convertApiReport } from "../../api/api-conversions";
import selectIdToken from "../auth/select-id-token";

export function* fetchReports(action: { payload: FetchReportsPayload }) {
    const companyId: number = action.payload.companyId;
    const from: string = action.payload.from;
    const until: string = action.payload.until;

    yield api.get(
        `/companies/${companyId}/consumption_report?from_incl=${from}&to_incl=${until}`,
        (resp: ApiReport[]) =>
            put(
                FetchReportsSuccessAction({
                    reports: resp.map((r) => convertApiReport(r)),
                    apartments: action.payload.apartments,
                }),
            ),
        (err) => all([put(FetchReportsFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* fetchInterimReport(action: { payload: FetchInterimReportPayload }) {
    const idToken: string = yield select(selectIdToken);
    const companyId: number = action.payload.companyId;

    yield api.download(
        `${API_URL}/companies/${companyId}/interimreport.pdf`,
        "GET",
        null,
        [{ name: "Authorization", value: `Bearer ${idToken}` }],
        [put(FetchInitReportSuccessAction())],
        [put(FetchInitReportFailureAction())],
    );
}

export function* fetchInitReport(action: { payload: FetchInitReportPayload }) {
    const idToken: string = yield select(selectIdToken);
    const companyId: number = action.payload.companyId;

    yield api.download(
        `${API_URL}/companies/${companyId}/initreport.pdf`,
        "GET",
        null,
        [{ name: "Authorization", value: `Bearer ${idToken}` }],
        [put(FetchInitReportSuccessAction())],
        [put(FetchInitReportFailureAction())],
    );
}

export function* downloadReport(action: { payload: DownloadReportPayload }) {
    const companyId: number = action.payload.companyId;
    const format: string = action.payload.format;
    const from: string = action.payload.from;
    const until: string = action.payload.until;
    const newLogic = action.payload.usingNewLogic ? `&new_logic` : "";
    const fromAndTo = `from_incl=${from}&to_incl=${until}`;
    const idToken: string = yield select(selectIdToken);
    const fileUrl = `${API_URL}/companies/${companyId}/report/${format}?${fromAndTo}${newLogic}`;

    yield api.download(
        fileUrl,
        "GET",
        null,
        [{ name: "Authorization", value: `Bearer ${idToken}` }],
        [put(DownloadReportSuccessAction())],
        [put(DownloadReportFailureAction())],
    );
}

export function* reportsSaga() {
    yield takeEvery(FetchReportsAction, fetchReports);
    yield takeEvery(DownloadReportAction, downloadReport);
    yield takeEvery(FetchInitReportAction, fetchInitReport);
    yield takeEvery(FetchInterimReportAction, fetchInterimReport);
}
