import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { faTrash, faChartColumn, faWrench } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import {
    LEAKAGE_METER,
    Meter,
    MeterHistoryType,
    MeterType,
    SMOKE_METER,
} from "../../../../redux/apartments/apartments-types";
import { DeleteMeterAction, OpenChartAction } from "../../../../redux/apartments/apartments-actions";
import {
    convertMeterStringIdToInt,
    displayAlarms,
    displayDate,
    displayDateTime,
    getMeterUnit,
} from "../../../../utils/utils";
import { colors } from "../../../../theme";
import MeterProperties from "./MeterProperties";
import { TableCell, TableRow } from "../../../../components/Table";
import FontAwesomeIconButton from "../../../../components/FontAwesomeIconButton";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import { FirstMetersByType, getMeterHistoryType } from "./MeterList";
import DeviceNotificationsCell from "../../../../components/device_notifications_cell/DeviceNotificationsCell";
import { Company } from "../../../../redux/companies/companies-types";
import { RootState } from "../../../../interfaces/RootState";

function GetTranslatedMeterTitle({ meter }: Readonly<{ meter: Meter }>): JSX.Element {
    const { t } = useTranslation();
    switch (meter.type) {
        case "water":
            return meter.warm ? <>{t("meters.warmWater")}</> : <>{t("meters.coldWater")}</>;
        case "ambient":
            return meter.humidity ? <>{t("meters.humidity")}</> : <>{t("meters.temperature")}</>;
        case "energy":
            if (meter.heating) {
                return (
                    <>
                        {t("meters.energy")}
                        {" - "}
                        <span style={{ color: colors.warm06 }}>{t("meters.heatingEnergy")}</span>
                    </>
                );
            } else if (meter.cooling) {
                return (
                    <>
                        {t("meters.energy")}
                        {" - "}
                        <span style={{ color: colors.cold06 }}>{t("meters.coolingEnergy")}</span>
                    </>
                );
            } else {
                return (
                    <>
                        {t("meters.energy")}
                        {" - "}
                        <span style={{ color: colors.electricity06 }}>{t("meters.electricityEnergy")}</span>
                    </>
                );
            }
        case "leakage":
            return <>{t("meters.leakage")}</>;
        case "smoke":
            return <>{t("meters.smoke")}</>;
        default:
            return <></>;
    }
}

const displayChartSymbol = (meter: Meter, firstMetersByType: FirstMetersByType): boolean => {
    const meterType = getMeterHistoryType(meter.type);
    if (!meterType) return false;
    return firstMetersByType[meterType] === meter.id;
};

type Props = {
    meter: Meter;
    apartmentId: number;
    company: Company;
    firstMetersByType: FirstMetersByType;
};

const MeterTableRow = ({ meter, apartmentId, company, firstMetersByType }: Props): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [propertiesOpen, setPropertiesOpen] = React.useState<boolean>(false);

    const propertiesOpenButtonRights = useHasPrivileges("can open meter properties", company.id);
    const deleteMeterButtonRights = useHasPrivileges("can delete meter", company.id);

    const canSeeAdminAlarms = useHasPrivileges("can see admin alarms", company.id).hasPrivilege;
    const alarmTypes = useSelector((state: RootState) => state.hydrolink.companies.alarmTypes);
    const alarmList = displayAlarms(meter.activeAlarmNames, canSeeAdminAlarms, alarmTypes);

    const handleOpenChartModal = (meterType: MeterHistoryType) => {
        dispatch(
            OpenChartAction({
                apartmentId: apartmentId,
                type: "month",
                offset: 0,
                meterType: meterType,
            }),
        );
    };

    const getIcon = (meterType: MeterHistoryType | null) => {
        if (meterType === null) {
            return null;
        }
        const tooltip = getToolTip(meterType);
        return (
            <FontAwesomeIconButton
                tooltip={tooltip}
                style={{ float: "none" }}
                onClick={() => handleOpenChartModal(meterType)}
                icon={faChartColumn}
            />
        );
    };

    const deleteMeter = (meter: Meter) => {
        if (window.confirm(`${t("apartments.deleteMeterConfirmation")} "${meter.code}"?`)) {
            dispatch(
                DeleteMeterAction({
                    companyId: company.id,
                    id: convertMeterStringIdToInt(meter.id),
                    type: meter.type,
                }),
            );
        }
    };

    const getToolTip = (meterType: MeterHistoryType): string => t("meters.tooltip." + meterType);

    const formatLeakageMeterReading = (reading: number | null | undefined): string => {
        if (reading === -1) return t("meters.leakageStatus.leakage");
        if (reading === 1) return t("meters.leakageStatus.ok");
        if (reading === null || reading === undefined || reading === 0) return t("meters.leakageStatus.noReading");
        return t("meters.leakageStatus.wrong");
    };

    const formatSmokeMeterReading = (
        alarmList: string[],
        reading: number | null | undefined,
        hasPremiumActiveAlarms: boolean,
    ): string => {
        if (alarmList.length > 0 || hasPremiumActiveAlarms) return t("meters.smokeAlarmStatus.alarm");
        if (reading === 1) return t("meters.smokeAlarmStatus.ok");
        if (reading === null || reading === undefined || reading === 0) return t("meters.smokeAlarmStatus.noReading");
        return t("meters.smokeAlarmStatus.wrong");
    };

    const formatReading = (reading: number | null | undefined, meterType: MeterType, humidity: boolean): string => {
        return reading != null ? `${reading.toLocaleString("fi-FI")} ${t(getMeterUnit(meterType, humidity))}` : "-";
    };

    const getMeterReading = (meter: Meter, alarmList: string[]): string => {
        if (meter.type === LEAKAGE_METER) {
            return formatLeakageMeterReading(meter.reading);
        } else if (meter.type === SMOKE_METER) {
            return formatSmokeMeterReading(alarmList, meter.reading, meter.hasActivePremiumAlarms);
        } else {
            return formatReading(meter.reading, meter.type, meter.humidity);
        }
    };

    const getMeterUpdatedTableCellColor = (): React.CSSProperties | undefined => {
        if (meter.type === LEAKAGE_METER) {
            const today = new Date();
            const updated = new Date(meter.updated);
            const isSameDay =
                today.getFullYear() === updated.getFullYear() &&
                today.getMonth() === updated.getMonth() &&
                today.getDay() === updated.getDay();
            if (!isSameDay) return { color: colors.rowColorRemove };
        }
        return undefined;
    };

    const displayMeterAddress = () => {
        if (meter.devEui) {
            return (
                <Tooltip style={{ cursor: "alias" }} title={meter.secondaryAddress}>
                    <p>{meter.devEui}</p>
                </Tooltip>
            );
        }
        return meter.secondaryAddress ? (
            <Tooltip style={{ cursor: "alias" }} title={meter.secondaryAddress}>
                <p>{meter.code}</p>
            </Tooltip>
        ) : (
            <p>{meter.code}</p>
        );
    };

    return (
        <>
            <TableRow style={{ backgroundColor: colors.neutral00 }}>
                <TableCell>
                    {displayChartSymbol(meter, firstMetersByType) ? (
                        <>{getIcon(getMeterHistoryType(meter.type))}</>
                    ) : (
                        ""
                    )}
                </TableCell>
                <TableCell>{displayMeterAddress()}</TableCell>
                <TableCell>
                    <DeviceNotificationsCell
                        companyId={company.id}
                        meter={meter}
                        alarmLoadingState={company.alarmLoadingState}
                        alarms={alarmList}
                    />
                </TableCell>
                <TableCell>
                    <GetTranslatedMeterTitle meter={meter} />
                </TableCell>
                <TableCell>{meter.earliestReading !== null ? displayDate(meter.earliestReading) : "-"}</TableCell>
                <TableCell style={getMeterUpdatedTableCellColor()}>
                    {meter.updated !== null && meter.updated !== 0 ? displayDateTime(new Date(meter.updated)) : "-"}
                </TableCell>
                <TableCell>{getMeterReading(meter, alarmList)}</TableCell>
                <TableCell>
                    <FontAwesomeIconButton
                        tooltip={t("apartments.meterPropertiesTooltip")}
                        disabled={!propertiesOpenButtonRights.hasPrivilege}
                        disabledTooltip={propertiesOpenButtonRights.missingRequirement}
                        style={propertiesOpen ? { transform: "rotate(-180deg)" } : {}}
                        onClick={() => setPropertiesOpen(!propertiesOpen)}
                        icon={faWrench}
                    />
                </TableCell>
                <TableCell>
                    <FontAwesomeIconButton
                        tooltip={t("apartments.removeMeterTooltip")}
                        disabled={!deleteMeterButtonRights.hasPrivilege}
                        disabledTooltip={deleteMeterButtonRights.missingRequirement}
                        onClick={() => deleteMeter(meter)}
                        icon={faTrash}
                    />
                </TableCell>
            </TableRow>
            {propertiesOpen && (
                <TableRow>
                    <TableCell colSpan={11}>
                        <MeterProperties
                            companyId={company.id}
                            apartmentId={apartmentId}
                            meter={meter}
                            deleteMeter={() => deleteMeter(meter)}
                        />
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default MeterTableRow;
