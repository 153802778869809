import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewContainer } from "../../components/ViewContainer";
import { elementWithHeaderAndFooter } from "../../components/common/header/Header";
import Pagination from "../../components/common/pagination";
import CommonSearchBar from "../../components/common/search-bar/CommonSearchBar";
import { RootState } from "../../interfaces/RootState";
import {
    CompaniesPaginationItemsPerPageChangeAction,
    CompaniesPaginationPageChangeAction,
    FetchAlarmTypesAction,
    FetchCompaniesWithoutAlarmsAction,
    FetchCompanyWarningNamesAction,
    OpenCompanyBasicInfoModal,
    SortCompaniesAction,
} from "../../redux/companies/companies-actions";
import { AlarmsLoadingState, Company, Manager } from "../../redux/companies/companies-types";
import { FetchUsersAction } from "../../redux/users/users-actions";
import { User } from "../../redux/users/users-types";
import { alignments } from "../../theme";
import useSortedAndFiltered from "../../utils/sortAndFilter";
import { CompaniesHeader } from "./Companies.style";
import CompaniesTable from "./CompaniesTable";
import { CompanyBasicInfoModal } from "./CompanyBasicInfoModal";
import CreateCompaniesButton from "./CreateCompaniesButton";
import { useLocation, useNavigate } from "react-router-dom";

const managerEmail = (manager: Manager | undefined, users: User[]): string => {
    if (users === undefined || manager === undefined) {
        return "";
    }

    let foundManager = users.find((user) => user.id === manager.userId);

    return foundManager ? foundManager.email : "";
};

const companyPrincipalManagerEmail = (company: Company, users: User[]) =>
    managerEmail(
        company.managers.find((manager) => manager.principal),
        users,
    );

const searchData = (users: User[]) => (company: Company) => [
    company.name,
    company.streetAddress,
    company.town,
    companyPrincipalManagerEmail(company, users) ?? "",
    ...company.clients.map((c) => {
        return c.code;
    }),
];

const getSortData = (currentSorting: string, users: User[]) => {
    switch (currentSorting) {
        case "name":
            return (company: Company) => company.name;
        case "streetAddress":
            return (company: Company) => company.streetAddress;
        case "town":
            return (company: Company) => company.town;
        case "principal":
            return (company: Company) => companyPrincipalManagerEmail(company, users) ?? "";
    }
};

function Companies(): ReactElement {
    const dispatch = useDispatch();
    const openModal = useSelector((state: RootState) => state.hydrolink.companies.companyBasicInfoModalOpen);

    const termsAndConditionsAccepted = useSelector(
        (state: RootState) => state.hydrolink.auth.termsAndConditionsAccepted,
    );

    const [search, setSearch] = React.useState("");
    const currentPage = useSelector((root: RootState) => root.hydrolink.companies.pagination.currentPage);
    const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin);
    const itemsPerPage = useSelector((state: RootState): number => state.hydrolink.companies.pagination.itemsPerPage);

    const allCompanies = useSelector((state: RootState) => state.hydrolink.companies.companies);

    const users = useSelector((state: RootState) => state.hydrolink.users.users);

    const currentSorting = useSelector((state: RootState) => state.hydrolink.companies.sortBy);
    const currentAscending = useSelector((state: RootState) => state.hydrolink.companies.ascending);

    const pagination = useSelector((state: RootState) => state.hydrolink.companies.pagination);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const pageOnUrl = Number(queryParams.get("pageQuery")) || 1;
    const shouldFetchData = termsAndConditionsAccepted || isAdmin;
    const [isSortingQueryChanged, setIsSortingQueryChanged] = useState(false);
    const sortKeyOnUrl = queryParams.get("sortByQuery") ?? "default";
    const orderKeyOnUrl = queryParams.get("orderByQuery") ?? "asc";

    const sortedAndFiltered = useSortedAndFiltered(
        allCompanies,
        getSortData(currentSorting, users),
        currentAscending,
        search,
        searchData(users),
        pagination,
    );

    const alarmsNotLoaded = sortedAndFiltered.items.filter((x) =>
        [AlarmsLoadingState.loading, undefined].includes(x.alarmLoadingState),
    );

    useEffect(() => {
        if (alarmsNotLoaded.length > 0) {
            dispatch(FetchCompanyWarningNamesAction({ companyIds: alarmsNotLoaded.map((x) => x.id) }));
        }
    }, [dispatch, alarmsNotLoaded]);

    useEffect(() => {
        if (shouldFetchData) {
            dispatch(FetchUsersAction());
            dispatch(FetchCompaniesWithoutAlarmsAction());
            dispatch(FetchAlarmTypesAction());
        }
    }, [dispatch, shouldFetchData]);

    useEffect(() => {
        if (shouldFetchData) {
            if (pageOnUrl !== currentPage) {
                dispatch(CompaniesPaginationPageChangeAction(pageOnUrl));
            }
        }
    }, [currentPage, dispatch, pageOnUrl, shouldFetchData]);

    useEffect(() => {
        if (shouldFetchData) {
            const isQueryChanged =
                sortKeyOnUrl !== currentSorting || orderKeyOnUrl !== (currentAscending ? "asc" : "desc");

            setIsSortingQueryChanged(isQueryChanged);
            dispatch(
                SortCompaniesAction({
                    sortBy: sortKeyOnUrl,
                    ascending: orderKeyOnUrl === "asc",
                }),
            );
        }
    }, [
        currentAscending,
        currentSorting,
        dispatch,
        isSortingQueryChanged,
        orderKeyOnUrl,
        queryParams,
        shouldFetchData,
        sortKeyOnUrl,
    ]);

    const handleOpenCreateCompanyModal = () => {
        dispatch(OpenCompanyBasicInfoModal());
    };

    const pageChange = useCallback(
        (pageNumber: number) => {
            dispatch(CompaniesPaginationPageChangeAction(pageNumber));
            const sortingQueriesOnPageChange =
                sortKeyOnUrl !== "default" ? `&sortByQuery=${sortKeyOnUrl}&orderByQuery=${orderKeyOnUrl}` : "";
            navigate(`?pageQuery=${pageNumber}${sortingQueriesOnPageChange}`);
        },
        [dispatch, navigate, orderKeyOnUrl, sortKeyOnUrl],
    );

    const itemsPerPageChange = (itemsPerPage: number) => {
        dispatch(CompaniesPaginationItemsPerPageChangeAction(itemsPerPage));
    };

    return (
        <>
            <CompanyBasicInfoModal isOpen={openModal} />
            <ViewContainer>
                <div
                    style={{
                        ...alignments.evenHorizontal,
                    }}
                >
                    <CommonSearchBar
                        search={search}
                        setSearch={setSearch}
                        pageChange={pageChange}
                        currentPage={currentPage}
                    />
                    <CompaniesHeader></CompaniesHeader>
                    {isAdmin && <CreateCompaniesButton openCreateCompanyModal={handleOpenCreateCompanyModal} />}
                </div>
            </ViewContainer>

            <ViewContainer>
                <CompaniesTable companies={sortedAndFiltered.items} />
            </ViewContainer>
            <Pagination
                current={currentPage}
                itemsCount={sortedAndFiltered.itemCount}
                currentPageCallback={pageChange}
                itemsPerPageCallback={itemsPerPageChange}
                itemsPerPage={itemsPerPage}
            />
        </>
    );
}

export default function CompaniesView() {
    return elementWithHeaderAndFooter(<Companies />);
}
