import { LogoutSuccessAction } from "./auth-actions";
import { HydrolinkDispatch } from "../../configureStore";
import { persistentStorage } from "../../utils/persistence-storage";

export function logout() {
    return (dispatch: HydrolinkDispatch) => {
        persistentStorage.authToken.clear();
        return dispatch(LogoutSuccessAction());
    };
}
