import { isSwedishVersion } from "../utils/location";
import { retrieveLanguage } from "../utils/languageStore";
import { ASSETS_HOST } from "../constants/urls";

/**
 * @todo This map is confusing: it mostly maps language to URL but in case we
 * are deployed in Sweden, it maps *country* ('se' = Sweden) to URL, and in that
 * case language is forced to 'sv' (Swedish). Probably should instead map
 * country-language pair to URL, e.g. 'fi-sv' -> URL, 'se-sv' -> URL, and also
 * have the default per country (Finnish doc for Finland, Swedish for Sweden).
 * Also note that nowadays the backend knows the country via its
 * `localization.country` parameter. That could be sent to the frontend and used
 * instead of checking whether the URL ends in '.se'.
 */
export type LanguageToUrlMap = {
    default: string;
    se: string;
    sv: string;
    [key: string]: string;
};

export type Props = {
    title: string;
    languageToUrlMap: LanguageToUrlMap;
};

export default function PdfView({ title, languageToUrlMap }: Props) {
    const lang = retrieveLanguage();

    const getDocument = (): string => {
        const language = isSwedishVersion() ? "se" : lang;
        const path = languageToUrlMap[language] || languageToUrlMap["default"];
        // Use an absolute path if ASSETS_HOST is defined, otherwise use a relative path
        if (ASSETS_HOST !== undefined) {
            return `${ASSETS_HOST}${path}`;
        }
        return path;
    };

    return (
        <iframe
            title={title}
            style={{
                minHeight: "calc(100vh - 150px)",
                width: "100%",
                marginBottom: "20px",
                border: "0px",
            }}
            src={getDocument()}
        ></iframe>
    );
}
