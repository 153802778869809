import React, { useEffect } from "react";
import { table } from "../theme";
import SorterIcon from "./SorterIcon";
import { TableHeadCell } from "./Table";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
    sortKey: string;
    sortBy: string;
    sortAction: (sortBy: string, ascending: boolean) => void;
    title: string;
    width?: string;
    currentAscending: boolean;
};

export default function Sorter({
    sortKey,
    currentAscending,
    sortAction,
    title,
    sortBy,
    width = table.tableCellWidthLarge,
}: Props): JSX.Element {
    const [ascending, setAscending] = React.useState<boolean>(true);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    useEffect(() => {
        if (sortKey === sortBy && currentAscending === ascending) {
            setAscending(!ascending);
        } else if (sortKey !== sortBy) {
            setAscending(true);
        }
    }, [ascending, currentAscending, sortBy, sortKey]);
    const onClick = () => {
        sortAction(sortKey, ascending);
        queryParams.set("sortByQuery", sortKey);
        queryParams.set("orderByQuery", ascending ? "asc" : "desc");
        navigate({ search: queryParams.toString() });
    };

    return (
        <TableHeadCell width={width} style={{ cursor: "pointer" }} onClick={onClick}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                {title}
                <SorterIcon sortKey={sortKey} sortBy={sortBy} ascending={ascending} />
            </div>
        </TableHeadCell>
    );
}
