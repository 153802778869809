import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { Modal } from "../../components/common/modal/Modal";
import { ModalInput } from "../../components/common/modal/ModalInput";
import { alignments } from "../../theme";
import { AppDispatch } from "../../interfaces/RootState";
import { ErrorOccurredAction } from "../../redux/error/error-actions";
import { CreateCompanySubscriptionRequestAction } from "../../redux/contracts/contracts-actions";

/**
 * Props for {@link CreateCompanySubscriptionModal}.
 */
export type Props = {
    /**
     * Redux dispatch function.
     */
    dispatch: AppDispatch;

    /**
     * Whether the modal is open.
     */
    isOpen: boolean;

    /**
     * Function to close the modal (set `isOpen` to `false`).
     */
    close: () => void;

    /**
     * User ID of the manager who will sign the contract.
     */
    userId: number;

    /**
     * ID of the company for which to create the contract.
     */
    companyId: number;

    /**
     * Initial data for the form.
     */
    initialData: FormData;

    /**
     * Test IDs for the modal elements.
     */
    testIds?: TestIds;
};

/**
 * Form data for a company subscription.
 */
export type FormData = {
    /**
     * Business ID (Y-tunnus) of the housing company.
     */
    housingCompanyBusinessId: string;

    /**
     * Street address of the housing company.
     */
    housingCompanyStreetAddress: string;

    /**
     * ZIP code of the housing company.
     */
    housingCompanyZipCode: string;

    /**
     * Town of the housing company.
     */
    housingCompanyTown: string;

    /**
     * Name of the management company.
     */
    managementCompanyName: string;

    /**
     * Business ID (Y-tunnus) of the management company.
     */
    managementCompanyBusinessId: string;

    /**
     * Street address of the management company.
     */
    managementCompanyStreetAddress: string;

    /**
     * ZIP code of the management company.
     */
    managementCompanyZipCode: string;

    /**
     * Town of the management company.
     */
    managementCompanyTown: string;

    /**
     * Phone number of the manager.
     */
    managerPhone: string;
};

/**
 * Test IDs for {@link CreateCompanySubscriptionModal}.
 */
export type TestIds = {
    closeButton?: string;
    saveButton?: string;
    housingCompanyBusinessIdInput?: string;
    housingCompanyStreetAddressInput?: string;
    housingCompanyZipCodeInput?: string;
    housingCompanyTownInput?: string;
    managementCompanyNameInput?: string;
    managementCompanyBusinessIdInput?: string;
    managementCompanyStreetAddressInput?: string;
    managementCompanyZipCodeInput?: string;
    managementCompanyTownInput?: string;
    managerPhoneInput?: string;
};

/**
 * Modal for entering data for a company subscription.
 */
export default function CreateCompanySubscriptionModal({
    dispatch,
    isOpen,
    close,
    userId,
    companyId,
    initialData,
    testIds,
}: Props) {
    const { t } = useTranslation();

    const [data, setData] = useState(initialData);

    const resetAndClose = () => {
        setData(initialData);
        close();
    };

    const onChange = (field: keyof FormData) => (e: React.ChangeEvent<HTMLInputElement>) =>
        setData((prev) => ({ ...prev, [field]: e.target.value }));

    const onSave = () => {
        const errorKey = validateFormData(data);
        if (errorKey !== null) {
            dispatch(ErrorOccurredAction(errorKey));
            return;
        }
        dispatch(CreateCompanySubscriptionRequestAction({ userId, companyId, ...data }));
        resetAndClose();
    };

    const onClose = resetAndClose;

    const DialogActions = () => {
        return (
            <>
                <Button onClick={onClose} data-testid={testIds?.closeButton}>
                    {t("generic.cancel")}
                </Button>
                <Button variant="contained" onClick={onSave} data-testid={testIds?.saveButton}>
                    {t("generic.save")}
                </Button>
            </>
        );
    };

    return (
        <Modal
            title={t("contracts.forms.createCompanySubscription.title")}
            open={isOpen}
            dialogActions={<DialogActions />}
            closeModal={onClose}
        >
            <div style={{ ...alignments.twoElementGrid }}>
                <ModalInput
                    key="housingCompanyBusinessId"
                    title={`${t("contracts.forms.createCompanySubscription.fields.housingCompanyBusinessId")} *`}
                    value={data.housingCompanyBusinessId}
                    handleFormValue={onChange("housingCompanyBusinessId")}
                    dataTestId={testIds?.housingCompanyBusinessIdInput}
                />
                <ModalInput
                    key="housingCompanyStreetAddress"
                    title={`${t("contracts.forms.createCompanySubscription.fields.housingCompanyStreetAddress")} *`}
                    value={data.housingCompanyStreetAddress}
                    handleFormValue={onChange("housingCompanyStreetAddress")}
                    dataTestId={testIds?.housingCompanyStreetAddressInput}
                />
                <ModalInput
                    key="housingCompanyZipCode"
                    title={`${t("contracts.forms.createCompanySubscription.fields.housingCompanyZipCode")} *`}
                    value={data.housingCompanyZipCode}
                    handleFormValue={onChange("housingCompanyZipCode")}
                    dataTestId={testIds?.housingCompanyZipCodeInput}
                />
                <ModalInput
                    key="housingCompanyTown"
                    title={`${t("contracts.forms.createCompanySubscription.fields.housingCompanyTown")} *`}
                    value={data.housingCompanyTown}
                    handleFormValue={onChange("housingCompanyTown")}
                    dataTestId={testIds?.housingCompanyTownInput}
                />
                <ModalInput
                    key="managementCompanyName"
                    title={`${t("contracts.forms.createCompanySubscription.fields.managementCompanyName")} *`}
                    value={data.managementCompanyName}
                    handleFormValue={onChange("managementCompanyName")}
                    dataTestId={testIds?.managementCompanyNameInput}
                />
                <ModalInput
                    key="managementCompanyBusinessId"
                    title={`${t("contracts.forms.createCompanySubscription.fields.managementCompanyBusinessId")} *`}
                    value={data.managementCompanyBusinessId}
                    handleFormValue={onChange("managementCompanyBusinessId")}
                    dataTestId={testIds?.managementCompanyBusinessIdInput}
                />
                <ModalInput
                    key="managementCompanyStreetAddress"
                    title={`${t("contracts.forms.createCompanySubscription.fields.managementCompanyStreetAddress")} *`}
                    value={data.managementCompanyStreetAddress}
                    handleFormValue={onChange("managementCompanyStreetAddress")}
                    dataTestId={testIds?.managementCompanyStreetAddressInput}
                />
                <ModalInput
                    key="managementCompanyZipCode"
                    title={`${t("contracts.forms.createCompanySubscription.fields.managementCompanyZipCode")} *`}
                    value={data.managementCompanyZipCode}
                    handleFormValue={onChange("managementCompanyZipCode")}
                    dataTestId={testIds?.managementCompanyZipCodeInput}
                />
                <ModalInput
                    key="managementCompanyTown"
                    title={`${t("contracts.forms.createCompanySubscription.fields.managementCompanyTown")} *`}
                    value={data.managementCompanyTown}
                    handleFormValue={onChange("managementCompanyTown")}
                    dataTestId={testIds?.managementCompanyTownInput}
                />
                <ModalInput
                    key="managerPhone"
                    title={`${t("contracts.forms.createCompanySubscription.fields.managerPhone")} *`}
                    value={data.managerPhone}
                    handleFormValue={onChange("managerPhone")}
                    dataTestId={testIds?.managerPhoneInput}
                />
            </div>
        </Modal>
    );
}

/**
 * Validate the form data.
 *
 * @param data Form data to validate.
 * @returns Error message translation key if the data is invalid, otherwise `null`.
 */
function validateFormData(data: FormData): string | null {
    const key = (suffix: string) => `contracts.forms.createCompanySubscription.errors.${suffix}`;

    const empty = /^\s*$/;

    if (empty.test(data.housingCompanyBusinessId)) {
        return key("housingCompanyBusinessIdMissing");
    }
    if (empty.test(data.housingCompanyStreetAddress)) {
        return key("housingCompanyStreetAddressMissing");
    }
    if (empty.test(data.housingCompanyZipCode)) {
        return key("housingCompanyZipCodeMissing");
    }
    if (empty.test(data.housingCompanyTown)) {
        return key("housingCompanyTownMissing");
    }
    if (empty.test(data.managementCompanyName)) {
        return key("managementCompanyNameMissing");
    }
    if (empty.test(data.managementCompanyBusinessId)) {
        return key("managementCompanyBusinessIdMissing");
    }
    if (empty.test(data.managementCompanyStreetAddress)) {
        return key("managementCompanyStreetAddressMissing");
    }
    if (empty.test(data.managementCompanyZipCode)) {
        return key("managementCompanyZipCodeMissing");
    }
    if (empty.test(data.managementCompanyTown)) {
        return key("managementCompanyTownMissing");
    }
    if (empty.test(data.managerPhone)) {
        return key("managerPhoneMissing");
    }

    return null;
}
