import { borders, spacings } from "../theme";

export default function SeparatorLine() {
    return (
        <hr
            style={{
                marginTop: spacings.inlineSpacing,
                border: borders.fineOutline,
            }}
        />
    );
}
