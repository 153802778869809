import { Company } from "../../../redux/companies/companies-types";
import { useTranslation } from "react-i18next";
import SIMcardList from "./SIMcardList";
import BasicCompanyInfo from "./BasicCompanyInfo";
import ExpandableBlock from "../../../components/ExpandableBlock";
import Reporting from "./Reporting";
import Alarms from "./Alarms";
import { useHasPrivileges } from "../../../utils/useHasPrivilegesOf";
import { Client } from "../../../redux/centers/centers-types";
import ApiSettings from "./api-settings/ApiSettings";
import { Managers } from "./managers/Managers";

type Props = {
    company: Company;
    clients: Client[];
    apiKey: string | null;
};

export default function CompanyInfo({ company, clients, apiKey }: Props) {
    const t = useTranslation().t;
    const canSeeApiSettings = useHasPrivileges("can see api settings", company.id).hasPrivilege;
    return (
        <ExpandableBlock leftSide={t("companies.info.companyInfo")} grey>
            <BasicCompanyInfo company={company} />
            <ExpandableBlock leftSide={t("companies.managers")}>
                <Managers company={company} />
            </ExpandableBlock>
            <ExpandableBlock leftSide={t("companies.errorAlarms")}>
                <Alarms companyId={company.id} />
            </ExpandableBlock>
            <ExpandableBlock leftSide={t("companies.info.reporting")}>
                <Reporting companyId={company.id} />
            </ExpandableBlock>
            <ExpandableBlock leftSide={t("companies.info.centerSimCardNumbers")}>
                <SIMcardList clients={clients} companyId={company.id} />
            </ExpandableBlock>
            {canSeeApiSettings && (
                <ExpandableBlock leftSide={t("companies.info.apiSettings")}>
                    <ApiSettings apiKey={apiKey} companyId={company.id} />
                </ExpandableBlock>
            )}
        </ExpandableBlock>
    );
}
