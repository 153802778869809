import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TextField } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import deepEqual from "deep-equal";
import { useHasPrivileges } from "../../../utils/useHasPrivilegesOf";
import { useButtonDisabled } from "../../../utils/useButtonDisabled";
import { Client } from "../../../redux/centers/centers-types";
import { UpdateClientTelephoneNumberAction } from "../../../redux/centers/centers-actions";
import { TableRowWithLabel } from "../../../components/TableRowWithLabel";

type Props = {
    clients: Client[];
    companyId: number;
};

const convertClientsToMap = (clients: Client[]) => {
    let numberByClientIdRecord = {} as Record<number, string | undefined>;
    clients.forEach((val) => {
        numberByClientIdRecord[val.id] = val.telephoneNumber;
    });
    return numberByClientIdRecord;
};

const SIMcardList = ({ clients, companyId }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [numberByClientId, setNumberByClientId] = React.useState<Record<number, string | undefined>>(
        convertClientsToMap(clients),
    );
    const canUpdateClientTelephoneNumber = useHasPrivileges(
        "can update client telephone number",
        companyId,
    ).hasPrivilege;

    const changed = (): boolean => !deepEqual(numberByClientId, convertClientsToMap(clients));

    const updateClientTelephoneNumberButtonDisabled = useButtonDisabled(canUpdateClientTelephoneNumber, !changed());

    const onNumberChanged = (id: number, e: string | undefined) => {
        setNumberByClientId({ ...numberByClientId, [id]: e });
    };

    const onSaveClicked = () => {
        for (let c of clients) {
            let telephoneNumber = numberByClientId[c.id] ?? undefined;
            if (telephoneNumber !== undefined) {
                if (c.telephoneNumber !== telephoneNumber) {
                    dispatch(
                        UpdateClientTelephoneNumberAction({
                            clientId: c.id,
                            newTelephoneNumber: telephoneNumber !== "" ? telephoneNumber : undefined,
                        }),
                    );
                }
            }
        }
    };

    return (
        <>
            <div style={{ width: "50%" }}>
                <TableContainer component="div">
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("apartments.centerId")}</TableCell>
                                <TableCell>{t("companies.info.simCardNumber")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clients.map((client) => (
                                <TableRowWithLabel key={client.id} htmlFor={client.code} label={client.code}>
                                    <TextField
                                        value={numberByClientId[client.id] ?? undefined}
                                        onChange={(e) => onNumberChanged(client.id, e.target.value)}
                                    />
                                </TableRowWithLabel>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Button disabled={updateClientTelephoneNumberButtonDisabled} variant="contained" onClick={onSaveClicked}>
                {t("generic.save")}
            </Button>
        </>
    );
};

export default SIMcardList;
