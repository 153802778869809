import AlarmCustomizationDropDowns from "./AlarmCustomizationDropDowns";
import { AlarmType, Company } from "../../../../redux/companies/companies-types";
import { AlarmCustomization } from "../../../../redux/apartments/apartments-types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../interfaces/RootState";
import AlarmCustomizationHead from "./AlarmCustomizationHead";
import { useTranslation } from "react-i18next";

function AlarmTypeRow({
    alarmType,
    comp,
    allCustomizations,
}: {
    alarmType: AlarmType;
    comp: Company;
    allCustomizations: AlarmCustomization[];
}) {
    let relevantCustomization = (allCustomizations || []).find((c) => c.alarmType === alarmType.alarmName) || null;

    return (
        <AlarmCustomizationDropDowns company={comp} alarmType={alarmType} alarmCustomization={relevantCustomization} />
    );
}

export type Props = {
    alarmCustomizations: AlarmCustomization[];
    company: Company;
};

const isSmokeDetectorAlarmTypes = (alarmType: AlarmType): boolean => {
    return alarmType.supportedMeterTypes.length === 1 && alarmType.supportedMeterTypes.includes("smoke");
};

const AlarmCustomizationMenu = ({ alarmCustomizations, company }: Props) => {
    const alarmTypes = useSelector((state: RootState) => state.hydrolink.companies.alarmTypes);
    const t = useTranslation().t;
    const smokeDetectorAlarms = alarmTypes.filter(
        (alarmType) => isSmokeDetectorAlarmTypes(alarmType) && !alarmType.adminOnly,
    );
    const meterAlarms = alarmTypes.filter((alarmType) => !isSmokeDetectorAlarmTypes(alarmType) && !alarmType.adminOnly);

    return (
        <>
            <span style={{ marginTop: "1em" }} className="text-bold">
                {t("alarms.alarmSettings")}
            </span>
            <AlarmCustomizationHead alarmTableTitle={t("alarms.meterAlarms")}>
                {meterAlarms.map((alarmType) => (
                    <AlarmTypeRow
                        key={alarmType.alarmName}
                        alarmType={alarmType}
                        comp={company}
                        allCustomizations={alarmCustomizations}
                    />
                ))}
            </AlarmCustomizationHead>
            <AlarmCustomizationHead alarmTableTitle={t("alarms.smokeDetectorAlarms")}>
                {smokeDetectorAlarms.map((alarmType) => (
                    <AlarmTypeRow
                        key={alarmType.alarmName}
                        alarmType={alarmType}
                        comp={company}
                        allCustomizations={alarmCustomizations}
                    />
                ))}
            </AlarmCustomizationHead>
        </>
    );
};

export default AlarmCustomizationMenu;
