import { Checkbox } from "@mui/material";

interface Props {
    mergeApartmentsOn: boolean;
    apartmentId: number;
    check: () => void;
    checked: boolean;
}

export default function ApartmentMergingCheckbox({ mergeApartmentsOn, apartmentId, check, checked }: Props) {
    if (mergeApartmentsOn)
        return (
            <Checkbox
                id={apartmentId.toString()}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onChange={() => {
                    check();
                }}
                checked={checked}
            />
        );
    else return <></>;
}
