import { createAction } from "@reduxjs/toolkit";
import {
    CompanyInvoiceSettings,
    CreateCompanyPayload,
    DeleteCompanyPayload,
    DeleteCompanyUserRolePayload,
    FetchAlarmTypesSuccessPayload,
    FetchCompaniesSuccessPayload,
    FetchCompanyApiKeyInput,
    FetchCompanyApiKeySuccessPayload,
    FetchCompanyInvoicePayload,
    FetchCompanyWarningNamesFailurePayload,
    FetchCompanyWarningNamesPayload,
    FetchCompanyWarningNamesSuccessPayload,
    ManageCompanyUsersPayload,
    MarkNewPrincipalManagerMessageAsAcknowledgedPayload,
    MergeApartmentsPayload,
    PremiumTogglePayload,
    RefreshCompanyApiKeyInput,
    RefreshCompanyApiKeySuccessPayload,
    SaveCompanyInvoicePayload,
    SaveCompanyPayload,
    SortCompaniesPayload,
    UpdateApiAccessPayload,
    UpdateUserRoleAsManagerPayload,
    UpdateUserRolesPayload,
} from "./companies-types";

export const UpdateUserRolesAction = createAction<UpdateUserRolesPayload>("UPDATE_USER_ROLES");
export const UpdateUserAsManagerAction = createAction<UpdateUserRoleAsManagerPayload>("UPDATE_USER_AS_MANAGER");
export const UpdateUserAsManagerSuccessAction = createAction<void>("UPDATE_USER_AS_MANAGER_SUCCESS");
export const UpdateUserRolesSuccessAction = createAction<void>("UPDATE_USER_ROLES_SUCCESS");
export const UpdateUserAsManagerFailureAction = createAction<void>("UPDATE_USER_AS_MANAGER_FAILURE");
export const UpdateUserRolesFailureAction = createAction<void>("UPDATE_USER_ROLES_FAILURE");

export const ManageCompanyUsersAction = createAction<ManageCompanyUsersPayload>("MANAGE_COMPANY_USERS");

export const ManageCompanyUsersSuccessAction = createAction<void>("MANAGE_COMPANY_USERS_SUCCESS");

export const ManageCompanyUsersFailureAction = createAction<void>("MANAGE_COMPANY_USERS_FAILURE");

export const DeleteCompanyUserRoleAction = createAction<DeleteCompanyUserRolePayload>("DELETE_COMPANY_USER");
export const DeleteCompanyUserRoleSuccessAction = createAction<void>("DELETE_COMPANY_USER_SUCCESS");
export const DeleteCompanyUserRoleFailureAction = createAction<void>("DELETE_COMPANY_USER_FAILURE");

export const FetchCompaniesWithoutAlarmsAction = createAction<void>("FETCH_COMPANIES_WITHOUT_ALARMS");
export const FetchCompaniesSuccessAction = createAction<FetchCompaniesSuccessPayload>("FETCH_COMPANIES_SUCCESS");
export const FetchCompaniesFailureAction = createAction<void>("FETCH_COMPANIES_FAILURE");

export const FetchCompanyWarningNamesAction =
    createAction<FetchCompanyWarningNamesPayload>("FETCH_COMPANY_WARNING_NAMES");
export const FetchCompanyWarningNamesSuccessAction = createAction<FetchCompanyWarningNamesSuccessPayload[]>(
    "FETCH_COMPANY_WARNING_NAMES_SUCCESS",
);
export const FetchCompanyWarningNamesFailureAction = createAction<FetchCompanyWarningNamesFailurePayload>(
    "FETCH_COMPANY_WARNING_NAMES_FAILURE",
);

export const FetchAlarmTypesAction = createAction<void>("FETCH_ALARM_TYPES");
export const FetchAlarmTypesSuccessAction = createAction<FetchAlarmTypesSuccessPayload>("FETCH_ALARM_TYPES_SUCCESS");
export const FetchAlarmTypesFailureAction = createAction<void>("FETCH_ALARM_TYPES_FAILURE");

export const FetchCompanyApiKeyAction = createAction<FetchCompanyApiKeyInput>("FETCH_COMPANY_API_KEY");
export const FetchCompanyApiKeySuccessAction = createAction<FetchCompanyApiKeySuccessPayload>(
    "FETCH_COMPANY_API_KEY_SUCCESS",
);
export const FetchCompanyApiKeyFailureAction = createAction<void>("FETCH_COMPANY_API_KEY_FAILURE");

export const SortCompaniesAction = createAction<SortCompaniesPayload>("SORT_COMPANIES");

export const RefreshCompanyApiKeyAction = createAction<RefreshCompanyApiKeyInput>("REFRESH_COMPANY_API_KEY");
export const RefreshCompanyApiKeySuccessAction = createAction<RefreshCompanyApiKeySuccessPayload>(
    "REFRESH_COMPANY_API_KEY_SUCCESS",
);
export const RefreshCompanyApiKeyFailureAction = createAction<void>("REFRESH_COMPANY_API_KEY_FAILURE");

export const CreateCompanyAction = createAction<CreateCompanyPayload>("CREATE_COMPANY");
export const CreateCompanySuccessAction = createAction<number>("CREATE_COMPANY_SUCCESS");
export const CreateCompanyFailureAction = createAction<string>("CREATE_COMPANY_FAILURE");
export const CloseCompanyBasicInfoModal = createAction<void>("CLOSE_CREATE_COMPANY_MODAL");
export const OpenCompanyBasicInfoModal = createAction<void>("OPEN_CREATE_COMPANY_MODAL");
export const SaveCompanyBasicInfoAction = createAction<SaveCompanyPayload>("SAVE_COMPANY_BASIC_INFO");
export const SaveCompanyBasicInfoSuccessAction = createAction<void>("SAVE_COMPANY_BASIC_INFO_SUCCESS");
export const SaveCompanyBasicInfoFailureAction = createAction<void>("SAVE_COMPANY_BASIC_INFO_FAILURE");

export const CompaniesPaginationPageChangeAction = createAction<number>("COMPANIES_PAGINATION_PAGE_CHANGE");
export const CompaniesPaginationItemsPerPageChangeAction = createAction<number>(
    "COMPANIES_PAGINATION_ITEMS_PER_PAGE_CHANGE",
);

export const UpdateApiAccessAction = createAction<UpdateApiAccessPayload>("UPDATE_API_ACCESS");
export const UpdateApiAccessSuccessAction = createAction<UpdateApiAccessPayload>("UPDATE_API_ACCESS_SUCCESS");

export const MergeApartmentsAction = createAction<MergeApartmentsPayload>("MERGE_APARTMENTS");
export const MergeApartmentsSuccessAction = createAction<void>("MERGE_APARTMENTS_SUCCESS");
export const MergeApartmentsFailureAction = createAction<void>("MERGE_APARTMENTS_FAILURE");

export const PremiumToggleAction = createAction<PremiumTogglePayload>("PREMIUM_TOGGLE");
export const PremiumToggleSuccessAction = createAction<void>("PREMIUM_TOGGLE_SUCCESS");
export const PremiumToggleFailureAction = createAction<void>("PREMIUM_TOGGLE_FAILURE");
export const DeleteCompanyAction = createAction<DeleteCompanyPayload>("DELETE_COMPANY");
export const DeleteCompanySuccessAction = createAction<void>("DELETE_COMPANY_SUCCESS");

export const SaveCompanyInvoiceInfoAction = createAction<SaveCompanyInvoicePayload>("SAVE_COMPANY_INVOICE_INFO");
export const SaveCompanyInvoiceInfoSuccessAction = createAction<void>("SAVE_COMPANY_INVOICE_INFO_SUCCESS");
export const SaveCompanyInvoiceInfoFailureAction = createAction<void>("SAVE_COMPANY_INVOICE_INFO_FAILURE");

export const FetchCompanyInvoiceInfoAction = createAction<FetchCompanyInvoicePayload>("FETCH_COMPANY_INVOICE_INFO");
export const FetchCompanyInvoiceInfoSuccessAction = createAction<CompanyInvoiceSettings>(
    "FETCH_COMPANY_INVOICE_INFO_SUCCESS",
);
export const FetchCompanyInvoiceInfoFailureAction = createAction<void>("FETCH_COMPANY_INVOICE_INFO_FAILURE");

export const MarkNewPrincipalManagerMessageAsAcknowledgedAction =
    createAction<MarkNewPrincipalManagerMessageAsAcknowledgedPayload>(
        "MARK_NEW_PRINCIPAL_MANAGER_MESSAGE_AS_ACKNOWLEDGED",
    );
export const MarkNewPrincipalManagerMessageAsAcknowledgedSuccessAction = createAction<void>(
    "MARK_NEW_PRINCIPAL_MANAGER_MESSAGE_AS_ACKNOWLEDGED_SUCCESS",
);
export const MarkNewPrincipalManagerMessageAsAcknowledgedFailureAction = createAction<void>(
    "MARK_NEW_PRINCIPAL_MANAGER_MESSAGE_AS_ACKNOWLEDGED_FAILURE",
);
export const DownloadIntegrationMeterIdsAction = createAction<number>("DOWNLOAD_INTEGRATION_METER_IDS");
export const DownloadIntegrationMeterIdsSuccessAction = createAction<void>("DOWNLOAD_INTEGRATION_METER_IDS_SUCCESS");
export const DownloadIntegrationMeterIdsFailureAction = createAction<void>("DOWNLOAD_INTEGRATION_METER_IDS_FAILURE");
