import { Modal } from "./common/modal/Modal";

type Props = {
    open: boolean;
    onClose: () => void;
    title: string;
    body: string;
};

/**
 * A stripped down version of `Modal` which should only be used for basic info texts.
 * @param open Determines if the modal is open or not coming from parent element
 * @param onClose A callback which will be called when the user closes the modal
 * @param title Title for the modal
 * @param body Body for the modal
 */
export default function InfoModal({ open, onClose, title, body }: Props): JSX.Element {
    return (
        <Modal open={open} title={title} closeModal={onClose} dialogActions={<></>}>
            {body}
        </Modal>
    );
}
