import { Contract, ContractState } from "../../redux/contracts/contracts-types";

/**
 * Get the latest active (or pending if none is active) contract from a list of contracts.
 *
 * @param contracts List of contracts to search in.
 * @returns Latest active contract, or the latest pending contract if no active contract is found, or null if no active
 * or pending contract is found.
 */
export function getCurrentContract(contracts: Contract[]): Contract | null {
    const mostRecentFirst = [...contracts].sort(
        (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    );
    const active = mostRecentFirst.find((contract) => contract.state === ContractState.ACTIVE);
    if (active !== undefined) {
        return active;
    }
    const pending = mostRecentFirst.find((contract) => contract.state === ContractState.PENDING);
    if (pending !== undefined) {
        return pending;
    }
    return null;
}
