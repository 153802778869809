import { ChangeEventHandler } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { alignments, borders, colors, spacings } from "../../../theme";
import { useTranslation } from "react-i18next";

const styles = {
    search: {
        ...borders.basicOutline,
        ...alignments.evenHorizontal,
        width: "30%",
        marginTop: spacings.inlineSpacing,
        marginBottom: spacings.inlineSpacing,
        marginRight: spacings.inlineSpacing,

        backgroundColor: colors.neutral00,
    },
    searchIcon: {
        alignSelf: "center",
        height: "100%",
    },
};

interface Props {
    searchValue: string;
    onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

export default function StyledSearchBar(props: Props) {
    const t = useTranslation().t;

    return (
        <div style={styles.search}>
            <InputBase
                value={props.searchValue}
                onChange={props.onChange}
                placeholder={t("generic.search") + "..."}
                style={{
                    width: "100%",
                }}
                inputProps={{
                    "aria-label": t("generic.search"),
                }}
            />
            <div style={styles.searchIcon}>
                <SearchIcon />
            </div>
        </div>
    );
}
