import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FetchCompanyApiKeyAction, RefreshCompanyApiKeyAction } from "../../../../redux/companies/companies-actions";
import { Button } from "../../../../components/Button";
import { alignments, spacings } from "../../../../theme";
import { RootState } from "../../../../interfaces/RootState";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import { useButtonDisabled } from "../../../../utils/useButtonDisabled";

type Props = {
    companyId: number;
    apiKey: string | null;
};

const ApiKey = ({ companyId, apiKey }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const apiAccessEnabled = useSelector((state: RootState) =>
        state.hydrolink.companies.companies.find((c) => c.id === companyId),
    )?.apiAccessEnabled;
    const canRefreshApiKey = useHasPrivileges("can refresh api key", companyId).hasPrivilege;
    const refreshApiKeyButtonDisabled = useButtonDisabled(canRefreshApiKey, !apiAccessEnabled);

    useEffect(() => {
        if (apiAccessEnabled) {
            dispatch(FetchCompanyApiKeyAction({ companyId }));
        }
    }, [dispatch, companyId, apiAccessEnabled]);

    const onRefreshApiKeyClicked = () => {
        if (window.confirm(`${t("companies.newApiKeyConfirmation")}`)) {
            dispatch(RefreshCompanyApiKeyAction({ companyId }));
        }
    };

    return (
        <div
            style={{
                ...alignments.evenHorizontal,
                ...alignments.twoElementGrid,
                marginTop: spacings.inlineSpacing,
                width: "50%",
            }}
        >
            <TextField
                disabled
                InputProps={{
                    style: { width: "280px" },
                }}
                value={!apiAccessEnabled ? t("companies.info.apiAccessDisabled") : apiKey ?? ""}
            />
            <Button disabled={refreshApiKeyButtonDisabled} onClick={onRefreshApiKeyClicked} startIcon={<RefreshIcon />}>
                {t("companies.info.newApiKey")}
            </Button>
        </div>
    );
};

export default ApiKey;
