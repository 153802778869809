import { AnyAction } from "redux";
import { stateWithLoadingFalse, stateWithLoadingTrue } from "../../utils/utils";
import {
    FetchSmokeAlarmsAction,
    FetchSmokeAlarmsSuccessAction,
    FetchSmokeAlarmsFailureAction,
    SortSmokeAlarmsAction,
    SmokeAlarmsHistoryPaginationPageChangeAction,
    SmokeAlarmsHistoryPaginationItemsPerPageChangeAction,
    FetchAlarmsHistorySuccessAction,
    FetchAlarmsHistoryFailureAction,
} from "./smoke-alarms-actions";
import { SmokeAlarmsState } from "./smoke-alarms-types";
import { FetchAlarmSettingsAction } from "../apartments/apartments-actions";

const initialState: SmokeAlarmsState = {
    alarms: [],
    alarmHistoryEvents: [],
    loading: 0,
    sortBy: "alarmEnabledDate",
    ascending: true,
    pagination: {
        currentPage: 1,
        itemsPerPage: 10,
    },
};

const actions = (state: SmokeAlarmsState, action: AnyAction) => {
    if (FetchSmokeAlarmsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (FetchAlarmSettingsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    return state;
};

const successActions = (state: SmokeAlarmsState, action: AnyAction) => {
    if (FetchSmokeAlarmsSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            alarms: action.payload,
        };
    }

    if (FetchAlarmsHistorySuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            alarmHistoryEvents: action.payload,
        };
    }

    return state;
};

const failureActions = (state: SmokeAlarmsState, action: AnyAction) => {
    if (FetchSmokeAlarmsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (FetchAlarmsHistoryFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
};

const otherActions = (state: SmokeAlarmsState, action: AnyAction) => {
    if (SortSmokeAlarmsAction.match(action)) {
        return {
            ...state,
            sortBy: action.payload.sortBy,
            ascending: action.payload.ascending,
        };
    }

    if (SmokeAlarmsHistoryPaginationPageChangeAction.match(action)) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                currentPage: action.payload,
            },
        };
    }

    if (SmokeAlarmsHistoryPaginationItemsPerPageChangeAction.match(action)) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                itemsPerPage: action.payload,
            },
        };
    }

    return state;
};

export default function smokeAlarmsReducer(
    state: SmokeAlarmsState = initialState,
    action: AnyAction,
): SmokeAlarmsState {
    state = actions(state, action);
    state = successActions(state, action);
    state = failureActions(state, action);
    state = otherActions(state, action);

    return state;
}
