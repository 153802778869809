import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewContainer } from "../../components/ViewContainer";
import { elementWithHeaderAndFooter } from "../../components/common/header/Header";
import Pagination from "../../components/common/pagination";
import CommonSearchBar from "../../components/common/search-bar/CommonSearchBar";
import { RootState } from "../../interfaces/RootState";
import { FetchCompaniesWithoutAlarmsAction } from "../../redux/companies/companies-actions";
import { Company, Role } from "../../redux/companies/companies-types";
import {
    FetchUsersAction,
    SortUsersAction,
    UsersPaginationItemsPerPageChangeAction,
    UsersPaginationPageChangeAction,
} from "../../redux/users/users-actions";
import { getCompanyManagerByUser, getHighestRoleForUser } from "../../redux/users/users-reducer";
import { User } from "../../redux/users/users-types";
import { alignments } from "../../theme";
import useSortedAndFiltered from "../../utils/sortAndFilter";
import CreateUserButton from "./CreateUserButton";
import CreateUserModal from "./CreateUserModal";
import MassMarkPrincipalsButton from "./MassMarkPrincipalsButton";
import UsersTable from "./UsersTable";
import { useLocation, useNavigate } from "react-router-dom";

const getCompaniesForUser = (user: User, companies: Company[]): Company[] =>
    companies.filter((c: Company) => getCompanyManagerByUser(c, user) !== undefined);

const getRole = (u: User, companies: Company[]) => {
    let role: Role = "viewer";
    if (getCompaniesForUser) {
        role = getHighestRoleForUser(getCompaniesForUser(u, companies), u);
    }
    switch (role) {
        case "admin":
            return "A";
        case "manager":
            return "B";
        case "viewer":
            return "C";
        default:
            return "D";
    }
};

const getSortData = (currentSorting: string, companies: Company[]) => {
    switch (currentSorting) {
        case "userFullName":
            return (user: User) => user.firstName + user.lastName;
        case "username":
            return (user: User) => user.email;
        case "highestRole":
            return (user: User) => getRole(user, companies);
    }
};

const searchData = (user: User) => [user.firstName, user.lastName, user.email, user.id.toString()];

function Users(): React.ReactElement {
    const dispatch = useDispatch();
    const [search, setSearch] = React.useState("");
    const users = useSelector((state: RootState) => state.hydrolink.users.users);
    const isAdmin = useSelector((state: RootState) => state.hydrolink.auth.isAdmin);
    const companies = useSelector((state: RootState) => state.hydrolink.companies.companies);
    const currentPage = useSelector((root: RootState) => root.hydrolink.users.pagination.currentPage);
    const currentSorting = useSelector((state: RootState) => state.hydrolink.users.sortBy);

    const pagination = useSelector((state: RootState) => state.hydrolink.users.pagination);

    const currentAscending = useSelector((state: RootState) => state.hydrolink.users.ascending);
    const itemsPerPage = useSelector((root: RootState) => root.hydrolink.users.pagination.itemsPerPage);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const pageOnUrl = Number(queryParams.get("pageQuery")) || 1;
    const userId = queryParams.get("id");
    const sortKeyOnUrl = queryParams.get("sortByQuery") ?? "default";
    const orderKeyOnUrl = queryParams.get("orderByQuery") ?? "asc";

    const [isSortingQueryChanged, setIsSortingQueryChanged] = useState(false);

    const sortedAndFiltered = useSortedAndFiltered(
        users,
        getSortData(currentSorting, companies),
        currentAscending,
        search,
        searchData,
        pagination,
    );
    useEffect(() => {
        if (userId) {
            setSearch(userId);
        }
    }, [dispatch, userId]);

    useEffect(() => {
        if (pageOnUrl !== currentPage) {
            dispatch(UsersPaginationPageChangeAction(pageOnUrl));
        }
    }, [currentPage, dispatch, pageOnUrl]);

    useEffect(() => {
        const isQueryChanged = sortKeyOnUrl !== currentSorting || orderKeyOnUrl !== (currentAscending ? "asc" : "desc");

        setIsSortingQueryChanged(isQueryChanged);
        dispatch(
            SortUsersAction({
                sortBy: sortKeyOnUrl,
                ascending: orderKeyOnUrl === "asc",
            }),
        );
    }, [currentAscending, currentSorting, dispatch, isSortingQueryChanged, orderKeyOnUrl, queryParams, sortKeyOnUrl]);

    const pageChange = useCallback(
        (pageNumber: number) => {
            const sortingQueriesOnPageChange =
                sortKeyOnUrl !== "default" ? `&sortByQuery=${sortKeyOnUrl}&orderByQuery=${orderKeyOnUrl}` : "";
            dispatch(UsersPaginationPageChangeAction(pageNumber));
            if (userId) {
                navigate(`?id=${userId}`);
            } else {
                navigate(`?pageQuery=${pageNumber}${sortingQueriesOnPageChange}`);
            }
        },
        [dispatch, navigate, orderKeyOnUrl, sortKeyOnUrl, userId],
    );

    const itemsPerPageChange = (itemsPerPage: number) => {
        dispatch(UsersPaginationItemsPerPageChangeAction(itemsPerPage));
    };

    return (
        <>
            <CreateUserModal />
            <ViewContainer style={alignments.evenHorizontal}>
                <CommonSearchBar
                    search={search}
                    setSearch={setSearch}
                    pageChange={pageChange}
                    currentPage={currentPage}
                />
                {isAdmin && <MassMarkPrincipalsButton />}
                <CreateUserButton withAdminCheckbox={true} />
            </ViewContainer>
            <ViewContainer>
                <UsersTable users={sortedAndFiltered.items} />
            </ViewContainer>
            <ViewContainer>
                <Pagination
                    current={currentPage}
                    itemsCount={sortedAndFiltered.itemCount}
                    currentPageCallback={pageChange}
                    itemsPerPageCallback={itemsPerPageChange}
                    itemsPerPage={itemsPerPage}
                />
            </ViewContainer>
        </>
    );
}

export default function UsersView() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FetchUsersAction());
        dispatch(FetchCompaniesWithoutAlarmsAction());
    }, [dispatch]);

    return elementWithHeaderAndFooter(<Users />);
}
