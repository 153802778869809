import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { borders } from "../theme";

type Option = {
    value: string;
    displayText: string;
};

interface DropDownProps {
    id: string;
    options: Option[];
    selectedValue: string;
    onChange: (event: any) => void;
    disabled?: boolean;
    style?: React.CSSProperties;
    placeholder?: boolean;
}

const DropDown = (props: DropDownProps) => {
    const { t } = useTranslation();
    const placeholder = t("generic.choose").toUpperCase();
    const usePlaceholder = props.selectedValue === "" && props.placeholder;
    const selectedValue = usePlaceholder ? placeholder : props.selectedValue;
    const defaultStyle = {
        height: "35px",
        border: usePlaceholder ? borders.attentionBorder : "none",
    };

    return (
        <Select
            disabled={props.disabled}
            labelId={props.id}
            id={props.id}
            value={selectedValue}
            label=""
            style={{ ...defaultStyle, ...(props.style ?? {}) }}
            onChange={props.onChange}
        >
            {usePlaceholder && (
                <MenuItem value={placeholder} disabled selected hidden>
                    {placeholder ?? ""}
                </MenuItem>
            )}
            {props.options.map((v, i) => (
                <MenuItem key={i} value={v.value}>
                    {v.displayText}
                </MenuItem>
            ))}
        </Select>
    );
};

export default DropDown;
