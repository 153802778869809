import React from "react";
import { spacings } from "../theme";
import { Block } from "./common/block";
import ExpandIcons from "./ExpandIcons";

const defaultStyle: React.CSSProperties = {
    flexDirection: "row",
    justifyContent: "space-between",
};

const titleBlockLeftSideStyle: React.CSSProperties = {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
};

interface BlockProps {
    children: React.ReactNode;
    clickAble?: boolean;
    grey?: boolean;
    style?: React.CSSProperties;
    closeOrOpenBlock: () => void;
    dataTestId?: string;
}

function VisibleBlock(props: BlockProps): JSX.Element {
    const mergedStyle = {
        ...defaultStyle,
        cursor: props.clickAble ? "pointer" : "auto",
        ...props.style,
    };
    const onClick = props.clickAble ? () => props.closeOrOpenBlock() : undefined;
    return (
        <Block
            variant={props.grey ? "grey" : "white"}
            onClick={onClick}
            style={{ ...mergedStyle }}
            dataTestId={props.dataTestId}
        >
            {props.children}
        </Block>
    );
}

function InvisibleBlock(props: BlockProps): JSX.Element {
    const mergedStyle = {
        ...defaultStyle,
        cursor: props.clickAble ? "pointer" : "auto",
        ...props.style,
    };
    const onClick = props.clickAble ? () => props.closeOrOpenBlock() : undefined;
    return (
        <div onClick={onClick} style={{ ...mergedStyle }} data-testid={props.dataTestId}>
            {props.children}
        </div>
    );
}

interface ExpandableBlockProps {
    leftSide: React.ReactNode;
    rightSide?: React.ReactNode;
    children?: React.ReactNode;
    grey?: boolean;
    childrenNoFlex?: boolean;
    isOpen?: boolean;
    onToggleOpen?: () => void;
    headerDataTestId?: string;
}

export default function ExpandableBlock(props: ExpandableBlockProps): JSX.Element {
    const [blockOpen, setBlockOpen] = React.useState<boolean>(false);

    const isThisBlockOpen = props.isOpen ?? blockOpen; //Apartment blocks are having their open/close state in the ApartmentList
    //for making it possible to open/close all the apartments at once.
    //Other components will use this local useState

    const titleBlockStyle: React.CSSProperties = {
        display: "flex",
        alignItems: "center",
        marginTop: !props.childrenNoFlex && !props.grey ? spacings.standardSpacing : "0px",
        marginBottom: !props.grey || (props.grey && isThisBlockOpen) ? "1px" : spacings.standardSpacing,
        maxHeight: "19px",
    };

    const contentStyle: React.CSSProperties = props.grey
        ? {}
        : {
              display: !props.childrenNoFlex ? "flex" : "",
              alignItems: "end",
          };

    const titleBlockLeftSide = <>{props.leftSide}</>;
    const closeOrOpenBlock = () => {
        //Opening/closing affects either to the props (apartment blocks) or the
        if (props.onToggleOpen && props.isOpen !== undefined) {
            //local useState, depending if the open/close state exists in the props
            props.onToggleOpen();
        } else {
            setBlockOpen(!blockOpen);
        }
    };

    return (
        <>
            <VisibleBlock
                closeOrOpenBlock={closeOrOpenBlock}
                grey={props.grey}
                clickAble
                style={titleBlockStyle}
                dataTestId={props.headerDataTestId}
            >
                <InvisibleBlock closeOrOpenBlock={closeOrOpenBlock} style={titleBlockLeftSideStyle}>
                    {titleBlockLeftSide}
                </InvisibleBlock>
                <InvisibleBlock
                    closeOrOpenBlock={closeOrOpenBlock}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <InvisibleBlock closeOrOpenBlock={() => {}}>{props.rightSide}</InvisibleBlock>
                    <InvisibleBlock closeOrOpenBlock={closeOrOpenBlock} clickAble>
                        <ExpandIcons closed={!isThisBlockOpen} />
                    </InvisibleBlock>
                </InvisibleBlock>
            </VisibleBlock>
            {isThisBlockOpen && (
                <VisibleBlock closeOrOpenBlock={closeOrOpenBlock} grey={props.grey} style={contentStyle}>
                    {props.children}
                </VisibleBlock>
            )}
        </>
    );
}
