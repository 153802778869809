import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../interfaces/RootState";
import { credentialsEmpty } from "./ResidentEdit";
import { StyledFieldsText } from "../../../components/common/modal/Modal.style";
import { spacings } from "../../../theme";

export default function CredentialsDisplayer({ id }: { id: number | undefined }) {
    const t = useTranslation().t;
    const credentials = useSelector((root: RootState) => root.hydrolink.residents.credentials);
    const targetCredentials = credentials[id as keyof typeof credentials];

    // The credentials will be empty if `SendCredentials` feature flag is off in backend
    if (credentialsEmpty(targetCredentials)) {
        return <></>;
    }

    return (
        <>
            <div style={{ padding: `${spacings.inlineSpacing} 0` }}>
                <StyledFieldsText>{t("residents.username")}</StyledFieldsText>
                <p>{targetCredentials.username}</p>
            </div>
            <div style={{ padding: `${spacings.inlineSpacing} 0` }}>
                <StyledFieldsText>{t("residents.password")}</StyledFieldsText>
                <p>{targetCredentials.password}</p>
            </div>
        </>
    );
}
