import { List, ListItem, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableHeadCell,
} from "../../components/Table";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteNoteAction, FetchNotesAction, SortNotesAction } from "../../redux/notes/notes-actions";
import { RootState } from "../../interfaces/RootState";
import { Note } from "../../redux/notes/notes-types";
import { displayDateTime } from "../../utils/utils";
import Sorter from "../../components/Sorter";
import { table } from "../../theme";
import FontAwesomeIconButton from "../../components/FontAwesomeIconButton";
import useSortedAndFiltered from "../../utils/sortAndFilter";

const getSortData = (currentSorting: string) => {
    switch (currentSorting) {
        case "date":
            return (note: Note) => note.created;
        case "comment":
            return (note: Note) => note.comment;
        case "creator":
            return (note: Note) => note.creator;
    }
};

function NotesTableRow({ note, companyId }: { note: Note; companyId: number }) {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    const deleteNote = (id: number) => {
        if (window.confirm(`${t("notes.deleteNoteConfirmation")}?`)) {
            dispatch(DeleteNoteAction({ id: id, companyId: companyId }));
        }
    };

    const createDisplayComment = (n: Note) =>
        n.comment.split("\n").map((line: string, i) => (
            <ListItem key={n.id + n.comment + i} style={{ padding: 0 }}>
                {line}
            </ListItem>
        ));

    return (
        <TableRow key={note.id}>
            <TableCell>{displayDateTime(new Date(note.created))}</TableCell>
            <TableCell>
                <List style={{ padding: 0 }}>{createDisplayComment(note)}</List>
            </TableCell>
            <TableCell>{note.creator}</TableCell>
            <TableCell>
                <FontAwesomeIconButton
                    tooltip={t("notes.removeNoteTooltip")}
                    onClick={() => deleteNote(note.id)}
                    icon={faTrash}
                />
            </TableCell>
        </TableRow>
    );
}

function NotesTableHead() {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const currentSorting = useSelector((state: RootState) => state.hydrolink.notes.sortBy);
    const currentAscending = useSelector((state: RootState) => state.hydrolink.notes.ascending);

    const sortNotesFunction = (sortBy: string, ascending: boolean) => {
        dispatch(
            SortNotesAction({
                sortBy: sortBy,
                ascending: ascending,
            }),
        );
    };

    return (
        <TableHead>
            <TableRow>
                <Sorter
                    sortKey={"date"}
                    sortAction={sortNotesFunction}
                    sortBy={currentSorting}
                    title={t("notes.date")}
                    currentAscending={currentAscending}
                />
                <Sorter
                    sortKey={"comment"}
                    sortAction={sortNotesFunction}
                    sortBy={currentSorting}
                    title={t("notes.comment")}
                    width={table.tableCellWidthExtraLarge}
                    currentAscending={currentAscending}
                />
                <Sorter
                    sortKey={"creator"}
                    sortAction={sortNotesFunction}
                    sortBy={currentSorting}
                    title={t("notes.creator")}
                    currentAscending={currentAscending}
                />
                <TableHeadCell>{/* Delete button column */}</TableHeadCell>
            </TableRow>
        </TableHead>
    );
}

const ViewNotes = ({ companyId }: { companyId: number }) => {
    const dispatch = useDispatch();
    const notes = useSelector((state: RootState) => state.hydrolink.notes.notes);

    const currentSorting = useSelector((state: RootState) => state.hydrolink.notes.sortBy);
    const currentAscending = useSelector((state: RootState) => state.hydrolink.notes.ascending);

    const sortedAndFiltered = useSortedAndFiltered(notes, getSortData(currentSorting), currentAscending);

    useEffect(() => {
        dispatch(FetchNotesAction({ companyId: companyId }));
    }, [companyId, dispatch]);

    return (
        <TableContainer component={Paper}>
            <Table>
                <NotesTableHead />
                <TableBody>
                    {sortedAndFiltered.items.map((n) => (
                        <NotesTableRow note={n} companyId={companyId} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ViewNotes;
