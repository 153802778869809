import { useTranslation } from "react-i18next";
import { Contract, ContractState } from "../../redux/contracts/contracts-types";
import { Tooltip } from "@mui/material";
import { Button } from "../../components/Button";
import { useState } from "react";
import { SendInviteeSignRequestAction } from "../../redux/contracts/contracts-actions";
import { AppDispatch } from "../../interfaces/RootState";

/**
 * Props for {@link ActionButton}.
 */
export type Props = {
    /**
     * Redux dispatch function.
     */
    dispatch: AppDispatch;

    /**
     * Contract for which the action button is displayed. May be `null` if there is no contract yet.
     */
    contract: Contract | null;

    /**
     * Reason why the sign action is disabled (shown as a tooltip). If null, the action is enabled.
     */
    signActionDisabledReason: string | null;

    /**
     * Callback to be called when the sign action is clicked.
     */
    onSignClicked: () => void;

    /**
     * Test ID.
     */
    testId?: string;
};

/**
 * Button that allows to sign a contract or re-send a sign request, depending on the current state of the contract.
 *
 * It is either:
 * - A "Sign contract" button if {@link Props.contract} is `null` or in state `CANCELLED`. In this case, it is disabled
 *   if {@link Props.signActionDisabledReason} is given. This is needed because some contracts cannot be signed before
 *   some others have been signed.
 * - A "Send sign request" button if {@link Props.contract} is in state `PENDING`. In this case, it is disabled if it
 *   was just clicked (less than 10 seconds ago). This is to prevent sending multiple links by accident.
 * - Empty otherwise.
 */
export default function ActionButton({
    dispatch,
    contract,
    signActionDisabledReason,
    onSignClicked,
    testId,
}: Props): JSX.Element | null {
    const { t } = useTranslation();

    const [signRequestJustSent, setSignRequestJustSent] = useState(false);

    const onSendSignRequestClicked = () => {
        if (contract === null) {
            return;
        }

        dispatch(SendInviteeSignRequestAction({ contractId: contract.id }));

        // This will disable the button even if there is an error. But that
        // should be rare and even then the button is re-enabled in 10 seconds.
        setSignRequestJustSent(true);
        setTimeout(() => setSignRequestJustSent(false), 10000);
    };

    if (contract?.state === ContractState.ACTIVE) {
        // we already have a signed contract, no further actions
        return null;
    }

    if (contract?.state === ContractState.PENDING) {
        // contract exists but is not yet signed
        return (
            <Button onClick={onSendSignRequestClicked} disabled={signRequestJustSent} data-testid={testId}>
                {t("contracts.table.action.sendSignRequest")}
            </Button>
        );
    }

    if (contract === null || contract?.state === ContractState.CANCELLED) {
        // contract does not exist yet or was cancelled
        if (signActionDisabledReason !== null) {
            return (
                <Tooltip title={signActionDisabledReason}>
                    <div>
                        {/* wrapped in div so that Tooltip works */}
                        <Button disabled data-testid={testId}>
                            {t("contracts.table.action.sign")}
                        </Button>
                    </div>
                </Tooltip>
            );
        }
        return (
            <Button variant="contained" onClick={onSignClicked} data-testid={testId}>
                {t("contracts.table.action.sign")}
            </Button>
        );
    }

    // Fallback: this state is not supported, and no action can be taken.
    return null;
}
