import { Pagination } from "../../components/common/pagination";
import BaseState from "../../interfaces/BaseState";

export interface UsersState extends BaseState {
    createUserModalState: CreateUserModalState;
    users: User[];
    pagination: Pagination;
    sortBy: string;
    ascending: boolean;
}

export enum CreateUserModalState {
    Closed,
    OpenWithAdminCheckbox,
    OpenWithoutAdminCheckbox,
}

export type User = {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    admin: boolean;
};

export type FetchUserSuccessPayload = User[];

export type SortUsersPayload = {
    sortBy: string;
    ascending: boolean;
};

export type UpdateUserPayload = User;

export type DeleteUserPayload = number;

export type OpenCreateUserModalPayload =
    | CreateUserModalState.OpenWithAdminCheckbox
    | CreateUserModalState.OpenWithoutAdminCheckbox;

export type CreateNewUserPasswordPayload = number; // userId

export type UpdateUserEmailPayload = {
    userId: number;
    newEmail: string;
};

export type UpdateUserAdminPayload = {
    userId: number;
    admin: boolean;
};

export type CreateUserPayload = {
    admin: boolean;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
};

export type MassMarkPrincipalsWithCSVPayload = {
    file: string;
};
