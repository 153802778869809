import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LocalizationProvider from "./LocalizationProvider";
import { borders, textInputForDataPicker } from "../theme";

type Props = {
    value: Date | null;
    setValue: (date: Date | null) => void;
    dayNotIncluded?: boolean | undefined;
    disableManualTyping?: boolean;
    onChange?: () => void;
    minDate?: Date | null;
    maxDate?: Date | null;
};

export function DatePickerComponent({
    value,
    setValue,
    dayNotIncluded,
    disableManualTyping,
    onChange,
    minDate,
    maxDate,
}: Props): JSX.Element {
    const day: "day" = "day";
    const views: ("year" | "month" | "day")[] = ["year", "month", ...(!dayNotIncluded ? [day] : [])];
    const inputFormat = !dayNotIncluded ? "d.M.yyyy" : "M.yyyy";

    return (
        <LocalizationProvider>
            <div>
                <DatePicker
                    views={views}
                    value={value}
                    format={inputFormat}
                    onChange={(date) => {
                        setValue(date);
                        if (onChange) {
                            onChange();
                        }
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    slotProps={{
                        textField: {
                            variant: "outlined",
                            inputProps: {
                                readOnly: true,
                            },
                            onKeyDown: (e) => {
                                if (disableManualTyping === true) {
                                    e.preventDefault();
                                }
                            },
                            style: { ...borders.modalTextAreaBorder },
                            sx: {
                                input: {
                                    ...textInputForDataPicker,
                                },
                                fieldset: {
                                    border: "0",
                                },
                            },
                        },
                    }}
                />
            </div>
        </LocalizationProvider>
    );
}
