import { MenuItem, Select } from "@mui/material";
import { AlarmCustomization } from "../../../../redux/apartments/apartments-types";
import { AlarmType, Company } from "../../../../redux/companies/companies-types";

export type AlarmDropdownProps = {
    custom: AlarmCustomization;
    alarmType: AlarmType;
    company: Company;
    onChange: (customization: AlarmCustomization, companyId: number) => void;
};

export const parseBool = (str: string | null): boolean | null => {
    switch (str) {
        case "true":
            return true;
        case "false":
            return false;
    }

    return null;
};

type Option = {
    value: string;
    displayText: string;
};

interface DropDownProps {
    id: string;
    options: Option[];
    selectedValue: string;
    onChange: (event: any) => void;
    disabled?: boolean;
    width?: string;
}

export const AlarmDropDown = (props: DropDownProps) => {
    return (
        <Select
            disabled={props.disabled}
            labelId={props.id}
            id={props.id}
            value={props.selectedValue}
            label=""
            style={{ height: "20px", minWidth: props.width, width: "12em" }}
            onChange={props.onChange}
        >
            {props.options.map((v, i) => (
                <MenuItem key={i} value={v.value}>
                    {v.displayText}
                </MenuItem>
            ))}
        </Select>
    );
};
