import { IconDefinition, faGauge } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faCreativeCommonsZero } from "@fortawesome/free-brands-svg-icons";
import { alignments, colors, spacings } from "../../../../theme";
import { SMOKE_METER } from "../../../../redux/apartments/apartments-types";

function ZeroOrNoReadingsNotification({
    title,
    icon,
    color,
    alarmText,
}: {
    title: string;
    icon: IconDefinition;
    color: string | undefined;
    alarmText?: string;
}): JSX.Element {
    return (
        <Tooltip title={title}>
            <div
                style={{
                    height: spacings.narrow /* For tooltip position */,
                    ...alignments.evenHorizontal,
                }}
            >
                <FontAwesomeIcon icon={icon} color={color} size="lg" />
                {<>&nbsp; {alarmText}</>}
            </div>
        </Tooltip>
    );
}

export function ZeroReadingsNotification(): JSX.Element {
    const { t } = useTranslation();
    return (
        <ZeroOrNoReadingsNotification
            title={t("meters.tooltip.readingIsZeroTooltip")}
            icon={faCreativeCommonsZero}
            color={colors.warm06}
            alarmText={t("meters.tooltip.readingIsZeroTooltip")}
        />
    );
}

export function NoReadingsNotification({ meterType }: { meterType?: string }): JSX.Element {
    const { t } = useTranslation();
    const isSmokeMeterType = meterType === SMOKE_METER;

    return (
        <ZeroOrNoReadingsNotification
            title={isSmokeMeterType ? t("meters.tooltip.noInformation") : t("meters.tooltip.noReadingsYetTooltip")}
            icon={faGauge}
            color={colors.cold06}
            alarmText={isSmokeMeterType ? t("meters.tooltip.noInformation") : t("meters.tooltip.noReadingsYetTooltip")}
        />
    );
}
