import { AnyAction } from "redux";
import { stateWithLoadingFalse, stateWithLoadingTrue } from "../../utils/utils";
import {
    FetchClientCommandsAction,
    FetchClientCommandsFailureAction,
    FetchClientCommandsSuccessAction,
    OpenEditCenterCodeAction,
    SendClientCommandAction,
    SendClientCommandFailureAction,
    SendClientCommandSuccessAction,
    SortCommandsAction,
    UpdateCenterCodeAction,
    UpdateCenterCodeFailureAction,
    UpdateCenterCodeSuccessAction,
    UpdateClientTelephoneNumberAction,
    UpdateClientTelephoneNumberFailureAction,
    UpdateClientTelephoneNumberSuccessAction,
} from "./centers-actions";
import { CentersState } from "./centers-types";

const initialState: CentersState = {
    clientCommands: [],
    loading: 0,
    newCenterCode: "",
    editingMode: false,
    sortBy: "createdDate",
    ascending: true,
};

export default function centersReducer(state: CentersState = initialState, action: AnyAction): CentersState {
    //ACTION
    if (FetchClientCommandsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SendClientCommandAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (UpdateCenterCodeAction.match(action)) {
        return {
            ...stateWithLoadingTrue(state),
            editingMode: true,
        };
    }

    if (UpdateClientTelephoneNumberAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SortCommandsAction.match(action)) {
        return {
            ...state,
            sortBy: action.payload.sortBy,
            ascending: action.payload.ascending,
        };
    }

    //SUCCESS
    if (FetchClientCommandsSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            clientCommands: action.payload,
        };
    }

    if (SendClientCommandSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (OpenEditCenterCodeAction.match(action)) {
        return {
            ...state,
            editingMode: true,
        };
    }

    if (UpdateCenterCodeSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            newCenterCode: action.payload.newCode,
            editingMode: false,
        };
    }

    if (UpdateClientTelephoneNumberSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    //FAILURE
    if (FetchClientCommandsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (SendClientCommandFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpdateCenterCodeFailureAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            editingMode: true,
        };
    }

    if (UpdateClientTelephoneNumberFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
}
