import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../theme";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

/**
 * Props for {@link CompanyContractStatusIcon}.
 */
export type Props = {
    /**
     * Whether all company contracts are signed.
     */
    allContractsSigned: boolean;
};

/**
 * Icon (plus tooltip) which indicates whether all company contracts are signed.
 */
export default function CompanyContractStatusIcon({ allContractsSigned }: Props): JSX.Element {
    const { t } = useTranslation();

    if (allContractsSigned) {
        return (
            <Tooltip title={t("contracts.tooltips.allCompanyContractsSigned")}>
                <DoneIcon className="text-green" style={{ verticalAlign: "middle" }} />
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={t("contracts.tooltips.notAllCompanyContractsSigned")}>
                <FontAwesomeIcon color={colors.warm06} icon={faTriangleExclamation} />
            </Tooltip>
        );
    }
}
