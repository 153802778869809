import { AnyAction } from "redux";
import { RootState } from "../../interfaces/RootState";
import { stateWithLoadingFalse, stateWithLoadingTrue } from "../../utils/utils";
import {
    CloseCompanyBasicInfoModal,
    CompaniesPaginationItemsPerPageChangeAction,
    CompaniesPaginationPageChangeAction,
    CreateCompanyAction,
    CreateCompanyFailureAction,
    DeleteCompanyUserRoleAction,
    DeleteCompanyUserRoleFailureAction,
    DeleteCompanyUserRoleSuccessAction,
    DownloadIntegrationMeterIdsAction,
    DownloadIntegrationMeterIdsFailureAction,
    DownloadIntegrationMeterIdsSuccessAction,
    FetchAlarmTypesAction,
    FetchAlarmTypesSuccessAction,
    FetchCompaniesFailureAction,
    FetchCompaniesWithoutAlarmsAction,
    FetchCompanyApiKeyAction,
    FetchCompanyApiKeyFailureAction,
    FetchCompanyInvoiceInfoAction,
    FetchCompanyInvoiceInfoFailureAction,
    FetchCompanyInvoiceInfoSuccessAction,
    FetchCompanyWarningNamesFailureAction,
    FetchCompanyWarningNamesSuccessAction,
    ManageCompanyUsersAction,
    ManageCompanyUsersFailureAction,
    ManageCompanyUsersSuccessAction,
    MarkNewPrincipalManagerMessageAsAcknowledgedAction,
    MarkNewPrincipalManagerMessageAsAcknowledgedFailureAction,
    MarkNewPrincipalManagerMessageAsAcknowledgedSuccessAction,
    MergeApartmentsAction,
    MergeApartmentsFailureAction,
    MergeApartmentsSuccessAction,
    OpenCompanyBasicInfoModal,
    PremiumToggleAction,
    PremiumToggleFailureAction,
    PremiumToggleSuccessAction,
    RefreshCompanyApiKeyAction,
    RefreshCompanyApiKeyFailureAction,
    SaveCompanyBasicInfoAction,
    SaveCompanyBasicInfoFailureAction,
    SaveCompanyBasicInfoSuccessAction,
    SaveCompanyInvoiceInfoAction,
    SaveCompanyInvoiceInfoFailureAction,
    SaveCompanyInvoiceInfoSuccessAction,
    SortCompaniesAction,
    UpdateApiAccessSuccessAction,
    UpdateUserAsManagerAction,
    UpdateUserAsManagerFailureAction,
    UpdateUserAsManagerSuccessAction,
    UpdateUserRolesAction,
    UpdateUserRolesFailureAction,
    UpdateUserRolesSuccessAction,
} from "../companies/companies-actions";
import {
    CreateCompanySuccessAction,
    FetchCompaniesSuccessAction,
    FetchCompanyApiKeySuccessAction,
    RefreshCompanyApiKeySuccessAction,
} from "./companies-actions";
import { AlarmsLoadingState, CompaniesState } from "./companies-types";

const initialState: CompaniesState = {
    loading: 0,
    companies: [],
    apiKeys: {},
    companyBasicInfoModalOpen: false,
    pagination: {
        currentPage: 1,
        itemsPerPage: 10,
    },
    sortBy: "name",
    ascending: true,
    alarmTypes: [],
    invoiceInfo: undefined,
};

const actions = (state: CompaniesState, action: AnyAction) => {
    if (FetchCompaniesWithoutAlarmsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (FetchCompanyApiKeyAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (RefreshCompanyApiKeyAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (CreateCompanyAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SaveCompanyBasicInfoAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (UpdateUserRolesAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (UpdateUserAsManagerAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (DeleteCompanyUserRoleAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (PremiumToggleAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (FetchAlarmTypesAction.match(action)) {
        return stateWithLoadingTrue(state);
    }
    if (PremiumToggleAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (MergeApartmentsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SaveCompanyInvoiceInfoAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (FetchCompanyInvoiceInfoAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (ManageCompanyUsersAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (DownloadIntegrationMeterIdsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (MarkNewPrincipalManagerMessageAsAcknowledgedAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    return state;
};

const successActions = (state: CompaniesState, action: AnyAction) => {
    if (FetchCompaniesSuccessAction.match(action)) {
        action.payload.sort((a, b) => a.name.localeCompare(b.name));
        return {
            ...stateWithLoadingFalse(state),
            companies: action.payload,
            companyBasicInfoModalOpen: false,
            invoiceInfo: undefined,
        };
    }

    if (FetchCompanyWarningNamesSuccessAction.match(action)) {
        return {
            ...state,
            companies: state.companies.map((c) => {
                const company = action.payload.find((x) => x.companyId === c.id);

                if (company) {
                    c.alarmNames = company.alarmNames;
                    c.alarmLoadingState = AlarmsLoadingState.success;
                }

                return c;
            }),
        };
    }

    if (FetchCompanyApiKeySuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            apiKeys: {
                ...state.apiKeys,
                [action.payload.companyId]: action.payload.apiKey,
            },
        };
    }

    if (RefreshCompanyApiKeySuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            apiKeys: {
                ...state.apiKeys,
                [action.payload.companyId]: action.payload.newApiKey,
            },
        };
    }

    if (CreateCompanySuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            companyBasicInfoModalOpen: false,
        };
    }

    if (SaveCompanyBasicInfoSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpdateUserRolesSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpdateUserAsManagerSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteCompanyUserRoleSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (PremiumToggleSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (FetchAlarmTypesSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            alarmTypes: action.payload,
        };
    }

    if (PremiumToggleSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (MergeApartmentsSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (SaveCompanyInvoiceInfoSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (ManageCompanyUsersSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DownloadIntegrationMeterIdsSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (FetchCompanyInvoiceInfoSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            invoiceInfo: action.payload,
        };
    }

    if (MarkNewPrincipalManagerMessageAsAcknowledgedSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
};

const otherActions = (state: CompaniesState, action: AnyAction) => {
    if (OpenCompanyBasicInfoModal.match(action)) {
        return {
            ...state,
            companyBasicInfoModalOpen: true,
        };
    }

    if (CloseCompanyBasicInfoModal.match(action)) {
        return {
            ...state,
            companyBasicInfoModalOpen: false,
        };
    }

    if (CompaniesPaginationPageChangeAction.match(action)) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                currentPage: action.payload,
            },
        };
    }

    if (CompaniesPaginationItemsPerPageChangeAction.match(action)) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                itemsPerPage: action.payload,
            },
        };
    }

    if (SortCompaniesAction.match(action)) {
        return {
            ...state,
            sortBy: action.payload.sortBy,
            ascending: action.payload.ascending,
        };
    }

    if (UpdateApiAccessSuccessAction.match(action)) {
        return {
            ...state,
            companies: state.companies.map((c) => {
                if (c.id === action.payload.companyId) {
                    c.apiAccessEnabled = action.payload.apiAccessEnabled;
                }

                return c;
            }),
        };
    }
    return state;
};

const failureActions = (state: CompaniesState, action: AnyAction) => {
    if (FetchCompaniesFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (FetchCompanyWarningNamesFailureAction.match(action)) {
        return {
            ...state,
            companies: state.companies.map((c) => {
                if (action.payload.companyIds.includes(c.id)) {
                    c.alarmLoadingState = AlarmsLoadingState.failed;
                }

                return c;
            }),
        };
    }

    if (FetchCompanyApiKeyFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (RefreshCompanyApiKeyFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (CreateCompanyFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (SaveCompanyBasicInfoFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpdateUserRolesFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpdateUserAsManagerFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteCompanyUserRoleFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (PremiumToggleFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (MergeApartmentsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (SaveCompanyInvoiceInfoFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (FetchCompanyInvoiceInfoFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (ManageCompanyUsersFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DownloadIntegrationMeterIdsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (MarkNewPrincipalManagerMessageAsAcknowledgedFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
};

export default function companiesReducer(state: CompaniesState = initialState, action: AnyAction): CompaniesState {
    state = actions(state, action);
    state = successActions(state, action);
    state = failureActions(state, action);
    state = otherActions(state, action);

    return state;
}

export const selectCompanyApiKey = (companyId: number) => (state: RootState) =>
    state.hydrolink.companies.apiKeys[companyId];
