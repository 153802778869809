import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "../../components/Table";
import { RootState } from "../../interfaces/RootState";
import { Company } from "../../redux/companies/companies-types";
import { getCompanyManagerByUser } from "../../redux/users/users-reducer";
import { User } from "../../redux/users/users-types";
import UsersRow from "./UsersRow";
import Sorter from "../../components/Sorter";
import { SortUsersAction } from "../../redux/users/users-actions";
import { table } from "../../theme";

const getCompaniesForUser = (user: User, companies: Company[]): Company[] =>
    companies.filter((c: Company) => getCompanyManagerByUser(c, user) !== undefined);

function UsersTableHead(): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const currentSorting = useSelector((state: RootState) => state.hydrolink.users.sortBy);
    const currentAscending = useSelector((state: RootState) => state.hydrolink.users.ascending);

    const sortUsersFunction = (sortBy: string, ascending: boolean) => {
        dispatch(
            SortUsersAction({
                sortBy: sortBy,
                ascending: ascending,
            }),
        );
    };

    return (
        <TableHead>
            <TableRow>
                <Sorter
                    sortKey="userFullName"
                    sortAction={sortUsersFunction}
                    sortBy={currentSorting}
                    title={t("users.name")}
                    currentAscending={currentAscending}
                />
                <Sorter
                    sortKey="username"
                    sortAction={sortUsersFunction}
                    sortBy={currentSorting}
                    title={t("users.username")}
                    currentAscending={currentAscending}
                />
                <TableCell width={table.tableCellWidthLarge}>{t("users.companies")}</TableCell>
                <Sorter
                    sortKey="highestRole"
                    sortAction={sortUsersFunction}
                    sortBy={currentSorting}
                    title={t("users.highestRole")}
                    currentAscending={currentAscending}
                />
                <TableCell>{/* Expand user button */}</TableCell>
            </TableRow>
        </TableHead>
    );
}

function UsersTable({ users }: { users: User[] }): JSX.Element {
    const companies = useSelector((state: RootState) => state.hydrolink.companies.companies);

    return (
        <TableContainer component={Paper}>
            <Table>
                <UsersTableHead />
                <TableBody>
                    {users.map((u: User) => (
                        <UsersRow key={u.id} user={u} companies={getCompaniesForUser(u, companies)} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default UsersTable;
