import { ChangeEvent } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { AmountSelectText, StyledPagination, PaginationContainer, SelectContainer } from "./Pagination.style";
import { useTranslation } from "react-i18next";

type Props = {
    itemsCount: number;
    itemsPerPage: number;
    current: number;
    currentPageCallback: (currentPage: number) => void;
    itemsPerPageCallback: (itemsPerPage: number) => void;
};

export type Pagination = {
    currentPage: number;
    itemsPerPage: number;
};

export default function PaginationComponent({
    itemsCount,
    current,
    itemsPerPage,
    currentPageCallback,
    itemsPerPageCallback,
}: Props) {
    const t = useTranslation().t;

    const itemsPerPageOptions = [10, 25, 50, 100];
    const selectedItemsPerPage = itemsPerPage ?? itemsPerPageOptions[0];

    const changePage = (e: ChangeEvent<unknown>, pageNumber: number) => {
        currentPageCallback(pageNumber);
    };

    const changeItemsPerPage = (e: SelectChangeEvent<number>) => {
        currentPageCallback(1);
        itemsPerPageCallback(e.target.value as number);
    };

    return (
        <PaginationContainer>
            <StyledPagination
                siblingCount={2}
                page={current}
                count={Math.ceil(itemsCount / selectedItemsPerPage)}
                onChange={changePage}
            />
            <SelectContainer>
                <AmountSelectText>{t("pagination.showPerPage")}</AmountSelectText>
                <Select disableUnderline variant="standard" value={selectedItemsPerPage} onChange={changeItemsPerPage}>
                    {itemsPerPageOptions.map((v) => (
                        <MenuItem key={v} value={v}>
                            {v}
                        </MenuItem>
                    ))}
                </Select>
            </SelectContainer>
        </PaginationContainer>
    );
}
