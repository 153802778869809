import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { elementWithHeaderAndFooter } from "../../components/common/header/Header";
import { RootState } from "../../interfaces/RootState";
import { FetchApartmentsAction } from "../../redux/apartments/apartments-actions";
import { FetchCompaniesWithoutAlarmsAction } from "../../redux/companies/companies-actions";
import ResidentApartmentsList from "./ResidentApartmentsList";

export default function ResidentsManagement() {
    const dispatch = useDispatch();
    const params = useParams();
    const companyId = parseInt(params.companyId ?? "0");
    const company = useSelector(selectCompany(companyId));

    useEffect(() => {
        if (company === undefined) {
            dispatch(FetchCompaniesWithoutAlarmsAction());
        }
    }, [company, dispatch]);

    useEffect(() => {
        dispatch(FetchApartmentsAction({ companyId }));
    }, [companyId, dispatch]);

    if (company === undefined) {
        return null;
    }

    return elementWithHeaderAndFooter(<ResidentApartmentsList companyId={company.id} />);
}

const selectCompany = (companyId: number) => (state: RootState) =>
    state.hydrolink.companies.companies.find((c) => c.id === companyId);
