import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import { Button } from "./Button";
import { ModalInput } from "./common/modal/ModalInput";
import { useDispatch } from "react-redux";
import { SendCurrentUserPasswordResetAction, SendPasswordResetAction } from "../redux/auth/auth-actions";
import Centered from "./Centered";
import { spacings } from "../theme";

type Props = {
    resident: boolean;
    token?: string;
};

type Passwords = {
    password: string;
    passwordConfirmation: string;
};

export default function PasswordResetView({ token, resident }: Props): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const [passwords, setPasswords] = React.useState<Passwords>({
        password: "",
        passwordConfirmation: "",
    });
    const [hidePassword, setHidePassword] = React.useState<boolean>(true);
    const navigate = useNavigate();
    const path = "/companies";

    const saveButtonStyle = resident ? { marginTop: "3em" } : { marginLeft: spacings.largeMargin };

    const canSave = (): boolean => {
        return passwordHasMinimumLenght() && passwords.password === passwords.passwordConfirmation;
    };

    const submit = () => {
        if (resident) {
            dispatch(
                SendPasswordResetAction({
                    password: passwords.password,
                    token: token!!,
                }),
            );
        } else {
            dispatch(SendCurrentUserPasswordResetAction({ password: passwords.password }));
            navigate(path, { replace: true });
        }
    };

    const passwordHasMinimumLenght = (): boolean => passwords.password.length > 4;

    const passwordsDoMatch = (): boolean =>
        passwords.password.substring(0, passwords.passwordConfirmation.length) === passwords.passwordConfirmation;

    const passwordsDoNotMatchAlertTriggered = (): boolean =>
        passwords.passwordConfirmation.length > 0 && !passwordsDoMatch();

    const createPasswordInput = (field: keyof Passwords) => {
        return (
            <ModalInput
                title={t(`passwordReset.${field}`)}
                type={"password"}
                style={{
                    width: "20em",
                    maxWidth: "50%",
                }}
                value={passwords[field]}
                hidePassword={hidePassword}
                setHidePassword={setHidePassword}
                handleFormValue={(e) => setPasswords({ ...passwords, [field]: e.target.value })}
            />
        );
    };

    return (
        <Centered style={{ marginTop: "8em" }}>
            {createPasswordInput("password")}
            {createPasswordInput("passwordConfirmation")}
            <div style={!resident ? { display: "flex", flexDirection: "row", marginTop: "3em" } : {}}>
                {!resident && (
                    <Button onClick={() => navigate(path, { replace: true })}>{t("passwordReset.cancel")}</Button>
                )}
                <Button disabled={!canSave()} onClick={submit} style={saveButtonStyle}>
                    {t("passwordReset.save")}
                </Button>
            </div>
            <br />
            <br />
            {passwordsDoNotMatchAlertTriggered() && <Alert severity="warning">{t("passwordReset.noMatch")}</Alert>}
        </Centered>
    );
}
