import { useTranslation } from "react-i18next";
import { Interval } from "../../../../redux/apartments/apartments-types";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import { AlarmDropDown, AlarmDropdownProps } from "./AlarmDropdown";

const intervalTranslationKey = (interval: Interval | null) => {
    switch (interval) {
        case Interval.Daily:
            return "alarms.interval.daily";
        case Interval.Monthly:
            return "alarms.interval.monthly";
        case Interval.Never:
            return "alarms.interval.never";
        case Interval.New:
            return "alarms.interval.new";
        case Interval.Weekly:
            return "alarms.interval.weekly";
    }
    return "alarms.interval.unknown";
};

const intervalToString = (interval: Interval): string => {
    switch (interval) {
        case Interval.Daily:
            return "DAILY";
        case Interval.Monthly:
            return "MONTHLY";
        case Interval.Never:
            return "NEVER";
        case Interval.New:
            return "NEW";
        case Interval.Weekly:
            return "WEEKLY";
    }
};

const toInterval = (intervalString: string | null): Interval | null => {
    switch (intervalString) {
        case "DAILY":
            return Interval.Daily;
        case "MONTHLY":
            return Interval.Monthly;
        case "NEVER":
            return Interval.Never;
        case "NEW":
            return Interval.New;
        case "WEEKLY":
            return Interval.Weekly;
    }
    return null;
};

const IntervalDropdown = ({ custom, alarmType, company, onChange }: AlarmDropdownProps) => {
    const t = useTranslation().t;

    const saveIntervalCustomization = (e: any) => {
        let value: string = e.target.value;

        let toSave = {
            alarmType: alarmType.alarmName,
            highPriority: custom.highPriority,
            notifyOnNew: custom.notifyOnNew,
            alarmInterval: value,
            companyId: company.id,
        };

        onChange(toSave, company.id);
    };

    const canSetPremiumAlarms = useHasPrivileges("can set premium alarm settings", company.id).hasPrivilege;

    const alarmDropDownDisabled = !canSetPremiumAlarms && alarmType.requiresPremium;

    return (
        <AlarmDropDown
            id={`interval-dropdown-${alarmType.alarmName}`}
            disabled={alarmDropDownDisabled}
            selectedValue={custom.alarmInterval !== null ? custom.alarmInterval : "null"}
            onChange={(e) => saveIntervalCustomization(e)}
            options={[
                {
                    value: "null",
                    displayText: `${t("generic.default")} (${t(
                        intervalTranslationKey(toInterval(alarmType.defaultInterval)),
                    )})`,
                },
                {
                    value: intervalToString(Interval.Daily),
                    displayText: t(intervalTranslationKey(Interval.Daily)),
                },
                {
                    value: intervalToString(Interval.Weekly),
                    displayText: t(intervalTranslationKey(Interval.Weekly)),
                },
                {
                    value: intervalToString(Interval.Monthly),
                    displayText: t(intervalTranslationKey(Interval.Monthly)),
                },
                {
                    value: intervalToString(Interval.Never),
                    displayText: t(intervalTranslationKey(Interval.Never)),
                },
                {
                    value: intervalToString(Interval.New),
                    displayText: t(intervalTranslationKey(Interval.New)),
                },
            ]}
        />
    );
};

export default IntervalDropdown;
