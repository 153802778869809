import React from "react";
import { Dialog } from "@mui/material";
import { StyledDialogActions, StyledTitle } from "./Modal.style";
import CloseIcon from "@mui/icons-material/Close";
import { alignments, spacings } from "../../../theme";

export function Modal({
    open,
    title,
    children,
    dialogActions,
    closeModal,
}: {
    open: boolean;
    title: string;
    children: React.ReactNode;
    dialogActions: JSX.Element;
    closeModal: () => void;
}) {
    const onCloseHandler = (_: React.SyntheticEvent, reason: string) => {
        // Called when the user clicks outside of modal
        if (reason === "backdropClick") {
            closeModal();
        }
    };

    return (
        <Dialog open={open} maxWidth="md" fullWidth onClose={onCloseHandler}>
            <div
                style={{
                    ...alignments.evenHorizontal,
                    padding: `${spacings.narrow} ${spacings.standardSpacing} 0 ${spacings.standardSpacing}`,
                }}
            >
                <StyledTitle>{title}</StyledTitle>
                <div style={{ cursor: "pointer" }}>
                    <CloseIcon data-testid="closeModalIcon" onClick={closeModal} />
                </div>
            </div>
            <div
                style={{
                    padding: `0 ${spacings.standardSpacing} 0 ${spacings.standardSpacing}`,
                    whiteSpace: "pre-line",
                }}
            >
                {children}
            </div>
            <StyledDialogActions>{dialogActions}</StyledDialogActions>
        </Dialog>
    );
}
