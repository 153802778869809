import { TableRow, TableCell } from "./Table";
import { InputLabel } from "@mui/material";

export function TableRowWithLabel({
    label,
    htmlFor,
    labelElement,
    children,
}: {
    htmlFor: string;
    label: string;
    labelElement?: React.ReactNode;
    children: React.ReactNode;
}) {
    return (
        <TableRow>
            <TableCell>
                <InputLabel htmlFor={htmlFor}>
                    {label} {labelElement}
                </InputLabel>
            </TableCell>
            <TableCell align="left">{children}</TableCell>
        </TableRow>
    );
}
