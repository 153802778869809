import ReactFlagsSelect from "react-flags-select";
import "../../../assets/App.css";
import "../../../assets/Mobile.css";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { storeLanguage } from "../../../utils/languageStore";

type Language = "sv" | "fi" | "en";

const LanguageSelector = ({ buttonClassName }: { buttonClassName: string }) => {
    const language = useTranslation().i18n.language;

    const getLanguage = (country: string): Language => {
        let la: Language = "fi";
        switch (country) {
            case "FI":
                la = "fi";
                break;
            case "SE":
                la = "sv";
                break;
            case "GB":
                la = "en";
                break;
        }
        storeLanguage(la);
        return la;
    };

    const setLanguage = (country: string) => {
        i18next.changeLanguage(getLanguage(country));
    };

    return (
        <ReactFlagsSelect
            countries={["FI", "SE", "GB"]}
            placeholder={language}
            onSelect={(country) => setLanguage(country)}
            selected={language}
            showOptionLabel={false}
            showSelectedLabel={false}
            className={"language-selector"}
            selectButtonClassName={buttonClassName}
        />
    );
};

export function asValidLanguage(language: string): Language {
    switch (language) {
        case "fi":
            return "fi";

        case "sv":
            return "sv";

        case "en":
            return "en";

        default:
            return "fi";
    }
}

export default LanguageSelector;
