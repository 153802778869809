import { Effect, put as putEffect } from "redux-saga/effects";
import { ForceLogoutAction } from "../redux/auth/auth-actions";

export async function apiError(response: Response) {
    if (response.status === 400 || response.status === 404) {
        return await response.text().then((text: string) => {
            let error = "";
            if (text === "Email already exists" || text === "Already accepted") {
                //In these cases this error message is displayed
                error = "errors." + text; //to the user without a title
            } else {
                let statusText = response.status === 400 ? "BAD REQUEST" : "NOT FOUND";
                error = statusText + "\n" + text; //Otherwise includes the title
            }
            throw new Error(error);
        });
    }
    throw response;
}

/**
 * Fetch error based on API response or API response based exception.
 * Note: the function has a side effect: it manages dispatching 'force logout' action when backend returns
 * 'Unauthorized' response (e.g. due to expired access token).
 * @param error produced API error.
 * @param onErr callback to handle error with input parameter for error translation key (when error case detected) and
 * http status code (included when available).
 */
export function* fetchError<TError>(
    error: any,
    onErr: (errKey: string, code: number | undefined) => Effect<string, TError>,
) {
    let statusCode: number = error.status;
    if (statusCode === 401) {
        yield putEffect(ForceLogoutAction());
    }

    console.debug("API Error: ", error);
    let errorTranslationKey: string;
    // Note: do not use 'statusText' field as it is not supported by HTTP/2
    // Ref: https://developer.mozilla.org/en-US/docs/Web/API/Response/statusText
    // Note 2: For known status code, mapping the message into translation keys so that the message is localized
    // (if visible in UI)
    if (error.message) {
        errorTranslationKey = error.message;
    } else if (statusCode === 401) {
        errorTranslationKey = "errors.Unauthorized";
    } else if (statusCode === 403) {
        // Forbidden (use message matching suitable translation key)
        errorTranslationKey = "errors.insufficientPermissions";
    } else if (statusCode === 404) {
        errorTranslationKey = "errors.notFound";
    } else {
        errorTranslationKey = "errors.unknownError";
        if (statusCode === 500) {
            console.error("Server responded with 'internal server error':", error);
        } else if (typeof error === "object" && Object.keys(error).length) {
            console.error("Unknown error: ", JSON.stringify(error));
        } else {
            console.error("Unknown error: ", error);
        }
    }
    yield onErr(errorTranslationKey, statusCode);
}
