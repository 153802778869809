import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { styled } from "@mui/material/styles";
import React from "react";

const StyledIconButton = styled((props: IconButtonProps) => (
    <IconButton sx={props.sx} disabled={props.disabled} onClick={props.onClick} children={props.children} />
))(() => ({
    root: {},
}));

interface Props {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    style?: React.CSSProperties;
    disabled?: boolean;
    icon: IconDefinition;
    tooltip?: string;
    disabledTooltip?: string;
    dataTestId?: string;
}

const FontAwesomeIconButton = (props: Props) => {
    const cursor = {
        cursor: props.disabled ? "none" : "pointer",
    };

    const getTooltipTitle: string = (props.disabled ? props.disabledTooltip : props.tooltip) ?? " ";

    const disableTooltip = (props.disabled && !props.disabledTooltip) || !props.tooltip;

    return (
        <Tooltip disableHoverListener={disableTooltip} title={getTooltipTitle}>
            <div data-testid={props.dataTestId}>
                <StyledIconButton
                    sx={{ ...cursor, ...(props.style ?? {}) }}
                    disabled={props.disabled}
                    onClick={props.onClick}
                >
                    <FontAwesomeIcon icon={props.icon} size="sm" />
                </StyledIconButton>
            </div>
        </Tooltip>
    );
};

export default FontAwesomeIconButton;
