import React from "react";
import { TableRow, TableCell } from "@mui/material";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import FontAwesomeIconButton from "../../../../components/FontAwesomeIconButton";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";
import { Link } from "react-router-dom";

interface Props {
    firstName: string;
    lastName: string;
    id: number;
    delete: (managerId: number) => void;
    color: string;
    companyId: number;
    children: React.ReactNode;
    email: string;
}

const ManagerRow = (props: Props) => {
    const { t } = useTranslation();
    const canRemoveManager = useHasPrivileges("can remove manager", props.companyId).hasPrivilege;
    const canSeeAllUsers = useHasPrivileges("can see all users", props.companyId).hasPrivilege;

    const CellContents = ({ text }: { text: String }) => {
        if (canSeeAllUsers) {
            return <Link to={{ pathname: "/users", search: `?id=${props.id}` }}>{text}</Link>;
        } else {
            return <>{text}</>;
        }
    };

    return (
        <TableRow style={{ backgroundColor: props.color }}>
            <TableCell>
                <CellContents text={props.firstName} />
            </TableCell>
            <TableCell>
                <CellContents text={props.lastName} />
            </TableCell>
            <TableCell>{props.email}</TableCell>
            <TableCell>{props.children}</TableCell>
            <TableCell>
                {canRemoveManager && (
                    <FontAwesomeIconButton
                        dataTestId="delete-manager-icon"
                        tooltip={t("apartments.removeManagerTooltip")}
                        onClick={() => props.delete(props.id)}
                        icon={faTrash}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};

export default ManagerRow;
