import styled from "styled-components";
import { TableHead, TableCell, IconButton, Table, TableBody, TableRow } from "@mui/material";
import { colors } from "../../theme";

export const CompaniesViewContainer = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
`;

export const CompaniesHeader = styled.p`
    font-weight: 600;
    font-size: 18px;
    font-family: Arial;
    color: ${colors.neutral08};
`;

export const StyledTableHead = styled(TableHead)`
    margin-top: 1rem;
`;

export const StyledTableRow = styled(TableRow)`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
`;

export const StyledTableHeadCell = styled(TableCell)`
    font-family: Arial;
    display: flex;
    align-items: center;
`;

export const StyledTableTitle = styled.p`
    padding-right: 5px;
    color: ${colors.neutral06};
    font-size: 10pt;
    font-weight: 600;
`;

export const StyledTable = styled(Table)`
    justify-self: center;
`;

export const StyledTableBody = styled(TableBody)`
    border: 1px solid ${colors.neutral04};
    display: grid;
`;

export const StyledSortButtonsContainer = styled.div`
    display: grid;
`;
export const StyledIconButton = styled(IconButton)`
    padding: 0;
    color: ${colors.neutral06};
    &:hover {
        cursor: pointer;
    }
`;

export const StyledTableCell = styled(TableCell)`
    font-size: 10pt;
    font-family: Arial;
    display: flex;
    justify-content: left;
    align-items: center;
    color: ${colors.neutral08};
`;
