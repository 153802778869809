import { createBrowserHistory } from "history";
import { Middleware, applyMiddleware, compose, createStore } from "redux";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from "redux-thunk";
import developmentTrue from "./constants/developmentTrue";
import rootSaga from "./redux/root-saga";
import createRootReducer from "./redux/rootReducer";

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const middlewares: Middleware[] = [thunkMiddleware, sagaMiddleware];

if (developmentTrue) {
    middlewares.push(logger);
}

const configureStore = () => {
    const composeEnhancer = compose;
    const store = createStore(createRootReducer(history), composeEnhancer(applyMiddleware(...middlewares)));
    sagaMiddleware.run(rootSaga);
    return store;
};

const store = configureStore();

export default function getStore() {
    return store;
}

export type HydrolinkStore = typeof store;

export type HydrolinkDispatch = typeof store.dispatch;
