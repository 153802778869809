import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../../../components/Button";
import { DownloadIntegrationMeterIdsAction } from "../../../../redux/companies/companies-actions";

type Props = {
    companyId: number;
};

export default function DownloadIntegrationMeterIds({ companyId }: Props) {
    const dispatch = useDispatch();
    const t = useTranslation().t;

    const downloadIntegrationMeterIds = () => {
        dispatch(DownloadIntegrationMeterIdsAction(companyId));
    };

    return (
        <Button style={{ float: "right" }} onClick={downloadIntegrationMeterIds}>
            {t("companies.downloadIntegrationMeterIds")}
        </Button>
    );
}
