let _storage: Record<string, string | null> = {};

/**
 * Storage for environment where web browser's local storage is not available.
 * (assumed this is made for testing purposes only).
 * Note: The object's API reflects 'localStorage' API.
 */
const inMemoryStorage = {
    setItem: (key: string, value: string): void => {
        _storage[key] = value;
    },
    getItem: (key: string): string | null => _storage[key],
    removeItem: (key: string): void => {
        _storage[key] = null;
    },
};

/* Internal Storage Identifiers */
const ID_TOKEN = "id_token";
const LAST_LOGIN_ERROR_KEY = "last login error message";

/* Store objects (interface for accessing persistent objects) */

/**
 * Authentication token store in local storage.
 */
const authTokenStore = {
    /**
     * Persist authentication token.
     * @param value authentication token value.
     */
    set: (value: string): void =>
        window.localStorage ? localStorage.setItem(ID_TOKEN, value) : inMemoryStorage.setItem(ID_TOKEN, value),
    /**
     * Get persisted authentication token (if available).
     */
    get: (): string | null =>
        window.localStorage ? localStorage.getItem(ID_TOKEN) : inMemoryStorage.getItem(ID_TOKEN),

    /**
     * Clear persisted authentication token.
     */
    clear: (): void => (window.localStorage ? localStorage.removeItem(ID_TOKEN) : inMemoryStorage.removeItem(ID_TOKEN)),
};

/**
 * Previous login error store in local storage.
 */
const previousLoginErrorStore = {
    /**
     * Get translation key for previous login error (if available).
     */
    getMessageKey: (): string | null =>
        window.localStorage
            ? localStorage.getItem(LAST_LOGIN_ERROR_KEY)
            : inMemoryStorage.getItem(LAST_LOGIN_ERROR_KEY),
    /**
     * Set translation key for previous login error (replacing any existing values).
     * @param translationKey
     */
    setMessageKey: (translationKey: string) =>
        window.localStorage
            ? localStorage.setItem(LAST_LOGIN_ERROR_KEY, translationKey)
            : inMemoryStorage.setItem(LAST_LOGIN_ERROR_KEY, translationKey),
    /**
     * Clear previous login error in local storage.
     */
    clear: () =>
        window.localStorage
            ? localStorage.removeItem(LAST_LOGIN_ERROR_KEY)
            : inMemoryStorage.removeItem(LAST_LOGIN_ERROR_KEY),
};

/* Exports */

/**
 * Persistent storage access object (backed by local storage).
 */
export const persistentStorage = {
    /**
     * Authentication token store in local storage.
     */
    authToken: authTokenStore,
    /**
     * Previous login error store in local storage.
     */
    previousLoginError: previousLoginErrorStore,
};
