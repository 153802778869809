import { useMemo } from "react";
import { Company } from "../../../redux/companies/companies-types";
import { Contract, ContractState, UserContracts } from "../../../redux/contracts/contracts-types";
import { getCurrentContract } from "../utils";

// Imported so it can be linked to in the JSDoc. @todo There should be a way to do this without importing
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import UserContractsView from "../UserContractsView";

/**
 * Data for {@link UserContractsView}.
 */
export type UserContractsViewData = {
    /**
     * ID of the user whose contracts are being viewed.
     */
    userId: number;

    /**
     * The user's personal DPA contract if any.
     */
    personalDpa: Contract | null;

    /**
     * Companies the user is the principal manager of, together with their contracts.
     */
    principallyManagedCompaniesWithContracts: CompanyWithContracts[];

    /**
     * Whether any of the companies the user is the principal manager of has an active subscription.
     */
    somePrincipallyManagedCompanyHasActiveSubscription: boolean;
};

/**
 * Company together with its contracts.
 */
export type CompanyWithContracts = {
    /**
     * The company.
     */
    company: Company;

    /**
     * The company's subscription contract if any.
     */
    subscription: Contract | null;

    /**
     * The company's DPA contract if any.
     */
    dpa: Contract | null;

    /**
     * Whether all contracts for the company are active.
     */
    allContractsActive: boolean;
};

/**
 * Hook for computing data for {@link UserContractsView}.
 *
 * @param userId ID of the user for whom to calculate the view data.
 * @param companies All companies in the system.
 * @param userContractsByUserId All user contracts in the system.
 * @returns Data for {@link UserContractsView}.
 */
export function useUserContractsViewData(
    userId: number,
    companies: Company[],
    userContractsByUserId: Record<number, UserContracts>,
): UserContractsViewData {
    const principallyManagedCompanies = useMemo(
        () =>
            companies.filter((company) =>
                company.managers.some(
                    (manager) => manager.userId === userId && manager.role === "manager" && manager.principal,
                ),
            ),
        [companies, userId],
    );

    const userContracts = useMemo(
        () =>
            userContractsByUserId[userId] ?? {
                principalManagerDpaContracts: [],
                companySubscriptionContractsByCompanyId: {},
                companyDpaContractsByCompanyId: {},
            },
        [userContractsByUserId, userId],
    );

    const personalDpa = useMemo(
        () => getCurrentContract(userContracts.principalManagerDpaContracts),
        [userContracts.principalManagerDpaContracts],
    );

    const principallyManagedCompaniesWithContracts = useMemo(
        () =>
            principallyManagedCompanies.map((company) => {
                const subscription = getCurrentContract(
                    userContracts.companySubscriptionContractsByCompanyId[company.id] ?? [],
                );
                const dpa = getCurrentContract(userContracts.companyDpaContractsByCompanyId[company.id] ?? []);
                const allContractsActive =
                    subscription?.state === ContractState.ACTIVE && dpa?.state === ContractState.ACTIVE;

                return { company, subscription, dpa, allContractsActive };
            }),
        [
            principallyManagedCompanies,
            userContracts.companySubscriptionContractsByCompanyId,
            userContracts.companyDpaContractsByCompanyId,
        ],
    );

    const somePrincipallyManagedCompanyHasActiveSubscription = useMemo(
        () =>
            principallyManagedCompaniesWithContracts.some(
                (contracts) => contracts.subscription?.state === ContractState.ACTIVE,
            ),
        [principallyManagedCompaniesWithContracts],
    );

    return {
        userId,
        personalDpa,
        principallyManagedCompaniesWithContracts,
        somePrincipallyManagedCompanyHasActiveSubscription,
    };
}
