import { elementWithHeaderAndFooter } from "../../components/common/header/Header";
import UserContractsView from "./UserContractsView";
import { useAppDispatch, useAppSelector } from "../../utils/store-helpers";
import { ViewContainer } from "../../components/ViewContainer";
import ContractInfoBox from "./ContractInfoBox";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { FetchCompaniesWithoutAlarmsAction } from "../../redux/companies/companies-actions";
import { FetchUserContractsAction } from "../../redux/contracts/contracts-actions";
import { usePeriodicCallback } from "../../utils/usePeriodicCallback";
import { useUserContractsViewData } from "./hooks/useUserContractsViewData";

/**
 * Interval in milliseconds between contract refreshes.
 */
const REFRESH_INTERVAL_MS = 60 * 1000; // one minute

/**
 * Page that displays a {@link UserContractsView} for the current user. Contract signatures are refreshed automatically
 * every minute.
 */
export function CurrentUserContractsPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const userId = useAppSelector((state) => state.hydrolink.auth.id);
    const companies = useAppSelector((state) => state.hydrolink.companies.companies);
    const userContractsByUserId = useAppSelector((state) => state.hydrolink.contracts.userContractsByUserId);

    const data = useUserContractsViewData(userId, companies, userContractsByUserId);

    useEffect(() => {
        dispatch(FetchCompaniesWithoutAlarmsAction());
    }, [dispatch]);

    useEffect(() => {
        if (userId !== 0) {
            dispatch(FetchUserContractsAction({ userId }));
        }
    }, [dispatch, userId]);

    usePeriodicCallback(
        () => userId !== 0 && dispatch(FetchUserContractsAction({ userId, background: true })),
        REFRESH_INTERVAL_MS,
    );

    return elementWithHeaderAndFooter(
        <ViewContainer>
            <ContractInfoBox />
            <h2>{t("contracts.pageTitle")}</h2>
            <UserContractsView dispatch={dispatch} data={data} />
        </ViewContainer>,
    );
}
