import React, { useCallback } from "react";
import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableHeadCell, TableContainer, TableHead, TableRow } from "../../components/Table";
import { RootState } from "../../interfaces/RootState";
import { useDispatch, useSelector } from "react-redux";
import ResidentRow from "./ResidentRow";
import { Apartment, ApartmentOpenById } from "../../redux/apartments/apartments-types";
import { SortResidentsAction } from "../../redux/residents/residents-actions";
import { table } from "../../theme";
import Sorter from "../../components/Sorter";
import ExpandIcons from "../../components/ExpandIcons";

function ResidentApartmentTableHead({ children }: { children: JSX.Element }): JSX.Element {
    const dispatch = useDispatch();
    const t = useTranslation().t;

    const currentSorting = useSelector((state: RootState) => state.hydrolink.residents.sortBy);
    const currentAscending = useSelector((state: RootState) => state.hydrolink.residents.ascending);

    const sortResidentApartment = (sortBy: string, ascending: boolean) => {
        dispatch(
            SortResidentsAction({
                sortBy: sortBy,
                ascending: ascending,
            }),
        );
    };

    return (
        <TableHead>
            <TableRow>
                <Sorter
                    sortKey={"code"}
                    sortAction={sortResidentApartment}
                    sortBy={currentSorting}
                    title={t("residents.apartment")}
                    width={table.tableCellWidthExtraSmall}
                    currentAscending={currentAscending}
                />
                <Sorter
                    sortKey={"residentEmails"}
                    sortAction={sortResidentApartment}
                    sortBy={currentSorting}
                    title={t("residents.emails")}
                    width={table.tableCellWidthExtraLarge}
                    currentAscending={currentAscending}
                />
                {children}
            </TableRow>
        </TableHead>
    );
}

const ResidentApartmentsTable = ({ companyId, apartments }: { companyId: number; apartments: Apartment[] }) => {
    const t = useTranslation().t;

    const residents = useSelector((state: RootState) => state.hydrolink.residents.residents);
    const authorized = useSelector((state: RootState) => state.hydrolink.residents.authorized);

    const [allApartmentsOpen, setAllApartmentsOpen] = React.useState<boolean>(false);

    const [apartmentOpenById, setApartmentOpenById] = React.useState<ApartmentOpenById>({});

    const createNewApartmentOpenById = useCallback(
        (allOpen: boolean) => {
            let newApartmentOpenById: ApartmentOpenById = {};
            apartments.forEach((a) => {
                newApartmentOpenById[a.id] = allOpen;
            });
            return newApartmentOpenById;
        },
        [apartments],
    );

    const onToggleOpen = (id: number) => {
        setApartmentOpenById({
            ...apartmentOpenById,
            [id]: !apartmentOpenById[id],
        });
    };

    const toggleAllApartments = () => {
        setAllApartmentsOpen(!allApartmentsOpen);
        setApartmentOpenById(createNewApartmentOpenById(!allApartmentsOpen));
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <ResidentApartmentTableHead>
                    <>
                        <TableHeadCell onClick={() => toggleAllApartments()} style={{ cursor: "pointer" }}>
                            <span style={{ float: "right" }}>
                                {!allApartmentsOpen
                                    ? t("apartments.openAllApartments")
                                    : t("apartments.closeAllApartments")}
                            </span>
                        </TableHeadCell>
                        <TableHeadCell onClick={() => toggleAllApartments()} style={{ cursor: "pointer" }}>
                            <ExpandIcons closed={!allApartmentsOpen} />
                        </TableHeadCell>
                    </>
                </ResidentApartmentTableHead>
                <TableBody>
                    {apartments.map((a) => (
                        <ResidentRow
                            key={a.id}
                            onToggleOpen={onToggleOpen}
                            isOpen={apartmentOpenById[a.id]}
                            companyId={companyId}
                            apartment={a}
                            resident={authorized ? residents.find((r) => r.apartmentId === a.id) : null}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ResidentApartmentsTable;
