import { AnyAction } from "redux";
import { hasUsageData, stateWithLoadingFalse, stateWithLoadingTrue } from "../../utils/utils";
import {
    ChangeMeterAction,
    ChangeMeterFailureAction,
    ChangeMeterSuccessAction,
    ClearApartmentsAction,
    CloseChartAction,
    DeleteApartmentAction,
    DeleteApartmentSuccessAction,
    DeleteApartmentFailureAction,
    DeleteMeterAction,
    DeleteMeterSuccessAction,
    DeleteMeterFailureAction,
    FetchAlarmSettingsAction,
    FetchAlarmSettingsFailureAction,
    FetchAlarmSettingsSuccessAction,
    FetchApartmentsAction,
    FetchApartmentsFailureAction,
    FetchApartmentsSuccessAction,
    FetchGraphsFailureAction,
    FetchGraphsSuccessAction,
    FetchPremiumSettingsAction,
    FetchPremiumSettingsFailureAction,
    FetchPremiumSettingsSuccessAction,
    FetchReportFormatsAction,
    FetchReportFormatsFailureAction,
    FetchReportFormatsSuccessAction,
    FetchReportSettingsAction,
    FetchReportSettingsFailureAction,
    FetchReportSettingsSuccessAction,
    ToggleAllApartmentsAction,
    ToggleApartmentAction,
    OpenChartAction,
    SaveAlarmSettingsAction,
    SaveAlarmSettingsFailureAction,
    SaveAlarmSettingsSuccessAction,
    SaveReportSettingsAction,
    SaveReportSettingsFailureAction,
    SaveReportSettingsSuccessAction,
    UpdateApartmentNameAction,
    UpdateApartmentNameFailureAction,
    UpdateApartmentNameSuccessAction,
    UploadCSVAction,
    UploadCSVFailureAction,
    UploadCSVSuccessAction,
    SendTestReportSuccessAction,
    SendTestReportFailureAction,
    SendTestReportAction,
    DownloadMeterUsageReportAction,
    DownloadMeterUsageReportSuccessAction,
    DownloadMeterUsageReportFailureAction,
    ToggleAlarmByMeterIdAction,
    ToggleAlarmByMeterIdSuccessAction,
    ToggleAlarmByMeterIdFailureAction,
    DeleteAllApartmentsAction,
    DeleteAllApartmentsSuccessAction,
    DeleteAllApartmentsFailureAction,
    ToggleAllApartmentsLoadingAction,
    SendSetDataLoggerDownlinkAction,
    SendSetDataLoggerDownlinkSuccessAction,
    SendSetDataLoggerDownlinkFailureAction,
} from "./apartments-actions";
import { Apartment, ApartmentOpenById, ApartmentsState, MeterHistoryReading } from "./apartments-types";

const initialState: ApartmentsState = {
    loading: 0,
    apartments: [],
    isReportingOpen: false,
    usageByMeterId: {},
    chartState: {
        apartmentId: null,
        isChartOpen: false,
        type: "day",
        offsetDays: 0,
        meterType: null,
    },
    premiumSettings: {
        phoneNumbers: "",
        warmWaterPrice: "",
        coldWaterPrice: "",
        energyPrice: "",
        billingStart: "",
        billingPeriodMonths: null,
    },
    alarmSettings: {
        stuckEmail: "",
        alarmLang: "",
        alarmCustomizations: [],
    },
    reportSettings: {
        reportingId: "",
        sendReports: false,
        reportEmail: "",
        reportFormat: "",
        reportIntervalMonths: 0,
        reportLang: "",
    },
    reportFormats: [],
    apartmentOpenById: {},
    allApartmentsOpen: false,
};

const actions = (state: ApartmentsState, action: AnyAction): ApartmentsState => {
    if (FetchApartmentsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (FetchReportFormatsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SaveAlarmSettingsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SaveReportSettingsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SendTestReportAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (ChangeMeterAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (UpdateApartmentNameAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (UploadCSVAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (DeleteMeterAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (DeleteApartmentAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (DownloadMeterUsageReportAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (DeleteAllApartmentsAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (FetchPremiumSettingsAction.match(action)) {
        return {
            ...stateWithLoadingTrue(state),
            premiumSettings: initialState.premiumSettings,
        };
    }

    if (FetchReportSettingsAction.match(action)) {
        return {
            ...stateWithLoadingTrue(state),
            reportSettings: initialState.reportSettings,
        };
    }

    if (FetchAlarmSettingsAction.match(action)) {
        return {
            ...stateWithLoadingTrue(state),
            alarmSettings: initialState.alarmSettings,
        };
    }

    if (OpenChartAction.match(action)) {
        return {
            ...stateWithLoadingTrue(state),
            chartState: {
                apartmentId: action.payload.apartmentId,
                isChartOpen: true,
                type: action.payload.type,
                offsetDays: action.payload.offset,
                meterType: action.payload.meterType,
            },
        };
    }

    if (FetchAlarmSettingsAction.match(action)) {
        return {
            ...stateWithLoadingTrue(state),
            alarmSettings: {
                ...initialState.alarmSettings,
                alarmCustomizations: state.alarmSettings.alarmCustomizations,
            },
        };
    }

    if (ToggleAlarmByMeterIdAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SendSetDataLoggerDownlinkAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    return state;
};

const successActions = (state: ApartmentsState, action: AnyAction): ApartmentsState => {
    if (SaveAlarmSettingsSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (SaveReportSettingsSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (SendTestReportSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpdateApartmentNameSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UploadCSVSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (ChangeMeterSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DownloadMeterUsageReportSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteApartmentSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteMeterSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteAllApartmentsSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            apartments: [],
        };
    }

    if (FetchApartmentsSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            apartments: action.payload,
        };
    }

    if (FetchGraphsSuccessAction.match(action)) {
        let payload: Record<string, MeterHistoryReading[]> = {};
        if (action.payload.usageByMeterId && hasUsageData(action.payload.usageByMeterId)) {
            for (let i in action.payload.usageByMeterId) {
                payload[i] = action.payload.usageByMeterId[i].map((u) => ({
                    created: u.created,
                    value: u.usageLitres,
                }));
            }
        } else if (action.payload.byTemperatureMeterId && hasUsageData(action.payload.byTemperatureMeterId)) {
            for (let i in action.payload.byTemperatureMeterId) {
                const temperatureKey = i + ":temperature";
                payload[temperatureKey] = action.payload.byTemperatureMeterId[i].map((u) => ({
                    created: u.timestamp,
                    value: u.averageTemperatureCelsius,
                }));
            }
            for (let i in action.payload.byTemperatureMeterId) {
                const humidityKey = i + ":humidity";
                payload[humidityKey] = action.payload.byTemperatureMeterId[i].map((u) => ({
                    created: u.timestamp,
                    value: u.averageHumidityPercent ?? null,
                }));
            }
        } else {
            if (
                action.payload.coolingUsageByEnergyMeterId &&
                hasUsageData(action.payload.coolingUsageByEnergyMeterId)
            ) {
                for (let i in action.payload.coolingUsageByEnergyMeterId) {
                    const coolingKey = i + ":cooling";
                    payload[coolingKey] = action.payload.coolingUsageByEnergyMeterId[i].map((u) => ({
                        created: u.created,
                        value: u.usageKwh,
                    }));
                }
            }
            if (
                action.payload.heatingUsageByEnergyMeterId &&
                hasUsageData(action.payload.heatingUsageByEnergyMeterId)
            ) {
                for (let i in action.payload.heatingUsageByEnergyMeterId) {
                    const heatingKey = i + ":heating";
                    payload[heatingKey] = action.payload.heatingUsageByEnergyMeterId[i].map((u) => ({
                        created: u.created,
                        value: u.usageKwh,
                    }));
                }
            }
            if (
                action.payload.electricityUsageByEnergyMeterId &&
                hasUsageData(action.payload.electricityUsageByEnergyMeterId)
            ) {
                for (let i in action.payload.electricityUsageByEnergyMeterId) {
                    const electricityKey = i + ":electricity";
                    payload[electricityKey] = action.payload.electricityUsageByEnergyMeterId[i].map((u) => ({
                        created: u.created,
                        value: u.usageKwh,
                    }));
                }
            }
        }
        return {
            ...stateWithLoadingFalse(state),
            usageByMeterId: payload,
        };
    }

    if (FetchAlarmSettingsSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            alarmSettings: action.payload,
        };
    }

    if (FetchReportSettingsSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            reportSettings: action.payload,
        };
    }

    if (FetchReportFormatsSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            reportFormats: action.payload,
        };
    }

    if (FetchPremiumSettingsSuccessAction.match(action)) {
        return {
            ...stateWithLoadingFalse(state),
            premiumSettings: action.payload,
        };
    }

    if (ToggleAlarmByMeterIdSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (ToggleAllApartmentsLoadingAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (SendSetDataLoggerDownlinkSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
};

const otherActions = (state: ApartmentsState, action: AnyAction): ApartmentsState => {
    if (ToggleApartmentAction.match(action)) {
        return {
            ...state,
            apartmentOpenById: {
                ...state.apartmentOpenById,
                [action.payload.id]: action.payload.isOpen,
            },
        };
    }

    if (ToggleAllApartmentsAction.match(action)) {
        const allApartmentsOpen = action.payload;
        let newApartmentsOpen: ApartmentOpenById = {};
        for (let a of state.apartments) {
            newApartmentsOpen[a.id] = allApartmentsOpen;
        }
        return {
            ...stateWithLoadingFalse(state),
            apartmentOpenById: newApartmentsOpen,
            allApartmentsOpen: allApartmentsOpen,
        };
    }

    if (CloseChartAction.match(action)) {
        return {
            ...state,
            chartState: { ...state.chartState, isChartOpen: false },
        };
    }

    if (ClearApartmentsAction.match(action)) {
        return {
            ...state,
            apartments: [],
            loading: 0,
            chartState: {
                apartmentId: null,
                isChartOpen: false,
                type: "day",
                offsetDays: 0,
                meterType: null,
            },
            isReportingOpen: false,
            apartmentOpenById: {},
            allApartmentsOpen: false,
        };
    }

    return state;
};

const failureActions = (state: ApartmentsState, action: AnyAction): ApartmentsState => {
    if (FetchApartmentsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (FetchGraphsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (FetchAlarmSettingsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (FetchReportSettingsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (FetchReportFormatsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (FetchPremiumSettingsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (SaveAlarmSettingsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (SaveReportSettingsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (SendTestReportFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UploadCSVFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (ChangeMeterFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteMeterFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteApartmentFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpdateApartmentNameFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DownloadMeterUsageReportFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (ToggleAlarmByMeterIdFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteAllApartmentsFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (SendSetDataLoggerDownlinkFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
};

export default function apartmentsReducer(state: ApartmentsState = initialState, action: AnyAction): ApartmentsState {
    state = actions(state, action);
    state = successActions(state, action);
    state = failureActions(state, action);
    state = otherActions(state, action);
    return state;
}

export function selectApartments(state: ApartmentsState): Apartment[] {
    return state.apartments;
}
