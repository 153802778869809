import { all, put, takeEvery } from "redux-saga/effects";
import { ErrorOccurredAction } from "../error/error-actions";
import * as api from "../../api";
import {
    DeleteNoteAction,
    DeleteNoteFailureAction,
    DeleteNoteSuccessAction,
    FetchNotesAction,
    FetchNotesFailureAction,
    FetchNotesSuccessAction,
    InsertNoteAction,
    InsertNoteFailureAction,
    InsertNoteSuccessAction,
} from "./notes-actions";
import { DeleteNotePayLoad, FetchNotesPayLoad, InsertNotePayLoad } from "./notes-types";
import { ApiNote } from "../../api/api-types";
import { convertApiNote } from "../../api/api-conversions";

export function* fetchNotes(action: { payload: FetchNotesPayLoad }) {
    const companyId: number = action.payload.companyId;
    yield api.get(
        `/companies/${companyId}/notes`,
        (resp: ApiNote[]) => put(FetchNotesSuccessAction(resp.map(convertApiNote))),
        (err) => all([put(FetchNotesFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* deleteNote(action: { payload: DeleteNotePayLoad }) {
    const companyId: number = action.payload.companyId;
    const noteId: number = action.payload.id;
    yield api.remove(
        `/companies/${companyId}/notes/${noteId}`,
        {},
        () => all([put(DeleteNoteSuccessAction()), put(FetchNotesAction({ companyId: companyId }))]),
        (err) => all([put(DeleteNoteFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* insertNote(action: { payload: InsertNotePayLoad }) {
    const companyId: number = action.payload.companyId;
    const comment: string = action.payload.comment;
    yield api.post(
        `/companies/${companyId}/notes`,
        { comment: comment },
        () => all([put(InsertNoteSuccessAction()), put(FetchNotesAction({ companyId: companyId }))]),
        (err) => all([put(InsertNoteFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* notesSaga() {
    yield takeEvery(FetchNotesAction, fetchNotes);
    yield takeEvery(DeleteNoteAction, deleteNote);
    yield takeEvery(InsertNoteAction, insertNote);
}
