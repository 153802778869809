import React from "react";
import StyledSearchBar from "./SearchBar";

type Props = {
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    pageChange: (arg0: number) => void;
    currentPage: number;
};

function SearchBar({ search, setSearch, pageChange, currentPage }: Props): JSX.Element {
    const onSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        if (currentPage !== 1) {
            pageChange(1);
        }
    };

    return <StyledSearchBar onChange={onSearchTermChange} searchValue={search} />;
}

export default SearchBar;
