import "./assets/index.css";
import { createRoot } from "react-dom/client";
import getStore, { HydrolinkStore } from "./configureStore";
import Application from "./components/application/Application";
import { FetchUserInformationAction, SetIdTokenAction } from "./redux/auth/auth-actions";
import { theme } from "./theme";
import { VERSION } from "./version";
import { persistentStorage } from "./utils/persistence-storage";

console.info(`Version ${VERSION}`);

const store = getStore();
loadIdToken(store);

if (store.getState().hydrolink.auth.isAuthenticated) {
    store.dispatch(FetchUserInformationAction({}));
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Application store={store} theme={theme} />);

/* Helper Functions */

/**
 * Restore authentication (ID) token from persistent storage.
 * @param store root store instance.
 */
function loadIdToken(store: HydrolinkStore): void {
    const idToken = persistentStorage.authToken.get();
    if (idToken) {
        store.dispatch(SetIdTokenAction(idToken));
    }
}
