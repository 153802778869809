import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "../../components/Button";

export default function CreateCompaniesButton({ openCreateCompanyModal }: { openCreateCompanyModal: () => void }) {
    const t = useTranslation().t;

    return (
        <Button onClick={openCreateCompanyModal} startIcon={<AddIcon />}>
            {t("companies.createNew")}
        </Button>
    );
}
