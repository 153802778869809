import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomTooltip } from "../../../../components/CustomTooltip";
import { TableCell, TableRow } from "../../../../components/Table";
import { asValidLanguage } from "../../../../components/common/language-selector/LanguageSelector";
import { RootState } from "../../../../interfaces/RootState";
import { ToggleAlarmByMeterIdAction } from "../../../../redux/apartments/apartments-actions";
import { Meter } from "../../../../redux/apartments/apartments-types";
import { AlarmType } from "../../../../redux/companies/companies-types";
import { convertMeterStringIdToInt } from "../../../../utils/utils";
import AlarmCustomizationHead from "../../premium-settings/alarm-customization/AlarmCustomizationHead";

export function MeterAlarmTypeRow({
    alarmType,
    companyId,
    meter,
}: {
    alarmType: AlarmType;
    companyId: number;
    meter: Meter;
}) {
    const dispatch = useDispatch();
    const language = useTranslation().i18n.language;
    const localization = alarmType.localizations[asValidLanguage(language)];
    const { t } = useTranslation();

    // The backend has meter type "ambient" that is equivalent here with "temperature".
    // TODO: Someday, standardize/create real mappers
    const meterTypeForAlarm = meter.type === "ambient" ? "ambient" : meter.type;

    const isDisabled = !alarmType.supportedMeterTypes.includes(meterTypeForAlarm);
    const checked = !meter.deactivatedAlarmTypes.includes(alarmType.alarmName) && !isDisabled;

    const change = () => {
        dispatch(
            ToggleAlarmByMeterIdAction({
                companyId: companyId,
                meterId: convertMeterStringIdToInt(meter.id),
                meterType: meter.type,
                alarmType: alarmType.alarmName,
                deactivate: checked,
            }),
        );
    };

    return (
        <TableRow style={isDisabled ? { color: "grey" } : {}}>
            <TableCell>
                <CustomTooltip title={isDisabled ? t("meters.tooltip.alarmNotSupported") : localization.explanation}>
                    <span className="contains-tooltip">{localization.alarmName}</span>
                </CustomTooltip>
            </TableCell>
            <TableCell>
                <CustomTooltip title={isDisabled ? t("meters.tooltip.alarmNotSupported") : ""}>
                    <span>
                        <Checkbox disabled={isDisabled} onChange={() => change()} checked={checked} />
                    </span>
                </CustomTooltip>
            </TableCell>
        </TableRow>
    );
}

export type Props = {
    companyId: number;
    meter: Meter;
};

const MeterAlarmCustomization = ({ companyId, meter }: Props) => {
    const alarmTypes = useSelector((state: RootState) => state.hydrolink.companies.alarmTypes);

    return (
        <AlarmCustomizationHead simplifiedHead>
            {alarmTypes
                .filter((alarmType) => !alarmType.adminOnly)
                .map((alarmType) => (
                    <MeterAlarmTypeRow
                        key={alarmType.alarmName}
                        alarmType={alarmType}
                        meter={meter}
                        companyId={companyId}
                    />
                ))}
        </AlarmCustomizationHead>
    );
};

export default MeterAlarmCustomization;
