import deepEqual from "deep-equal";
import { Role } from "../../../../redux/companies/companies-types";
import { User } from "../../../../redux/users/users-types";
import { CompanyUser } from "./CompanyUser";

// TODO:
// - added-from-list should check for duplicate userId
// - added-new should check for duplicate userId and email
// - if manager is marked deleted, they shouldn't be able to be made principal
// - if manager is principal, they shouldn't be able to be made a viewer
// - etc?

export type AddedFromList = {
    event: "added-from-list";
    userId: number;
    role: Role;
};

export type AddedNew = {
    event: "added-new";
    userId: number;
    email: string;
    firstName: string;
    lastName: string;
    role: Role;
};

export type RoleChanged = {
    event: "role-changed";
    userId: number;
    role: Role;
};

export type MadePrincipal = {
    event: "made-principal";
    userId: number;
};

export type DeleteToggled = {
    event: "delete-toggled";
    userId: number;
};

export type EditEvent = AddedFromList | AddedNew | RoleChanged | MadePrincipal | DeleteToggled;

export type EditState = {
    toBeCreated: boolean;
    toBeUpdated: boolean;
    toBeDeleted: boolean;
};

export type CompanyUserWithState = {
    user: CompanyUser;
    state: EditState;
};

export function withEdits(users: User[], originals: CompanyUser[], edits: EditEvent[]): CompanyUserWithState[] {
    const edited = edits.reduce((res, edit) => withEdit(users, res, edit), init(originals));
    const withUniquePrincipal = tryEnsureUniquePrincipal(edited);
    return markModified(originals, withUniquePrincipal);
}

function init(originals: CompanyUser[]): CompanyUserWithState[] {
    return originals.map((user) => ({ user, state: initState }));
}

const initState = {
    toBeCreated: false,
    toBeUpdated: false,
    toBeDeleted: false,
};

function markModified(originals: CompanyUser[], edited: CompanyUserWithState[]): CompanyUserWithState[] {
    return edited.map((cu) => markOneModified(originals, cu));
}

function markOneModified(originals: CompanyUser[], edited: CompanyUserWithState): CompanyUserWithState {
    const original = originals.find((cu) => cu.userId === edited.user.userId);
    if (original === undefined) {
        return edited;
    }
    return withToBeUpdated(edited, !deepEqual(edited.user, original));
}

function tryEnsureUniquePrincipal(companyUsers: CompanyUserWithState[]): CompanyUserWithState[] {
    return ensureAtMostOnePrincipal(tryEnsureAtLeastOnePrincipal(companyUsers));
}

function tryEnsureAtLeastOnePrincipal(companyUsers: CompanyUserWithState[]): CompanyUserWithState[] {
    const hasPrincipal = companyUsers.some((cu) => cu.user.principal);
    if (hasPrincipal) {
        return companyUsers;
    }

    const firstManagerIdx = companyUsers.findIndex((cu) => cu.user.role === "manager");
    if (firstManagerIdx < 0) {
        // no managers, so cannot make any of the principal either
        return companyUsers;
    }

    const beforeFirstManager = companyUsers.slice(0, firstManagerIdx);
    const firstManager = companyUsers[firstManagerIdx];
    const afterFirstManager = companyUsers.slice(firstManagerIdx + 1);

    return [...beforeFirstManager, withPrincipal(firstManager, true), ...afterFirstManager];
}

function ensureAtMostOnePrincipal(companyUsers: CompanyUserWithState[]): CompanyUserWithState[] {
    let seenPrincipal = false;
    const result: CompanyUserWithState[] = [];
    companyUsers.forEach((cu) => {
        if (!cu.user.principal) {
            result.push(cu);
            return;
        }

        if (!seenPrincipal) {
            seenPrincipal = true;
            result.push(cu);
            return;
        }

        result.push(withPrincipal(cu, false));
    });
    return result;
}

function withEdit(users: User[], companyUsers: CompanyUserWithState[], edit: EditEvent): CompanyUserWithState[] {
    switch (edit.event) {
        case "added-from-list":
            return withAddedFromList(users, companyUsers, edit);

        case "added-new":
            return withAddedNew(companyUsers, edit);

        case "role-changed":
            return withRoleChanged(companyUsers, edit);

        case "made-principal":
            return withMadePrincipal(companyUsers, edit);

        case "delete-toggled":
            return withDeleteToggled(companyUsers, edit);
    }
}

function withAddedFromList(
    users: User[],
    companyUsers: CompanyUserWithState[],
    edit: AddedFromList,
): CompanyUserWithState[] {
    const user = users.find((u) => u.id === edit.userId);
    if (user === undefined) {
        console.warn(`Error applying ${edit}: user not found`);
        return companyUsers;
    }

    const newUser: CompanyUser = {
        userId: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        role: edit.role,
        principal: false,
    };

    return companyUsers.concat({
        user: newUser,
        state: { toBeCreated: true, toBeUpdated: false, toBeDeleted: false },
    });
}

function withAddedNew(companyUsers: CompanyUserWithState[], edit: AddedNew): CompanyUserWithState[] {
    const newUser: CompanyUser = {
        userId: edit.userId,
        email: edit.email,
        firstName: edit.firstName,
        lastName: edit.lastName,
        role: edit.role,
        principal: false,
    };

    return companyUsers.concat({
        user: newUser,
        state: { toBeCreated: true, toBeUpdated: false, toBeDeleted: false },
    });
}

function withRoleChanged(companyUsers: CompanyUserWithState[], edit: RoleChanged): CompanyUserWithState[] {
    return companyUsers.map((cu) => (cu.user.userId === edit.userId ? withRole(cu, edit.role) : cu));
}

function withMadePrincipal(companyUsers: CompanyUserWithState[], edit: MadePrincipal): CompanyUserWithState[] {
    return companyUsers.map((cu) => ensureNewPrincipal(cu, edit.userId));
}

function ensureNewPrincipal(cu: CompanyUserWithState, newPrincipalUserId: number): CompanyUserWithState {
    if (cu.user.role !== "manager") {
        return cu;
    }

    return withPrincipal(cu, cu.user.userId === newPrincipalUserId);
}

function withDeleteToggled(companyUsers: CompanyUserWithState[], edit: DeleteToggled): CompanyUserWithState[] {
    if (tryingToDeletePrincipal(companyUsers, edit) && companyUsers.length > 1) {
        // just skip this edit
        return companyUsers;
    }

    // if a company user was added and then removed, just remove it from the list
    // instead of marking as to-be-deleted
    const withoutNewDeleted = companyUsers.filter((cu) => {
        if (cu.user.userId !== edit.userId) {
            // not edited by this edit; keep it
            return true;
        }

        // keep user if they haven't been added in this edit session
        return !cu.state.toBeCreated;
    });

    // for others, just toggle the deleted state
    return withoutNewDeleted.map((cu) =>
        cu.user.userId === edit.userId ? withToBeDeleted(cu, !cu.state.toBeDeleted) : cu,
    );
}

function tryingToDeletePrincipal(companyUsers: CompanyUserWithState[], edit: DeleteToggled) {
    return companyUsers.some((cu) => cu.user.userId === edit.userId && cu.user.principal && !cu.state.toBeDeleted);
}

function withRole(cu: CompanyUserWithState, role: Role) {
    if (cu.user.role === role) {
        return cu;
    }
    return { ...cu, user: { ...cu.user, role } };
}

function withPrincipal(cu: CompanyUserWithState, principal: boolean) {
    if (cu.user.principal === principal) {
        return cu;
    }
    return { ...cu, user: { ...cu.user, principal } };
}

function withToBeUpdated(cu: CompanyUserWithState, toBeUpdated: boolean) {
    if (cu.state.toBeUpdated === toBeUpdated) {
        return cu;
    }
    return { ...cu, state: { ...cu.state, toBeUpdated } };
}

function withToBeDeleted(cu: CompanyUserWithState, toBeDeleted: boolean) {
    if (cu.state.toBeDeleted === toBeDeleted) {
        return cu;
    }
    return { ...cu, state: { ...cu.state, toBeDeleted } };
}
