import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../components/Button";
import { CompanyInvoiceSettings, InvoiceType } from "../../../redux/companies/companies-types";
import { TableContainer, Table, TableRow, TableBody, TableCell } from "../../../components/Table";
import { InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useEffect } from "react";
import {
    FetchCompanyInvoiceInfoAction,
    SaveCompanyInvoiceInfoAction,
} from "../../../redux/companies/companies-actions";
import { RootState } from "../../../interfaces/RootState";
import deepEqual from "deep-equal";
import InfoModal from "../../../components/InfoModal";
import InfoLink from "../../../components/InfoLink";
import { useHasPrivileges } from "../../../utils/useHasPrivilegesOf";
import { alignments } from "../../../theme";
import { isSwedishVersion } from "../../../utils/location";

type LocalSettingsType = {
    recipientName: string;
    bank: string;
    bic: string;
    iban: string;
    bankgirot: string;
    contactPerson: string;
    daysToPay: string;
    referenceNumberBody: string;
};

function CompanyInvoiceSettingsRow({
    id,
    value,
    changeHandler,
    hasPrivileges,
    isNumberField,
}: {
    hasPrivileges: boolean;
    isNumberField?: boolean;
    id: string;
    value: string | number | undefined;
    changeHandler: (key: string, value: string) => void;
}) {
    const t = useTranslation().t;
    const parseValue = () => {
        return value === undefined ? "" : value.toString();
    };

    return (
        <TableRow>
            <TableCell>
                <InputLabel htmlFor={id}>{t(`apartments.companyInvoiceSettings.${id}`)} </InputLabel>
            </TableCell>
            <TableCell>
                {hasPrivileges ? (
                    <TextField
                        id={id}
                        type={isNumberField ? "number" : "text"}
                        size="small"
                        value={parseValue()}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(id, e.target.value)}
                    />
                ) : (
                    parseValue()
                )}
            </TableCell>
        </TableRow>
    );
}

export default function CompanyInvoiceInfo({ companyId }: { companyId: number }) {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const sweden = isSwedishVersion();
    const DEFAULT_TYPE: InvoiceType = sweden ? "BANKGIROT" : "IBAN";
    const hasPrivileges = useHasPrivileges("can edit company invoice settings", companyId).hasPrivilege;
    const fetchedSettings = useSelector(
        (state: RootState): CompanyInvoiceSettings | undefined => state.hydrolink.companies.invoiceInfo,
    );
    const [invoiceType, setInvoiceType] = React.useState<InvoiceType>(fetchedSettings?.invoiceType ?? DEFAULT_TYPE);
    const [localSettings, setLocalSettings] = React.useState<LocalSettingsType>({
        recipientName: "",
        bank: "",
        bic: "",
        iban: "",
        bankgirot: "",
        contactPerson: "",
        daysToPay: "",
        referenceNumberBody: "",
    });
    const [originalSettings, setOriginalSettings] = React.useState<LocalSettingsType>(localSettings);

    const [infoModalOpen, setInfoModalOpen] = React.useState(false);

    const NaNIncluded = (): boolean => {
        const daysToPay = parseInt(localSettings.daysToPay);
        const referenceNumberBody = parseInt(localSettings.referenceNumberBody);
        if (isNaN(daysToPay) || isNaN(referenceNumberBody)) return true;
        return false;
    };

    const isBlank = (field: string) => field.trim() === "";

    const savingAllowed = (): boolean => {
        if (deepEqual(localSettings, originalSettings) || NaNIncluded()) return false;
        if (isBlank(localSettings.bank)) return false;
        if (isBlank(localSettings.daysToPay)) return false;
        if (isBlank(localSettings.referenceNumberBody)) return false;
        if (isBlank(localSettings.contactPerson)) return false;
        return true;
    };

    useEffect(() => {
        if (!fetchedSettings) {
            dispatch(FetchCompanyInvoiceInfoAction({ companyId: companyId }));
        }
    }, [companyId, dispatch, fetchedSettings]);

    useEffect(() => {
        if (fetchedSettings) {
            const fetched = {
                recipientName: fetchedSettings.recipientName ?? "",
                bank: fetchedSettings.bank ?? "",
                bic: fetchedSettings.bic ?? "",
                iban: fetchedSettings.iban ?? "",
                bankgirot: fetchedSettings.bankgirot ?? "",
                invoiceType: fetchedSettings.invoiceType ?? DEFAULT_TYPE,
                contactPerson: fetchedSettings.contactPerson ?? "",
                daysToPay: fetchedSettings.daysToPay ? fetchedSettings.daysToPay.toString() : "",
                referenceNumberBody: fetchedSettings.referenceNumberBody
                    ? fetchedSettings.referenceNumberBody.toString()
                    : "",
            };
            setOriginalSettings(fetched);
            setLocalSettings(fetched);
        }
    }, [fetchedSettings, DEFAULT_TYPE]);

    const onChangeHandler = (key: string, value: string) => {
        setLocalSettings({ ...localSettings, [key]: value });
    };

    const setInvoiceTypeHandler = (e: SelectChangeEvent) => {
        setInvoiceType(e.target.value as InvoiceType);
    };

    const onSave = () => {
        if (savingAllowed()) {
            dispatch(
                SaveCompanyInvoiceInfoAction({
                    companyId: companyId,
                    recipientName: localSettings.recipientName.trim(),
                    bank: localSettings.bank.trim(),
                    bic: localSettings.bic.trim(),
                    iban: localSettings.iban.trim().replaceAll(" ", ""),
                    bankgirot: localSettings.bankgirot.trim(),
                    invoiceType: invoiceType,
                    contactPerson: localSettings.contactPerson.trim(),
                    daysToPay: parseInt(localSettings.daysToPay.replace(/\s/g, "")),
                    referenceNumberBody: parseInt(localSettings.referenceNumberBody.replace(/\s/g, "")),
                }),
            );
        }
    };

    return (
        <>
            <InfoModal
                open={infoModalOpen}
                onClose={() => setInfoModalOpen(false)}
                title={t("apartments.companyInvoiceSettings.infoModalTitle")}
                body={t("apartments.companyInvoiceSettings.infoModalBody")}
            />
            <div style={{ marginTop: "3em", display: "flex", flexDirection: "column" }}>
                <div>
                    <span style={{ marginTop: "1em" }} className="text-bold">
                        {t("apartments.companyInvoiceSettings.title")}
                    </span>
                    <InfoLink onClick={() => setInfoModalOpen(true)} />
                    {/* Leaving invoice type selection for Sweden, or for Finland if there is issue with having correct default invoiceType.
                        Should help testing and transition, can probably be removed later. */}
                    {sweden || invoiceType !== "IBAN" ? (
                        <Select style={{ float: "right" }} value={invoiceType} onChange={setInvoiceTypeHandler}>
                            <MenuItem value="IBAN">IBAN</MenuItem>
                            <MenuItem value="BANKGIROT">Bankgirot</MenuItem>
                        </Select>
                    ) : (
                        <></>
                    )}
                </div>

                <div style={{ width: "60%" }}>
                    <TableContainer component="div">
                        <Table aria-label="simple table">
                            <TableBody>
                                <CompanyInvoiceSettingsRow
                                    id={"recipientName"}
                                    value={localSettings.recipientName}
                                    changeHandler={onChangeHandler}
                                    hasPrivileges={hasPrivileges}
                                />
                                <CompanyInvoiceSettingsRow
                                    id={"bank"}
                                    value={localSettings.bank}
                                    changeHandler={onChangeHandler}
                                    hasPrivileges={hasPrivileges}
                                />
                                {invoiceType === "IBAN" && (
                                    <CompanyInvoiceSettingsRow
                                        id={"bic"}
                                        value={localSettings.bic}
                                        changeHandler={onChangeHandler}
                                        hasPrivileges={hasPrivileges}
                                    />
                                )}

                                {invoiceType === "IBAN" && (
                                    <CompanyInvoiceSettingsRow
                                        id={"iban"}
                                        value={localSettings.iban}
                                        changeHandler={onChangeHandler}
                                        hasPrivileges={hasPrivileges}
                                    />
                                )}

                                {invoiceType === "BANKGIROT" && (
                                    <CompanyInvoiceSettingsRow
                                        id={"bankgirot"}
                                        value={localSettings.bankgirot}
                                        changeHandler={onChangeHandler}
                                        hasPrivileges={hasPrivileges}
                                    />
                                )}

                                <CompanyInvoiceSettingsRow
                                    id={"contactPerson"}
                                    value={localSettings.contactPerson}
                                    changeHandler={onChangeHandler}
                                    hasPrivileges={hasPrivileges}
                                />
                                <CompanyInvoiceSettingsRow
                                    id={"daysToPay"}
                                    value={localSettings.daysToPay}
                                    changeHandler={onChangeHandler}
                                    hasPrivileges={hasPrivileges}
                                    isNumberField={true}
                                />
                                <CompanyInvoiceSettingsRow
                                    id={"referenceNumberBody"}
                                    value={localSettings.referenceNumberBody}
                                    changeHandler={onChangeHandler}
                                    hasPrivileges={hasPrivileges}
                                    isNumberField={true}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div style={{ ...alignments.evenHorizontal }}>
                    <div></div>
                    {hasPrivileges && (
                        <Button disabled={!savingAllowed()} onClick={onSave}>
                            {t("generic.save")}
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
}
