import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "../../components/Table";
import { Paper } from "@mui/material";
import { DatePickerComponent } from "../../components/DatePicker";
import { useTranslation } from "react-i18next";
import { colors, typography } from "../../theme";
import { Button } from "../../components/Button";
import { Dispatch, SetStateAction } from "react";

type Props = {
    from: Date | null;
    until: Date | null;
    setFrom: React.Dispatch<React.SetStateAction<Date | null>>;
    setUntil: React.Dispatch<React.SetStateAction<Date | null>>;
    dayNotIncluded: boolean;
    invalidTimeSpan: boolean;
    fetchReport: () => void;
    setDatesAreValid: Dispatch<SetStateAction<boolean>>;
    datesAreValid: boolean;
};

export function DateChoosingTable({
    from,
    until,
    setFrom,
    setUntil,
    dayNotIncluded,
    invalidTimeSpan,
    fetchReport,
    setDatesAreValid,
    datesAreValid,
}: Props): JSX.Element {
    const t = useTranslation().t;

    const onChange = () => {
        setDatesAreValid(false);
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3}>{t("consumptionReporting.determinePeriod")}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t("consumptionReporting.from")}</TableCell>
                        <TableCell>{t("consumptionReporting.until")}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <DatePickerComponent
                                value={from}
                                setValue={setFrom}
                                onChange={onChange}
                                dayNotIncluded={dayNotIncluded}
                            />
                            <div
                                style={{
                                    color: colors.warm06,
                                    fontSize: typography.fontSize.small,
                                    position: "absolute",
                                }}
                            >
                                {invalidTimeSpan ? t("errors.endingDateIsBeforeStartingDate") : ""}
                            </div>
                        </TableCell>
                        <TableCell>
                            <DatePickerComponent
                                value={until}
                                setValue={setUntil}
                                onChange={onChange}
                                dayNotIncluded={dayNotIncluded}
                            />
                        </TableCell>
                        <TableCell>
                            <Button
                                // style={{ width: "100%" }}
                                onClick={fetchReport}
                                disabled={datesAreValid}
                            >
                                {t("OK")}
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
