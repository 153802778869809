import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { Modal } from "../../components/common/modal/Modal";
import { ModalInput } from "../../components/common/modal/ModalInput";
import { alignments } from "../../theme";
import { AppDispatch } from "../../interfaces/RootState";
import { ErrorOccurredAction } from "../../redux/error/error-actions";
import { CreateCompanyDPARequestAction } from "../../redux/contracts/contracts-actions";

/**
 * Props for {@link CreateCompanyDpaModal}.
 */
export type Props = {
    /**
     * Redux dispatch function.
     */
    dispatch: AppDispatch;

    /**
     * Whether the modal is open.
     */
    isOpen: boolean;

    /**
     * Function to close the modal (set `isOpen` to `false`).
     */
    close: () => void;

    /**
     * User ID of the manager who will sign the contract.
     */
    userId: number;

    /**
     * ID of the company for which to create the contract.
     */
    companyId: number;

    /**
     * Initial data for the form.
     */
    initialData: FormData;

    /**
     * Test IDs for the modal elements.
     */
    testIds?: TestIds;
};

/**
 * Form data for a company DPA.
 */
export type FormData = {
    /**
     * Business ID (Y-tunnus) of the company.
     */
    businessId: string;

    /**
     * Street address of the company.
     */
    streetAddress: string;

    /**
     * ZIP code of the company.
     */
    zipCode: string;

    /**
     * Town of the company.
     */
    town: string;
};

/**
 * Test IDs for {@link CreateCompanyDpaModal}.
 */
export type TestIds = {
    closeButton?: string;
    saveButton?: string;
    businessIdInput?: string;
    streetAddressInput?: string;
    zipCodeInput?: string;
    townInput?: string;
};

/**
 * Modal for entering data for a company DPA.
 */
export default function CreateCompanyDpaModal({
    dispatch,
    isOpen,
    close,
    userId,
    companyId,
    initialData,
    testIds,
}: Props) {
    const { t } = useTranslation();

    const [data, setData] = useState(initialData);

    const resetAndClose = () => {
        setData(initialData);
        close();
    };

    const onChange = (field: keyof FormData) => (e: React.ChangeEvent<HTMLInputElement>) =>
        setData((prev) => ({ ...prev, [field]: e.target.value }));

    const onSave = () => {
        const errorKey = validateFormData(data);
        if (errorKey !== null) {
            dispatch(ErrorOccurredAction(errorKey));
            return;
        }
        dispatch(CreateCompanyDPARequestAction({ userId, companyId, ...data }));
        resetAndClose();
    };

    const onClose = resetAndClose;

    const DialogActions = () => {
        return (
            <>
                <Button onClick={onClose} data-testid={testIds?.closeButton}>
                    {t("generic.cancel")}
                </Button>
                <Button variant="contained" onClick={onSave} data-testid={testIds?.saveButton}>
                    {t("generic.save")}
                </Button>
            </>
        );
    };

    return (
        <Modal
            title={t("contracts.forms.createCompanyDpa.title")}
            open={isOpen}
            dialogActions={<DialogActions />}
            closeModal={onClose}
        >
            <div style={{ ...alignments.twoElementGrid }}>
                <ModalInput
                    key="businessId"
                    title={`${t("contracts.forms.createCompanyDpa.fields.businessId")} *`}
                    value={data.businessId}
                    handleFormValue={onChange("businessId")}
                    dataTestId={testIds?.businessIdInput}
                />
                <ModalInput
                    key="streetAddress"
                    title={`${t("contracts.forms.createCompanyDpa.fields.streetAddress")} *`}
                    value={data.streetAddress}
                    handleFormValue={onChange("streetAddress")}
                    dataTestId={testIds?.streetAddressInput}
                />
                <ModalInput
                    key="zipCode"
                    title={`${t("contracts.forms.createCompanyDpa.fields.zipCode")} *`}
                    value={data.zipCode}
                    handleFormValue={onChange("zipCode")}
                    dataTestId={testIds?.zipCodeInput}
                />
                <ModalInput
                    key="town"
                    title={`${t("contracts.forms.createCompanyDpa.fields.town")} *`}
                    value={data.town}
                    handleFormValue={onChange("town")}
                    dataTestId={testIds?.townInput}
                />
            </div>
        </Modal>
    );
}

/**
 * Validate the form data.
 *
 * @param data Form data to validate.
 * @returns Error message translation key if the data is invalid, otherwise `null`.
 */
function validateFormData(data: FormData): string | null {
    const key = (suffix: string) => `contracts.forms.createCompanyDpa.errors.${suffix}`;

    const empty = /^\s*$/;

    if (empty.test(data.businessId)) {
        return key("businessIdMissing");
    }
    if (empty.test(data.streetAddress)) {
        return key("streetAddressMissing");
    }
    if (empty.test(data.zipCode)) {
        return key("zipCodeMissing");
    }
    if (empty.test(data.town)) {
        return key("townMissing");
    }

    return null;
}
