import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UpdateApiAccessAction } from "../../../../redux/companies/companies-actions";
import { RootState } from "../../../../interfaces/RootState";
import Checkbox from "../../../../components/Checkbox";
import { Tooltip } from "@mui/material";
import { useHasPrivileges } from "../../../../utils/useHasPrivilegesOf";

type Props = {
    companyId: number;
};

export default function ApiAccess({ companyId }: Props) {
    const dispatch = useDispatch();
    const t = useTranslation().t;

    const canToggleApiAccess = useHasPrivileges("can toggle api access", companyId).hasPrivilege;

    const apiAccessEnabled = useSelector((state: RootState) =>
        state.hydrolink.companies.companies.find((c) => c.id === companyId),
    )?.apiAccessEnabled;

    const toggleApiAccess = () => {
        if (canToggleApiAccess) {
            dispatch(
                UpdateApiAccessAction({
                    companyId,
                    apiAccessEnabled: !apiAccessEnabled,
                }),
            );
        }
    };

    return (
        <Tooltip title={!canToggleApiAccess ? (t("companies.info.apiAccessContactKoka") as string) : ""}>
            <div>
                {" "}
                {/* div required for tooltip */}
                <Checkbox
                    label={t("companies.info.apiAccess")}
                    checked={apiAccessEnabled!!}
                    onChange={toggleApiAccess}
                    disabled={!canToggleApiAccess}
                />
            </div>
        </Tooltip>
    );
}
