import { useEffect, useRef, useState } from "react";
import { Alert } from "@mui/material";
import "../../assets/App.css";
import "../../assets/Mobile.css";
import "../../assets/index.css";
import Auth0Lock from "auth0-lock";
import { useDispatch, useSelector } from "react-redux";
import { AUTH0_CLIENT_ID, LOGIN_DOMAIN } from "../../constants/auth";
import i18n from "../../i18n";
import { FetchUserInformationAction, LockSuccessAction } from "../../redux/auth/auth-actions";
import { persistentStorage } from "../../utils/persistence-storage";
import log from "../../utils/logUtils";
import { ErrorOccurredAction } from "../../redux/error/error-actions";
import { selectNextError } from "../../redux/error/error-selectors";
import HydrolinkLogo from "../../assets/LOGO_login_menu.svg";
import Centered from "../../components/Centered";
import { borders, colors, typography } from "../../theme";
import LanguageSelector from "../../components/common/language-selector/LanguageSelector";
import { useTranslation } from "react-i18next";
import { redirectUrl } from "../../redirectUrl";
import NoticeBanner from "../../components/NoticeBanner";
import { LOGIN_PAGE_NOTICE_BANNER_ENABLED } from "../../constants/noticeBanner";

interface AuthResult {
    idToken: string;
}

interface Auth0LockInstance {
    show: () => void;
    hide: () => void;
}

export function useAuth0Lock(
    onAuthenticated: (authResult: AuthResult) => void,
    onAuthorizationError: (err: string) => void,
): null | Auth0LockInstance {
    const dispatch = useDispatch();
    const callbacks = useRef({ onAuthenticated, onAuthorizationError });
    const [lock, setLock] = useState<Auth0LockInstance | null>(null);
    const previousLoginErrorKey = persistentStorage.previousLoginError.getMessageKey();
    const language = useTranslation().i18n.language;
    const [prevLang, setPrevLang] = useState(language);

    useEffect(() => {
        if (lock != null && prevLang !== language) {
            setPrevLang(language);
            lock.hide();
        }
    }, [language, lock, prevLang]);

    useEffect(() => {
        const newLock = new Auth0Lock(AUTH0_CLIENT_ID ?? "", LOGIN_DOMAIN ?? "", {
            language: language,
            theme: {
                primaryColor: "#2C7BE5",
                logo: HydrolinkLogo,
            },
            languageDictionary: {
                title: "",
                signUpTitle: "",
            },
            auth: {
                redirectUrl: redirectUrl(),
                responseType: "token id_token",
                params: {
                    scope: "openid email app_metadata",
                },
            },
            allowSignUp: false,
            container: "auth0-login",
            avatar: null,
        });

        newLock.on("authenticated", (authResult: AuthResult) => {
            callbacks.current.onAuthenticated(authResult);
        });

        newLock.on("authorization_error", (err: string) => {
            callbacks.current.onAuthorizationError(err);
        });

        newLock.show();

        setLock(newLock);
    }, [language]);

    useEffect(() => {
        if (previousLoginErrorKey) {
            // Re-dispatch error loaded from local storage into error state
            dispatch(ErrorOccurredAction(previousLoginErrorKey));
            persistentStorage.previousLoginError.clear();
        }
    }, [dispatch, previousLoginErrorKey]);

    useEffect(() => {
        callbacks.current = {
            onAuthenticated,
            onAuthorizationError,
        };
    }, [onAuthenticated, onAuthorizationError]);

    return lock;
}

function LoginErrorNotification(): JSX.Element {
    const error = i18n.t(useSelector(selectNextError));

    if (!error) {
        return <></>;
    }

    return (
        <Alert
            severity="error"
            sx={{
                width: "auto",
                alignItems: "center",
                fontSize: typography.fontSize.large,
                whiteSpace: "pre-line",
            }}
        >
            <span>{error}</span>
        </Alert>
    );
}

export function Login(): JSX.Element {
    return (
        <>
            {LOGIN_PAGE_NOTICE_BANNER_ENABLED && <NoticeBanner />}
            <Centered style={{ paddingTop: "1em" }}>
                <div
                    style={{
                        display: "inline-block",
                        backgroundColor: colors.neutral05,
                        borderRadius: borders.defaultBorderRadius,
                        marginBottom: "5rem",
                    }}
                >
                    <LanguageSelector buttonClassName="language-select-button-login" />
                </div>
                <div id="auth0-login" />
                <LoginErrorNotification />
            </Centered>
        </>
    );
}

export default function ConnectedLogin(): JSX.Element {
    const dispatch = useDispatch();
    const lock = useAuth0Lock(onAuthenticated, onAuthorizationError);

    function onAuthenticated(authResult: AuthResult): void {
        persistentStorage.authToken.set(authResult.idToken);
        dispatch(LockSuccessAction({ token: authResult.idToken }));
        dispatch(FetchUserInformationAction({}));
        if (lock) {
            lock.hide();
        }
    }

    function onAuthorizationError(_err: string): void {
        log.debug("Failed login");
    }

    return <Login />;
}
