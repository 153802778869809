import React from "react";
import * as Mui from "@mui/material";
import { borders, colors } from "../theme";
import { styled } from "@mui/material/styles";

type Props = {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    startIcon?: React.ReactNode;
    variant?: "text" | "outlined" | "contained";
    disabled?: boolean;
    style?: React.CSSProperties;
    children: React.ReactNode;
};

const StyledButton = styled(Mui.Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: "bold",
    border: borders.styledButtonBorder,
    borderRadius: borders.buttonBorderRadius,
    ":hover": {
        backgroundColor: colors.primary06,
    },
    ":active": {
        backgroundColor: colors.primary04,
    },
}));

export const Button = (props: Props) => {
    const { variant, ...rest } = props;
    return <StyledButton color="primary" variant={variant ?? "outlined"} {...rest} />;
};
