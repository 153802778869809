import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import { SortCompaniesAction } from "../../redux/companies/companies-actions";
import { Company } from "../../redux/companies/companies-types";
import { Table, TableBody, TableContainer, TableHead, TableRow, TableHeadCell } from "../../components/Table";
import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import Sorter from "../../components/Sorter";
import { table } from "../../theme";
import CompanyTableRow from "./CompanyTableRow";

function CompanyTableHead(): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    const currentSorting = useSelector((state: RootState) => state.hydrolink.companies.sortBy);
    const currentAscending = useSelector((state: RootState) => state.hydrolink.companies.ascending);

    const sortCompaniesFunction = (sortBy: string, ascending: boolean) => {
        dispatch(
            SortCompaniesAction({
                sortBy: sortBy,
                ascending: ascending,
            }),
        );
    };
    return (
        <TableHead>
            <TableRow>
                <TableHeadCell width={table.tableCellWidthExtraSmall}>{t("companies.premium")}</TableHeadCell>
                <Sorter
                    sortKey={"name"}
                    sortAction={sortCompaniesFunction}
                    sortBy={currentSorting}
                    title={t("companies.name")}
                    width={table.tableCellWidthExtraLarge}
                    currentAscending={currentAscending}
                />
                <TableHeadCell width={table.tableCellWidthExtraSmall}>{t("companies.alarms")}</TableHeadCell>
                <Sorter
                    sortKey={"streetAddress"}
                    sortAction={sortCompaniesFunction}
                    sortBy={currentSorting}
                    title={t("companies.address")}
                    currentAscending={currentAscending}
                />
                <Sorter
                    sortKey={"town"}
                    sortAction={sortCompaniesFunction}
                    sortBy={currentSorting}
                    title={t("companies.city")}
                    width={table.tableCellWidthMedium}
                    currentAscending={currentAscending}
                />
                <Sorter
                    sortKey={"principal"}
                    sortAction={sortCompaniesFunction}
                    sortBy={currentSorting}
                    title={t("companies.principal")}
                    currentAscending={currentAscending}
                />
                <TableHeadCell width={table.tableCellWidthExtraSmall}></TableHeadCell>
            </TableRow>
        </TableHead>
    );
}

const CompaniesTable = ({ companies }: { companies: Company[] }) => {
    const companyRoles = useSelector((state: RootState) => state.hydrolink.auth.roleByCompanyId);

    return (
        <TableContainer component={Paper}>
            <Table>
                <CompanyTableHead />
                <TableBody>
                    {[...companies].map((c) => (
                        <CompanyTableRow key={c.id} company={c} companyRole={companyRoles[c.id]} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CompaniesTable;
