import React, { useEffect } from "react";
import { Chip, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat, NumberFormatValues } from "react-number-format";
import { Block } from "../../../components/common/block";
import { Button } from "../../../components/Button";
import { SavePremiumSettingsAction } from "../../../redux/apartments/apartments-actions";
import { alignments, colors, spacings, textFieldProps } from "../../../theme";
import { RootState } from "../../../interfaces/RootState";
import { PremiumSettings as PremiumSettingsType } from "../../../redux/apartments/apartments-types";
import SeparatorLine from "../../../components/SeparatorLine";
import { Company } from "../../../redux/companies/companies-types";
import { useHasPrivileges } from "../../../utils/useHasPrivilegesOf";
import { useButtonDisabled } from "../../../utils/useButtonDisabled";
import CompanyInvoiceInfo from "./CompanyInvoiceSettings";
import InfoModal from "../../../components/InfoModal";
import InfoLink from "../../../components/InfoLink";
import AlarmCustomization from "./alarm-customization/AlarmCustomization";
import { DatePickerComponent } from "../../../components/DatePicker";
import { isSwedishVersion } from "../../../utils/location";

const GrayText = ({ children }: { children: React.ReactNode }) => (
    <span style={{ color: colors.neutral06 }}>{children}</span>
);

export default function PremiumSettings({ company }: { company: Company }): JSX.Element {
    const dispatch = useDispatch();
    const t = useTranslation().t;

    const existingCustomizations = useSelector(
        (state: RootState) => state.hydrolink.apartments.alarmSettings.alarmCustomizations,
    );

    const existingSettings = useSelector((state: RootState) => state.hydrolink.apartments.premiumSettings);
    const [premiumSettings, setPremiumSettings] = React.useState<PremiumSettingsType>(existingSettings);
    const [editing, setEditing] = React.useState(false);
    const canSavePremiumSettings = useHasPrivileges("can save premium settings", company.id).hasPrivilege;
    const savePremiumSettingsButtonDisabled = useButtonDisabled(canSavePremiumSettings, !editing);

    const canSeeCompanyInvoiceSettings = useHasPrivileges("can see company invoice settings", company.id);
    const [infoModalOpen, setInfoModalOpen] = React.useState(false);
    const [energyInfoModalOpen, setEnergyInfoModalOpen] = React.useState(false);

    useEffect(() => {
        setPremiumSettings(existingSettings);
    }, [existingSettings]);

    // Updates premium setting in local state
    const updateLocalSetting = (field: keyof PremiumSettingsType, value: string | number) => {
        setEditing(true);
        setPremiumSettings({
            ...premiumSettings,
            [field]: value,
        });
    };

    const savePremiumSettings = () => {
        setEditing(false);
        dispatch(
            SavePremiumSettingsAction({
                settings: premiumSettings,
                companyId: company.id,
            }),
        );
    };

    const textWithChipsInputKeyHandler = (e: React.KeyboardEvent, field: keyof PremiumSettingsType) => {
        // Mobile compatible
        if (e.key === "Enter") {
            let targetElement = e.target as HTMLInputElement;
            updateLocalSetting(field, addValueToString(premiumSettings[field] as string, targetElement.value));
            targetElement.value = "";
        }
    };

    // Removes a value from a semicolor separated string and cleans it
    const removeValueFromString = (list: string, value: string): string =>
        list
            .replace(value, "") // Erase value from list
            .replace(";;", ";") // Remove double semicolons
            .replace(/^(;)|(;)$/g, ""); // Remove start and end semicolons

    const textWithChipsInput = (target: HTMLInputElement, field: keyof PremiumSettingsType) => {
        let targetElement = target;
        updateLocalSetting(field, addValueToString(premiumSettings[field] as string, targetElement.value));
        targetElement.value = "";
    };

    // This can't be reduced to it's own <Section /> component because text fields lose focus after every input
    const sectionStyling = (bordered: boolean = false): React.CSSProperties => {
        return {
            ...alignments.twoElementGrid,

            paddingLeft: "28px",
            border: bordered ? `2px solid ${colors.neutral04}` : "",
            borderRadius: "8px",
            marginBottom: bordered ? "2.5em" : "",
            marginTop: !bordered ? "1.5em" : "6px",
        };
    };

    // Adds a value to a string. Also adds `;` if there are other values already in the list
    const addValueToString = (list: string, value: string): string => {
        if (value.trim() !== "") {
            list += list.length === 0 || list.endsWith(";") ? value : `;${value}`;
        }
        return list;
    };

    const getChips = (field: keyof PremiumSettingsType): React.ReactNode =>
        (premiumSettings[field] as string)
            .split(";")
            .filter((value) => !!value)
            .map((value) => (
                <Chip
                    size="small"
                    key={value}
                    label={value}
                    onDelete={() =>
                        updateLocalSetting(field, removeValueFromString(premiumSettings[field] as string, value))
                    }
                />
            ));

    const phoneNumbersInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        // If the user inserts a `;` character (or pastes a string of phone numbers like "123456;123543;123987") then parse it automatically
        if (input.includes(";")) {
            updateLocalSetting("phoneNumbers", addValueToString(premiumSettings["phoneNumbers"] as string, input));
            e.target.value = "";
        }
    };

    function createPriceField(field: keyof PremiumSettingsType): JSX.Element {
        return (
            <NumericFormat
                size="small"
                variant="outlined"
                inputMode="decimal"
                inputProps={{
                    style: {
                        padding: "4px 8px",
                    },
                }}
                style={{
                    width: "18em",
                    margin: "12px 0",
                }}
                decimalScale={3}
                decimalSeparator=","
                allowNegative={false}
                customInput={TextField}
                allowLeadingZeros={false}
                value={premiumSettings[field]}
                onValueChange={(values: NumberFormatValues) => updateLocalSetting(field, values.formattedValue)}
            />
        );
    }

    const PriceFieldLabel = ({ label }: { label: string }): JSX.Element => {
        return <GrayText>{t(label, { currency: isSwedishVersion() ? "SEK" : "€" })}</GrayText>;
    };

    return (
        <>
            <InfoModal
                open={infoModalOpen}
                onClose={() => setInfoModalOpen(false)}
                title={t("apartments.premiumSettings.waterInfoModalTitle")}
                body={t("apartments.premiumSettings.waterInfoModalBody")}
            />
            <InfoModal
                open={energyInfoModalOpen}
                onClose={() => setEnergyInfoModalOpen(false)}
                title={t("apartments.premiumSettings.energyInfoModalTitle")}
                body={t("apartments.premiumSettings.energyInfoModalBody")}
            />
            <Block variant="white" style={{ marginTop: spacings.standardMargin }}>
                {t("apartments.premiumSettings.alarmSettings")}
                <div style={sectionStyling()}>
                    <GrayText>{t("apartments.premiumSettings.emailRecipients")}</GrayText>
                    <GrayText>{t("apartments.premiumSettings.phoneNumbers")}</GrayText>
                </div>
                <div style={sectionStyling(true)}>
                    <p>{t("apartments.premiumSettings.emailRecipientsMoved")}</p>
                    <TextField
                        onBlur={(e) => textWithChipsInput(e.target as HTMLInputElement, "phoneNumbers")}
                        {...textFieldProps}
                        InputProps={{
                            startAdornment: getChips("phoneNumbers"),
                        }}
                        onKeyUp={(e) => textWithChipsInputKeyHandler(e, "phoneNumbers")}
                        onChange={phoneNumbersInputHandler}
                    />
                </div>
                {/* Water price */}
                <>
                    {t("apartments.premiumSettings.priceSettings")}
                    <InfoLink onClick={() => setInfoModalOpen(true)} />
                </>
                <div style={sectionStyling()}>
                    <PriceFieldLabel label="apartments.premiumSettings.warmWaterPrice" />
                    <PriceFieldLabel label="apartments.premiumSettings.coldWaterPrice" />
                </div>
                <div style={sectionStyling(true)}>
                    {createPriceField("warmWaterPrice")}
                    {createPriceField("coldWaterPrice")}
                </div>
                {/* Energy price */}
                <>
                    {t("apartments.premiumSettings.energyPriceSettings")}
                    <InfoLink onClick={() => setEnergyInfoModalOpen(true)} />
                </>
                <div style={sectionStyling()}>
                    <PriceFieldLabel label="apartments.premiumSettings.energyPrice" />
                </div>
                <div style={sectionStyling(true)}>{createPriceField("energyPrice")}</div>
                {/* Billing period */}
                <div style={sectionStyling()}>
                    <GrayText>{t("apartments.premiumSettings.billingStart")}</GrayText>
                    <GrayText>{t("apartments.premiumSettings.billingPeriodMonths")}</GrayText>
                </div>
                <div style={sectionStyling(true)}>
                    <div style={{ marginTop: "10px" }}>
                        <DatePickerComponent
                            value={premiumSettings.billingStart ? new Date(premiumSettings.billingStart) : null}
                            setValue={(date) => {
                                updateLocalSetting(
                                    "billingStart",
                                    date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : "",
                                );
                            }}
                            disableManualTyping={true}
                        />
                    </div>
                    {createPriceField("billingPeriodMonths")}
                </div>
                <div
                    style={{
                        marginTop: "28px",
                        display: "flex",
                        justifyContent: "right",
                    }}
                >
                    <Button onClick={savePremiumSettings} disabled={savePremiumSettingsButtonDisabled}>
                        {t("generic.save")}
                    </Button>
                </div>
                {/*TODO get rid of prop drilling and move components to some central place?*/}
                <SeparatorLine />
                <AlarmCustomization company={company} alarmCustomizations={existingCustomizations} />
                {canSeeCompanyInvoiceSettings && <CompanyInvoiceInfo companyId={company.id} />}
            </Block>
        </>
    );
}
