import { NavigateFunction } from "react-router-dom";
import { Pagination } from "../../components/common/pagination";
import BaseState from "../../interfaces/BaseState";
import { Client } from "../centers/centers-types";
import { MeterType } from "../apartments/apartments-types";

export interface CompaniesState extends BaseState {
    companies: Company[];
    apiKeys: Record<number, string | null>;
    companyBasicInfoModalOpen: boolean;
    pagination: Pagination;
    sortBy: string;
    ascending: boolean;
    alarmTypes: AlarmType[];
    invoiceInfo?: CompanyInvoiceSettings;
}

export type Localization = {
    alarmName: string;
    explanation: string;
};

export type AlarmTypeLocalizations = {
    fi: Localization;
    sv: Localization;
    en: Localization;
};

export type AlarmType = {
    defaultInterval: string;
    requiresPremium: boolean;
    adminOnly: boolean;
    visibleForUser: boolean;
    defaultHighPriority: boolean;
    defaultNotifyOnNew: boolean;
    alarmName: string;
    localizations: AlarmTypeLocalizations;
    supportedMeterTypes: MeterType[];
};

export type Company = {
    name: string;
    premium: boolean;
    streetAddress: string;
    town: string;
    managers: Manager[];
    alarmNames: string[];
    alarmLoadingState: AlarmsLoadingState | undefined;
    id: number;
    zipCode: string;
    apiAccessEnabled: boolean;
    reportFormat: string;
    clients: Client[];
    displayNewPrincipalManagerMessage: boolean;
};

export enum AlarmsLoadingState {
    failed,
    success,
    loading,
}

export type Role = "admin" | "manager" | "viewer" | "no role";

export type Manager = {
    id: number;
    userId: number;
    role: Role;
    principal: boolean;
};

export type ManageCompanyUsersPayload = {
    companyId: number;
    toCreate: CreateNewCompanyUserPayload[];
    toUpdate: UpdateCompanyUserPayload[];
    toDelete: number[];
};

export type CreateNewCompanyUserPayload = {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    principal: boolean;
};

export type UpdateCompanyUserPayload = {
    userId: number;
    role: string;
    principal: boolean;
};

export type FetchAlarmTypesSuccessPayload = AlarmType[];

export type FetchCompaniesSuccessPayload = Company[];

export type FetchCompanyWarningNamesPayload = {
    companyIds: number[];
};

export type FetchCompanyWarningNamesSuccessPayload = {
    companyId: number;
    alarmNames: string[];
};

export type FetchCompanyWarningNamesFailurePayload = FetchCompanyWarningNamesPayload;

export type FetchCompanyApiKeyInput = {
    companyId: number;
};

export type FetchCompanyApiKeySuccessPayload = {
    companyId: number;
    apiKey: string | null;
};

export type RefreshCompanyApiKeyInput = {
    companyId: number;
};

export type RefreshCompanyApiKeySuccessPayload = {
    companyId: number;
    newApiKey: string | null;
};

export type CreateCompanyPayload = {
    name: string;
    streetAddress: string;
    zipCode: string;
    town: string;
    navigate: NavigateFunction;
};

export type SaveCompanyPayload = {
    id: number;
    name: string;
    streetAddress: string;
    zipCode: string;
    town: string;
};

export type UpdateUserRoleAsManagerPayload = {
    companyId: number;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    principal: boolean;
};

export type UpdateUserRolesPayload = {
    companyId: number;
    users: {
        userId: number;
        role: string;
        principal: boolean;
    }[];
};

export type DeleteCompanyUserRolePayload = {
    companyId: number;
    userId: number;
};

export type SortCompaniesPayload = {
    sortBy: string;
    ascending: boolean;
};

export type UpdateApiAccessPayload = {
    companyId: number;
    apiAccessEnabled: boolean;
};

export type PremiumTogglePayload = {
    companyId: number;
    premium: boolean;
};

export type DeleteCompanyPayload = number;

export type MergeApartmentsPayload = {
    companyId: number;
    targetApartmentId: number;
    sourceApartmentIds: number[];
};

export type InvoiceType = "BANKGIROT" | "IBAN";

export type CompanyInvoiceSettings = {
    recipientName?: string;
    bank?: string;
    bic?: string;
    iban?: string;
    bankgirot?: string;
    invoiceType?: InvoiceType;
    contactPerson?: string;
    daysToPay?: number;
    referenceNumberBody?: number;
};

export type SaveCompanyInvoicePayload = {
    companyId: number;
    recipientName: string;
    bank: string;
    bic: string;
    iban: string;
    bankgirot: string;
    invoiceType: string;
    contactPerson: string;
    daysToPay: number;
    referenceNumberBody: number;
};

export type FetchCompanyInvoicePayload = { companyId: number };

export type MarkNewPrincipalManagerMessageAsAcknowledgedPayload = {
    companyId: number;
};

export type NewUser = {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    role: Role;
};
