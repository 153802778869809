import { createAction } from "@reduxjs/toolkit";
import {
    FetchAlarmsHistoryPayload,
    FetchAlarmsHistorySuccessPayload,
    FetchSmokeAlarmsPayload,
    FetchSmokeAlarmsSuccessPayload,
    SortSmokeAlarmsPayload,
} from "./smoke-alarms-types";

export const FetchSmokeAlarmsAction = createAction<FetchSmokeAlarmsPayload>("FETCH_SMOKE_ALARMS");
export const FetchSmokeAlarmsSuccessAction = createAction<FetchSmokeAlarmsSuccessPayload>("FETCH_SMOKE_ALARMS_SUCCESS");
export const FetchSmokeAlarmsFailureAction = createAction<void>("FETCH_SMOKE_ALARMS_FAILURE");

export const FetchAlarmsHistoryAction = createAction<FetchAlarmsHistoryPayload>("FETCH_ALARMS_HISTORY");
export const FetchAlarmsHistorySuccessAction =
    createAction<FetchAlarmsHistorySuccessPayload>("FETCH_ALARMS_HISTORY_SUCCESS");
export const FetchAlarmsHistoryFailureAction = createAction<void>("FETCH_ALARMS_HISTORY_FAILURE");

export const SortSmokeAlarmsAction = createAction<SortSmokeAlarmsPayload>("SORT_ALARMS_HISTORY_ACTION");
export const SmokeAlarmsHistoryPaginationPageChangeAction = createAction<number>(
    "SMOKE_ALARMS_HISTORY_PAGINATION_PAGE_CHANGE",
);
export const SmokeAlarmsHistoryPaginationItemsPerPageChangeAction = createAction<number>(
    "SMOKE_ALARMS_HISTORY_PAGINATION_ITEMS_PER_PAGE_CHANGE",
);
