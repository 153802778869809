import { TableContainer, Table, TableBody, TextField } from "@mui/material";
import DropDown from "../../../components/DropDown";
import { Button } from "../../../components/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../interfaces/RootState";
import React, { useEffect, useState } from "react";
import { AlarmSettings } from "../../../redux/apartments/apartments-types";
import { SaveAlarmSettingsAction } from "../../../redux/apartments/apartments-actions";
import { useHasPrivileges } from "../../../utils/useHasPrivilegesOf";
import { useButtonDisabled } from "../../../utils/useButtonDisabled";
import { TableRowWithLabel } from "../../../components/TableRowWithLabel";

type Props = {
    companyId: number;
};

export default function Alarms({ companyId }: Props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const existingAlarmSettings = useSelector((state: RootState) => state.hydrolink.apartments.alarmSettings);
    const [alarmSettings, setAlarmSettings] = useState<AlarmSettings>(existingAlarmSettings);
    const [editing, setEditing] = useState(false);
    const canUpdateAlarmSettings = useHasPrivileges("can update alarm settings", companyId).hasPrivilege;
    const saveAlarmButtonDisabled = useButtonDisabled(canUpdateAlarmSettings, !editing);

    const languages = [
        { value: "fi", displayText: t("country.finland") },
        { value: "se", displayText: t("country.sweden") },
    ];

    useEffect(() => {
        setAlarmSettings(existingAlarmSettings);
    }, [existingAlarmSettings]);

    // Updates alarm setting in local state
    const updateLocalSetting = (field: keyof AlarmSettings, value: string) => {
        setEditing(true);
        setAlarmSettings({
            ...alarmSettings,
            [field]: value,
        });
    };

    const saveAlarmSettings = () => {
        setEditing(false);
        dispatch(
            SaveAlarmSettingsAction({
                alarmSettings: alarmSettings,
                companyId: companyId,
            }),
        );
    };

    return (
        <>
            <div style={{ width: "50%" }}>
                <TableContainer component="div">
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRowWithLabel htmlFor={"email"} label={t("apartments.report.emailForAlarms") + " "}>
                                <TextField
                                    id="email"
                                    size="small"
                                    value={alarmSettings.stuckEmail}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        updateLocalSetting("stuckEmail", e.target.value)
                                    }
                                />
                            </TableRowWithLabel>
                            <TableRowWithLabel
                                htmlFor={"alarmLanguage"}
                                label={t("apartments.report.alarmLanguage") + " "}
                            >
                                <DropDown
                                    id={"reportLanguage"}
                                    options={languages}
                                    selectedValue={alarmSettings.alarmLang}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        updateLocalSetting("alarmLang", e.target.value)
                                    }
                                />
                            </TableRowWithLabel>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Button onClick={saveAlarmSettings} disabled={saveAlarmButtonDisabled}>
                {t("generic.save")}
            </Button>
        </>
    );
}
