import { useTranslation } from "react-i18next";
import ExpandableBlock from "../../components/ExpandableBlock";
import { ViewContainer } from "../../components/ViewContainer";
import ActiveSmokeAlarms from "./ActiveSmokeAlarms";
import { Block } from "../../components/common/block";
import { spacings } from "../../theme";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import { useEffect } from "react";
import { FetchSmokeAlarmsAction } from "../../redux/smoke-alarms/smoke-alarms-actions";
import { FetchAlarmTypesAction } from "../../redux/companies/companies-actions";
import SmokeAlarmsHistory from "./SmokeAlarmsHistory";

export default function SmokeAlarmsView() {
    const params = useParams();
    const companyId = parseInt(params.companyId ?? "0");
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const smokeAlarms = useSelector((state: RootState) => state.hydrolink.smokeAlarms.alarms);
    const alarmHistoryEvents = useSelector((state: RootState) => state.hydrolink.smokeAlarms.alarmHistoryEvents);
    const alarmTypes = useSelector((state: RootState) => state.hydrolink.companies.alarmTypes);
    const currentSorting = useSelector((state: RootState) => state.hydrolink.smokeAlarms.sortBy);
    const currentAscending = useSelector((state: RootState) => state.hydrolink.smokeAlarms.ascending);

    useEffect(() => {
        dispatch(FetchSmokeAlarmsAction({ companyId: companyId }));
        dispatch(FetchAlarmTypesAction());
    }, [companyId, dispatch]);

    return (
        <ViewContainer>
            <ExpandableBlock leftSide={t("smokeAlarms.activeAlarms")} grey>
                <Block variant="white" style={{ marginTop: spacings.inlineSpacing }}>
                    <ActiveSmokeAlarms activeSmokeAlarms={smokeAlarms} companyId={companyId} alarmTypes={alarmTypes} />
                </Block>
            </ExpandableBlock>
            <ExpandableBlock leftSide={t("smokeAlarms.history")} grey>
                <Block variant="white" style={{ marginTop: spacings.inlineSpacing }}>
                    <SmokeAlarmsHistory
                        alarmHistoryEvents={alarmHistoryEvents}
                        sortByField={currentSorting}
                        isAscending={currentAscending}
                        alarmTypes={alarmTypes}
                    />
                </Block>
            </ExpandableBlock>
        </ViewContainer>
    );
}
