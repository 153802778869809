import { spacings } from "../theme";

interface BlockProps {
    buttons: JSX.Element[];
    noTopMargin?: boolean;
}

const FloatButtonsRight = (props: BlockProps) => {
    const button = (b: JSX.Element, i: number) => (
        <div key={i} style={{ display: "flex", justifyContent: "space-around" }}>
            {b}
            <div style={{ marginLeft: spacings.standardMargin }} />
        </div>
    );

    return (
        <div
            style={{
                display: "flex",
                float: "right",
                marginTop: !props.noTopMargin ? spacings.standardMargin : "0px",
            }}
        >
            {props.buttons.map((b, i) => button(b, i))}
        </div>
    );
};

export default FloatButtonsRight;
