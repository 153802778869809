import { ManageCompanyUsersPayload, Manager } from "../../../../redux/companies/companies-types";
import { colors } from "../../../../theme";
import { User } from "../../../../redux/users/users-types";
import { CompanyUserWithState, EditState } from "./listEditor";
import { CompanyUser } from "./CompanyUser";

export const getCompanyUserSortFunction = (sortKey: string): ((manager: CompanyUserWithState) => string) => {
    const sortKeyMap: { [key: string]: (user: CompanyUserWithState) => string } = {
        firstName: (companyUser) => companyUser.user.firstName,
        lastName: (companyUser) => companyUser.user.lastName,
        email: (companyUser) => companyUser.user.email,
    };

    return sortKeyMap[sortKey] || (() => "");
};

export const sortManagersById = (companyManagers: Manager[]): Manager[] => {
    return [...companyManagers].sort((a, b) => a.id - b.id);
};

export const withUserInfo = (users: User[], manager: Manager): CompanyUser | undefined => {
    const user = users.find((user) => user.id === manager.userId);
    return user
        ? {
              userId: manager.userId,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              role: manager.role,
              principal: manager.principal,
          }
        : undefined;
};

export const getEditStateColor = (managerState: EditState): string => {
    const actionColors = {
        neutralColor: colors.neutral00,
        addColor: colors.success06,
        removeColor: colors.warm06,
    };
    if (managerState.toBeDeleted) return actionColors.removeColor;
    if (managerState.toBeCreated || managerState.toBeUpdated) return actionColors.addColor;
    return actionColors.neutralColor;
};

export const createCompanyUsersPayload = (
    companyId: number,
    editedUsers: CompanyUserWithState[],
): ManageCompanyUsersPayload => {
    return editedUsers.reduce<ManageCompanyUsersPayload>(
        (payload, companyUser) => {
            const { state, user } = companyUser;

            if (state.toBeCreated) {
                payload.toCreate.push({
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    role: user.role,
                    principal: user.principal,
                });
            } else if (state.toBeUpdated) {
                payload.toUpdate.push({
                    userId: user.userId,
                    role: user.role,
                    principal: user.principal,
                });
            }
            if (state.toBeDeleted) {
                payload.toDelete.push(user.userId);
            }
            return payload;
        },
        { companyId, toCreate: [], toUpdate: [], toDelete: [] },
    );
};
