import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../components/Button";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import { useFilePicker } from "use-file-picker";
import UploadIcon from "@mui/icons-material/Upload";
import { MassMarkPrincipalsWithCSVAction } from "../../redux/users/users-actions";

export default function MassMarkPrincipalsButton() {
    const dispatch = useDispatch();
    const t = useTranslation().t;
    const [openFileSelector, { filesContent, loading, clear }] = useFilePicker({
        accept: [".csv", ".CSV"],
        multiple: false,
    });

    const canUpload = () => !loading && filesContent.length > 0;

    const handleUpload = () => {
        if (!canUpload) {
            return;
        }

        clear(); // Clear files from file picker

        dispatch(MassMarkPrincipalsWithCSVAction({ file: filesContent[0].content }));
    };

    return canUpload() ? (
        <Button onClick={handleUpload} startIcon={<UploadIcon />}>
            {t("generic.upload")} {filesContent[0].name}
        </Button>
    ) : (
        <Button onClick={openFileSelector} startIcon={<ImportContactsIcon />}>
            {t("users.massMarkPrincipalsFromCsv")}
        </Button>
    );
}
