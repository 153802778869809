import { Modal } from "../../../components/common/modal/Modal";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
    LineElement,
    PointElement,
    TimeScale,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../interfaces/RootState";
import { Button } from "../../../components/Button";
import { DownloadMeterUsageReportAction, OpenChartAction } from "../../../redux/apartments/apartments-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import {
    Apartment,
    ChartState,
    ChartType,
    Meter,
    MeterHistoryReading,
    MeterHistoryType,
} from "../../../redux/apartments/apartments-types";
import { Alert, FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { displayDate, displayDateTime, getNextYearOffsetDays, getNextMonthOffsetDays } from "../../../utils/utils";
import { alignments, barChartStyles, colors, spacings } from "../../../theme";
import DropDown from "../../../components/DropDown";
import React, { useEffect, useState } from "react";

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, TimeScale, Tooltip, Legend);

type Dataset = {
    meter: Meter;
    label: string;
    data: Data[];
    dataUnit: String;
    backgroundColor: string;
    borderColor?: string;
    borderRadius: number;
    beforeTitle: string;
};
type SortedDataset = {
    label: string;
    data: Data[];
    dataUnit: String;
    backgroundColor: string;
    borderColor?: string;
    borderRadius: number;
    beforeTitle: string;
};

type Sorted = {
    labels: number[];
    datasets: SortedDataset[];
};

type ChartDataType = {
    labels: string[];
    datasets: SortedDataset[];
};

type Data = number;

type TemporalDataSet = {
    meterId: string;
    value: number;
};

type TemporalDateAndDatasetObject = {
    date: number;
    datasets: TemporalDataSet[];
};

const options = {
    responsive: true,
    scales: {
        y: {
            beginAtZero: true,
        },
    },
    plugins: {
        tooltip: {
            callbacks: {
                beforeTitle: function (context: any) {
                    return context[0].dataset.beforeTitle;
                },
                label: function (context: any) {
                    let label = context.dataset.label || "";
                    const meterType = context.dataset.dataUnit;

                    if (label) {
                        label += ": ";
                    }
                    if (context.parsed.y !== null) {
                        label += `${context.parsed.y} ${meterType}`;
                    }
                    return label;
                },
            },
        },
        legend: {
            position: "top" as const,
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart",
        },
    },
};

const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const displayDates = (dates: number[], chartState: ChartState, t: TFunction<"translation", undefined>) => {
    const labels: string[] = [];
    for (let d of dates) {
        let str = "";
        if (chartState.type === "year") {
            str = t("date." + monthNames[new Date(d).getMonth()]).substring(0, 3) + " " + new Date(d).getFullYear();
        } else if (chartState.type === "month") {
            str = displayDate(new Date(d));
        } else {
            str = displayDateTime(new Date(d));
        }
        labels.push(str);
    }
    return labels;
};

const getDatasetName = (meter: Meter | undefined, t: TFunction<"translation", undefined>): string => {
    if (meter?.type === "water") {
        return meter?.warm ? t("meters.warmWater") : t("meters.coldWater");
    }
    if (meter?.type === "energy") {
        if (meter?.heating) return t("meters.values.heating");
        if (meter?.cooling) return t("meters.values.cooling");
        else return t("meters.values.electricity");
    }
    if (meter?.type === "ambient") {
        return meter?.humidity ? t("meters.values.humidity") : t("meters.values.temperature");
    }
    return "";
};

const getUnitForMeterType = (t: TFunction<"translation", undefined>, meter: Meter): string => {
    if (meter.type === "water") {
        return t("meters.units.water");
    } else if (meter.type === "energy") {
        return t("meters.units.energy");
    } else if (meter.type === "ambient" && !meter.humidity) {
        return t("meters.units.ambient");
    } else if (meter.type === "ambient" && meter.humidity) {
        return t("meters.units.humidity");
    }
    return "";
};

const getBarColor = (meter: Meter | undefined): string => {
    if (meter?.type === "water") {
        return meter?.warm ? colors.warm06 : colors.cold06;
    }
    if (meter?.type === "energy") {
        if (meter.heating) return colors.warm06;
        if (meter.cooling) return colors.cold06;
        return colors.electricity06;
    }
    if (meter?.type === "ambient") {
        return meter.humidity ? colors.cold06 : colors.warm06;
    }
    return colors.cold06;
};

const getOffsetDays = (type: ChartType, offset: number, direction: number) => {
    switch (type) {
        case "day":
            return -direction;
        case "week":
            return -direction * 7;
        case "month":
            return getNextMonthOffsetDays(offset, new Date(), direction);
        case "year":
            return getNextYearOffsetDays(offset, new Date(), direction);
    }
};

const bothMetersShareType = (a: Meter, b: Meter) =>
    (a.type === "water" && b.type === "water") ||
    (a.type === "energy" && b.type === "energy") ||
    (a.type === "ambient" && b.type === "ambient");

const aWarmAndBCold = (a: Meter, b: Meter) =>
    (a.warm && !b.warm) || (a.heating && !b.heating) || (!a.humidity && b.humidity);

const sortWarmValuesFirst = (datasets: Dataset[]): SortedDataset[] => {
    let sortedDatasets: SortedDataset[] = [];
    datasets.sort(function (a, b) {
        if (bothMetersShareType(a.meter, b.meter) && aWarmAndBCold(a.meter, b.meter)) return -1;
        else return 0;
    });
    for (let d of datasets) {
        sortedDatasets.push({
            label: d.label,
            data: d.data,
            dataUnit: d.dataUnit,
            backgroundColor: d.backgroundColor,
            borderColor: d.borderColor,
            borderRadius: d.borderRadius,
            beforeTitle: d.beforeTitle,
        });
    }
    return sortedDatasets;
};

const sort = (dates: number[], datasets: Dataset[]) => {
    let temporalDateandDatasetArray: TemporalDateAndDatasetObject[] = [];
    let sortedDates: number[] = [];
    let targetDatasets: Dataset[] = JSON.parse(JSON.stringify(datasets));

    for (let s of targetDatasets) {
        //Empties the data arrays of the target datasets
        s.data = [];
    }

    dates.forEach((date, i) => {
        //Creates an array of objects which includes both;
        temporalDateandDatasetArray.push(
            //the dates and the data arrays of the datasets
            {
                date: date,
                datasets: getDatasets(datasets, i),
            },
        );
    });

    temporalDateandDatasetArray.sort((a, b) => {
        //Sorts the temporal array
        return a.date < b.date ? -1 : a.date === b.date ? 0 : 1;
    });

    for (let a of temporalDateandDatasetArray) {
        //Converts the temporal array back into the originals forms
        sortedDates.push(a.date);
        for (let d of a.datasets) {
            targetDatasets.find((da) => da.meter.id === d.meterId)?.data.push(d.value);
        }
    }
    const returnObj: Sorted = {
        labels: sortedDates,
        datasets: sortWarmValuesFirst(targetDatasets),
    };
    return returnObj;
};

const dateNotAlreadyIncludedInArray = (dates: number[], date: Date) => dates.indexOf(new Date(date).getTime()) === -1;

const createNewDataset = (meter: Meter, t: TFunction<"translation", undefined>) => {
    let dataset: Dataset = {
        meter: meter,
        label: "Dataset",
        data: [],
        dataUnit: getUnitForMeterType(t, meter),
        beforeTitle: t("meters.code") + ": " + meter.code,
        backgroundColor: getBarColor(meter),
        borderColor: getBarColor(meter),
        ...barChartStyles,
    };
    return dataset;
};

const addValues = (m: MeterHistoryReading, datasetName: string, dataset: Dataset, dates: number[]) => {
    dataset.label = datasetName;
    dataset.data.push(m.value ? Math.round(m.value) : 0);
    if (dateNotAlreadyIncludedInArray(dates, m.created)) {
        let d = new Date(m.created).getTime();
        dates.push(d);
    }
    return {
        dataset: dataset,
        dates: dates,
    };
};

const getChartData = (
    usageData: Record<number, MeterHistoryReading[]>,
    meters: Meter[],
    chartState: ChartState,
    t: TFunction<"translation", undefined>,
    isWaterMeter: boolean,
    combinedView: boolean,
): ChartDataType => {
    let dates: number[] = [];
    let datasets: Dataset[] = [];

    for (let meterId in usageData) {
        const meter = meters.find((m: Meter) => m.id === meterId);
        if (meter) {
            let datasetName = getDatasetName(meter, t);
            let dataset = createNewDataset(meter, t);
            let sortedUsageData = [...usageData[meterId]];
            sortedUsageData.sort((a, b) => (a.created < b.created ? -1 : a.created === b.created ? 0 : 1));
            for (let m of sortedUsageData) {
                let newValues = addValues(m, datasetName, dataset, dates);
                dataset = newValues.dataset;
                dates = newValues.dates;
            }
            datasets.push(dataset);
        }
    }
    if (combinedView && isWaterMeter) {
        datasets = merge(datasets);
    }

    const sortedData: Sorted = sort(dates, datasets);
    return {
        labels: displayDates(sortedData.labels, chartState, t),
        datasets: sortedData.datasets,
    };
};

const merge = (datasets: Dataset[]): Dataset[] => {
    let mergedDataSet: Dataset[] = [];

    datasets.forEach((d) => {
        const label = d.label;
        let alreadyIncluded = false;
        for (let m of mergedDataSet) {
            if (m.label === label) {
                alreadyIncluded = true;
                for (let i = 0; m.data.length > i; i++) {
                    m.data[i] += d.data[i];
                }
                m.beforeTitle += " / " + d.meter.code;
            }
        }
        if (!alreadyIncluded) {
            mergedDataSet.push(d);
        }
    });
    return mergedDataSet;
};

const getDatasets = (dataset: Dataset[], i: number): TemporalDataSet[] => {
    const temporalDataset: TemporalDataSet[] = [];
    for (let d of dataset) {
        temporalDataset.push({
            meterId: d.meter.id,
            value: d.data[i],
        });
    }
    return temporalDataset;
};

const isWaterMeter = (meterType: MeterHistoryType | null): boolean => meterType === "water";

type BarChartType = ChartData<"bar", number[], string>;
type LineGraphType = ChartData<"line", number[], string>;

type Props = {
    isOpen: boolean;
    close: () => void;
};

const emptyChartData: ChartDataType = {
    labels: [],
    datasets: [],
};

function ConsumptionChart({
    useLineGraph,
    chartData,
}: {
    useLineGraph: boolean;
    chartData: ChartDataType;
}): JSX.Element {
    if (useLineGraph) {
        const lineGraphData: LineGraphType = chartData;
        return <Line options={options} data={lineGraphData} />;
    } else {
        const barChartData: BarChartType = chartData;
        return <Bar options={options} data={barChartData} />;
    }
}

export function ChartModal({ isOpen, close }: Props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const getDialogActions = () => <></>;

    const day = { value: "day", displayText: t("meters.day") };
    const week = { value: "week", displayText: t("meters.week") };
    const month = { value: "month", displayText: t("meters.month") };
    const year = { value: "year", displayText: t("meters.year") };

    const chartState = useSelector((state: RootState) => state.hydrolink.apartments.chartState);
    const isViewingTemperatureMeters = chartState.meterType === "ambient";
    const usageData = useSelector((state: RootState) => state.hydrolink.apartments.usageByMeterId);

    const apartment = useSelector((state: RootState) =>
        state.hydrolink.apartments.apartments.find((a: Apartment) => a.id === chartState.apartmentId),
    );
    let dayMonthDropDownOptions: { value: string; displayText: string }[] = [];
    const selectedMeterViewOptions = [
        { value: "combined", displayText: t("meters.combined") },
        { value: "separated", displayText: t("meters.separated") },
    ];

    const [combinedView, setCombinedView] = React.useState(true);
    const meters = apartment?.meters;
    const hasVisibleData = Object.keys(usageData).length > 0;
    const [chartData, setChartData] = useState<ChartDataType>(emptyChartData);

    useEffect(() => {
        setChartData(
            getChartData(usageData, meters ?? [], chartState, t, isWaterMeter(chartState.meterType), combinedView),
        );
    }, [usageData, chartState, meters, t, combinedView]);

    const changeDayMonthView = (e: any) => {
        dispatch(
            OpenChartAction({
                apartmentId: chartState.apartmentId ?? 0,
                type: e.target.value,
                offset: chartState.offsetDays,
                meterType: chartState.meterType,
            }),
        );
    };

    const downloadUsageReport = () => {
        if (chartState.apartmentId && chartState.meterType && !isViewingTemperatureMeters) {
            dispatch(
                DownloadMeterUsageReportAction({
                    apartmentId: chartState.apartmentId,
                    type: chartState.type,
                    offset: chartState.offsetDays,
                    meterType: chartState.meterType,
                }),
            );
        }
    };

    const getMeterView = () => {
        if (combinedView) return "combined";
        return "separated";
    };

    const changeMeterView = () => {
        setCombinedView(!combinedView);
    };

    let title = "";
    let titleAddition = "";
    switch (chartState.meterType) {
        case "ambient":
            dayMonthDropDownOptions = [day, week, month];
            titleAddition = t("meters.titles.temperature");
            break;
        case "energy":
            dayMonthDropDownOptions = [month, year];
            titleAddition = t("meters.titles.energy");
            break;
        case "water":
            dayMonthDropDownOptions = [month, year];
            titleAddition = t("meters.titles.water");
            break;
    }
    title = t("meters.apartments") + " " + apartment?.code + " " + titleAddition;

    return (
        <Modal title={title} open={isOpen} dialogActions={getDialogActions()} closeModal={close}>
            <div
                style={{
                    ...alignments.evenHorizontal,
                    flexDirection: "row",
                }}
            >
                <div></div>
                <div
                    style={{
                        ...alignments.evenHorizontal,
                        flexDirection: "column",
                    }}
                >
                    <FormControl>
                        <DropDown
                            id={"day-month"}
                            options={dayMonthDropDownOptions}
                            selectedValue={chartState.type}
                            onChange={changeDayMonthView}
                        />
                    </FormControl>
                    {isWaterMeter(chartState.meterType) && (
                        <FormControl style={{ marginTop: spacings.inlineSpacing }}>
                            <DropDown
                                id={"all-invidual"}
                                options={selectedMeterViewOptions}
                                selectedValue={getMeterView()}
                                onChange={changeMeterView}
                            />
                        </FormControl>
                    )}
                </div>
            </div>
            <div>
                {!hasVisibleData && (
                    <Alert severity="warning" style={{ margin: spacings.inlineSpacing }}>
                        {t("meters.noData")}
                    </Alert>
                )}
                <ConsumptionChart useLineGraph={isViewingTemperatureMeters} chartData={chartData} />
            </div>
            <div style={{ ...alignments.evenHorizontal }}>
                <Button
                    variant="outlined"
                    onClick={() =>
                        dispatch(
                            OpenChartAction({
                                apartmentId: chartState.apartmentId ?? 0,
                                type: chartState.type,
                                offset:
                                    chartState.offsetDays + getOffsetDays(chartState.type, chartState.offsetDays, -1),
                                meterType: chartState.meterType,
                            }),
                        )
                    }
                >
                    <FontAwesomeIcon icon={faAngleLeft} size="1x" />
                </Button>
                {!isViewingTemperatureMeters && (
                    <Button variant="outlined" onClick={downloadUsageReport}>
                        <FontAwesomeIcon icon={faDownload} size="1x" />
                    </Button>
                )}
                <Button
                    variant="outlined"
                    onClick={() =>
                        dispatch(
                            OpenChartAction({
                                apartmentId: chartState.apartmentId ?? 0,
                                type: chartState.type,
                                offset:
                                    chartState.offsetDays + getOffsetDays(chartState.type, chartState.offsetDays, +1),
                                meterType: chartState.meterType,
                            }),
                        )
                    }
                >
                    <FontAwesomeIcon icon={faAngleRight} size="1x" />
                </Button>
            </div>
        </Modal>
    );
}
