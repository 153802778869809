import React from "react";
import { borders, colors, spacings } from "../../../theme";

interface BlockProps {
    variant: "white" | "grey";
    style?: React.CSSProperties;
    children?: React.ReactNode;
    noMargin?: boolean;
    noBottomBorder?: boolean;
    onClick?: () => void; //what happens when you click the "open" icon
    dataTestId?: string;
}

type ClickPosition = {
    x: number;
    y: number;
};

export function Block(props: BlockProps) {
    const [mouseDownPos, setMouseDownPos] = React.useState<ClickPosition>({ x: -1, y: -1 });

    let bgColor = "#FFFFFF";

    if (props.variant === "grey") {
        bgColor = colors.neutral04;
    }
    let defaultStyle = {
        padding: spacings.standardSpacing,
        backgroundColor: bgColor,
        borderRadius: borders.defaultBorderRadius,
        borderBottom: props.noBottomBorder ? "" : borders.basicOutline.border,
        borderColor: colors.neutral04,
        marginBottom: props.noMargin ? "0" : spacings.standardSpacing,
    };

    const onMouseDown = (e: React.MouseEvent) => {
        setMouseDownPos({ x: e.clientX, y: e.clientY });
    };

    const onClick = (e: React.MouseEvent) => {
        // Do not call `onClick` if mouse has been dragged away from when it was first held down.
        // This fixes the apartment renaming bug where the apartment closes if you first hold down
        // your mouse1 at the renaming input field and then move the mouse to a Block element and release mouse1
        if (props.onClick !== undefined && mouseDownPos.x === e.clientX && mouseDownPos.y === e.clientY) {
            props.onClick();
        }

        setMouseDownPos({ x: -1, y: -1 });
    };

    return (
        <div
            onMouseDown={onMouseDown}
            onClick={onClick}
            style={{ ...defaultStyle, ...(props.style ?? {}) }}
            data-testid={props.dataTestId}
        >
            {props.children}
        </div>
    );
}
