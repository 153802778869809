import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { alignments } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import { useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useHasPrivileges } from "../../utils/useHasPrivilegesOf";
import { useEffect } from "react";
import { FetchCompaniesWithoutAlarmsAction } from "../../redux/companies/companies-actions";

export enum Tabs {
    COMPANY,
    REPORTS,
    INITIALIZATIONREPORT,
    CENTERS,
    RESIDENTS,
    NOTES,
    SMOKEALARMS,
}

type TabObject = {
    tab: Tabs;
    title: string;
    link: string;
    adminOnly: boolean;
    requiresPremium: boolean;
};

const tabs: TabObject[] = [
    {
        tab: Tabs.COMPANY,
        title: "companies.tabs.company",
        link: "./../",
        adminOnly: false,
        requiresPremium: false,
    },
    {
        tab: Tabs.REPORTS,
        title: "companies.tabs.reports",
        link: "./../reports",
        adminOnly: false,
        requiresPremium: false,
    },
    {
        tab: Tabs.INITIALIZATIONREPORT,
        title: "companies.tabs.initializationReport",
        link: "./../initialization-report",
        adminOnly: true,
        requiresPremium: false,
    },
    {
        tab: Tabs.CENTERS,
        title: "companies.tabs.centers",
        link: "./../centers",
        adminOnly: true,
        requiresPremium: false,
    },
    {
        tab: Tabs.RESIDENTS,
        title: "companies.tabs.residents",
        link: "./../residents",
        adminOnly: false,
        requiresPremium: true,
    },
    {
        tab: Tabs.NOTES,
        title: "companies.tabs.notes",
        link: "./../notes",
        adminOnly: true,
        requiresPremium: false,
    },
    {
        tab: Tabs.SMOKEALARMS,
        title: "companies.tabs.smokeAlarms",
        link: "./../smokeAlarms",
        adminOnly: false,
        requiresPremium: true,
    },
];

export default function CompanyTabs({ tab: currentTab }: { tab: Tabs }) {
    const t = useTranslation().t;
    const params = useParams();
    const dispatch = useDispatch();
    const translatedTitle = t(tabs[currentTab].title);
    const companyId = params.companyId ? parseInt(params.companyId) : undefined;

    useEffect(() => {
        dispatch(FetchCompaniesWithoutAlarmsAction());
    }, [companyId, dispatch]);

    const canSeeAllCompanyTabs = useHasPrivileges("can see admin company tabs", companyId).hasPrivilege;

    const hasPremium: boolean = useSelector((state: RootState) => {
        if (companyId !== undefined) {
            return state.hydrolink.companies.companies.find((c) => c.id === companyId)?.premium ?? false;
        }
        return false;
    });

    const companyName = useSelector((state: RootState) => {
        if (params.companyId !== undefined) {
            return state.hydrolink.companies.companies.find((c) => c.id === companyId)?.name;
        }
        return undefined;
    });

    const shouldBeDisabled = (tabObject: TabObject) => (tabObject.requiresPremium ? !hasPremium : false);

    const getTabLink = (tabObject: TabObject) => {
        if (shouldBeDisabled(tabObject)) {
            return "./";
        }

        return currentTab === Tabs.COMPANY ? tabObject.link.replace("../", "./") : tabObject.link;
    };

    const companyTabTooltip = (tabObject: TabObject) => {
        if (!shouldBeDisabled(tabObject)) return "";
        return t("errors.premiumRequired");
    };

    return (
        <div style={alignments.evenHorizontal}>
            <h2>
                {companyName} | {translatedTitle}
            </h2>
            <div style={alignments.rightSide}>
                {tabs
                    .filter((tab) => canSeeAllCompanyTabs || !tab.adminOnly)
                    .map((tabObject) => (
                        <Tooltip key={tabObject.title} title={companyTabTooltip(tabObject)}>
                            <Link draggable="false" to={getTabLink(tabObject)} key={tabObject.title}>
                                <Button
                                    variant="text"
                                    disabled={shouldBeDisabled(tabObject)}
                                    style={{
                                        border: "hidden",
                                        textTransform: "none",
                                        fontWeight: tabObject.tab === currentTab ? "bold" : "normal",
                                    }}
                                >
                                    {t(tabObject.title)}
                                </Button>
                            </Link>
                        </Tooltip>
                    ))}
            </div>
        </div>
    );
}
