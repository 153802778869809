import { useEffect } from "react";

/**
 * Calls {@link callback} periodically. It is called every {@link periodMillis} milliseconds (after the last render)
 * until the component is destroyed.
 *
 * @param callback Function to call.
 * @param periodMillis Period in milliseconds between calls.
 */
export function usePeriodicCallback(callback: () => void, periodMillis: number) {
    useEffect(() => {
        const interval = setInterval(() => callback(), periodMillis);
        return () => clearInterval(interval);
    }, [callback, periodMillis]);
}
