import { useTranslation } from "react-i18next";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { useDispatch } from "react-redux";
import { table } from "../../../../theme";
import { SortUsersAction } from "../../../../redux/users/users-actions";
import Sorter from "../../../../components/Sorter";
import { useAppSelector } from "../../../../utils/store-helpers";

const ManagerTableHead = (): JSX.Element => {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const currentSorting = useAppSelector((state) => state.hydrolink.users.sortBy);
    const currentAscending = useAppSelector((state) => state.hydrolink.users.ascending);

    const dispatchSortManagersAction = (sortBy: string, ascending: boolean) => {
        dispatch(
            SortUsersAction({
                sortBy: sortBy,
                ascending: ascending,
            }),
        );
    };

    return (
        <TableHead>
            <TableRow>
                <Sorter
                    sortKey={"firstName"}
                    sortAction={dispatchSortManagersAction}
                    sortBy={currentSorting}
                    title={t("users.firstName")}
                    width={table.tableCellWidthExtraSmall}
                    currentAscending={currentAscending}
                />
                <Sorter
                    sortKey={"lastName"}
                    sortAction={dispatchSortManagersAction}
                    sortBy={currentSorting}
                    title={t("users.lastName")}
                    width={table.tableCellWidthExtraSmall}
                    currentAscending={currentAscending}
                />
                <Sorter
                    sortKey={"email"}
                    sortAction={dispatchSortManagersAction}
                    sortBy={currentSorting}
                    title={t("users.email")}
                    width={table.tableCellWidthExtraSmall}
                    currentAscending={currentAscending}
                />
                <TableCell>{t("users.role")}</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
    );
};

export default ManagerTableHead;
