import { AnyAction } from "redux";
import { stateWithLoadingFalse, stateWithLoadingTrue } from "../../utils/utils";
import { Company, Manager, Role } from "../companies/companies-types";
import {
    UpdateUserAdminAction,
    UpdateUserAdminSuccessAction,
    UpdateUserSuccessAction,
    FetchUsersAction,
    FetchUsersSuccessAction,
    UpdateUserAction,
    OpenCreateUserModalAction,
    CloseCreateUserModalAction,
    DeleteUserSuccessAction,
    UsersPaginationPageChangeAction,
    UsersPaginationItemsPerPageChangeAction,
    DeleteUserAction,
    CreateUserAction,
    CreateUserSuccessAction,
    CreateNewUserPasswordAction,
    CreateNewUserPasswordSuccessAction,
    UpdateUserEmailAction,
    UpdateUserEmailSuccessAction,
    FetchUsersFailureAction,
    UpdateUserFailureAction,
    UpdateUserAdminFailureAction,
    CreateUserFailureAction,
    CreateNewUserPasswordFailureAction,
    UpdateUserEmailFailureAction,
    DeleteUserFailureAction,
    SortUsersAction,
    MassMarkPrincipalsWithCSVAction,
    MassMarkPrincipalsWithCSVSuccessAction,
    MassMarkPrincipalsWithCSVFailureAction,
} from "./users-actions";
import { CreateUserModalState, User, UsersState } from "./users-types";

const initialState: UsersState = {
    createUserModalState: CreateUserModalState.Closed,
    users: [],
    pagination: {
        currentPage: 1,
        itemsPerPage: 10,
    },
    loading: 0,
    sortBy: "userFullName",
    ascending: true,
};

export const getCompanyManagerByUser = (company: Company, user: User): Manager | undefined =>
    company.managers.find((i: Manager) => i.userId === user.id);

// Returns the highest role for user out of a list of companies where the user is
export const getHighestRoleForUser = (companies: Company[], user: User): Role => {
    let roles = companies
        .map((c: Company) => getCompanyManagerByUser(c, user)?.role)
        .filter((i) => i !== undefined) as Role[];
    return roles.sort()[0];
};

const actions = (state: UsersState, action: AnyAction) => {
    if (FetchUsersAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (UpdateUserAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (UpdateUserAdminAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (CreateUserAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (CreateNewUserPasswordAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (UpdateUserEmailAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (DeleteUserAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (MassMarkPrincipalsWithCSVAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    return state;
};

const successActions = (state: UsersState, action: AnyAction) => {
    if (FetchUsersSuccessAction.match(action)) {
        const sortedPayload = [...action.payload];
        sortedPayload.sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName));
        return {
            ...stateWithLoadingFalse(state),
            users: sortedPayload,
        };
    }

    if (UpdateUserSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpdateUserAdminSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (CreateUserSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (CreateNewUserPasswordSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpdateUserEmailSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteUserSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (MassMarkPrincipalsWithCSVSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
};

const failureActions = (state: UsersState, action: AnyAction) => {
    if (FetchUsersFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpdateUserFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpdateUserAdminFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (CreateUserFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (CreateNewUserPasswordFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (UpdateUserEmailFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteUserFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (MassMarkPrincipalsWithCSVFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
};

const otherActions = (state: UsersState, action: AnyAction) => {
    if (SortUsersAction.match(action)) {
        return {
            ...state,
            sortBy: action.payload.sortBy,
            ascending: action.payload.ascending,
        };
    }

    if (OpenCreateUserModalAction.match(action)) {
        return {
            ...state,
            createUserModalState: action.payload,
        };
    }

    if (CloseCreateUserModalAction.match(action)) {
        return {
            ...state,
            createUserModalState: CreateUserModalState.Closed,
        };
    }

    if (UsersPaginationPageChangeAction.match(action)) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                currentPage: action.payload,
            },
        };
    }

    if (UsersPaginationItemsPerPageChangeAction.match(action)) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                itemsPerPage: action.payload,
            },
        };
    }
    return state;
};

export default function usersReducer(state: UsersState = initialState, action: AnyAction): UsersState {
    state = actions(state, action);
    state = successActions(state, action);
    state = failureActions(state, action);
    state = otherActions(state, action);

    return state;
}
