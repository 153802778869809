import { combineReducers, Reducer } from "redux";
import authReducer from "./auth/auth-reducer";
import errorReducer from "./error/error-reducer";
import AppState from "../interfaces/AppState";
import { BrowserHistory } from "history";
import messagesReducer from "./messages/messages-reducer";
import companiesReducer from "./companies/companies-reducer";
import apartmentsReducer from "./apartments/apartments-reducer";
import usersReducer from "./users/users-reducer";
import residentsReducer from "./residents/residents-reducer";
import centersReducer from "./centers/centers-reducer";
import notesReducer from "./notes/notes-reducer";
import reportsReducer from "./reports/reports-reducer";
import contractsReducer from "./contracts/contracts-reducer";
import smokeAlarmsReducer from "./smoke-alarms/smoke-alarms-reducer";

export const hydrolink = combineReducers({
    auth: authReducer,
    error: errorReducer,
    users: usersReducer,
    centers: centersReducer,
    messages: messagesReducer,
    companies: companiesReducer,
    apartments: apartmentsReducer,
    residents: residentsReducer,
    notes: notesReducer,
    contracts: contractsReducer,
    reports: reportsReducer,
    smokeAlarms: smokeAlarmsReducer,
});

function createRootReducer(_history: BrowserHistory): Reducer<AppState> {
    return combineReducers({
        hydrolink,
    });
}

export default createRootReducer;
