import { createAction } from "@reduxjs/toolkit";
import {
    CreateNewResidentPasswordPayload,
    Credentials,
    DeleteResidentPayload,
    DownloadResidentsListPayload,
    FetchResidentsPayload,
    FetchResidentsSuccessPayload,
    Resident,
    SortResidentApartmentPayload,
    UploadResidentsPayload,
    UpsertResidentPayload,
    UpsertResidentSuccessPayload,
} from "./residents-types";

export const FetchResidentsAction = createAction<FetchResidentsPayload>("FETCH_RESIDENTS");
export const FetchResidentsSuccessAction = createAction<FetchResidentsSuccessPayload>("FETCH_RESIDENTS_SUCCESS");
export const FetchResidentsUnauthorizedAction = createAction<string>("FETCH_RESIDENTS_UNAUTHORIZED");
export const FetchResidentsFailureAction = createAction<void>("FETCH_RESIDENTS_FAILURE");

export const UpsertResidentAction = createAction<UpsertResidentPayload>("UPSERT_RESIDENT");
export const UpsertResidentSuccessAction = createAction<UpsertResidentSuccessPayload>("UPSERT_RESIDENT_SUCCESS");
export const UpsertResidentFailureAction = createAction<string>("UPSERT_RESIDENT_FAILURE");

export const DeleteResidentAction = createAction<DeleteResidentPayload>("DELETE_RESIDENT");
export const DeleteResidentSuccessAction = createAction<void>("DELETE_RESIDENT_SUCCESS");
export const DeleteResidentFailureAction = createAction<void>("DELETE_RESIDENT_FAILURE");

export const SortResidentsAction = createAction<SortResidentApartmentPayload>("SORT_USERS");

export const ResidentsPaginationPageChangeAction = createAction<number>("RESIDENTS_PAGINATION_PAGE_CHANGE");
export const ResidentsPaginationItemsPerPageChangeAction = createAction<number>(
    "RESIDENTS_PAGINATION_ITEMS_PER_PAGE_CHANGE",
);

export const DownloadResidentsListAction = createAction<DownloadResidentsListPayload>("DOWNLOAD_RESIDENTS_LIST");
export const DownloadResidentsListSuccessAction = createAction<void>("DOWNLOAD_RESIDENTS_LIST_SUCCESS");
export const DownloadResidentsListFailureAction = createAction<void>("DOWNLOAD_RESIDENTS_LIST_FAILURE");

export const InsertResidentsAction = createAction<Resident>("INSERT_RESIDENTS_LOCAL");

export const UploadResidentsAction = createAction<UploadResidentsPayload>("UPLOAD_RESIDENTS");
export const UploadResidentsSuccessAction = createAction<void>("UPLOAD_RESIDENTS_SUCCESS");
export const UploadResidentsFailureAction = createAction<void>("UPLOAD_RESIDENTS_FAILURE");

export const CreateNewResidentPasswordAction =
    createAction<CreateNewResidentPasswordPayload>("CREATE_NEW_RESIDENT_PASSWORD");
export const CreateNewResidentPasswordSuccessAction = createAction<Credentials>("CREATE_NEW_RESIDENT_PASSWORD_SUCCESS");
export const CreateNewResidentPasswordFailureAction = createAction<void>("CREATE_NEW_RESIDENT_PASSWORD_FAILURE");
