import { AnyAction } from "redux";
import { stateWithLoadingFalse, stateWithLoadingTrue } from "../../utils/utils";
import {
    DeleteNoteAction,
    DeleteNoteFailureAction,
    DeleteNoteSuccessAction,
    FetchNotesAction,
    FetchNotesFailureAction,
    FetchNotesSuccessAction,
    InsertNoteAction,
    InsertNoteFailureAction,
    InsertNoteSuccessAction,
    SortNotesAction,
} from "./notes-actions";
import { NotesState } from "./notes-types";

const initialState: NotesState = {
    notes: [],
    loading: 0,
    sortBy: "date",
    ascending: true,
};

const actions = (state: NotesState, action: AnyAction) => {
    if (FetchNotesAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (InsertNoteAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    if (DeleteNoteAction.match(action)) {
        return stateWithLoadingTrue(state);
    }

    return state;
};

const successActions = (state: NotesState, action: AnyAction) => {
    if (FetchNotesSuccessAction.match(action)) {
        action.payload.sort((a, b) => {
            if (a.created < b.created) {
                return -1;
            }
            if (a.created > b.created) {
                return 1;
            }
            return 0;
        });
        return {
            ...stateWithLoadingFalse(state),
            notes: action.payload,
        };
    }

    if (InsertNoteSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteNoteSuccessAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
};

const failureActions = (state: NotesState, action: AnyAction) => {
    if (FetchNotesFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (InsertNoteFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    if (DeleteNoteFailureAction.match(action)) {
        return stateWithLoadingFalse(state);
    }

    return state;
};

const otherActions = (state: NotesState, action: AnyAction) => {
    if (SortNotesAction.match(action)) {
        return {
            ...state,
            sortBy: action.payload.sortBy,
            ascending: action.payload.ascending,
        };
    }

    return state;
};

export default function notesReducer(state: NotesState = initialState, action: AnyAction): NotesState {
    state = actions(state, action);
    state = successActions(state, action);
    state = failureActions(state, action);
    state = otherActions(state, action);

    return state;
}
