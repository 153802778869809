import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../interfaces/RootState";

type AppSelectorHook = <TSelected>(
    selector: (state: RootState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
) => TSelected;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: AppSelectorHook = useSelector;
