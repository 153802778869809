import React from "react";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import i18n from "../i18n";

import fiLocale from "date-fns/locale/fi";
import enLocale from "date-fns/locale/en-GB";
import svLocale from "date-fns/locale/sv";

export default function LocalizationProvider({ children }: { children: React.ReactNode }): JSX.Element {
    const getLocale = (): Locale => {
        switch (i18n.language) {
            case "en":
                return enLocale;
            case "sv":
                return svLocale;
            default:
                return fiLocale;
        }
    };

    return (
        <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
            {children}
        </MuiLocalizationProvider>
    );
}
