import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchApartmentsAction } from "../../redux/apartments/apartments-actions";
import { RootState } from "../../interfaces/RootState";
import { useParams } from "react-router-dom";
import { SortWaterMeterApartmentsAction, SortEnergyMeterApartmentsAction } from "../../redux/reports/reports-actions";
import ReportTable from "./ReportTable";
import { Apartment } from "../../redux/apartments/apartments-types";
import { TopBlock } from "./TopBlock";
import { Report } from "../../redux/reports/reports-types";
import useSortedAndFiltered from "../../utils/sortAndFilter";
import React from "react";

export type TableItem = {
    apartmentId: number;
    value: number;
};

export type Column = {
    titleText: string;
    titleKey: string;
    content: TableItem[];
};

const getReportValue = (apartmentId: number, reports: Report[], key: string) => {
    let report = reports.find((r) => r.apartmentId === apartmentId);
    let n = 0;
    if (report) {
        n = report[key as keyof typeof report] as unknown as number;
    }
    return n;
};

const getSortData = (currentSorting: string, reports: Report[]) => {
    switch (currentSorting) {
        case "code":
            return (apartment: Apartment) => apartment.code;
        case "coldWaterConsumptionM3":
            return (apartment: Apartment) => getReportValue(apartment.id, reports, "coldWaterConsumptionM3");
        case "warmWaterConsumptionM3":
            return (apartment: Apartment) => getReportValue(apartment.id, reports, "warmWaterConsumptionM3");
        case "heatingEnergyConsumptionKwh":
            return (apartment: Apartment) => getReportValue(apartment.id, reports, "heatingEnergyConsumptionKwh");
        case "coolingEnergyConsumptionKwh":
            return (apartment: Apartment) => getReportValue(apartment.id, reports, "coolingEnergyConsumptionKwh");
        case "electricityEnergyConsumptionKwh":
            return (apartment: Apartment) => getReportValue(apartment.id, reports, "electricityEnergyConsumptionKwh");
    }
};

const ConsumptionReport = (): JSX.Element => {
    const t = useTranslation().t;
    const dispatch = useDispatch();
    const params = useParams();
    const companyId = parseInt(params.companyId ?? "0");
    const [waterColumns, setWaterColumns] = React.useState<Column[]>([]);
    const [energyColumns, setEnergyColumns] = React.useState<Column[]>([]);

    const reports = useSelector((state: RootState) => state.hydrolink.reports.reports);
    const apartments = useSelector((state: RootState) => state.hydrolink.apartments.apartments);
    const currentWaterSortBy = useSelector((state: RootState) => state.hydrolink.reports.waterMeterApartmentsSortBy);
    const currentEnergySortBy = useSelector((state: RootState) => state.hydrolink.reports.energyMeterApartmentsSortBy);
    const currentWaterAscending = useSelector(
        (state: RootState) => state.hydrolink.reports.waterMeterApartmentsAscending,
    );
    const currentEnergyAscending = useSelector(
        (state: RootState) => state.hydrolink.reports.energyMeterApartmentsAscending,
    );
    const waterMeterApartmentsSortedAndFiltered = useSortedAndFiltered(
        apartments,
        getSortData(currentWaterSortBy, reports),
        currentWaterAscending,
    );

    const energyMeterApartmentsSortedAndFiltered = useSortedAndFiltered(
        apartments,
        getSortData(currentEnergySortBy, reports),
        currentEnergyAscending,
    );

    useEffect(() => {
        dispatch(FetchApartmentsAction({ companyId: companyId }));
    }, [companyId, dispatch]);

    const sortWaterMeterApartments = (sortBy: string, ascending: boolean = true) => {
        dispatch(
            SortWaterMeterApartmentsAction({
                waterMeterApartmentsSortBy: sortBy,
                waterMeterApartmentsAscending: ascending,
            }),
        );
    };

    const sortEnergyMeterApartments = (sortBy: string, ascending: boolean = true) => {
        dispatch(
            SortEnergyMeterApartmentsAction({
                energyMeterApartmentsSortBy: sortBy,
                energyMeterApartmentsAscending: ascending,
            }),
        );
    };

    const updateColumns = (
        columns: Column[],
        titleText: string,
        titleKey: string,
        apartmentId: number,
        value: number,
    ) => {
        const tableItem: TableItem = { apartmentId: apartmentId, value: value };
        const column = columns.find((c: Column) => c.titleKey === titleKey);
        if (column) {
            column.content.push(tableItem);
        } else {
            columns.push({ titleText, titleKey, content: [tableItem] });
        }
        return columns;
    };

    useEffect(() => {
        let newWaterColumns: Column[] = [];
        let newEnergyColumns: Column[] = [];
        reports.forEach((r) => {
            if (r.coldWaterMeterIds.length > 0) {
                newWaterColumns = updateColumns(
                    newWaterColumns,
                    t("consumptionReporting.cold"),
                    "coldWaterConsumptionM3",
                    r.apartmentId,
                    r.coldWaterConsumptionM3,
                );
            }
            if (r.warmWaterMeterIds.length > 0) {
                newWaterColumns = updateColumns(
                    newWaterColumns,
                    t("consumptionReporting.warm"),
                    "warmWaterConsumptionM3",
                    r.apartmentId,
                    r.warmWaterConsumptionM3,
                );
            }
            if (r.heatingEnergyMeterIds.length > 0) {
                newEnergyColumns = updateColumns(
                    newEnergyColumns,
                    t("consumptionReporting.heating"),
                    "heatingEnergyConsumptionKwh",
                    r.apartmentId,
                    r.heatingEnergyConsumptionKwh,
                );
            }
            if (r.coolingEnergyMeterIds.length > 0) {
                newEnergyColumns = updateColumns(
                    newEnergyColumns,
                    t("consumptionReporting.cooling"),
                    "coolingEnergyConsumptionKwh",
                    r.apartmentId,
                    r.coolingEnergyConsumptionKwh,
                );
            }
            if (r.electricityEnergyMeterIds.length > 0) {
                newEnergyColumns = updateColumns(
                    newEnergyColumns,
                    t("consumptionReporting.electricity"),
                    "electricityEnergyConsumptionKwh",
                    r.apartmentId,
                    r.electricityEnergyConsumptionKwh,
                );
            }
        });
        setWaterColumns(newWaterColumns);
        setEnergyColumns(newEnergyColumns);
    }, [reports, t]);

    return (
        <>
            <TopBlock companyId={companyId} apartments={apartments} />
            <>
                {waterColumns.length > 0 && (
                    <ReportTable
                        sortAction={sortWaterMeterApartments}
                        sortBy={currentWaterSortBy}
                        title={t("consumptionReporting.apartment")}
                        ascending={currentWaterAscending}
                        columns={waterColumns}
                        apartments={waterMeterApartmentsSortedAndFiltered.items}
                        unit={t("meters.units.cubicMeter")}
                    />
                )}
                {energyColumns.length > 0 && (
                    <ReportTable
                        sortAction={sortEnergyMeterApartments}
                        sortBy={currentEnergySortBy}
                        title={`${t("consumptionReporting.apartment")} (${t("consumptionReporting.meter")})`}
                        ascending={currentEnergyAscending}
                        columns={energyColumns}
                        apartments={energyMeterApartmentsSortedAndFiltered.items}
                        unit={t("meters.units.energy")}
                    />
                )}
            </>
        </>
    );
};

export default ConsumptionReport;
