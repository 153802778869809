import React, { useEffect } from "react";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../components/Button";
import { Block } from "../../../components/common/block";
import { alignments, spacings } from "../../../theme";
import { Apartment } from "../../../redux/apartments/apartments-types";
import {
    Credentials,
    Resident,
    ResidentData,
    UpsertResident,
    UpsertResidents,
} from "../../../redux/residents/residents-types";
import { CreateNewResidentPasswordAction, UpsertResidentAction } from "../../../redux/residents/residents-actions";
import { RootState } from "../../../interfaces/RootState";
import ResidentEditRow from "./ResidentEditRow";
import CredentialsDisplayer from "./CredentialsDisplayer";

const emailChanged = (original: string | undefined, state: string) => {
    if (!original) {
        original = "";
    }
    return original !== state && state.trim() !== "";
};

const newResidentValidationCheck = (resident: ResidentEditType) => {
    const email = resident.email;
    const year = resident.moveInMonth.getFullYear();
    const month = resident.moveInMonth.getMonth();
    if (email.trim() !== "") {
        return {
            email: email.trim(),
            dataVisibleFrom: {
                year: year,
                month: month + 1,
            },
        };
    }
    return null;
};

const moveInMonthChanged = (original: { year: number; month: number } | undefined, compare: Date | null) => {
    if (original === undefined) {
        return false;
    }
    return original.year !== compare?.getFullYear() || original.month - 1 !== compare.getMonth();
};

export const credentialsEmpty = (credentials?: { password?: string; username?: string }): boolean => {
    return (
        credentials === undefined ||
        credentials.username === undefined ||
        credentials.username === "" ||
        credentials.password === undefined ||
        credentials.password === ""
    );
};

const makeResident = (resident: ResidentData, newResident: ResidentEditType) => {
    if (resident.id) {
        newResident.id = resident.id;
    }
    if (resident.email) {
        newResident.email = resident.email;
    }
    if (resident.dataVisibleFrom) {
        let date = new Date();
        date.setFullYear(resident.dataVisibleFrom.year, resident.dataVisibleFrom.month - 1);
        newResident.moveInMonth = date;
    }
    return newResident;
};

const credentialsVisible = (credentials: Credentials): boolean => {
    for (const i in credentials) {
        if (!credentialsEmpty(credentials[i])) {
            return true;
        }
    }
    return false;
};

const getDefaultResident = (companyId: number, apartmentId: number) => {
    return {
        id: undefined,
        email: "",
        moveInMonth: new Date(),
        apartmentId: apartmentId,
        companyId: companyId,
        passwordChangeRequested: false,
    };
};

export type ResidentEditType = {
    id: number | undefined;
    email: string;
    moveInMonth: Date;
    apartmentId: number;
    companyId: number;
    passwordChangeRequested: boolean;
};

type Props = {
    apartment: Apartment;
    residents: Resident | null | undefined;
    companyId: number;
};

export default function ResidentEdit({ apartment, residents, companyId }: Props): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    const [resident, setFirstResident] = React.useState<ResidentEditType>(getDefaultResident(companyId, apartment.id));
    const [otherResident, setOtherResident] = React.useState<ResidentEditType>(
        getDefaultResident(companyId, apartment.id),
    );
    const credentials = useSelector((root: RootState) => root.hydrolink.residents.credentials);
    const [credentialsDisplayed, setCredentialsDisplayed] = React.useState<boolean>(false);

    useEffect(() => {
        if (residents?.main) {
            setFirstResident(makeResident(residents.main, getDefaultResident(companyId, apartment.id)));
        }
        if (residents?.other) {
            setOtherResident(makeResident(residents.other, getDefaultResident(companyId, apartment.id)));
        }
    }, [apartment.id, companyId, residents]);

    const anythingChanged = (): boolean =>
        emailChanged(residents?.main?.email, resident.email) ||
        emailChanged(residents?.other?.email, otherResident.email) ||
        moveInMonthChanged(residents?.main?.dataVisibleFrom, resident.moveInMonth) ||
        moveInMonthChanged(residents?.other?.dataVisibleFrom, otherResident.moveInMonth) ||
        resident.passwordChangeRequested ||
        otherResident.passwordChangeRequested;

    const passwordRequest = (id: number) => {
        dispatch(
            CreateNewResidentPasswordAction({
                companyId: companyId,
                residentId: id,
            }),
        );
    };

    const save = () => {
        if (resident.passwordChangeRequested && resident.id) {
            passwordRequest(resident.id);
        }
        if (otherResident.passwordChangeRequested && otherResident.id) {
            passwordRequest(otherResident.id);
        }
        let newMain = newResidentValidationCheck(resident);
        let newOther = newResidentValidationCheck(otherResident);
        if (newMain || newOther) {
            let newResident: UpsertResident = {
                main: newMain,
                other: newOther,
            };
            let newResidents: UpsertResidents = { [apartment.id]: newResident };
            dispatch(
                UpsertResidentAction({
                    companyId: companyId,
                    toUpsertByApartmentId: newResidents,
                }),
            );
        }
    };

    const displayCredentials = (id: number | undefined) => {
        if (id && credentials[id]) {
            if (!credentialsDisplayed) {
                setCredentialsDisplayed(true);
            }
            return true;
        }
        return false;
    };

    const getAlertTitle = () =>
        credentialsVisible(credentials) ? t("residents.credentialsAlert") : t("residents.credentialsSent");

    return (
        <Block noMargin noBottomBorder variant={"white"}>
            <h3>
                {apartment.code} {t("residents.residents")}
            </h3>
            <div>
                <ResidentEditRow resident={resident} title={t("residents.mainEmail")} setResident={setFirstResident}>
                    {displayCredentials(resident.id) ? <CredentialsDisplayer id={resident.id} /> : <></>}
                </ResidentEditRow>
                <ResidentEditRow
                    resident={otherResident}
                    title={t("residents.otherEmail")}
                    setResident={setOtherResident}
                >
                    {displayCredentials(otherResident.id) ? <CredentialsDisplayer id={otherResident.id} /> : <></>}
                </ResidentEditRow>
            </div>
            <div style={{ ...alignments.rightSide, marginTop: spacings.standardMargin }}>
                {credentialsDisplayed ? (
                    <Alert severity="info" style={{ marginRight: spacings.inlineSpacing }}>
                        {getAlertTitle()}
                    </Alert>
                ) : (
                    <></>
                )}
                <Button disabled={!anythingChanged()} variant="contained" onClick={save}>
                    {t("generic.save")}
                </Button>
            </div>
        </Block>
    );
}
