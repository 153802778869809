import BaseState from "../../interfaces/BaseState";

export type FetchUserContractsRequestPayload = {
    userId: number;
    /** If true, do not show loading spinner. */
    background?: boolean;
};
export type FetchUserContractsSuccessPayload = {
    userId: number;
    contracts: UserContracts;
    /** If true, do not decrement loading spinner counter. Value should be copied from {@link FetchUserContractsRequestPayload.background} */
    background: boolean;
};
export type FetchUserContractsFailurePayload = {
    /** If true, do not decrement loading spinner counter. Value should be copied from {@link FetchUserContractsRequestPayload.background} */
    background: boolean;
};

export type Contract = {
    id: number;
    createdAt: string;
    updatedAt: string;
    providerId: string;
    providerName: string;
    providerContractId: string;
    state: ContractState;
};

export enum ContractState {
    UNKNOWN,
    PENDING,
    CANCELLED,
    ACTIVE,
}

export type UserContracts = {
    principalManagerDpaContracts: Contract[];
    companySubscriptionContractsByCompanyId: Record<number, Contract[]>;
    companyDpaContractsByCompanyId: Record<number, Contract[]>;
};

export interface ContractsState extends BaseState {
    userContractsByUserId: Record<number, UserContracts>;
}

export interface CreatePrincipalManagerDpaRequestPayload {
    userId: number;
    managementCompanyBusinessId: string;
    managementCompanyStreetAddress: string;
    managementCompanyZipCode: string;
    managementCompanyTown: string;
}

export interface CreatePrincipalManagerDpaRequestSuccessPayload {
    userId: number;
    created: Contract;
}

export interface CreateCompanySubscriptionRequestPayload {
    userId: number; // used to refresh the user contracts state after creation
    companyId: number;
    housingCompanyBusinessId: string;
    housingCompanyStreetAddress: string;
    housingCompanyZipCode: string;
    housingCompanyTown: string;
    managementCompanyName: string;
    managementCompanyBusinessId: string;
    managementCompanyStreetAddress: string;
    managementCompanyZipCode: string;
    managementCompanyTown: string;
    managerPhone: string;
}

export interface CreateCompanySubscriptionRequestSuccessPayload {
    companyId: number;
    created: Contract;
}

export interface CreateCompanyDpaRequestPayload {
    userId: number; // used to refresh the user contracts state after creation
    companyId: number;
    businessId: string;
    streetAddress: string;
    zipCode: string;
    town: string;
}

export interface CreateCompanyDpaRequestSuccessPayload {
    companyId: number;
    created: Contract;
}

export interface SendInviteeSignRequestPayload {
    contractId: number;
}

export interface SendInviteeSignRequestSuccessPayload {
    contractId: number;
}
