import * as log from "loglevel";
import developmentTrue from "../constants/developmentTrue";

if (developmentTrue) {
    log.setLevel("DEBUG");
} else {
    log.setLevel("ERROR");
}

export default log;
