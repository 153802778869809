import Fuse from "fuse.js";
import { useMemo } from "react";
import { Pagination } from "../components/common/pagination";

function filter<T>(items: T[], search: string | undefined, searchData: ((item: T) => string[]) | undefined) {
    if (search === undefined || searchData === undefined) {
        return items;
    }
    const itemsWithSearchData = items.map((item) => ({
        item,
        searchData: searchData(item),
    }));
    const fuse = new Fuse(itemsWithSearchData, {
        minMatchCharLength: 2,
        ignoreLocation: true,
        threshold: 0.4,
        keys: ["searchData"],
    });

    // We should only do a search if there are 2 or more characters, otherwise just show all items
    return search.length < 2 ? items : fuse.search(search).map((res) => res.item.item);
}

function sort<T>(items: T[], sortData: ((item: T) => SortDataType) | undefined, currentAscending: boolean) {
    const smaller = currentAscending ? -1 : 1;
    const bigger = -smaller;

    const compare = (a: SortDataType, b: SortDataType) => {
        if (a < b) {
            return smaller;
        }
        if (a > b) {
            return bigger;
        }
        return 0;
    };
    if (sortData) {
        const itemsWithSortData = items.map((item) => ({
            item,
            sortData: sortData(item),
        }));

        itemsWithSortData.sort(function (a, b) {
            if (typeof a.sortData === "string" && typeof b.sortData === "string") {
                return compare(a.sortData.toLowerCase(), b.sortData.toLowerCase());
            } else {
                return compare(a.sortData, b.sortData);
            }
        });
        return itemsWithSortData.map((item) => item.item);
    }
    return items;
}

export type SortDataType = string | number | Date;

export default function useSortedAndFiltered<T>(
    items: T[],
    sortData: ((item: T) => SortDataType) | undefined,
    currentAscending: boolean,
    search?: string,
    searchData?: (item: T) => string[],
    pagination?: Pagination,
) {
    const itemsWithoutSortData = useMemo(
        () => sort(items, sortData, currentAscending),
        [items, sortData, currentAscending],
    );

    const filteredItems = useMemo(
        () => filter(itemsWithoutSortData, search, searchData),
        [itemsWithoutSortData, search, searchData],
    );

    const itemCount = filteredItems.length;
    if (pagination) {
        const pagesTotal = Math.ceil(itemCount / pagination.itemsPerPage);
        const maxPage = Math.min(pagesTotal, pagination.currentPage);
        let startIndex = (maxPage - 1) * pagination.itemsPerPage;
        let endIndex = startIndex + pagination.itemsPerPage;
        const paginatedItems = filteredItems.slice(startIndex, endIndex);
        return { items: paginatedItems, itemCount: itemCount };
    }

    return { items: filteredItems, itemCount: itemCount };
}
