import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Client, ClientCommand, QueuedCommand } from "../../redux/centers/centers-types";
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadCell } from "../../components/Table";
import { displayDateTime } from "../../utils/utils";
import { Button } from "../../components/Button";
import { RootState } from "../../interfaces/RootState";
import { SendClientCommandAction, SortCommandsAction } from "../../redux/centers/centers-actions";
import FloatButtonsRight from "../../components/FloatButtonsRight";
import Sorter from "../../components/Sorter";
import useSortedAndFiltered from "../../utils/sortAndFilter";

const getSortFunction = (sortByField: string) => {
    if (sortByField === "createdDate") {
        return (command: QueuedCommand) => command.createdAt;
    }
};

function CommandsTableHead({
    currentSorting,
    currentAscending,
}: {
    currentSorting: string;
    currentAscending: boolean;
}): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    const sortCommandsFunction = (sortBy: string, ascending: boolean) => {
        dispatch(
            SortCommandsAction({
                sortBy: sortBy,
                ascending: ascending,
            }),
        );
    };
    return (
        <TableHead>
            <TableRow>
                <TableHeadCell>{t("centers.command")}</TableHeadCell>
                <Sorter
                    sortKey="createdDate"
                    sortAction={sortCommandsFunction}
                    sortBy={currentSorting}
                    title={t("centers.created")}
                    currentAscending={currentAscending}
                />
                <TableHeadCell>{t("centers.sent")}</TableHeadCell>
                <TableHeadCell>{t("centers.responseReceived")}</TableHeadCell>
            </TableRow>
        </TableHead>
    );
}

function CommandsTableRow({ queuedCommand }: { queuedCommand: QueuedCommand }): JSX.Element {
    const parseDate = (date?: string): string => {
        if (date === null || date === undefined) {
            return "-";
        }

        return displayDateTime(new Date(date));
    };

    return (
        <TableRow key={queuedCommand.id}>
            <TableCell>{queuedCommand.tag}</TableCell>
            <TableCell>{parseDate(queuedCommand.createdAt)}</TableCell>
            <TableCell>{parseDate(queuedCommand.sentAt)}</TableCell>
            <TableCell>{parseDate(queuedCommand.responseReceivedAt)}</TableCell>
        </TableRow>
    );
}

type Props = {
    client: Client;
    clientCommandsInfo: ClientCommand[];
    companyId: number; // Only used for fetching apartments after sendClientCommand
    language: string;
    sortByField: string;
    isAscending: boolean;
};

function CentersInfoView({
    client,
    clientCommandsInfo,
    language,
    companyId,
    sortByField,
    isAscending,
}: Props): JSX.Element {
    const dispatch = useDispatch();
    const location = useLocation();

    const queuedCommands = useMemo(() => client.commandQueue, [client.commandQueue]);
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const [isSortingQueryChanged, setIsSortingQueryChanged] = useState(false);

    const sortedAndFiltered = useSortedAndFiltered(queuedCommands, getSortFunction(sortByField), isAscending);

    useEffect(() => {
        const sortKeyOnUrl = queryParams.get("sortByQuery") ?? "default";
        const orderKeyOnUrl = queryParams.get("orderByQuery") ?? "asc";
        const isQueryChanged = sortKeyOnUrl !== sortByField || orderKeyOnUrl !== (isAscending ? "asc" : "desc");

        setIsSortingQueryChanged(isQueryChanged);
        dispatch(
            SortCommandsAction({
                sortBy: sortKeyOnUrl,
                ascending: orderKeyOnUrl === "asc",
            }),
        );
    }, [isAscending, sortByField, dispatch, isSortingQueryChanged, queryParams]);
    const getTranslatedClientCommandName = (tag: string): string =>
        clientCommandsInfo.find((val) => val.tag === tag)!!.nameByLang[language];

    const sendClientCommand = (tag: string) => {
        dispatch(SendClientCommandAction({ tag, clientId: client.id, companyId }));
    };

    const supportedCommandButtons = () => {
        let list: JSX.Element[] = [];
        for (let sc of client.supportedCommands) {
            list.push(
                <Button onClick={() => sendClientCommand(sc.tag)}>{getTranslatedClientCommandName(sc.tag)}</Button>,
            );
        }
        return list;
    };

    return (
        <>
            <Table>
                <CommandsTableHead currentSorting={sortByField} currentAscending={isAscending} />
                <TableBody>
                    {sortedAndFiltered.items.map((qc) => (
                        <CommandsTableRow queuedCommand={qc} />
                    ))}
                </TableBody>
            </Table>
            <FloatButtonsRight buttons={supportedCommandButtons()} />
        </>
    );
}

export default function CentersInfo({ client }: { client: Client }) {
    const clientCommandsInfo = useSelector((state: RootState) => state.hydrolink.centers.clientCommands);
    const currentSorting = useSelector((state: RootState) => state.hydrolink.centers.sortBy);
    const currentAscending = useSelector((state: RootState) => state.hydrolink.centers.ascending);

    const companyId = parseInt(useParams().companyId ?? "0");
    const language = useTranslation().i18n.language;

    return (
        <CentersInfoView
            client={client}
            clientCommandsInfo={clientCommandsInfo}
            companyId={companyId}
            language={language}
            sortByField={currentSorting}
            isAscending={currentAscending}
        />
    );
}
